import React, {useEffect, useState} from 'react';
import PriceMapRegionList from "../../components/PriceExplorerMap/PriceMapRegionList";

import styles from '../../styles/pages/Agroekonomi.module.scss'
import {TabTitle} from "../../utils/GeneralFunctions";
import MSTGraph from "../../components/MST/MSTGraph/MSTGraph";
import {formatDateToString} from "../../utils/textFormatter";
import {generateAPIURL} from "../../utils/generator";
import {axios6717} from "../../utils/axiosInstance";
import axios from "axios";
import {
    handleNationalMSTExport,
    handleNationalPriceExport,
} from "../../utils/downloadHandler";

function Agroekonomi(props) {
    TabTitle('Sembako - Harga Komoditas')
    const [isRetrieved, setIsRetrieved] = useState(false);

    const [graphData, setGraphData] = useState(); // -- mstURL
    const [nPrice, setNPrice] = useState(); // nPriceURL
    const [dPrice, setDPrice] = useState(); // dPriceURL
    const [comPrices, setComPrices] = useState(); // allPriceURL

    const retrievePriceData = async ()=>{
        const d = new Date();
        d.setDate(d.getDate() - 3);
        const current = formatDateToString(d);

        const d2 = new Date();
        d2.setDate(d2.getDate() - 10);
        const lastWeek = formatDateToString(d2);

        const d4 = new Date();
        d4.setMonth(d4.getMonth() - 1);
        const lastMonth = formatDateToString(d4);

        const mstURL = generateAPIURL('mst', 'national', 'nasional', current);
        const nPriceURL = generateAPIURL('price', 'national', 'nasional', current);
        const dPriceURL = generateAPIURL('price', 'national', 'nasional', lastWeek);
        //const allPriceURL = `/api/price/all/${region}/from=${lastMonth}`;

        const getMST = axios6717.get(mstURL);
        const getNPrice = axios6717.get(nPriceURL);
        const getDPrice = axios6717.get(dPriceURL);
        //const getAllPrice = axios6717.get(allPriceURL );


        axios.all([
            getMST,
            getNPrice,
            getDPrice,
        ]).then(
            axios.spread((...allData)=>{
                setGraphData(allData[0].data);
                setNPrice(allData[1].data);
                setDPrice(allData[2].data);

                setIsRetrieved(true);

            })
        );

    };

    useEffect(() => {
        if(!isRetrieved){
            retrievePriceData()
        }
        // return () => {
        //     effect
        // };
    }, [isRetrieved]);

    if(isRetrieved){
        return (
            <div className={styles.main_container}>
                <div className={styles.agroekonomi_main_title}>
                    <h2>TINJAUAN HARGA KOMODITAS</h2>
                    <div className={styles.agroekonomi_title_caption}>
                        <p>Pilih provinsi untuk melihat tinjauan harga komoditas</p>
                    </div>
                </div>
                <div>
                    <PriceMapRegionList/>
                </div>
                <div className={styles.national_mst_title}>
                    <h3> JARING INTERKORELASI HARGA NASIONAL</h3>
                </div>
                <div className={styles.national_mst_container}>
                    <MSTGraph graphData={graphData.edges} nPrice={nPrice} dPrice={dPrice} level={'national'}></MSTGraph>
                </div>
                <div className={styles.download_button_container}>
                    <div className={styles.download_button} onClick={()=>{handleNationalPriceExport()}}>
                        Unduh Data Harga Nasional dari 1 Januari 2018
                    </div>
                    <div className={styles.download_button} onClick={()=>{handleNationalMSTExport()}}>
                        Unduh Edgelist MST Nasional Hari Ini
                    </div>
                </div>


            </div>
        );
    } else {
        return(
            <div className={styles.main_container}>
                <div className={styles.agroekonomi_main_title}>
                    <h2>TINJAUAN HARGA KOMODITAS</h2>
                    <div className={styles.agroekonomi_title_caption}>
                        <p>Pilih provinsi untuk melihat tinjauan harga komoditas</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Agroekonomi;