import {
    handleMouseOver,
    handleMouseOut,
    handleMouseMove,
} from "../../helpers/handleTooltip";

import styles from '../../styles/components/PriceMapRegion.module.scss'

import * as d3 from "d3";
import {useEffect} from "react";

export default function PriceMapRegion(props) {
    const {path, tooltipData, data} = props;
    //each path defines the shape of a region in the map

    useEffect(() => {
        return () => {
            handleMouseOut();
        };
    }, []);


       const province =  <path
            className={styles.provincepath}
            id={data.properties.VARNAME_1}

            fill={"lightgrey"}
            d={path}
            onMouseOver={() => {
                handleMouseOver(tooltipData.name);
            }}
            onMouseOut={handleMouseOut}
            onMouseMove={(event) => {
                handleMouseMove(event);
            }}
            onClick={() => {
                d3.select(`#${data.properties.VARNAME_1}`)
                    .attr("fill","#036666");

            }}
        />
    return (
        <>{province}</>
    );
}