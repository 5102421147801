import React, {useEffect, useState} from 'react';

import styles from '../../styles/pages/MSTPage.module.scss'
import {TabTitle} from "../../utils/GeneralFunctions";
import {pDropdownDict} from "../../utils/dictionary";
import Dropdown from "../../components/Dropdown/Dropdown";
import {formatDateToString} from "../../utils/textFormatter";
import {generateAPIURL} from "../../utils/generator";
import {axios6717} from "../../utils/axiosInstance";
import axios from "axios";
import MSTInfo from "../../components/MST/MSTInfo/MSTInfo";
import MSTGraph from "../../components/MST/MSTGraph/MSTGraph";
import {Navigate} from "react-router-dom";
function MSTNational(props) {
    TabTitle("Sembako - Jaring Interkorelasi Harga")

    const [selectedProvince, setSelectedProvince] = useState({label:"Sumatera Utara", value: "sumatera_utara"});
    const [data, setData] = useState({ tanggal: "", pDate:"" });

    const [graphData, setGraphData] = useState(); // -- mstURL
    const [nPrice, setNPrice] = useState(); // nPriceURL
    const [dPrice, setDPrice] = useState(); // dPriceURL

    const [isRetrieved, setIsRetrieved] = useState(false);

    const retrievePriceData = async ()=>{
        const d = new Date();
        d.setDate(d.getDate() - 1);
        const current = formatDateToString(d);

        const d2 = new Date();
        d2.setDate(d2.getDate() - 7);
        const lastWeek = formatDateToString(d2);

        const d4 = new Date();
        d4.setMonth(d4.getMonth() - 1);
        const lastMonth = formatDateToString(d4);

        const mstURL = generateAPIURL('mst', 'national', 'nasional', current);
        const nPriceURL = generateAPIURL('price', 'national', 'nasional', current);
        const dPriceURL = generateAPIURL('price', 'national', 'nasional', lastWeek);

        const getMST = axios6717.get(mstURL);
        const getNPrice = axios6717.get(nPriceURL);
        const getDPrice = axios6717.get(dPriceURL);


        axios.all([
            getMST,
            getNPrice,
            getDPrice,
        ]).then(
            axios.spread((...allData)=>{
                setGraphData(allData[0].data);
                setNPrice(allData[1].data);
                setDPrice(allData[2].data);

                setIsRetrieved(true);

            })
        );

    };

    useEffect(() => {
        if(!isRetrieved){
            retrievePriceData()
        }
        // return () => {
        //     effect
        // };
    }, [isRetrieved]);
    const handleDateChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleSelectedParams = ()=>{
        const d = new Date(data.tanggal);

        d.setDate(d.getDate() - 7);
        const prevDate = formatDateToString(d);


        console.log(
            "Pilihan Provinsi " + selectedProvince.value +
            " Pilihan Tanggal " + data.tanggal +
            " Tanggal sebelumnya " + prevDate
    )

        const mstURL = generateAPIURL('mst', 'national', 'nasional', data.tanggal);
        const nPriceURL = generateAPIURL('price', 'national', 'nasional', data.tanggal);
        const dPriceURL = generateAPIURL('price', 'national', 'nasional', prevDate);

        const getMST = axios6717.get(mstURL);
        const getNPrice = axios6717.get(nPriceURL);
        const getDPrice = axios6717.get(dPriceURL);

        axios.all([
            getMST,
            getNPrice,
            getDPrice,
        ]).then(
            axios.spread((...allData)=>{
                setGraphData(allData[0].data);
                setNPrice(allData[1].data);
                setDPrice(allData[2].data);
            })
        );
    }

    if(!isRetrieved){
        return(
            <div className={styles.mst_page_main_container}>
                <div className={styles.sp_main_title}>
                    <h2>Jaring Interkorelasi Harga Nasional</h2>
                    <div className={styles.sp_title_caption}>
                        <p>Pilih daerah untuk melihat jaring interkorelasi harga</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.mst_page_main_container}>
                <div className={styles.sp_main_title}>
                    <h2>Jaring Interkorelasi Harga Nasional</h2>
                    <div className={styles.sp_title_caption}>
                        <p>Pilih tangal untuk melihat jaring interkorelasi harga</p>
                    </div>
                </div>
                <div className={styles.param_selection_field}>

                    <div className={styles.input_tanggal}>
                        <label className={styles.input_label}>Tanggal</label>
                        <input
                            type="date"
                            placeholder="tanggal"
                            name="tanggal"
                            onChange={handleDateChange}
                            value={data.tanggal}
                            required
                            className={styles.date_input_field}
                        />
                    </div>
                    <button type="submit" className={styles.submit_param_button} onClick={handleSelectedParams}>
                        Lihat MST
                    </button>

                </div>

                <div className={styles.mst_wrapper}>
                    <div className={styles.mst_info_container}>
                        <MSTInfo />
                    </div>
                    <div className={styles.mst_graph_container}>
                        <MSTGraph graphData={graphData.edges} nPrice={nPrice} dPrice={dPrice} level={'province'}></MSTGraph>
                    </div>
                </div>
            </div>
        );
    }

}

export default MSTNational;