import React, {useRef} from 'react';
import styles from "../../styles/components/StatsPanel.module.scss";

import {formatUnits, getDateandMonth} from "../../utils/textFormatter";
import {
    getInfoSVG,
    getInterpTitle,
    getInterpValue,
    getTrendStatus,
    interpHandler
} from "../../utils/generator";

function StatsPanel(props) {

    const data = props.data;

    const tempData = data['temperature'];
    const isTempHigh = interpHandler(tempData, 'temperature');
    const interpTempValue = getInterpValue(tempData, 'temperature', isTempHigh);
    const tempIcon = getInfoSVG('temperature', isTempHigh);
    const interpTempTitle = getInterpTitle('temperature', isTempHigh);
    const annualTemp = formatUnits('temperature', tempData['annual_mean']);

    const tempTrendTuple = getTrendStatus('temperature', tempData['status']);
    const tempTrend = tempTrendTuple[0];
    const tempTrendCaption = tempTrendTuple[1];
    const tempCritPoint =getDateandMonth(new Date(tempData['crit_point']));

    const tempFluctStats = {
        mean:tempData['mean'],
        stdev:tempData['stdev'],
        skew:tempData['skew'],
        kurt:tempData['kurt']
    };

    const rhData = data['relative_humidity'];
    const isRHHigh = interpHandler(rhData, 'relative_humidity');
    const rhIcon = getInfoSVG('relative_humidity', isRHHigh);
    const interpRHTitle = getInterpTitle('relative_humidity', isRHHigh);
    const interpRHValue = getInterpValue(rhData, 'relative_humidity', isRHHigh);


    const annualRH = formatUnits('relative_humidity', rhData['annual_mean']);

    const rhTrendTuple = getTrendStatus('relative_humidity', rhData['status']);
    const rhTrend = rhTrendTuple[0];
    const rhTrendCaption = rhTrendTuple[1];
    const rhCritPoint =getDateandMonth(new Date(rhData['crit_point']));

    const rhFluctStats = {
        mean:rhData['mean'],
        stdev:rhData['stdev'],
        skew:rhData['skew'],
        kurt:rhData['kurt']
    };


    //========== Temperature Data ======== //


    //========== Relative Humidity Data ======== //


    const panelRef = useRef();

    function clickRotate() {
        panelRef.current.classList.toggle(`${styles.rotated}`);
    }


    return (
        <div className={styles.weather_stats_panel_container}>
            <div className={styles.weather_stats_panel} ref={panelRef} onClick={clickRotate}>
                <div className={`${styles.panel_contents} ${styles.panel_temperature}`}>
                    <div className={styles.stats_title}>
                        <h2>GAMBARAN TEMPERATUR UDARA</h2>
                    </div>
                    <div className={styles.stats_panel_depth}>
                        <div className={styles.weather_stats_container}>
                            <div className={styles.weather_stats}>
                                <div className={styles.interp_icon_container}>
                                    {tempIcon}
                                </div>
                                <div className={styles.weather_stats_table}>
                                    <div className={styles.weather_stats_info}>
                                        <table className={styles.stats_info_table}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.cell_title}>{interpTempTitle}</td>
                                                    <td className={styles.cell_title}>Suhu Tahunan</td>
                                                </tr>
                                                <tr>
                                                    <td className={(isTempHigh) ? styles.temp_high : styles.temp_low}>{interpTempValue}</td>
                                                    <td className={styles.cell_value}>{annualTemp}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={styles.weather_stats_info}>
                                        <table className={styles.stats_info_table}>
                                            <tbody>
                                            <tr>
                                                <td className={styles.cell_title} colSpan={2}>Tren Suhu:  <span className={(!isTempHigh) ? styles.trend_high : styles.trend_low}> {tempTrend} </span></td>
                                            </tr>
                                            <tr>
                                                <td className={styles.cell_caption} colSpan={2}>{tempTrendCaption}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className={(!isTempHigh) ? styles.date_low : styles.date_high}>{tempCritPoint}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className={styles.divider_line}>

                            </div>
                            <div className={styles.dist_stats_table}>
                                <div className={styles.dist_caption}>
                                    STATISTIK FLUKTUASI TEMPERATUR UDARA
                                </div>
                                <div className={styles.dist_table}>
                                    <table className={styles.dist_table}>
                                        <tbody>
                                        <tr>
                                            <td className={styles.dist_cell_title}>Standar Deviasi</td>
                                            <td className={styles.dist_cell_value}>{tempFluctStats.stdev}</td>
                                        </tr>
                                        <tr>
                                            <td className={styles.dist_cell_title}>Skewness</td>
                                            <td className={styles.dist_cell_value}>{tempFluctStats.skew}</td>
                                        </tr>
                                        <tr>
                                            <td className={styles.dist_cell_title}>Kurtosis</td>
                                            <td className={styles.dist_cell_value}>{tempFluctStats.kurt}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*===========================================================================================*/}

                <div className={`${styles.panel_contents} ${styles.panel_humidity}`}>
                    <div className={styles.stats_title}>
                        <h2>GAMBARAN KELEMBAPAN UDARA</h2>
                    </div>
                    <div className={styles.stats_panel_depth}>
                        <div className={styles.weather_stats_container}>
                            <div className={styles.weather_stats}>
                                <div className={styles.interp_icon_container}>
                                    {rhIcon}
                                </div>
                                <div className={styles.weather_stats_table}>
                                    <div className={styles.weather_stats_info}>
                                        <table className={styles.stats_info_table}>
                                            <tbody>
                                            <tr>
                                                <td className={styles.cell_title}>{interpRHTitle}</td>
                                                <td className={styles.cell_title}>Kelembapan Tahunan</td>
                                            </tr>
                                            <tr>
                                                <td className={(!isRHHigh) ? styles.temp_high : styles.temp_low}>{interpRHValue}</td>
                                                <td className={styles.cell_value}>{annualRH}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={styles.weather_stats_info}>
                                        <table className={styles.stats_info_table}>
                                            <tbody>
                                            <tr>
                                                <td className={styles.cell_title} colSpan={2}>Tren Kelembapan:  <span className={(isRHHigh) ? styles.trend_high : styles.trend_low}> {rhTrend} </span></td>
                                            </tr>
                                            <tr>
                                                <td className={styles.cell_caption} colSpan={2}>{rhTrendCaption}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className={(isRHHigh) ? styles.date_low : styles.date_high}>{rhCritPoint}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className={styles.divider_line}>

                            </div>
                            <div className={styles.dist_stats_table}>
                                <div className={styles.dist_caption}>
                                    STATISTIK FLUKTUASI KELEMBAPAN UDARA
                                </div>
                                <div className={styles.dist_table}>
                                    <table className={styles.dist_table}>
                                        <tbody>
                                        <tr>
                                            <td className={styles.dist_cell_title}>Standar Deviasi</td>
                                            <td className={styles.dist_cell_value}>{rhFluctStats.stdev}</td>
                                        </tr>
                                        <tr>
                                            <td className={styles.dist_cell_title}>Skewness</td>
                                            <td className={styles.dist_cell_value}>{rhFluctStats.skew}</td>
                                        </tr>
                                        <tr>
                                            <td className={styles.dist_cell_title}>Kurtosis</td>
                                            <td className={styles.dist_cell_value}>{rhFluctStats.kurt}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}

export default StatsPanel;