import React, {useEffect, useState} from 'react';
import styles from "../../styles/components/StationMap.module.scss";
import {MapContainer, Marker, TileLayer, Tooltip} from "react-leaflet";
import useSensorDetails from "../../hooks/useSensorDetails";
import useStationData from "../../hooks/useStationData";
import L from 'leaflet';

import {axios6717} from "../../utils/axiosInstance";

import semnetMarker from '../../assets/icons/markers/SemnetMarker.png';
import stationMarker from '../../assets/icons/markers/StationMarker.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const SemnetMarker = L.icon({
    iconUrl: semnetMarker,
    shadowUrl: iconShadow,
    iconSize: new L.Point(50, 50),
});


const StationMarker =  L.icon({
    iconUrl: stationMarker,
    shadowUrl: iconShadow,
    iconSize: new L.Point(50, 50),
});

L.Marker.prototype.options.icon = SemnetMarker;


function StationMap() {

    const {sensorDetails} = useSensorDetails()
    const {setStationData} = useStationData()

    const [showStations, setShowStations] = useState(false);
    const [isStationRetrieved, setIsStationRetrieved] = useState(false);
    const [markersRetrieved, setMarkersRetrieved] = useState(false);
    const [stationsData, setStationsData] = useState({});
    const [stationDetails, setStationDetails] = useState ([]);


    const locationName = sensorDetails.locationName;
    const latitude = sensorDetails.latitude;
    const longitude = sensorDetails.longitude;
    //const nodeID = sensorDetails.nodeID;
    const region = sensorDetails.region;

    const stationDataURL = `/api/station/${region}?lat=${latitude.toString()}&long=${longitude.toString()}`


    useEffect(() => {

       if(!isStationRetrieved) {
            axios6717(stationDataURL).then((d) => {
                setStationsData(d.data)

                setStationDetails([
                    {
                        name: Object.keys(d.data)[0],
                        location: [parseFloat(d.data[Object.keys(d.data)[0]]['latitude']), parseFloat(d.data[Object.keys(d.data)[0]]['longitude'])]
                    },
                    {
                        name: Object.keys(d.data)[1],
                        location: [parseFloat(d.data[Object.keys(d.data)[1]]['latitude']), parseFloat(d.data[Object.keys(d.data)[1]]['longitude'])]
                    },
                    {
                        name: Object.keys(d.data)[2],
                        location: [parseFloat(d.data[Object.keys(d.data)[2]]['latitude']), parseFloat(d.data[Object.keys(d.data)[2]]['longitude'])]
                    }
                ])
            })
            setIsStationRetrieved(true)
        }else{
           setMarkersRetrieved(true)
       }

    },[isStationRetrieved, stationDataURL]);


    
    if(markersRetrieved){
        return (
            <div className={styles.map_container}>
                <MapContainer className={styles.map_canvas} center={[`${latitude}`, `${longitude}`]} zoom={9} scrollWheelZoom={true} style={{width:"inherit",height:"inherit"}}>
                    <TileLayer
                        attribution='Map tiles by Mapbox. Data by OpenStreetMap, under ODbL.'
                        url='https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGFyLWhlbCIsImEiOiJjbDJnYWRieGMwMTlrM2luenIzMzZwbGJ2In0.RQRMAJqClc4qoNwROT8Umg'
                    />

                    {/* Insert sensor marker*/}


                     <Marker
                        position={[latitude, longitude]}
                        eventHandlers={{
                            click: () => {
                                setShowStations((prevState) => !prevState);
                            }
                        }}
                    >
                         <Tooltip direction="bottom" offset={[0, 20]} opacity={1} >
                             <b>{sensorDetails.locationName}</b>
                             <br/>
                             Klik untuk menampilkan stasiun cuaca di sekitar {locationName}
                         </Tooltip>
                        {/*<Popup>*/}
                        {/*    <b>{sensorDetails.locationName}</b>*/}
                        {/*    <br/>*/}
                        {/*    Klik untuk menampilkan stasiun cuaca di sekitar {locationName}*/}
                        {/*</Popup>*/}
                    </Marker>

                    {showStations && (stationDetails.map((d) => {
                            return (
                                <Marker
                                    icon={StationMarker}
                                    key={d["name"]}
                                    position={d["location"]}
                                    eventHandlers={{
                                        click: () => {
                                            setStationData(
                                                {
                                                    ...stationsData[d["name"]],
                                                    name: d["name"],
                                                }
                                            )
                                        }
                                    }}
                                >
                                    <Tooltip>
                                        <b> Stasiun Cuaca {d.name}</b>
                                        <br/>
                                        Klik untuk melihat prakiraan cuaca dari stasiun cuaca {d.name}

                                    </Tooltip>
                                </Marker>
                            )
                        }
                    ))}


                </MapContainer>
            </div>
        );
    } else {
        return <></>
    }

}

export default StationMap;


// setStationDetails([
//     {
//         name :Object.keys(stationsData)[0],
//         location : [parseFloat(stationsData[Object.keys(stationsData)[0]]['latitude']), parseFloat(stationsData[Object.keys(stationsData)[0]]['longitude'])]
//     },
//     {
//         name : Object.keys(stationsData)[1],
//         location : [parseFloat(stationsData[Object.keys(stationsData)[1]]['latitude']), parseFloat(stationsData[Object.keys(stationsData)[1]]['longitude'])]
//     },
//     {
//         name : Object.keys(stationsData)[2],
//         location : [parseFloat(stationsData[Object.keys(stationsData)[2]]['latitude']), parseFloat(stationsData[Object.keys(stationsData)[2]]['longitude'])]
//     }
// ])
// setStationMarkers(
//     stationDetails.map((d) => {
//         return(
//             <Marker
//                 icon={StationMarker}
//                 key={d["name"]}
//                 position={d["location"]}
//                 eventHandlers={{
//                     click: () => {
//                         setStationData(
//                             {
//                                 ...stationsData[d["name"]],
//                                 name: d["name"],
//                             }
//                         )
//                     }
//                 }}
//             >
//                 <Popup>
//                     <b> Stasiun Cuaca {d.name}</b>
//                     <br/>
//                     Jarak stasiun cuaca dengan sensor {stationsData[d["name"]]["distance"]} km
//
//                 </Popup>
//             </Marker>
//         )}
//     )
// )