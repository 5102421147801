import React, {useEffect, useState} from 'react';
import useSensorDetails from "../../hooks/useSensorDetails";
import {axios6717} from "../../utils/axiosInstance";

import styles from '../../styles/components/WeatherCycle.module.scss'
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
    Chart,
    TimeScale, //Import timescale instead of category for X axis
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import Annotation  from 'chartjs-plugin-annotation';
import {cycleOptions, defaultSoilData} from "../../utils/dictionary";
import Dropdown from "../Dropdown/Dropdown";

Chart.register(
    TimeScale, //Register timescale instead of category for X axis
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Annotation,
);

function WeatherCyclePanel() {
    const {sensorDetails} = useSensorDetails();
    const latitude = sensorDetails.latitude;
    const longitude = sensorDetails.longitude;

    const [isRetrieved, setIsRetrieved] = useState(false);
    const [powerCycle, setPowerCycle] = useState({});

    const retrievePowerCycle = () => {
        const powerAllURL = `/api/power/all?lat=${latitude}&long=${longitude}`;

        // fetch(`https://iotsf.bfitech.co.id:6717/api/power/all?lat=${latitude}&long=${longitude}`, {
        //     method: 'GET',
        //     credentials: 'include',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        // })
        //     .then((response) => {
        //         response.json()
        //     })
        //     .then((responseJson) => {
        //         console.log(responseJson);
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });

        axios6717.get(powerAllURL).then((d) => {
            setPowerCycle(d.data)
        }).catch((e) => {
            console.error(e)
        }).finally(()=>{
            setIsRetrieved(true)
        })

        // try{
        //     const res = await axios6717.get(powerAllURL)
        //     setPowerCycle(res.data)
        // }catch (e) {
        //     console.error(e)
        // }finally {
        //     setIsRetrieved(true)
        // }

    }

    // ==================================================== //

    // eslint-disable-next-line
    const [selectedMeasurement, setSelectedMeasurement] = useState({label:'Suhu Udara',value:'temperature'});
    const [chartData, setChartData] = useState({});
    const [isAssigned, setIsAssigned] = useState(false);
    const [annotationValue, setAnnotationValue] = useState(  0);

    useEffect(() => {
        if(!isRetrieved){
           retrievePowerCycle();
        } else {
            const m = selectedMeasurement.value;
            setAnnotationValue(powerCycle[selectedMeasurement.value]['cycle']['mean']);
            const tsData = powerCycle[selectedMeasurement.value]['cycle']['ts'];
            const currentDate = new Date().toISOString().slice(0, 10);
            const now = [{'x': currentDate, 'y': powerCycle[m]['cycle']['now']}];

            const cd = {
                labels: tsData.map(function (d) {
                    return d.x;
                }),
                datasets: [
                    {
                        label: 'Hari ini',
                        data: now,
                        fill: true,
                        tension: 0.1,
                        showTooltips: false ,
                        elements: {
                            point:{
                                radius:4,
                                backgroundColor: '#56AB91'
                            }
                        }
                    },
                    {
                        label: selectedMeasurement['label'],
                        data: tsData.map(function (d) {
                            return d.y;
                        }),
                        fill: false,
                        backgroundColor: '#036666',
                        borderColor: '#036666',
                        borderWidth: 4,
                        tension: 1.5,
                        showTooltips: false,
                        elements: {
                            point:{
                                radius: 0
                            }
                        }
                    },
                ]
            };
            setChartData(cd);
            setIsAssigned(true);
        }
        // eslint-disable-next-line
    }, [powerCycle, isRetrieved, selectedMeasurement]);

    if(!isAssigned){
        return (
            <div className={styles.loading_container}>
                <h2>SIKLUS KOMPONEN CUACA</h2>
            </div>
        )
    }else {
        return (
            <div className={styles.cycle_panel_container}>
                <div className={styles.panel_title}>
                    <h2>SIKLUS KOMPONEN CUACA</h2>
                    <h3 className={styles.panel_caption}>{sensorDetails.locationName.toUpperCase()}</h3>
                </div>
                <div className={styles.line_chart_container}>
                    <Line
                        height={'inherit'}
                        width={'inherit'}
                        data={chartData}
                        options={{
                            responsive:true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        displayFormats: {
                                            month: 'MMM'
                                        },
                                        unit: 'month'
                                    },
                                    ticks: {
                                        color:'#FFFFFF',
                                        maxRotation: 0,
                                        minRotation: 0,
                                    },
                                    grid:{
                                        display : false,
                                        borderWidth:0,
                                    },

                                },
                                y: {
                                    display:true,
                                    ticks: {
                                        color:'#FFFFFF'
                                    },
                                    grid:{
                                        display : false,
                                        borderWidth:0,
                                    },

                                }
                            },

                            plugins: {
                                legend:{
                                    display:false
                                },
                                title:{
                                    display:false,
                                },
                                tooltip:{
                                    display:false,
                                },
                                annotation: {
                                    annotations: {
                                        meanLine: {
                                            type: 'line',
                                            scaleID: 'y',
                                            value: `${annotationValue}`,
                                            borderColor: 'rgba(255,255,255,0.6)',
                                            borderWidth: 2,
                                            label: {
                                                content: 'Mean',
                                                enabled: true
                                            },
                                        }
                                    },
                                }
                            },
                        }}
                    />
                </div>
                <div className={styles.dropdown_menu_container}>
                    <Dropdown
                        dropdownOptions={cycleOptions}
                        selected={selectedMeasurement}
                        onSelectedChange={setSelectedMeasurement}
                    />
                </div>
            </div>

        );
    }
}

export default WeatherCyclePanel;

