
export const retrieveBorderColor = (n, d, com) =>{
	const pc = 100 * (n[com] - d[com]) / d[com];

	let borderColor;

	if (pc < 0){
		borderColor = '#56AB91';
		return borderColor;
	} else if(pc > 0){
		borderColor = '#d34b74';
		return borderColor;
	}
	else if (pc === 0){
		borderColor = '#FFFFFF';
		return borderColor;
	}
};

