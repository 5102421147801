import * as React from 'react';

const SvgDry = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.86 121.53" {...props}>
		<path
			d="M.13 79.39C2.65 42.74 23.3 40.05 37.43 0c14.13 40.05 34.78 42.74 37.3 79.39 3.86 56.19-78.46 56.19-74.59 0Z"
			style={{
				fill: '#5ebae9',
				fillRule: 'evenodd',
			}}
		/>
		<path
			d="M62.12 97.37C48.33 124.94 9.07 111.42 9.07 85.84c0-29.69 34.69-26.79 26.19-2.38-4 11.49 6.75 28.54 26.86 13.91Z"
			style={{
				fill: '#fff',
				fillRule: 'evenodd',
			}}
		/>
	</svg>
);

export default SvgDry;
