import React, {useState} from 'react';
import styles from '../../styles/components/Dropdown.module.scss'
import * as FaIcons from "react-icons/fa";

function Dropdown({dropdownOptions, selected, onSelectedChange}) {
    const [open, setOpen] = useState(false);

    const renderedOptions = dropdownOptions.map(option => {

        if (option.value === selected.value) {
            return null;
        }
        return (
            <div
                key={option.value}
                className={styles.dropdown_item}
                onClick={() => onSelectedChange(option)}
            >
                {option.label}
            </div>

        );
    });

    return(
        <div className={styles.dropdown_container}>
            <div className={styles.selected_area} onClick={() => setOpen(!open) }>
                <table className={styles.selected_table}>
                    <tbody>
                    <tr className={styles.selscted_row}>
                        <td className={styles.selected_label} colSpan={2}> {selected.label===undefined ? `${selected}` : `${selected.label}`}</td>
                        <td className={styles.dropdown_icon}> <FaIcons.FaChevronDown /></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={open ? styles.drop_visible : styles.drop_hidden}>
                <div onClick={() => setOpen(!open)}
                     className={open ? styles.menu_visible : styles.menu_hidden }>{renderedOptions}</div>
            </div>
        </div>
    )
}

export default Dropdown;