

import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home"
import Login from "./pages/Login"
import Register from "./pages/Register";
import {Navbar} from "./components/Navbar/Navbar";
import Agroklimatologi from "./pages/Agroklimatologi";
import Agroekonomi from "./pages/Agroekonomi";
import Dashboard from "./pages/Dashboard";

import RequireAuth from "./components/Auth/RequireAuth";

import PersistLogin from "./components/Auth/PersistLogin";
import useAuth from "./hooks/useAuth";
import ErrorPageNotFound from "./pages/Error404";
import PriceRegionDetails from "./pages/PriceRegionDetails";
import TentangKami from "./pages/TentangKami/TentangKami";
import DashboardMap from "./pages/DashboardMap";
import SoilProfile from "./pages/SoilProfile";
import MSTPage from "./pages/MSTPage";
import MSTPageNational from "./pages/MSTPageNational";




function App() {

  window.addEventListener("orientationchange", function() {
    window.location.reload();
  }, false);

  const {auth} = useAuth()
  const user = auth.user;

  return (
      <BrowserRouter>
        <Navbar isUserExist={user}/>
        <Routes>
            {/*<Route path="/agroklimatologi" exact element={<Dashboard />} />*/}

            <Route exact path="/"  element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register-new-user" element={<Register />} />
            <Route path="tentang-kami" element={<TentangKami />} />



            {/*PROTECTED ROUTES*/}
            <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                    <Route path="/" element={<Navigate replace to="/dashboard" />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="/dashboard" element={<Navigate replace to="/agroklimatologi" />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="/dashboard-map" element={<DashboardMap />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="/agroklimatologi" element={<Dashboard />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="agroklimatologi" exact element={<Dashboard />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="tinjauan-agroklimatologi"  element={<Agroklimatologi />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="profil-tanah"  element={<SoilProfile />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="agroekonomi" exact element={<Agroekonomi />} />
                    <Route path="/agroekonomi/:type"  element={<PriceRegionDetails />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="jaring-interkorelasi-harga"  element={<MSTPage />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="mst-national"  element={<MSTPageNational />} />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route path="tentang"  element={<TentangKami />} />
                </Route>
                {/*<Route element={<RequireAuth />}>*/}
                {/*    <Route path="download"  element={<Download />} />*/}
                {/*</Route>*/}
                <Route path="*" element={<ErrorPageNotFound />} />
            </Route>

            <Route path="*" element={<ErrorPageNotFound />} />

        </Routes>
      </BrowserRouter>

  );
}

export default App;
