import React from 'react';

import styles from '../../styles/pages/SoilProfile.module.scss'
import RadarPanel from "../../components/RadarPanel/RadarPanel";
import {TabTitle} from "../../utils/GeneralFunctions";
function SoilProfile(props) {
    TabTitle("Sembako - Profil Tanah")

    return (
        <div className={styles.soil_profile_main_container}>
            <div className={styles.sp_main_title}>
                <h2>Tinjauan Profil Tanah</h2>
                <div className={styles.sp_title_caption}>
                    <p>Pilih sensor untuk melihat profil tanah</p>
                </div>
            </div>

            <div className={styles.soil_radar_container}>
                <RadarPanel />
            </div>
        </div>
    );
}

export default SoilProfile;