import * as React from 'react';

const BerawanMalam = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="berawanMalam_svg__b"
				cx={148.46}
				cy={93.41}
				fx={148.46}
				fy={93.41}
				r={158.02}
				gradientTransform="matrix(-1 0 0 -.73 296.92 161.64)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="berawanMalam_svg__a"
				cx={894.55}
				cy={-3238.14}
				fx={894.55}
				fy={-3238.14}
				r={19.06}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -4352.21 -5179.72)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="berawanMalam_svg__c"
				cx={893.98}
				cy={-3273.36}
				fx={893.98}
				fy={-3273.36}
				r={14.98}
				xlinkHref="#berawanMalam_svg__a"
			/>
			<radialGradient
				id="berawanMalam_svg__d"
				cx={43.34}
				cy={178.32}
				fx={43.34}
				fy={178.32}
				r={10.96}
				gradientTransform="rotate(-25.71 -703.308 348.658) scale(1 2.91)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M182.93 118.99c-2.16-31.6-28.45-56.56-60.6-56.56s-58.44 24.96-60.59 56.56c-23.95 1.18-42.99 20.97-42.99 45.2s20.26 45.26 45.26 45.26h116.65c25 0 45.26-20.27 45.26-45.26s-19.04-44.02-42.99-45.2Z"
				style={{
					fill: 'url(#berawanMalam_svg__b)',
				}}
			/>
			<path
				d="M122.34 124.61c-17.04-13.83-23.69-34.88-14.86-47.02 8.84-12.15 29.82-10.78 46.86 3.03 17.04 13.82 23.68 34.88 14.86 47.01-8.84 12.15-29.82 10.79-46.86-3.03Z"
				style={{
					fill: 'url(#berawanMalam_svg__a)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M183.26 165.47c-13.4-10.87-18.63-27.41-11.68-36.95 6.95-9.55 23.43-8.47 36.83 2.38 13.39 10.86 18.61 27.41 11.68 36.94-6.95 9.55-23.44 8.48-36.82-2.38Z"
				style={{
					fill: 'url(#berawanMalam_svg__c)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M51.11 166.99c-9.88-15.41-21.36-22.83-25.66-16.58-4.28 6.25.25 23.81 10.12 39.24 9.89 15.42 21.37 22.84 25.66 16.59 4.29-6.27-.24-23.81-10.12-39.24Z"
				style={{
					fill: 'url(#berawanMalam_svg__d)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="m47.85 99.44-4.73 9.59a.52.52 0 0 1-.4.29l-10.59 1.54c-.44.06-.61.6-.3.91l7.66 7.47c.13.12.18.3.15.47l-1.81 10.54c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-10.59-1.54a.544.544 0 0 1-.4-.29l-4.73-9.59c-.2-.4-.76-.4-.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m48.33 101.65 4.22 8.17c.22.43.65.71 1.13.76l9.16.84-9.68.45c-.61.03-1.18-.34-1.39-.92l-3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m58.61 129.04.21 1.21c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l3.55-.52c5.73 10.29 14.52 15.71 22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m64.67 86.5-3.13 6.34a.34.34 0 0 1-.27.19l-6.99 1.02c-.29.04-.41.4-.2.6l5.06 4.93c.08.08.12.2.1.31l-1.19 6.96c-.05.29.25.51.51.37l6.25-3.29c.1-.05.23-.05.33 0l6.25 3.29c.26.14.56-.08.51-.37l-1.19-6.96c-.02-.11.02-.23.1-.31l5.06-4.93c.21-.2.09-.56-.2-.6l-6.99-1.02a.382.382 0 0 1-.27-.19l-3.13-6.34a.352.352 0 0 0-.63 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m64.99 87.96 2.79 5.4c.15.28.43.47.74.5l6.05.55-6.39.3a.932.932 0 0 1-.92-.61l-2.27-6.14Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m71.78 106.05.14.8c.05.29-.25.51-.51.37l-6.25-3.29a.382.382 0 0 0-.33 0l-6.25 3.29a.349.349 0 0 1-.51-.37l1.19-6.96a.358.358 0 0 0-.1-.31l-5.06-4.93c-.21-.2-.09-.56.2-.6l2.34-.34c3.79 6.8 9.59 10.38 15.15 12.35Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m77.47 107.56-4.73 9.59a.52.52 0 0 1-.4.29l-10.59 1.54c-.44.06-.61.6-.3.91l7.66 7.47c.13.12.18.3.15.47l-1.81 10.54c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-10.59-1.54a.544.544 0 0 1-.4-.29l-4.73-9.59c-.2-.4-.76-.4-.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m77.94 109.78 4.22 8.17c.22.43.65.71 1.13.76l9.16.84-9.68.45c-.61.03-1.18-.34-1.39-.92l-3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m88.23 137.17.21 1.21c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l3.55-.52c5.73 10.29 14.52 15.71 22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
		</g>
	</svg>
);

export default BerawanMalam;
