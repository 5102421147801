import React, {useEffect, useState} from 'react';

import styles from '../../styles/components/PriceWidget.module.scss';

import {retrieveBorderColor} from '../../utils/retrieveBorderColor';
import {formatPrice} from '../../utils/textFormatter';
import * as FaIcons from 'react-icons/fa';

import {cDict,pDict, comDict, svgDict} from '../../utils/dictionary';



const createInfo = (lv, reg, p, c)=>{

	if(lv === 'national'){
		return 'Nasional';
	}
	else if(lv === 'province') {
		return `${p[reg]}`;
	} else{
		return `${c[reg]}`;
	}
};

export const PriceWidget = (props)=>{

	const [isRetrieved, setIsRetreived]=useState(false);

	const [percentChange, setPercentChange] = useState(0.0);
	const [priceChange, setPriceChange] = useState(0);
	const [isNeutral, setIsNeutral] = useState(false);
	//const [priceStat, setPriceStat] = useState('')

	const getPercentChanges = (n, d, com) =>{
		return 100 * (n[com] - d[com]) / d[com];
	};

	const getPriceChanges = (n, d, com) =>{
		return (n[com] - d[com]);
	};

	const getPriceStatus = (n, d, com) => {
		const pc = getPriceChanges(n,d,com);

		if (pc < 0){
			return 'Turun';

		} else if(pc > 0){
			return 'Naik';
		}
		else if (pc === 0){
			return 'Harga Tidak Berubah';
		}
	};

	const retrieveIcon = (n, d, com) =>{
		const pc = 100 * (n[com] - d[com]) / d[com];

		if (pc < 0){
			return <FaIcons.FaAngleDoubleDown />;

		} else if(pc > 0){
			return <FaIcons.FaAngleDoubleUp />;
		}
		else if (pc === 0){
			return <FaIcons.FaAngleDoubleRight />;
		}
	};

	useEffect(()=>{
		if(props.nPrice.length !== 0){
			const diff = Math.abs(getPercentChanges(props.nPrice, props.dPrice, props.com));
			setPriceChange(Math.abs(getPriceChanges(props.nPrice, props.dPrice, props.com)));
			setPercentChange(diff);
			if(diff === 0){
				setIsNeutral(true);
			}
			setIsRetreived(true);
		}
	}, // eslint-disable-next-line
    [ props.delta])

	return(
		<div className={styles.widget_container} style={
			{borderColor: `${!isRetrieved ? 'white' : `${retrieveBorderColor(props.nPrice, props.dPrice, props.com)}`}`,
			}}>
			<div className={styles.icon_container}>
				{svgDict[props.com]}
			</div>

			{!isNeutral && (
				<div className={styles.details_container}>
					<div className={styles.price_container} style={{'color':`${retrieveBorderColor(props.nPrice, props.dPrice, props.com)}`}}>
						<h4 style={{alignItems:'center'}}>
							{formatPrice(props.nPrice[props.com])}/<sub>kg</sub>
						</h4>
					</div>


					<div className={styles.change_container}>
						{getPriceStatus(props.nPrice, props.dPrice, props.com)} : {formatPrice(priceChange)} ( {percentChange.toFixed(2)}% )
					</div>

					<div className={styles.area_container}>
						{comDict[props.com]} - {createInfo(props.level, props.region, pDict, cDict)}
					</div>

				</div>
			)}

			{isNeutral && (
				<div className={styles.details_container}>
					<div className={styles.price_container} style={{'color':`${retrieveBorderColor(props.nPrice, props.dPrice, props.com)}`}}>
						<h4 style={{alignItems:'center'}}>
							{formatPrice(props.nPrice[props.com])}/<sub>kg</sub>
						</h4>
					</div>


					<div className={styles.change_container}>
						{getPriceStatus(props.nPrice, props.dPrice, props.com)}
					</div>

					<div className={styles.area_container}>
						{comDict[props.com]} - {createInfo(props.level, props.region, pDict, cDict)}
					</div>

				</div>
			)}





		</div>
	);

};
