import * as React from 'react';

const HujanLebat = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="hujanLebat_svg__e"
				cx={152.93}
				cy={52.87}
				fx={152.93}
				fy={52.87}
				r={159.41}
				gradientTransform="matrix(-1 0 0 -.73 305.85 91.49)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#b5ccd2" />
				<stop offset={0.2} stopColor="#97b6be" />
				<stop offset={1} stopColor="#5d87b5" />
			</radialGradient>
			<radialGradient
				id="hujanLebat_svg__b"
				cx={-1311.46}
				cy={-3319.38}
				fx={-1311.46}
				fy={-3319.38}
				r={19.23}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -6326.33 -2781.96)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanLebat_svg__f"
				cx={-1310.14}
				cy={-3353.25}
				fx={-1310.14}
				fy={-3353.25}
				r={15.11}
				xlinkHref="#hujanLebat_svg__b"
			/>
			<radialGradient
				id="hujanLebat_svg__g"
				cx={46.88}
				cy={138.52}
				fx={46.88}
				fy={138.52}
				r={11.05}
				gradientTransform="rotate(-25.71 -533.16 270.847) scale(1 2.91)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanLebat_svg__c"
				cx={-165.91}
				cy={-902.21}
				fx={-165.91}
				fy={-902.21}
				r={22.23}
				gradientTransform="scale(-1 1) rotate(30 -2162.105 -133.326)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#d0edfb" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="hujanLebat_svg__h"
				cx={-1699.87}
				cy={-2924.7}
				fx={-1699.87}
				fy={-2924.7}
				r={3.48}
				gradientTransform="matrix(1.30678 .58072 .7446 -1.94524 4443.75 -4509.09)"
				xlinkHref="#hujanLebat_svg__b"
			/>
			<radialGradient
				id="hujanLebat_svg__i"
				cx={-18.39}
				cy={-987.38}
				fx={-18.39}
				fy={-987.38}
				r={22.23}
				gradientTransform="rotate(30 -2120.7 21.2)"
				xlinkHref="#hujanLebat_svg__c"
			/>
			<radialGradient
				id="hujanLebat_svg__j"
				cx={-1811.52}
				cy={-2957.94}
				fx={-1811.52}
				fy={-2957.94}
				r={3.48}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -4360.94 -4509.09)"
				xlinkHref="#hujanLebat_svg__b"
			/>
			<radialGradient
				id="hujanLebat_svg__k"
				cx={-144.14}
				cy={-883.52}
				fx={-144.14}
				fy={-883.52}
				r={22.23}
				gradientTransform="scale(-1 1) rotate(30 -2177 -188.915)"
				xlinkHref="#hujanLebat_svg__c"
			/>
			<radialGradient
				id="hujanLebat_svg__l"
				cx={-1699.31}
				cy={-2938.44}
				fx={-1699.31}
				fy={-2938.44}
				r={3.48}
				gradientTransform="matrix(1.30678 .58072 .7446 -1.94524 4473.54 -4509.09)"
				xlinkHref="#hujanLebat_svg__b"
			/>
			<radialGradient
				id="hujanLebat_svg__m"
				cx={-22.42}
				cy={-953.8}
				fx={-22.42}
				fy={-953.8}
				r={22.23}
				gradientTransform="rotate(30 -2120.7 21.2)"
				xlinkHref="#hujanLebat_svg__c"
			/>
			<radialGradient
				id="hujanLebat_svg__n"
				cx={-1791.44}
				cy={-2965.87}
				fx={-1791.44}
				fy={-2965.87}
				r={3.48}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -4360.94 -4509.09)"
				xlinkHref="#hujanLebat_svg__b"
			/>
			<linearGradient
				id="hujanLebat_svg__a"
				x1={83.48}
				y1={232.54}
				x2={109.62}
				y2={150.2}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.16} stopColor="#fee034" />
				<stop offset={1} stopColor="#f47621" />
			</linearGradient>
			<linearGradient
				id="hujanLebat_svg__d"
				x1={139.34}
				y1={232.54}
				x2={165.49}
				y2={150.2}
				xlinkHref="#hujanLebat_svg__a"
			/>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M113.64 181.69h-9.9l16.31-31.71c.7-1.38 1.28-2.96 1.62-4.54 2.1-9.84-4.18-19.52-14.03-21.61-9.84-2.1-19.51 4.18-21.61 14.03l-10.91 51.23-.34 1.57c-.15.73-.23 1.48-.23 2.24 0 6.1 4.95 11.05 11.04 11.05h12.07l-6.51 27.17c-.23.98.12 2.04.99 2.67 1.15.83 2.75.58 3.59-.57l25.94-35.75a9.913 9.913 0 0 0 1.89-5.84c0-5.49-4.45-9.94-9.94-9.94Z"
				style={{
					fill: 'url(#hujanLebat_svg__a)',
				}}
			/>
			<path
				d="M169.5 181.69h-9.9l16.31-31.71c.7-1.38 1.28-2.96 1.62-4.54 2.1-9.84-4.18-19.52-14.02-21.61-9.84-2.1-19.52 4.18-21.61 14.03L131 189.09l-.34 1.57c-.15.73-.23 1.48-.23 2.24 0 6.1 4.95 11.05 11.05 11.05h12.07l-6.51 27.17c-.23.98.12 2.04.98 2.67 1.15.83 2.75.58 3.59-.57l25.94-35.75a9.921 9.921 0 0 0 1.9-5.84c0-5.49-4.45-9.94-9.94-9.94Z"
				style={{
					fill: 'url(#hujanLebat_svg__d)',
				}}
			/>
			<path
				d="M187.7 78.67c-2.18-31.87-28.7-57.06-61.13-57.06S67.62 46.8 65.44 78.67c-24.15 1.19-43.37 21.15-43.37 45.6s20.44 45.66 45.66 45.66h117.68c25.22 0 45.66-20.44 45.66-45.66s-19.22-44.41-43.37-45.6Z"
				style={{
					fill: 'url(#hujanLebat_svg__e)',
				}}
			/>
			<path
				d="M130.87 84.35c-17.19-13.95-23.9-35.17-14.99-47.43 8.92-12.25 30.08-10.88 47.28 3.06 17.18 13.94 23.89 35.19 14.98 47.43-8.92 12.26-30.08 10.88-47.27-3.06Z"
				style={{
					fill: 'url(#hujanLebat_svg__b)',
					mixBlendMode: 'overlay',
					opacity: 0.6,
				}}
			/>
			<path
				d="M188.02 125.57c-13.51-10.96-18.78-27.64-11.78-37.27 7.01-9.63 23.64-8.55 37.15 2.4 13.51 10.96 18.78 27.65 11.78 37.27-7.01 9.63-23.64 8.55-37.15-2.4Z"
				style={{
					fill: 'url(#hujanLebat_svg__f)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M54.72 127.1c-9.97-15.56-21.55-23.04-25.89-16.73-4.32 6.3.25 24.02 10.21 39.58 9.98 15.55 21.56 23.04 25.89 16.73 4.32-6.32-.24-24.02-10.21-39.58Z"
				style={{
					fill: 'url(#hujanLebat_svg__g)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M36.54 201.03c2.55 4.42 8.2 5.93 12.62 3.38s5.93-8.2 3.38-12.62-18.33-13.27-18.33-13.27-.22 18.09 2.33 22.51Z"
				style={{
					fill: 'url(#hujanLebat_svg__c)',
				}}
			/>
			<path
				d="M52.27 199.49c1.43-3.74.56-7.68-1.95-8.79-2.5-1.12-5.7 1.01-7.13 4.76-1.43 3.74-.56 7.68 1.94 8.79 2.51 1.12 5.7-1.01 7.13-4.76Z"
				style={{
					fill: 'url(#hujanLebat_svg__h)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M216.61 201.03c-2.55 4.42-8.2 5.93-12.62 3.38s-5.94-8.2-3.38-12.62c2.55-4.42 18.33-13.27 18.33-13.27s.22 18.09-2.33 22.51Z"
				style={{
					fill: 'url(#hujanLebat_svg__i)',
				}}
			/>
			<path
				d="M200.87 199.49c-1.43-3.74-.56-7.68 1.94-8.79 2.51-1.12 5.7 1.01 7.13 4.76 1.43 3.74.56 7.68-1.94 8.79-2.51 1.12-5.7-1.01-7.13-4.76Z"
				style={{
					fill: 'url(#hujanLebat_svg__j)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M56.81 228.1c2.55 4.42 8.2 5.93 12.62 3.38s5.93-8.2 3.38-12.62-18.33-13.27-18.33-13.27-.22 18.09 2.33 22.51Z"
				style={{
					fill: 'url(#hujanLebat_svg__k)',
				}}
			/>
			<path
				d="M72.55 226.56c1.43-3.74.56-7.68-1.94-8.79-2.51-1.11-5.7 1.01-7.13 4.76-1.43 3.74-.56 7.68 1.94 8.79 2.51 1.12 5.7-1.01 7.14-4.76Z"
				style={{
					fill: 'url(#hujanLebat_svg__l)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M196.33 228.1c-2.55 4.42-8.2 5.93-12.62 3.38s-5.93-8.2-3.38-12.62 18.33-13.27 18.33-13.27.22 18.09-2.33 22.51Z"
				style={{
					fill: 'url(#hujanLebat_svg__m)',
				}}
			/>
			<path
				d="M180.6 226.56c-1.43-3.74-.56-7.68 1.94-8.79 2.51-1.11 5.7 1.01 7.14 4.76 1.43 3.74.56 7.68-1.94 8.79-2.51 1.12-5.7-1.01-7.14-4.76Z"
				style={{
					fill: 'url(#hujanLebat_svg__n)',
					mixBlendMode: 'overlay',
				}}
			/>
		</g>
	</svg>
);

export default HujanLebat;
