import * as React from 'react';

const HujanRinganPagi = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="hujanRinganPagi_svg__e"
				cx={-2860.65}
				cy={67.26}
				fx={-2860.65}
				fy={67.26}
				r={91.8}
				gradientTransform="matrix(-1 0 0 1 -2678.33 0)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff4c4" />
				<stop offset={0.16} stopColor="#fee034" />
				<stop offset={1} stopColor="#f47621" />
			</radialGradient>
			<radialGradient
				id="hujanRinganPagi_svg__a"
				cx={-526.56}
				cy={-3125.78}
				fx={-526.56}
				fy={-3125.78}
				r={13.78}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -5311.09 -3418.75)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanRinganPagi_svg__b"
				cx={-5343.36}
				cy={3169.78}
				fx={-5343.36}
				fy={3169.78}
				r={13.3}
				gradientTransform="matrix(-1.17345 -1.10233 .2198 -.7265 -6820.4 -3449.98)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanRinganPagi_svg__f"
				cx={137.59}
				cy={89.34}
				fx={137.59}
				fy={89.34}
				r={141.43}
				gradientTransform="matrix(-1 0 0 -.73 275.17 154.6)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="hujanRinganPagi_svg__g"
				cx={155.8}
				cy={97.38}
				fx={155.8}
				fy={97.38}
				r={124.22}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fee034" />
				<stop offset={0.08} stopColor="#fccb30" stopOpacity={0.81} />
				<stop offset={0.18} stopColor="#f9b52c" stopOpacity={0.6} />
				<stop offset={0.28} stopColor="#f8a128" stopOpacity={0.41} />
				<stop offset={0.38} stopColor="#f69226" stopOpacity={0.27} />
				<stop offset={0.48} stopColor="#f58523" stopOpacity={0.15} />
				<stop offset={0.58} stopColor="#f47d22" stopOpacity={0.07} />
				<stop offset={0.68} stopColor="#f47721" stopOpacity={0.02} />
				<stop offset={0.77} stopColor="#f47621" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanRinganPagi_svg__h"
				cx={-487.92}
				cy={-3107.72}
				fx={-487.92}
				fy={-3107.72}
				r={17.06}
				xlinkHref="#hujanRinganPagi_svg__a"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__i"
				cx={-488.43}
				cy={-3139.24}
				fx={-488.43}
				fy={-3139.24}
				r={13.41}
				xlinkHref="#hujanRinganPagi_svg__a"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__j"
				cx={43.5}
				cy={165.33}
				fx={43.5}
				fy={165.33}
				r={9.81}
				gradientTransform="rotate(-25.71 -648.785 323.273) scale(1 2.91)"
				xlinkHref="#hujanRinganPagi_svg__b"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__c"
				cx={-62.55}
				cy={-623.22}
				fx={-62.55}
				fy={-623.22}
				r={19.91}
				gradientTransform="rotate(30 -1568.692 21.23)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#d0edfb" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="hujanRinganPagi_svg__k"
				cx={-797.29}
				cy={-2829.28}
				fx={-797.29}
				fy={-2829.28}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3089.35 -4828.91)"
				xlinkHref="#hujanRinganPagi_svg__a"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__l"
				cx={-32.58}
				cy={-640.53}
				fx={-32.58}
				fy={-640.53}
				r={19.91}
				xlinkHref="#hujanRinganPagi_svg__c"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__m"
				cx={-819.98}
				cy={-2836.03}
				fx={-819.98}
				fy={-2836.03}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3089.35 -4828.91)"
				xlinkHref="#hujanRinganPagi_svg__a"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__n"
				cx={-2.6}
				cy={-657.83}
				fx={-2.6}
				fy={-657.83}
				r={19.91}
				xlinkHref="#hujanRinganPagi_svg__c"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__o"
				cx={-842.66}
				cy={-2842.79}
				fx={-842.66}
				fy={-2842.79}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3089.35 -4828.91)"
				xlinkHref="#hujanRinganPagi_svg__a"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__p"
				cx={27.37}
				cy={-675.14}
				fx={27.37}
				fy={-675.14}
				r={19.91}
				xlinkHref="#hujanRinganPagi_svg__c"
			/>
			<radialGradient
				id="hujanRinganPagi_svg__q"
				cx={-865.35}
				cy={-2849.54}
				fx={-865.35}
				fy={-2849.54}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3089.35 -4828.91)"
				xlinkHref="#hujanRinganPagi_svg__a"
			/>
			<linearGradient
				id="hujanRinganPagi_svg__d"
				x1={230.79}
				y1={31.06}
				x2={116.05}
				y2={159.28}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fdb925" />
				<stop offset={1} stopColor="#f15822" />
			</linearGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				style={{
					fill: 'url(#hujanRinganPagi_svg__d)',
				}}
				d="m222.35 138.27-14.31.94-2.69 14.08-13.76-4.01-7.35 12.31-11.56-8.48-11.11 9.05-7.97-11.91-13.53 4.7-3.41-13.92-14.34-.21 1.56-14.25-13.39-5.09 6.33-12.86-10.84-9.37 10.35-9.92-6.98-12.51 13.12-5.78-2.29-14.15 14.3-.93 2.7-14.08 13.76 4 7.35-12.3 11.56 8.47L180.96 33l7.97 11.91 13.53-4.7 3.41 13.92 14.34.21-1.56 14.25 13.39 5.1-6.33 12.85 10.84 9.38-10.35 9.91 6.98 12.51-13.12 5.78 2.29 14.15z"
			/>
			<path
				d="M122.91 97.58c0 26.71 21.65 48.36 48.35 48.36s48.36-21.65 48.36-48.36-21.65-48.35-48.36-48.35-48.35 21.65-48.35 48.35Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__e)',
				}}
			/>
			<path
				d="M178.63 92.61c-12.31-9.99-17.13-25.2-10.74-33.98 6.39-8.78 21.55-7.79 33.87 2.19 12.31 9.99 17.12 25.21 10.74 33.98-6.39 8.79-21.55 7.8-33.87-2.19Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__a)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M128.53 108.27c1.63-5.35 9.92-3.13 18.53 4.95 8.6 8.08 14.25 18.97 12.63 24.32-1.63 5.35-9.92 3.13-18.53-4.95-8.6-8.08-14.26-18.98-12.63-24.32Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__b)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M168.44 112.23c-1.93-28.28-25.47-50.62-54.23-50.62s-52.3 22.34-54.23 50.62C38.55 113.29 21.5 131 21.5 152.69s18.14 40.51 40.51 40.51h104.41c22.37 0 40.51-18.14 40.51-40.51s-17.05-39.4-38.48-40.46Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__f)',
				}}
			/>
			<path
				d="M168.44 112.23c-1.93-28.28-25.47-50.62-54.23-50.62s-52.3 22.34-54.23 50.62C38.55 113.29 21.5 131 21.5 152.69s18.14 40.51 40.51 40.51h104.41c22.37 0 40.51-18.14 40.51-40.51s-17.05-39.4-38.48-40.46Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__g)',
				}}
			/>
			<path
				d="M114.21 117.27C98.96 104.9 93 86.06 100.91 75.19c7.91-10.87 26.69-9.65 41.94 2.71 15.25 12.37 21.2 31.21 13.29 42.08-7.91 10.88-26.69 9.65-41.94-2.71Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__h)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M168.73 153.83c-11.99-9.72-16.67-24.53-10.45-33.07 6.22-8.54 20.97-7.58 32.96 2.13 11.98 9.72 16.66 24.53 10.45 33.07-6.22 8.55-20.98 7.59-32.96-2.13Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__i)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M50.46 155.19c-8.84-13.8-19.12-20.44-22.96-14.84-3.83 5.59.22 21.31 9.06 35.12 8.85 13.8 19.13 20.44 22.96 14.85 3.84-5.61-.22-21.32-9.06-35.12Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__j)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M68.97 218.09c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__c)',
				}}
			/>
			<path
				d="M54.87 216.72c-1.28-3.35-.51-6.88 1.74-7.88s5.11.91 6.39 4.27c1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__k)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M103.58 218.09c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__l)',
				}}
			/>
			<path
				d="M89.48 216.72c-1.28-3.35-.5-6.88 1.74-7.88 2.25-1 5.11.91 6.39 4.27 1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__m)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M138.19 218.09c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__n)',
				}}
			/>
			<path
				d="M124.1 216.72c-1.28-3.35-.51-6.88 1.74-7.88s5.11.91 6.39 4.27c1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__o)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M172.8 218.09c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__p)',
				}}
			/>
			<path
				d="M158.71 216.72c-1.28-3.35-.5-6.88 1.74-7.88 2.25-1 5.11.91 6.39 4.27 1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganPagi_svg__q)',
					mixBlendMode: 'overlay',
				}}
			/>
		</g>
	</svg>
);

export default HujanRinganPagi;
