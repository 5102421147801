import * as React from 'react';

const HujanRinganMalam = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="hujanRinganMalam_svg__d"
				cx={-2853.91}
				cy={56.94}
				fx={-2853.91}
				fy={56.94}
				r={116.3}
				gradientTransform="matrix(-1 0 0 1 -2678.33 0)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#f8f8f8" />
				<stop offset={0.16} stopColor="#ede8f0" />
				<stop offset={1} stopColor="#9a9ca1" />
			</radialGradient>
			<radialGradient
				id="hujanRinganMalam_svg__a"
				cx={-338.8}
				cy={-3568.7}
				fx={-338.8}
				fy={-3568.7}
				r={17.46}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -5875.06 -4224.54)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanRinganMalam_svg__b"
				cx={-5403.5}
				cy={2427.6}
				fx={-5403.5}
				fy={2427.6}
				r={16.85}
				gradientTransform="matrix(-1.17345 -1.10233 .2198 -.7265 -6743.05 -4052.18)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanRinganMalam_svg__e"
				cx={139.51}
				cy={81}
				fx={139.51}
				fy={81}
				r={141.43}
				gradientTransform="matrix(-1 0 0 -.73 279.03 140.18)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="hujanRinganMalam_svg__f"
				cx={-303.34}
				cy={-3553.04}
				fx={-303.34}
				fy={-3553.04}
				r={17.06}
				xlinkHref="#hujanRinganMalam_svg__a"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__g"
				cx={-303.86}
				cy={-3584.56}
				fx={-303.86}
				fy={-3584.56}
				r={13.41}
				xlinkHref="#hujanRinganMalam_svg__a"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__h"
				cx={45.43}
				cy={157}
				fx={45.43}
				fy={157}
				r={9.81}
				gradientTransform="rotate(-25.71 -611.94 306.97) scale(1 2.91)"
				xlinkHref="#hujanRinganMalam_svg__b"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__c"
				cx={109.18}
				cy={-678.08}
				fx={109.18}
				fy={-678.08}
				r={19.91}
				gradientTransform="rotate(30 -1568.677 -327.214)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#d0edfb" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="hujanRinganMalam_svg__i"
				cx={-713.16}
				cy={-3327.43}
				fx={-713.16}
				fy={-3327.43}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3349.07 -5855.4)"
				xlinkHref="#hujanRinganMalam_svg__a"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__j"
				cx={139.15}
				cy={-695.38}
				fx={139.15}
				fy={-695.38}
				r={19.91}
				xlinkHref="#hujanRinganMalam_svg__c"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__k"
				cx={-735.85}
				cy={-3334.19}
				fx={-735.85}
				fy={-3334.19}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3349.07 -5855.4)"
				xlinkHref="#hujanRinganMalam_svg__a"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__l"
				cx={169.13}
				cy={-712.69}
				fx={169.13}
				fy={-712.69}
				r={19.91}
				xlinkHref="#hujanRinganMalam_svg__c"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__m"
				cx={-758.53}
				cy={-3340.94}
				fx={-758.53}
				fy={-3340.94}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3349.07 -5855.4)"
				xlinkHref="#hujanRinganMalam_svg__a"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__n"
				cx={199.1}
				cy={-730}
				fx={199.1}
				fy={-730}
				r={19.91}
				xlinkHref="#hujanRinganMalam_svg__c"
			/>
			<radialGradient
				id="hujanRinganMalam_svg__o"
				cx={-781.22}
				cy={-3347.7}
				fx={-781.22}
				fy={-3347.7}
				r={3.12}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3349.07 -5855.4)"
				xlinkHref="#hujanRinganMalam_svg__a"
			/>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M100.3 95.35c0 33.84 27.43 61.26 61.27 61.26s61.27-27.43 61.27-61.26-27.43-61.27-61.27-61.27-61.27 27.43-61.27 61.27Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__d)',
				}}
			/>
			<path
				d="M118.76 106.19c5.76-.52 9.87-7.05 9.19-14.59-.67-7.54-5.89-13.23-11.65-12.71-5.75.52-9.87 7.05-9.19 14.58.68 7.54 5.89 13.23 11.65 12.71ZM113.77 111.67c-3.45 1.81-4.32 6.94-1.95 11.46 2.37 4.52 7.09 6.71 10.54 4.9 3.44-1.81 4.32-6.94 1.95-11.45-2.37-4.52-7.09-6.71-10.53-4.9ZM127.43 106.23c-2.04 1.07-2.55 4.1-1.15 6.77s4.19 3.96 6.23 2.89c2.03-1.07 2.55-4.1 1.15-6.77s-4.19-3.96-6.23-2.89ZM218.16 97.57c-1.06-7.49-6.55-12.91-12.28-12.11-5.72.81-9.5 7.54-8.44 15.03 1.05 7.49 6.55 12.91 12.28 12.1 5.72-.81 9.51-7.54 8.45-15.03ZM210.59 79.73c3.35-1.99 3.96-7.15 1.36-11.54-2.59-4.39-7.41-6.34-10.77-4.36-3.35 1.98-3.96 7.15-1.36 11.54 2.59 4.39 7.41 6.34 10.77 4.36ZM198.02 79.04c-1.53-2.59-4.38-3.74-6.36-2.57-1.98 1.17-2.34 4.22-.81 6.82 1.53 2.6 4.38 3.74 6.36 2.57 1.98-1.17 2.34-4.22.81-6.82Z"
				style={{
					fill: '#adadb2',
				}}
			/>
			<path
				d="M170.91 89.05c-15.6-12.67-21.7-31.93-13.61-43.05 8.09-11.12 27.3-9.88 42.92 2.77 15.6 12.66 21.68 31.94 13.6 43.05-8.1 11.14-27.31 9.88-42.91-2.77Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__a)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M107.42 108.89c2.07-6.78 12.57-3.97 23.48 6.28 10.9 10.24 18.06 24.03 16 30.81-2.06 6.77-12.57 3.97-23.48-6.27-10.9-10.24-18.06-24.05-16-30.82Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__b)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M170.37 103.89c-1.93-28.28-25.47-50.62-54.23-50.62s-52.3 22.34-54.23 50.62c-21.43 1.06-38.48 18.77-38.48 40.46s18.14 40.51 40.51 40.51h104.41c22.37 0 40.51-18.14 40.51-40.51s-17.05-39.4-38.48-40.46Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__e)',
				}}
			/>
			<path
				d="M170.37 103.89c-1.93-28.28-25.47-50.62-54.23-50.62s-52.3 22.34-54.23 50.62c-21.43 1.06-38.48 18.77-38.48 40.46s18.14 40.51 40.51 40.51h104.41c22.37 0 40.51-18.14 40.51-40.51s-17.05-39.4-38.48-40.46Z"
				style={{
					fill: '#9cbdd8',
				}}
			/>
			<path
				d="M116.14 108.93c-15.25-12.37-21.21-31.21-13.3-42.08 7.91-10.87 26.69-9.65 41.94 2.71 15.25 12.37 21.2 31.21 13.29 42.08-7.91 10.88-26.69 9.65-41.94-2.71Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__f)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M170.65 145.5c-11.99-9.72-16.67-24.53-10.45-33.07 6.22-8.54 20.97-7.58 32.96 2.13 11.98 9.72 16.66 24.53 10.45 33.07-6.22 8.55-20.98 7.59-32.96-2.13Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__g)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M52.38 146.86c-8.84-13.8-19.12-20.44-22.96-14.84-3.83 5.59.22 21.31 9.06 35.12 8.85 13.8 19.13 20.44 22.96 14.85 3.84-5.61-.22-21.32-9.06-35.12Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__h)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M70.89 209.76c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__c)',
				}}
			/>
			<path
				d="M56.8 208.38c-1.28-3.35-.51-6.88 1.74-7.88s5.11.91 6.39 4.27c1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__i)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M105.51 209.76c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__j)',
				}}
			/>
			<path
				d="M91.41 208.38c-1.28-3.35-.5-6.88 1.74-7.88 2.25-1 5.11.91 6.39 4.27 1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__k)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M140.12 209.76c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__l)',
				}}
			/>
			<path
				d="M126.02 208.38c-1.28-3.35-.51-6.88 1.74-7.88s5.11.91 6.39 4.27c1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__m)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M174.73 209.76c-2.29 3.96-7.35 5.32-11.31 3.03-3.96-2.28-5.31-7.34-3.03-11.3 2.29-3.96 16.42-11.89 16.42-11.89s.2 16.21-2.08 20.17Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__n)',
				}}
			/>
			<path
				d="M160.63 208.38c-1.28-3.35-.5-6.88 1.74-7.88 2.25-1 5.11.91 6.39 4.27 1.28 3.35.5 6.88-1.74 7.88-2.25 1-5.11-.91-6.39-4.26Z"
				style={{
					fill: 'url(#hujanRinganMalam_svg__o)',
					mixBlendMode: 'overlay',
				}}
			/>
		</g>
	</svg>
);

export default HujanRinganMalam;
