import useAuth from "./useAuth";
import {axios8081} from "../utils/axiosInstance";


const useLogout = () => {
    const { setAuth } = useAuth()

    const logout = async () => {
        setAuth({})

        try{
            await axios8081.post('/api/logout')
        }catch (e) {
         console.error(e)
        }
    }

    return logout;
}


export default useLogout;