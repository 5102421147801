import {axios6717} from "./axiosInstance";
import {formatDateToString} from "./textFormatter";

export const handleSoilExport = async(node, name) => {
    const resSoil = await axios6717.get(`/api/${node}/all/from=2018-08-08`)
    const res = resSoil.data;

    const CSV = Object.keys(res).map((i) => res[i].map((r) => [i,r.x, r.y].join(',')).join('\r\n')).join('\r\n')

    const h = document.createElement('a');

    h.setAttribute('href', window.URL.createObjectURL(new Blob([CSV], {type: 'csv'})));
    h.setAttribute('download', `Data Sensor ${name}.csv`);
    h.click();
    h.remove();
}

export const handlePriceExport = async(region, name) => {
    const price_res = await axios6717.get(`/api/price/all/${region}/from=2018-01-01`)
    const res = price_res.data;

    const PRICE_CSV = Object.keys(res).map((i) => res[i].map((r) => [i,r.x, r.y].join(',')).join('\r\n')).join('\r\n')

    const h = document.createElement('a');

    h.setAttribute('href', window.URL.createObjectURL(new Blob([PRICE_CSV], {type: 'csv'})));
    h.setAttribute('download', `Data Harga ${name}.csv`);
    h.click();
    h.remove();
}

export const handleNationalPriceExport = async() => {
    const price_res = await axios6717.get(`/api/price/all/national/from=2018-01-01`)
    const res = price_res.data;

    const PRICE_CSV = Object.keys(res).map((i) => res[i].map((r) => [i,r.x, r.y].join(',')).join('\r\n')).join('\r\n')

    const h = document.createElement('a');

    h.setAttribute('href', window.URL.createObjectURL(new Blob([PRICE_CSV], {type: 'csv'})));
    h.setAttribute('download', `Data Harga Nasional.csv`);
    h.click();
    h.remove();
}

export const handleMSTExport = async(region, name) => {
    const d4 = new Date();
    d4.setDate(d4.getDate() - 1);
    const dateAPI = formatDateToString(d4);

    const resMST = await axios6717.get(`/api/mst/province/${region}/date=${dateAPI}`)
    const res = resMST.data;

    const MST_CSV = res.edges.map((i) => [ i.from, i.to, i.value].join(',')).join('\r\n')

    const h = document.createElement('a');

    h.setAttribute('href', window.URL.createObjectURL(new Blob([MST_CSV], {type: 'csv'})));
    h.setAttribute('download', `Edgelist ${name}.csv`);
    h.click();
    h.remove();
}

export const handleNationalMSTExport = async() => {
    const d4 = new Date();
    d4.setDate(d4.getDate() - 1);
    const dateAPI = formatDateToString(d4);


    const resMST = await axios6717.get(`/api/mst/national/date=${dateAPI}`)
    const res = resMST.data;

    const MST_CSV = res.edges.map((i) => [ i.from, i.to, i.value].join(',')).join('\r\n')

    const h = document.createElement('a');

    h.setAttribute('href', window.URL.createObjectURL(new Blob([MST_CSV], {type: 'csv'})));
    h.setAttribute('download', 'National MST Edgelist.csv');
    h.click();
    h.remove();
}

// https://iotsf.bfitech.co.id:6717/api/mst/national/date=2022-10-11