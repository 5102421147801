import * as React from "react";

const SvgSemnetLogo = props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 898.06 916.51" {...props}><path d="M602.3 659.87c39.38 10.84 63.51 42.15 68.37 88.48.78 7.4 2.47 14.7 3.16 22.1.32 3.45.6 9.88-5.62 6.23-22.64-13.29-47.86-22.79-63.85-45.85-14.66-21.13-17.52-44.45-16.1-68.97.3-5.11 2.18-5.26 14.04-1.99ZM550.24 498.3c-7.25 3.54-7.23 5.83-4.97 9.11 21.89 31.73 67.35 46.39 103.55 33.62 10.63-3.75 37.86-13.1 37.86-13.1s-47.06-41.25-78.94-43.56c-22.36-1.62-42.02 5.2-57.5 13.93ZM747.34 577.13c-38.25-29.77-76.11-30.28-115.19-7.35-6.36 3.73-5.53 7.74-.95 12.29 33.41 33.19 69.6 40.37 113.35 22.69 6.25-2.53 24.28-10.39 24.28-10.39s-16.78-14.2-21.49-17.24ZM791.64 637.83c-36.64-17.21-68.13-12.28-98.19 7.58-4.22 2.79-4.06 5.7-.6 9.45 29.74 32.23 65.79 40.72 107.29 25.17 8.9-3.33 31.86-13.61 31.86-13.61s-26.96-22.78-40.36-28.59Z" style={{
    fill: "#56ab91"
  }} /><path d="M568.42 647.01c-9.17 38.95-11.67 69.17-12.17 105.29-.46 33.4 20.43 110.81 21.01 113.18-1.82-1.31-24.87-41.92-29.4-64.25-6.91-34.07-10.82-68.04-5.38-102.52 5.85-37.11 15.82-72.81 28.53-107.93 1.76-4.87.65-8.23-3.36-11.41-27.38-21.72-54.04-43.4-78.33-68.78-10.14-10.6-30.4-37.41-28.43-39.16.22-.2 55.22 45.67 82.58 65.18 30.49 22.28 57.21 45.02 84.74 72.05 6.92 6.79 13.18 14.25 18.64 22.27 17.44 25.64 31.26 48.97 44.01 76.85.43.93 8.42 18.59 4.51 25.7-5.48-4.18-28.06-47.18-46.47-76.99-23.72-34.24-36.42-44.24-44.97-51.99-18.52-16.8-20.37-5.84-22.52 1.04-4.83 15.48-9.29 29.76-12.99 41.47Z" style={{
    fill: "#56ab91"
  }} /><path d="M539.78 594.35c8.04.69 8.84 2.83 7.92 6.7-8.98 37.49-46.1 67.55-84.47 68.69-11.27.34-40.04 1.43-40.04 1.43s29.03-55.44 57.93-69.09c20.27-9.57 41.07-10.29 58.66-7.73ZM588.23 775.48c39.38 10.84 63.51 42.15 68.37 88.48.78 7.4 2.47 14.7 3.16 22.1.32 3.45.6 9.88-5.62 6.23-22.64-13.29-47.86-22.79-63.85-45.85-14.66-21.13-17.52-44.45-16.1-68.97.3-5.11 2.18-5.26 14.04-1.99ZM459.3 867.08c8.59-39.55 31.64-61.57 65.69-73.37 4.77-1.66 6.99.23 7.87 5.26 7.52 43.2-7.64 76.99-45.2 100.5-8.05 5.04-30.17 17.04-30.17 17.04s-1.71-35.25 1.82-49.43ZM443.19 765.6c6.94-47.97 33.88-74.58 77.94-85.13 7.17-1.72 9.35 1.75 9.2 8.2-1.08 47.08-22.19 77.34-65.99 94.91-6.26 2.51-24.7 9.34-24.7 9.34s2.26-21.87 3.55-27.32ZM256.64 607.14c-10.84 39.38-42.15 63.51-88.48 68.37-7.4.78-14.7 2.47-22.1 3.16-3.45.32-9.88.6-6.23-5.62 13.29-22.64 22.79-47.86 45.85-63.85 21.13-14.66 44.45-17.52 68.97-16.1 5.11.3 5.26 2.18 1.99 14.04ZM418.21 555.08c-3.54-7.25-5.83-7.23-9.11-4.97-31.73 21.89-46.39 67.35-33.62 103.55 3.75 10.63 13.1 37.86 13.1 37.86s41.25-47.06 43.56-78.94c1.62-22.36-5.2-42.02-13.93-57.5ZM339.38 752.18c29.77-38.25 30.28-76.11 7.35-115.19-3.73-6.36-7.74-5.53-12.29-.95-33.19 33.41-40.37 69.6-22.69 113.35 2.53 6.25 10.39 24.28 10.39 24.28s14.2-16.78 17.24-21.49ZM278.67 796.48c17.21-36.64 12.28-68.13-7.58-98.19-2.79-4.22-5.7-4.06-9.45-.6-32.23 29.74-40.72 65.79-25.17 107.29 3.33 8.9 13.61 31.86 13.61 31.86s22.78-26.96 28.59-40.36Z" style={{
    fill: "#56ab91"
  }} /><path d="M269.5 573.26c-38.95-9.17-69.17-11.67-105.29-12.17-33.4-.46-110.81 20.43-113.18 21.01 1.31-1.82 41.92-24.87 64.25-29.4 34.07-6.91 68.04-10.82 102.52-5.38 37.11 5.85 72.81 15.82 107.93 28.53 4.87 1.76 8.23.65 11.41-3.36 21.72-27.38 43.4-54.04 68.78-78.33 10.6-10.14 37.41-30.4 39.16-28.43.2.22-45.67 55.22-65.18 82.58-22.28 30.49-45.02 57.21-72.05 84.74a147.622 147.622 0 0 1-22.27 18.64c-25.64 17.44-48.97 31.26-76.85 44.01-.93.43-18.59 8.42-25.7 4.51 4.18-5.48 47.18-28.06 76.99-46.47 34.24-23.72 44.56-36.15 51.99-44.97 13.74-16.32 15.32-15.95-4.03-25.04-15.44-7.25-26.58-8.5-38.48-10.47Z" style={{
    fill: "#56ab91"
  }} /><path d="M322.16 544.62c-.69 8.04-2.83 8.84-6.7 7.92-37.49-8.98-67.55-46.1-68.69-84.47-.34-11.27-1.43-40.04-1.43-40.04s55.44 29.03 69.09 57.93c9.57 20.27 10.29 41.07 7.73 58.66ZM141.03 593.07c-10.84 39.38-42.15 63.51-88.48 68.37-7.4.78-14.7 2.47-22.1 3.16-3.45.32-9.88.6-6.23-5.62 13.29-22.64 22.79-47.86 45.85-63.85 21.13-14.66 44.45-17.52 68.97-16.1 5.11.3 5.26 2.18 1.99 14.04ZM49.43 464.14c39.55 8.59 61.57 31.64 73.37 65.69 1.66 4.77-.23 6.99-5.26 7.87-43.2 7.52-76.99-7.64-100.5-45.2C12 484.45 0 462.33 0 462.33s35.25-1.71 49.43 1.82ZM150.91 448.03c47.97 6.94 74.58 33.88 85.13 77.94 1.72 7.17-1.75 9.35-8.2 9.2-47.08-1.08-77.34-22.19-94.91-65.99-2.51-6.26-9.34-24.7-9.34-24.7s21.87 2.26 27.32 3.55ZM290.65 256.64c-39.38-10.84-63.51-42.15-68.37-88.48-.78-7.4-2.47-14.7-3.16-22.1-.32-3.45-.6-9.88 5.62-6.23 22.64 13.29 47.86 22.79 63.85 45.85 14.66 21.13 17.52 44.45 16.1 68.97-.3 5.11-2.18 5.26-14.04 1.99ZM342.7 418.21c7.25-3.54 7.23-5.83 4.97-9.11-21.89-31.73-67.35-46.39-103.55-33.62-10.63 3.75-37.86 13.1-37.86 13.1s47.06 41.25 78.94 43.56c22.36 1.62 42.02-5.2 57.5-13.93ZM145.6 339.38c38.25 29.77 76.11 30.28 115.19 7.35 6.36-3.73 5.53-7.74.95-12.29-33.41-33.19-69.6-40.37-113.35-22.69-6.25 2.53-24.28 10.39-24.28 10.39s16.78 14.2 21.49 17.24ZM101.31 278.67c36.64 17.21 68.13 12.28 98.19-7.58 4.22-2.79 4.06-5.7.6-9.45-29.74-32.23-65.79-40.72-107.29-25.17-8.9 3.33-31.86 13.61-31.86 13.61s26.96 22.78 40.36 28.59Z" style={{
    fill: "#56ab91"
  }} /><path d="M324.52 269.5c9.17-38.95 11.67-69.17 12.17-105.29.46-33.4-20.43-110.81-21.01-113.18 1.82 1.31 24.87 41.92 29.4 64.25 6.91 34.07 10.82 68.04 5.38 102.52-5.85 37.11-15.82 72.81-28.53 107.93-1.76 4.87-.65 8.23 3.36 11.41 27.38 21.72 54.04 43.4 78.33 68.78 10.14 10.6 30.4 37.41 28.43 39.16-.22.2-55.22-45.67-82.58-65.18-30.49-22.28-57.21-45.02-84.74-72.05a147.622 147.622 0 0 1-18.64-22.27c-17.44-25.64-31.26-48.97-44.01-76.85-.43-.93-8.42-18.59-4.51-25.7 5.48 4.18 28.06 47.18 46.47 76.99 23.72 34.24 36.66 43.99 44.97 51.99 20.27 19.51 19.81 5.85 22.13-1.09 5.13-15.32 9.68-29.7 13.37-41.42Z" style={{
    fill: "#56ab91"
  }} /><path d="M353.16 322.16c-8.04-.69-8.84-2.83-7.92-6.7 8.98-37.49 46.1-67.55 84.47-68.69 11.27-.34 40.04-1.43 40.04-1.43s-29.03 55.44-57.93 69.09c-20.27 9.57-41.07 10.29-58.66 7.73ZM304.72 141.03c-39.38-10.84-63.51-42.15-68.37-88.48-.78-7.4-2.47-14.7-3.16-22.1-.32-3.45-.6-9.88 5.62-6.23 22.64 13.29 47.86 22.79 63.85 45.85 14.66 21.13 17.52 44.45 16.1 68.97-.3 5.11-2.18 5.26-14.04 1.99ZM433.65 49.43c-8.59 39.55-31.64 61.57-65.69 73.37-4.77 1.66-6.99-.23-7.87-5.26-7.52-43.2 7.64-76.99 45.2-100.5C413.34 12 435.46 0 435.46 0s1.71 35.25-1.82 49.43ZM449.75 150.91c-6.94 47.97-33.88 74.58-77.94 85.13-7.17 1.72-9.35-1.75-9.2-8.2 1.08-47.08 22.19-77.34 65.99-94.91 6.26-2.51 24.7-9.34 24.7-9.34s-2.26 21.87-3.55 27.32ZM641.42 313.45c10.84-39.38 42.15-63.51 88.48-68.37 7.4-.78 14.7-2.47 22.1-3.16 3.45-.32 9.88-.6 6.23 5.62-13.29 22.64-22.79 47.86-45.85 63.85-21.13 14.66-44.45 17.52-68.97 16.1-5.11-.3-5.26-2.18-1.99-14.04ZM479.85 365.51c3.54 7.25 5.83 7.23 9.11 4.97 31.73-21.89 46.39-67.35 33.62-103.55-3.75-10.63-13.1-37.86-13.1-37.86s-41.25 47.06-43.56 78.94c-1.62 22.36 5.2 42.02 13.93 57.5ZM558.68 168.41c-29.77 38.25-30.28 76.11-7.35 115.19 3.73 6.36 7.74 5.53 12.29.95 33.19-33.41 40.37-69.6 22.69-113.35-2.53-6.25-10.39-24.28-10.39-24.28s-14.2 16.78-17.24 21.49ZM619.38 124.11c-17.21 36.64-12.28 68.13 7.58 98.19 2.79 4.22 5.7 4.06 9.45.6 32.23-29.74 40.72-65.79 25.17-107.29-3.33-8.9-13.61-31.86-13.61-31.86s-22.78 26.96-28.59 40.36Z" style={{
    fill: "#56ab91"
  }} /><path d="M628.56 347.33c38.95 9.17 69.17 11.67 105.29 12.17 33.4.46 110.81-20.43 113.18-21.01-1.31 1.82-41.92 24.87-64.25 29.4-34.07 6.91-68.04 10.82-102.52 5.38-37.11-5.85-72.81-15.82-107.93-28.53-4.87-1.76-8.23-.65-11.41 3.36-21.72 27.38-43.4 54.04-68.78 78.33-10.6 10.14-37.41 30.4-39.16 28.43-.2-.22 45.67-55.22 65.18-82.58 22.28-30.49 45.02-57.21 72.05-84.74 6.79-6.92 14.25-13.18 22.27-18.64 25.64-17.44 48.97-31.26 76.85-44.01.93-.43 18.59-8.42 25.7-4.51-4.18 5.48-47.18 28.06-76.99 46.47-34.24 23.72-43.92 36.73-51.99 44.97-19.88 20.32-5.84 20.37 1.04 22.52 15.48 4.83 29.76 9.29 41.47 12.99Z" style={{
    fill: "#56ab91"
  }} /><path d="M575.9 375.97c.69-8.04 2.83-8.84 6.7-7.92 37.49 8.98 67.55 46.1 68.69 84.47.34 11.27 1.43 40.04 1.43 40.04s-55.44-29.03-69.09-57.93c-9.57-20.27-10.29-41.07-7.73-58.66ZM757.03 327.52c10.84-39.38 42.15-63.51 88.48-68.37 7.4-.78 14.7-2.47 22.1-3.16 3.45-.32 9.88-.6 6.23 5.62-13.29 22.64-22.79 47.86-45.85 63.85-21.13 14.66-44.45 17.52-68.97 16.1-5.11-.3-5.26-2.18-1.99-14.04ZM848.63 456.45c-39.55-8.59-61.57-31.64-73.37-65.69-1.66-4.77.23-6.99 5.26-7.87 43.2-7.52 76.99 7.64 100.5 45.2 5.04 8.05 17.04 30.17 17.04 30.17s-35.25 1.71-49.43-1.82ZM747.15 472.56c-47.97-6.94-74.58-33.88-85.13-77.94-1.72-7.17 1.75-9.35 8.2-9.2 47.08 1.08 77.34 22.19 94.91 65.99 2.51 6.26 9.34 24.7 9.34 24.7s-21.87-2.26-27.32-3.55Z" style={{
    fill: "#56ab91"
  }} /></svg>;

export default SvgSemnetLogo;