import * as d3 from "d3";
import { setMapProjection } from "../../helpers/setMapProjection";
import { useMapData } from "../../hooks/useMapData/useMapData";
import PriceMapRegion from "./PriceMapRegion";

import styles from '../../styles/components/PriceMapRegionList.module.scss'
import {Link} from "react-router-dom";

export default function PriceMapRegionList(props){
    // Loading the geojson using useMapData Hooks in the /hooks directory
    const {mapData} = useMapData();

    //render map when the data is fully loaded
    if(!mapData.loading){
        //Compute a path function based on correct projections that we will use
        const path = d3.geoPath().projection(setMapProjection(mapData.data.provinces));

        //
        const priceRegions = mapData.data.provinces.features.map((data) => {
            const region_name = data.properties["NAME_1"];
            const region_id = data.properties["VARNAME_1"];
            //console.log(data)
            return (

                <Link
                    key={data.properties.ISO_1}
                    to={`/agroekonomi/${data.properties["VARNAME_1"]}`}
                    state={
                        {
                            provinceID: data.properties["VARNAME_1"],
                        }
                    }
                >

                    <PriceMapRegion
                        key={data.properties.ISO_1}
                        path={path(data)}
                        data={data}
                        tooltipData={{name:region_name, id:region_id}}
                        height={'80%'}
                    />

                </Link>
            );
        });

        return (
            <>
                <svg className={styles.map_canvas}>
                    <g>{priceRegions}</g>
                </svg>
            </>
        );
    } else {
        return <h1>Loading...</h1>;
    }
}

