// This is a hook for retrieving map data in the overview-map page

import * as d3 from "d3";
import {useState, useEffect} from "react";
import {feature, mesh} from "topojson";

// creating a constant for storing url of our topo.json data
const topojsonURL = '/data/province-s20-topo.json';

export const useMapData = function (){
    const [mapData, setMapData] = useState({
        data: {},
        loading:true,
    });

    useEffect(() => {
        d3.json(topojsonURL).then((topographyData) => {
            const {provinces} = topographyData.objects;

            setMapData((prevState) => {
                return{
                    ...prevState,
                    data: {
                        provinces: feature(topographyData, provinces),
                        interiors: mesh(topographyData, provinces, (a,b) => a!== b)
                    },
                    loading:false
                };
            });

        })

        //Create our tooltip div with opacity 0.
        d3.select("body")
            .append("div")
            .attr("id", "tooltip")
            .attr("style", "position: absolute; opacity: 0");
    }, []);

    return {mapData};
}