import * as React from 'react';

const MinyakGoreng = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<defs>
			<linearGradient
				id="minyak_goreng_svg__b"
				x1={72.21}
				y1={149.41}
				x2={185.36}
				y2={135.52}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fbd821" />
				<stop offset={1} stopColor="#f0b41d" />
			</linearGradient>
			<linearGradient
				id="minyak_goreng_svg__c"
				x1={149.66}
				y1={118.09}
				x2={98.96}
				y2={82.59}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#feda00" />
				<stop offset={0.03} stopColor="#fdd801" />
				<stop offset={0.47} stopColor="#f5be14" />
				<stop offset={0.73} stopColor="#f3b51b" />
			</linearGradient>
			<linearGradient
				id="minyak_goreng_svg__a"
				x1={130.78}
				y1={70.21}
				x2={130.78}
				y2={59.57}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fbf289" />
				<stop offset={0.85} stopColor="#fcbf36" />
				<stop offset={1} stopColor="#fdb627" />
			</linearGradient>
			<linearGradient
				id="minyak_goreng_svg__d"
				x1={101.25}
				y1={64.3}
				x2={101.25}
				y2={63.54}
				xlinkHref="#minyak_goreng_svg__a"
			/>
			<linearGradient
				id="minyak_goreng_svg__e"
				x1={160.33}
				y1={64.23}
				x2={160.33}
				y2={63.61}
				xlinkHref="#minyak_goreng_svg__a"
			/>
			<linearGradient
				id="minyak_goreng_svg__h"
				x1={130.89}
				y1={30.98}
				x2={130.89}
				y2={24.12}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#f8a38f" />
				<stop offset={0.75} stopColor="#ee2d31" />
			</linearGradient>
			<radialGradient
				id="minyak_goreng_svg__g"
				cx={722.05}
				cy={696.16}
				fx={722.05}
				fy={696.16}
				r={15.22}
				gradientTransform="matrix(.75 0 0 .72 -399.49 -446.56)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#e4e983" />
				<stop offset={0.14} stopColor="#e0e580" />
				<stop offset={0.27} stopColor="#d5da7a" />
				<stop offset={0.4} stopColor="#c2c76f" />
				<stop offset={0.53} stopColor="#a8ac61" />
				<stop offset={0.66} stopColor="#878a4d" />
				<stop offset={0.78} stopColor="#5e6036" />
				<stop offset={0.9} stopColor="#2e2f1b" />
				<stop offset={1} stopColor="#010101" />
			</radialGradient>
			<clipPath id="minyak_goreng_svg__f">
				<path
					d="M111.15 60.67c-2.79 1.62-4.87 3.94-7.36 5.95-3.34 2.71-6.36 6.29-8.45 10.07-.79 1.43-2.53 3.86-2.25 5.61.35 2.19 2.44.57 3.63-.26 5.39-3.74 10.58-7.67 15.22-12.26 3.38-3.35 5.6-5.93 8.28-9.87 4.93-7.25 5.31-10.81 5.92-17.8 0 0-1.95-.27-3.51.07 0 0 2.09 10.6-11.48 18.49Z"
					style={{
						fill: 'none',
					}}
				/>
			</clipPath>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M146.67 53.11s-6.94-10.27-3.93-17.75h-23.89c3 7.47-3.94 17.75-3.94 17.75-35.49 22.48-35.49 57.91-35.49 57.91v97.13c2.45 16.14 51.37 13.08 51.37 13.08s48.92 3.07 51.37-13.08v-97.13s0-35.43-35.49-57.91Zm16 75.3c-5.19 0-10.83-8.85-13.51-17.24-3.29-10.29-5.15-29.1-.89-31.23 4.11-2.06 11.69 7.29 14.39 14.2 2.87 7.35 4.66 16.59 4.63 24.49-.02 4.99-1.16 9.78-4.63 9.78Z"
				style={{
					fill: '#dfb225',
				}}
			/>
			<path
				d="M160.66 64.23c.15.22.24.44.24.66 0 2.94-13.48 5.32-30.11 5.32s-30.11-2.38-30.11-5.32c0-.2.07-.4.19-.59-21.43 21.41-21.43 46.71-21.43 46.71v97.13c2.45 16.14 51.37 13.08 51.37 13.08s48.92 3.07 51.37-13.08v-97.12s0-25.37-21.51-46.79Zm2.02 64.18c-5.19 0-10.83-8.85-13.51-17.24-3.29-10.29-5.15-29.1-.89-31.23 4.11-2.06 11.69 7.29 14.39 14.2 2.87 7.35 4.66 16.59 4.63 24.49-.02 4.99-1.16 9.78-4.63 9.78Z"
				style={{
					fill: 'url(#minyak_goreng_svg__b)',
				}}
			/>
			<path
				d="M141.55 128.78c4.83-1.53 1.64-7.22.03-9.31-3.4-4.41-6.96-11.24-6.96-16.86 0-9.41 4.6-18.35 3.44-22.17-1.47-4.83-10.86-5.11-11.27-3.59-1.91 7.1-2.29 14.77-1.93 22.09.58 11.91 11.73 31.42 16.71 29.85Z"
				style={{
					fill: '#fbf06e',
					opacity: 0.41,
				}}
			/>
			<path
				d="M151.67 117.37c-.98-2-1.83-4.12-2.5-6.21-1.86-5.82-3.26-14.36-3.36-21.07-.41 2.14-.8 4.26-.97 6.32-.47 5.76-.21 11.74 3.38 16.58 1.09 1.47 2.3 2.91 3.45 4.38Z"
				style={{
					fill: '#fbf06e',
					opacity: 0.47,
				}}
			/>
			<path
				d="M145.66 160.19c-15.92.57-36.2.44-49.85-9.1-5.06-3.54-12.56-9.59-16.38-16.42v73.48c2.45 16.14 51.37 13.08 51.37 13.08s48.92 3.07 51.37-13.08V144.5c-8.93 11.04-22.66 15.19-36.51 15.68Z"
				style={{
					fill: '#eca221',
					opacity: 0.21,
				}}
			/>
			<path
				d="M83.5 140.32c-1.41-.66-2.78-.81-4.07-.15v67.98c2.45 16.14 51.37 13.08 51.37 13.08s48.92 3.07 51.37-13.08V144.5c-2.67 3.3-5.78 5.97-9.18 8.13-7.39 5.58-18.39 7.23-27.32 7.55-15.92.57-36.2.44-49.85-9.1-3.6-2.52-8.44-6.32-12.31-10.77Zm7.64 22.44c3.11-2.21 8.26-.8 12.1.62.96.36 1.84.72 2.59 1.02 10.16 4.14 17.4 4.17 28.42 4.74 16.62.85 17.72 29.89 6.38 37.9-6.08 4.3-13.67 4.52-21.03 4.14-7.3-.37-15.6-1.91-22.03-5.49-4.55-2.54-6.38-6.46-9.21-10.05-.16-1.93-.28-3.85-.34-5.79-.17-5.58-1.92-23.51 3.12-27.09Z"
				style={{
					fill: '#e19226',
					opacity: 0.29,
				}}
			/>
			<path
				d="M160.66 64.23c.15.22.24.44.24.66 0 1.36-2.9 2.6-7.65 3.54 3.38 2.32 12.6 16.8 14.89 27.09 5.28 23.78 2.15 75.92.42 92.74-1.52 14.8-5.6 26.68-40.08 26.66-41.43-.02-43.9-13.97-49.05-22.99v16.23c2.45 16.14 51.37 13.08 51.37 13.08s48.92 3.07 51.37-13.08v-97.14s0-25.37-21.51-46.79Z"
				style={{
					fill: '#e59425',
					opacity: 0.58,
				}}
			/>
			<path
				d="M142.87 127.11c-.25-1.12-1.81-2.44-2.67-3.55-2.09-2.72-3.86-5.95-5.26-9.07-3.16-7.08-4.24-15-2.37-22.58.69-2.81 4.8-10.41 3-13.01-2.01-2.9-8.14-2.8-11.13-3.05-13.43-1.1-26.97 1.41-29.21 15.51-1.89 11.92 6.78 22.88 15.91 29.4 7.46 5.32 20.5 10.76 29.84 8.22 1.64-.44 2.06-1.11 1.89-1.86Z"
				style={{
					fill: 'url(#minyak_goreng_svg__c)',
				}}
			/>
			<path
				d="M140.2 123.55c-2.02-2.63-3.74-5.73-5.11-8.77-10.55 6.05-28.16-3.52-28.39-18.59-.17-11.38 7.97-18.54 17.48-20.37-13.35-1.05-26.73 1.52-28.96 15.53-1.89 11.92 6.78 22.88 15.91 29.4 7.46 5.32 20.5 10.76 29.84 8.22 1.64-.44 2.06-1.11 1.89-1.86-.25-1.12-1.81-2.44-2.67-3.55Z"
				style={{
					fill: '#e59425',
					opacity: 0.54,
				}}
			/>
			<path
				d="M107.26 116.73c-5.61-8.29-8.57-19.05-6.59-26.65 2.69-10.31 16.55-13.39 27.82-13.95-1.53-.15-2.99-.2-4.05-.28-13.43-1.1-26.97 1.41-29.21 15.51-1.68 10.58 4.97 20.41 12.89 27.04-.29-.56-.5-1.16-.85-1.67Z"
				style={{
					fill: '#e09226',
				}}
			/>
			<path
				d="M130.72 95.61c-1.2 9.02-.29 15.95 4.81 23.55 2.14 3.19 4.23 6.32 6.02 9.62 1.16-.44 1.47-1.02 1.32-1.68-.25-1.12-1.81-2.44-2.67-3.55-2.09-2.72-3.86-5.95-5.26-9.07-3.16-7.08-4.24-15-2.37-22.58.69-2.81 4.8-10.41 3-13.01-.92-1.32-2.69-2.02-4.62-2.41 6.55 3.45 1 9.95-.22 19.13Z"
				style={{
					fill: '#fbf06e',
					opacity: 0.8,
				}}
			/>
			<path
				d="M160.9 64.89c0 2.94-13.48 5.32-30.11 5.32s-30.11-2.38-30.11-5.32 13.48-5.32 30.11-5.32 30.11 2.38 30.11 5.32Z"
				style={{
					fill: 'url(#minyak_goreng_svg__a)',
				}}
			/>
			<path
				d="M162.67 128.41c-5.19 0-10.83-8.85-13.51-17.24-3.29-10.29-5.15-29.1-.89-31.23.2-.1-4.45.36-7.99 7.52-3.61 7.31-1.06 26.62 6.76 35.26 7.41 8.18 16.16 5.68 15.62 5.68Z"
				style={{
					fill: '#eca221',
				}}
			/>
			<path
				d="M160.66 64.23c.15.22.24.44.24.66 0 .77-.95 1.51-2.64 2.17 11.7 11.13 15.38 25.71 17.15 41.37 2.55 22.65 3.36 90.95-1.77 100.81-4.24 8.15-33.27 8.09-43.07 8.27-24.35.44-36.77-2.59-43.7-9.13-5.77-5.44-5.95-77.11-4.52-99.87 1.4-22.24 16.24-39.08 19.27-42.32-.61-.42-.94-.86-.94-1.32 0-.2.07-.4.19-.59-21.43 21.41-21.43 46.71-21.43 46.71v97.13c2.45 16.14 51.37 13.08 51.37 13.08s48.92 3.07 51.37-13.08v-97.1s0-25.37-21.51-46.79Z"
				style={{
					fill: '#d27b28',
					opacity: 0.42,
				}}
			/>
			<path
				d="M160.01 63.61c-3.77-3.68-8.17-7.23-13.34-10.5 0 0-6.94-10.27-3.93-17.75h-23.89c3 7.47-3.94 17.75-3.94 17.75a81.459 81.459 0 0 0-13.27 10.43c3.38-2.28 15.14-3.97 29.13-3.97s25.98 1.72 29.23 4.04Z"
				style={{
					fill: '#d9edde',
				}}
			/>
			<path
				d="M118.98 51.72c2.55-5.01 2.8-10.59 2.76-16.36h-2.88c3 7.47-3.94 17.75-3.94 17.75a81.459 81.459 0 0 0-13.27 10.43c1.42-.96 4.33-1.8 8.24-2.47 2.98-3.01 6.97-5.76 9.09-9.35ZM146.67 53.11s-6.94-10.27-3.93-17.75h-3.62c.08 4.75.72 9.13 1.77 13.39.78 1.44 1.74 2.81 2.81 4.21 2.06 2.67 4.24 5.22 6.32 7.83 4.85.71 8.42 1.69 9.99 2.81-3.77-3.68-8.17-7.23-13.34-10.5Z"
				style={{
					fill: '#bfd1c4',
				}}
			/>
			<path
				d="M115.52 53.65c5.17-5.33 4.97-11.21 5.09-18.29h-1.76c3 7.47-3.94 17.75-3.94 17.75a81.459 81.459 0 0 0-13.27 10.43c1.05-.71 2.92-1.36 5.41-1.92 2.55-3.21 6.02-5.46 8.47-7.97ZM140.5 35.36c.44 5.28.23 12.75 4.98 17.89 1.29 1.4 2.65 2.9 5.58 5.35.97.81 2.58 2.18 3.5 3.04 2.54.57 4.43 1.24 5.46 1.97-3.77-3.68-8.17-7.23-13.34-10.5 0 0-6.94-10.27-3.93-17.75h-2.24Z"
				style={{
					fill: '#a2b0a6',
				}}
			/>
			<path
				d="m100.86 64.3.79-.76c-.37.25-.63.5-.79.76Z"
				style={{
					fill: 'url(#minyak_goreng_svg__d)',
				}}
			/>
			<path
				d="M160.01 63.61c.21.21.44.41.65.62-.15-.21-.36-.42-.65-.62Z"
				style={{
					fill: 'url(#minyak_goreng_svg__e)',
				}}
			/>
			<path
				d="M130.78 59.57c-16.63 0-30.11 2.38-30.11 5.32 0 .52.43 1.02 1.21 1.49 3.24-3.68 15.49-4.12 28.37-4.79 13.87-.72 28.07 2.64 29.3 4.88.87-.5 1.35-1.03 1.35-1.58 0-2.94-13.48-5.32-30.11-5.32Z"
				style={{
					fill: '#eca221',
					opacity: 0.44,
				}}
			/>
			<path
				d="M104.49 121.68A33.903 33.903 0 0 1 97.03 111c-2.16-4.87-2.64-10.88-5.45-15.37-1.94-3.1-3.36 1.15-3.69 2.8-.62 3.13-3.69 28.47-1.67 38.24 1.12 5.41 7.22 10.79 12.04 13.55 24.8 14.19 59.59 8.61 70.26-.42 4.32-3.66 1.84-5.37-1.37-6.96-9.11-4.51-29.02-5.76-32.54-6.43-11.01-2.1-21.97-6.91-30.12-14.74Z"
				style={{
					fill: '#fbf06e',
					opacity: 0.47,
				}}
			/>
			<path
				d="M123.83 157.02c16.06.81 43.3-3.39 43.32-10.35 0-3.65-30.58 0-52.93-9.34-10.19-4.26-17.45-12.48-19.98-18.36-.61-1.42-2.41-6.5-3.75-6.32-2 .27-2.92 22.96 6.13 33.23 6.6 7.49 19.4 10.74 27.22 11.14Z"
				style={{
					fill: '#fbf06e',
					opacity: 0.83,
				}}
			/>
			<path
				d="M123.88 151.44c.47 0 .93 0 1.4-.02 1.93-.04 15.41-1.73 9.59-4.8-3.58-1.88-9.94-1.59-14.02-2.38-9.73-1.89-17.49-9.03-24.12-15.9-2.1-2.18-3.67-3.98-4.06.07-.76 7.81 6.81 16.11 13.33 19.36 5.52 2.75 11.76 3.66 17.88 3.66Z"
				style={{
					fill: '#fef8c3',
					opacity: 0.83,
				}}
			/>
			<path
				d="M101.84 205.11c8.72 3.69 21.04 2.29 23.53-7.39 2.9-11.25-1.72-19.64-11.27-25.51-3.85-2.37-14.16-8.01-18.78-5.37-9.61 5.49-5.67 27.74.61 34.33 1.55 1.63 3.6 2.96 5.9 3.93Z"
				style={{
					fill: '#fbf06e',
					opacity: 0.28,
				}}
			/>
			<g
				style={{
					mixBlendMode: 'screen',
				}}
			>
				<g
					style={{
						clipPath: 'url(#minyak_goreng_svg__f)',
					}}
				>
					<path
						style={{
							fill: '#010101',
						}}
						d="M92.81 41.84h33.33V84.5H92.81z"
					/>
					<path
						d="M127.54 68.93c-5.66 11.92-17.71 18.05-26.9 13.68-9.19-4.37-12.05-17.57-6.38-29.49 5.66-11.92 17.71-18.05 26.9-13.68 9.19 4.37 12.05 17.57 6.38 29.49Z"
						style={{
							fill: '#010101',
						}}
					/>
					<path
						d="M127.47 68.9c-5.64 11.88-17.64 17.98-26.79 13.63s-12-17.5-6.36-29.38c5.64-11.88 17.64-17.98 26.79-13.63 9.15 4.35 12 17.5 6.36 29.38Z"
						style={{
							fill: '#040404',
						}}
					/>
					<path
						d="M127.41 68.87c-5.62 11.83-17.57 17.91-26.69 13.57-9.12-4.33-11.95-17.43-6.33-29.26 5.62-11.83 17.57-17.91 26.69-13.57 9.12 4.33 11.95 17.43 6.33 29.26Z"
						style={{
							fill: '#050505',
						}}
					/>
					<path
						d="M127.34 68.84c-5.6 11.78-17.5 17.83-26.58 13.52-9.08-4.32-11.91-17.36-6.31-29.15 5.6-11.78 17.5-17.83 26.58-13.52 9.08 4.32 11.91 17.36 6.31 29.15Z"
						style={{
							fill: '#090907',
						}}
					/>
					<path
						d="M127.28 68.8c-5.58 11.74-17.43 17.76-26.48 13.47-9.05-4.3-11.86-17.29-6.28-29.03C100.1 41.5 111.95 35.48 121 39.77c9.05 4.3 11.86 17.29 6.28 29.03Z"
						style={{
							fill: '#0c0d0a',
						}}
					/>
					<path
						d="M127.21 68.77c-5.55 11.69-17.36 17.69-26.37 13.41-9.01-4.28-11.81-17.23-6.26-28.91 5.55-11.69 17.36-17.69 26.37-13.41 9.01 4.28 11.81 17.23 6.26 28.91Z"
						style={{
							fill: '#0d0d0b',
						}}
					/>
					<path
						d="M127.15 68.74c-5.53 11.64-17.29 17.62-26.27 13.36-8.97-4.26-11.77-17.16-6.23-28.8 5.53-11.64 17.29-17.62 26.27-13.36 8.97 4.26 11.77 17.16 6.23 28.8Z"
						style={{
							fill: '#0e0f0c',
						}}
					/>
					<path
						d="M127.08 68.71c-5.51 11.59-17.22 17.55-26.16 13.31-8.94-4.25-11.72-17.09-6.21-28.68 5.51-11.59 17.22-17.55 26.16-13.31 8.94 4.25 11.72 17.09 6.21 28.68Z"
						style={{
							fill: '#10110d',
						}}
					/>
					<path
						d="M127.02 68.68c-5.49 11.55-17.15 17.48-26.05 13.25-8.9-4.23-11.67-17.02-6.18-28.57s17.15-17.48 26.05-13.25c8.9 4.23 11.67 17.02 6.18 28.57Z"
						style={{
							fill: '#10110e',
						}}
					/>
					<path
						d="M126.95 68.65c-5.46 11.5-17.08 17.41-25.95 13.2-8.87-4.21-11.62-16.95-6.16-28.45 5.46-11.5 17.08-17.41 25.95-13.2 8.87 4.21 11.62 16.95 6.16 28.45Z"
						style={{
							fill: '#13140f',
						}}
					/>
					<path
						d="M126.89 68.62c-5.44 11.45-17.01 17.34-25.84 13.14-8.83-4.2-11.58-16.88-6.13-28.34 5.44-11.45 17.01-17.34 25.84-13.14 8.83 4.2 11.58 16.88 6.13 28.34Z"
						style={{
							fill: '#141610',
						}}
					/>
					<path
						d="M126.82 68.59C121.4 80 109.88 85.86 101.08 81.68c-8.79-4.18-11.53-16.81-6.11-28.22 5.42-11.41 16.94-17.27 25.74-13.09 8.79 4.18 11.53 16.81 6.11 28.22Z"
						style={{
							fill: '#15160f',
						}}
					/>
					<path
						d="M126.76 68.56c-5.4 11.36-16.87 17.2-25.63 13.04-8.76-4.16-11.48-16.74-6.08-28.11s16.87-17.2 25.63-13.04c8.76 4.16 11.48 16.74 6.08 28.11Z"
						style={{
							fill: '#161810',
						}}
					/>
					<path
						d="M126.69 68.53c-5.38 11.31-16.8 17.13-25.53 12.98-8.72-4.14-11.43-16.68-6.06-27.99 5.38-11.31 16.8-17.13 25.53-12.98 8.72 4.14 11.43 16.68 6.06 27.99Z"
						style={{
							fill: '#181910',
						}}
					/>
					<path
						d="M126.63 68.49c-5.35 11.27-16.73 17.06-25.42 12.93-8.69-4.13-11.39-16.61-6.03-27.87 5.35-11.27 16.73-17.06 25.42-12.93 8.69 4.13 11.39 16.61 6.03 27.87Z"
						style={{
							fill: '#191a11',
						}}
					/>
					<path
						d="M126.56 68.46c-5.33 11.22-16.67 16.98-25.32 12.88-8.65-4.11-11.34-16.54-6.01-27.76 5.33-11.22 16.67-16.98 25.32-12.88 8.65 4.11 11.34 16.54 6.01 27.76Z"
						style={{
							fill: '#191b11',
						}}
					/>
					<path
						d="M126.5 68.43c-5.31 11.17-16.6 16.91-25.21 12.82S90 64.78 95.31 53.61c5.31-11.17 16.6-16.91 25.21-12.82s11.29 16.47 5.98 27.64Z"
						style={{
							fill: '#1a1c11',
						}}
					/>
					<path
						d="M126.43 68.4c-5.29 11.13-16.53 16.84-25.1 12.77-8.58-4.08-11.25-16.4-5.96-27.53 5.29-11.13 16.53-16.84 25.1-12.77 8.58 4.08 11.25 16.4 5.96 27.53Z"
						style={{
							fill: '#1c1e12',
						}}
					/>
					<path
						d="M126.36 68.37c-5.26 11.08-16.46 16.77-25 12.71-8.54-4.06-11.2-16.33-5.93-27.41 5.26-11.08 16.46-16.77 25-12.71 8.54 4.06 11.2 16.33 5.93 27.41Z"
						style={{
							fill: '#1d2014',
						}}
					/>
					<path
						d="M126.3 68.34c-5.24 11.03-16.39 16.7-24.89 12.66-8.51-4.04-11.15-16.26-5.91-27.3 5.24-11.03 16.39-16.7 24.89-12.66 8.51 4.04 11.15 16.26 5.91 27.3Z"
						style={{
							fill: '#1e2114',
						}}
					/>
					<path
						d="M126.23 68.31c-5.22 10.99-16.32 16.63-24.79 12.61-8.47-4.02-11.1-16.19-5.88-27.18 5.22-10.99 16.32-16.63 24.79-12.61 8.47 4.02 11.1 16.19 5.88 27.18Z"
						style={{
							fill: '#202315',
						}}
					/>
					<path
						d="M126.17 68.28c-5.2 10.94-16.25 16.56-24.68 12.55-8.43-4.01-11.06-16.13-5.86-27.07 5.2-10.94 16.25-16.56 24.68-12.55 8.43 4.01 11.06 16.13 5.86 27.07Z"
						style={{
							fill: '#212316',
						}}
					/>
					<path
						d="M126.1 68.25c-5.18 10.89-16.18 16.49-24.58 12.5-8.4-3.99-11.01-16.06-5.83-26.95s16.18-16.49 24.58-12.5c8.4 3.99 11.01 16.06 5.83 26.95Z"
						style={{
							fill: '#232516',
						}}
					/>
					<path
						d="M126.04 68.22c-5.15 10.85-16.11 16.42-24.47 12.45-8.36-3.97-10.96-15.99-5.81-26.83 5.15-10.85 16.11-16.42 24.47-12.45 8.36 3.97 10.96 15.99 5.81 26.83Z"
						style={{
							fill: '#232716',
						}}
					/>
					<path
						d="M125.97 68.19c-5.13 10.8-16.04 16.35-24.37 12.39-8.33-3.96-10.91-15.92-5.78-26.72 5.13-10.8 16.04-16.35 24.37-12.39 8.33 3.96 10.91 15.92 5.78 26.72Z"
						style={{
							fill: '#252817',
						}}
					/>
					<path
						d="M125.91 68.15c-5.11 10.75-15.97 16.28-24.26 12.34-8.29-3.94-10.87-15.85-5.76-26.6 5.11-10.75 15.97-16.28 24.26-12.34 8.29 3.94 10.87 15.85 5.76 26.6Z"
						style={{
							fill: '#262a19',
						}}
					/>
					<path
						d="M125.84 68.12c-5.09 10.71-15.9 16.21-24.15 12.29-8.25-3.92-10.82-15.78-5.73-26.49 5.09-10.71 15.9-16.21 24.15-12.29 8.25 3.92 10.82 15.78 5.73 26.49Z"
						style={{
							fill: '#272b19',
						}}
					/>
					<path
						d="M125.78 68.09c-5.06 10.66-15.83 16.14-24.05 12.23-8.22-3.9-10.77-15.71-5.71-26.37s15.83-16.14 24.05-12.23c8.22 3.9 10.77 15.71 5.71 26.37Z"
						style={{
							fill: '#292d1a',
						}}
					/>
					<path
						d="M125.71 68.06c-5.04 10.61-15.76 16.07-23.94 12.18-8.18-3.89-10.73-15.64-5.68-26.25 5.04-10.61 15.76-16.07 23.94-12.18 8.18 3.89 10.73 15.64 5.68 26.25Z"
						style={{
							fill: '#2a2e1a',
						}}
					/>
					<path
						d="M125.65 68.03c-5.02 10.57-15.69 15.99-23.84 12.12-8.15-3.87-10.68-15.57-5.66-26.14s15.69-15.99 23.84-12.12c8.15 3.87 10.68 15.57 5.66 26.14Z"
						style={{
							fill: '#2c301a',
						}}
					/>
					<path
						d="M125.58 68c-5 10.52-15.62 15.92-23.73 12.07-8.11-3.85-10.63-15.5-5.63-26.02 5-10.52 15.62-15.92 23.73-12.07 8.11 3.85 10.63 15.5 5.63 26.02Z"
						style={{
							fill: '#2d311b',
						}}
					/>
					<path
						d="M125.52 67.97c-4.98 10.47-15.55 15.85-23.63 12.02-8.07-3.84-10.58-15.44-5.61-25.91 4.98-10.47 15.55-15.85 23.63-12.02 8.07 3.84 10.58 15.44 5.61 25.91Z"
						style={{
							fill: '#2d331b',
						}}
					/>
					<path
						d="M125.45 67.94c-4.95 10.43-15.49 15.78-23.52 11.96-8.04-3.82-10.54-15.37-5.58-25.79 4.95-10.43 15.49-15.78 23.52-11.96 8.04 3.82 10.54 15.37 5.58 25.79Z"
						style={{
							fill: '#30351d',
						}}
					/>
					<path
						d="M125.39 67.91c-4.93 10.38-15.42 15.71-23.42 11.91-8-3.8-10.49-15.3-5.56-25.68 4.93-10.38 15.42-15.71 23.42-11.91 8 3.8 10.49 15.3 5.56 25.68Z"
						style={{
							fill: '#31361d',
						}}
					/>
					<path
						d="M125.32 67.87c-4.91 10.33-15.35 15.64-23.31 11.86-7.96-3.78-10.44-15.23-5.53-25.56 4.91-10.33 15.35-15.64 23.31-11.86 7.96 3.78 10.44 15.23 5.53 25.56Z"
						style={{
							fill: '#33381e',
						}}
					/>
					<path
						d="M125.26 67.84c-4.89 10.29-15.28 15.57-23.21 11.8-7.93-3.77-10.4-15.16-5.51-25.45 4.89-10.29 15.28-15.57 23.21-11.8 7.93 3.77 10.4 15.16 5.51 25.45Z"
						style={{
							fill: '#34391f',
						}}
					/>
					<path
						d="M125.19 67.81c-4.86 10.24-15.21 15.5-23.1 11.75-7.89-3.75-10.35-15.09-5.48-25.33 4.86-10.24 15.21-15.5 23.1-11.75 7.89 3.75 10.35 15.09 5.48 25.33Z"
						style={{
							fill: '#353b20',
						}}
					/>
					<path
						d="M125.12 67.78c-4.84 10.19-15.14 15.43-23 11.7-7.86-3.73-10.3-15.02-5.46-25.21 4.84-10.19 15.14-15.43 23-11.7 7.86 3.73 10.3 15.02 5.46 25.21Z"
						style={{
							fill: '#373c20',
						}}
					/>
					<path
						d="M125.06 67.75c-4.82 10.14-15.07 15.36-22.89 11.64-7.82-3.72-10.25-14.95-5.43-25.1 4.82-10.14 15.07-15.36 22.89-11.64 7.82 3.72 10.25 14.95 5.43 25.1Z"
						style={{
							fill: '#383e20',
						}}
					/>
					<path
						d="M124.99 67.72c-4.8 10.1-15 15.29-22.78 11.59-7.78-3.7-10.21-14.88-5.41-24.98 4.8-10.1 15-15.29 22.78-11.59 7.78 3.7 10.21 14.88 5.41 24.98Z"
						style={{
							fill: '#393f21',
						}}
					/>
					<path
						d="M124.93 67.69c-4.78 10.05-14.93 15.22-22.68 11.53-7.75-3.68-10.16-14.82-5.38-24.87s14.93-15.22 22.68-11.53c7.75 3.68 10.16 14.82 5.38 24.87Z"
						style={{
							fill: '#3b4122',
						}}
					/>
					<path
						d="M124.86 67.66c-4.75 10.01-14.86 15.15-22.57 11.48-7.71-3.66-10.11-14.75-5.36-24.75 4.75-10.01 14.86-15.15 22.57-11.48 7.71 3.66 10.11 14.75 5.36 24.75Z"
						style={{
							fill: '#3c4223',
						}}
					/>
					<path
						d="M124.8 67.63c-4.73 9.96-14.79 15.07-22.47 11.43-7.68-3.65-10.06-14.68-5.33-24.64s14.79-15.07 22.47-11.43c7.68 3.65 10.06 14.68 5.33 24.64Z"
						style={{
							fill: '#3e4423',
						}}
					/>
					<path
						d="M124.73 67.6c-4.71 9.91-14.72 15-22.36 11.37-7.64-3.63-10.02-14.61-5.31-24.52s14.72-15 22.36-11.37c7.64 3.63 10.02 14.61 5.31 24.52Z"
						style={{
							fill: '#3e4524',
						}}
					/>
					<path
						d="M124.67 67.56c-4.69 9.86-14.65 14.93-22.26 11.32-7.6-3.61-9.97-14.54-5.28-24.4s14.65-14.93 22.26-11.32c7.6 3.61 9.97 14.54 5.28 24.4Z"
						style={{
							fill: '#404725',
						}}
					/>
					<path
						d="M124.6 67.53c-4.66 9.82-14.58 14.86-22.15 11.27-7.57-3.6-9.92-14.47-5.26-24.29 4.66-9.82 14.58-14.86 22.15-11.27 7.57 3.6 9.92 14.47 5.26 24.29Z"
						style={{
							fill: '#424826',
						}}
					/>
					<path
						d="M124.54 67.5c-4.64 9.77-14.51 14.79-22.05 11.21-7.53-3.58-9.88-14.4-5.23-24.17 4.64-9.77 14.51-14.79 22.05-11.21s9.88 14.4 5.23 24.17Z"
						style={{
							fill: '#434a26',
						}}
					/>
					<path
						d="M124.47 67.47c-4.62 9.72-14.44 14.72-21.94 11.16-7.5-3.56-9.83-14.33-5.21-24.06 4.62-9.72 14.44-14.72 21.94-11.16 7.5 3.56 9.83 14.33 5.21 24.06Z"
						style={{
							fill: '#444b27',
						}}
					/>
					<path
						d="M124.41 67.44c-4.6 9.68-14.37 14.65-21.83 11.1C95.12 75 92.8 64.28 97.4 54.6c4.6-9.68 14.37-14.65 21.83-11.1 7.46 3.54 9.78 14.26 5.18 23.94Z"
						style={{
							fill: '#464d28',
						}}
					/>
					<path
						d="M124.34 67.41c-4.58 9.63-14.3 14.58-21.73 11.05-7.42-3.53-9.73-14.2-5.16-23.83 4.58-9.63 14.3-14.58 21.73-11.05 7.42 3.53 9.73 14.2 5.16 23.83Z"
						style={{
							fill: '#474e28',
						}}
					/>
					<path
						d="M124.28 67.38c-4.55 9.59-14.24 14.51-21.62 11-7.39-3.51-9.69-14.13-5.13-23.71 4.55-9.59 14.23-14.51 21.62-11 7.39 3.51 9.69 14.13 5.13 23.71Z"
						style={{
							fill: '#485029',
						}}
					/>
					<path
						d="M124.21 67.35c-4.53 9.54-14.17 14.44-21.52 10.95s-9.64-14.06-5.11-23.59c4.53-9.54 14.17-14.44 21.52-10.95s9.64 14.06 5.11 23.6Z"
						style={{
							fill: '#4a5129',
						}}
					/>
					<path
						d="M124.15 67.32c-4.51 9.49-14.1 14.37-21.41 10.89-7.32-3.48-9.59-13.99-5.08-23.48s14.1-14.37 21.41-10.89c7.32 3.48 9.59 13.99 5.08 23.48Z"
						style={{
							fill: '#4b532a',
						}}
					/>
					<path
						d="M124.08 67.29c-4.49 9.44-14.03 14.3-21.31 10.84-7.28-3.46-9.55-13.92-5.06-23.36 4.49-9.44 14.03-14.3 21.31-10.84 7.28 3.46 9.55 13.92 5.06 23.36Z"
						style={{
							fill: '#4c542b',
						}}
					/>
					<path
						d="M124.02 67.25c-4.46 9.4-13.96 14.23-21.2 10.78-7.24-3.44-9.5-13.85-5.03-23.25 4.46-9.4 13.96-14.23 21.2-10.78 7.24 3.44 9.5 13.85 5.03 23.25Z"
						style={{
							fill: '#4d552c',
						}}
					/>
					<path
						d="M123.95 67.22c-4.44 9.35-13.89 14.16-21.1 10.73-7.21-3.43-9.45-13.78-5.01-23.13 4.44-9.35 13.89-14.16 21.1-10.73 7.21 3.43 9.45 13.78 5.01 23.13Z"
						style={{
							fill: '#4e572d',
						}}
					/>
					<path
						d="M123.88 67.19c-4.42 9.3-13.82 14.08-20.99 10.68-7.17-3.41-9.4-13.71-4.98-23.02 4.42-9.3 13.82-14.08 20.99-10.68 7.17 3.41 9.4 13.71 4.98 23.02Z"
						style={{
							fill: '#50582e',
						}}
					/>
					<path
						d="M123.82 67.16c-4.4 9.26-13.75 14.01-20.89 10.62-7.14-3.39-9.36-13.64-4.96-22.9 4.4-9.26 13.75-14.01 20.89-10.62 7.14 3.39 9.36 13.64 4.96 22.9Z"
						style={{
							fill: '#505a2f',
						}}
					/>
					<path
						d="M123.75 67.13c-4.38 9.21-13.68 13.94-20.78 10.57-7.1-3.37-9.31-13.58-4.93-22.79 4.38-9.21 13.68-13.94 20.78-10.57 7.1 3.37 9.31 13.58 4.93 22.79Z"
						style={{
							fill: '#535b2f',
						}}
					/>
					<path
						d="M123.69 67.1c-4.35 9.16-13.61 13.87-20.67 10.52-7.06-3.36-9.26-13.51-4.91-22.67 4.35-9.16 13.61-13.87 20.67-10.52 7.06 3.36 9.26 13.51 4.91 22.67Z"
						style={{
							fill: '#545d2f',
						}}
					/>
					<path
						d="M123.62 67.07c-4.33 9.12-13.54 13.8-20.57 10.46-7.03-3.34-9.21-13.44-4.88-22.55 4.33-9.12 13.54-13.8 20.57-10.46 7.03 3.34 9.21 13.44 4.88 22.55Z"
						style={{
							fill: '#555e30',
						}}
					/>
					<path
						d="M123.56 67.04c-4.31 9.07-13.47 13.73-20.46 10.41-6.99-3.32-9.17-13.37-4.86-22.44 4.31-9.07 13.47-13.73 20.46-10.41 6.99 3.32 9.17 13.37 4.86 22.44Z"
						style={{
							fill: '#565f30',
						}}
					/>
					<path
						d="M123.49 67.01c-4.29 9.02-13.4 13.66-20.36 10.35-6.96-3.3-9.12-13.3-4.83-22.32 4.29-9.02 13.4-13.66 20.36-10.35 6.96 3.3 9.12 13.3 4.83 22.32Z"
						style={{
							fill: '#586131',
						}}
					/>
					<path
						d="M123.43 66.98c-4.27 8.98-13.33 13.59-20.25 10.3-6.92-3.29-9.07-13.23-4.81-22.21 4.27-8.98 13.33-13.59 20.25-10.3 6.92 3.29 9.07 13.23 4.81 22.21Z"
						style={{
							fill: '#596232',
						}}
					/>
					<path
						d="M123.36 66.94c-4.24 8.93-13.26 13.52-20.15 10.25-6.88-3.27-9.02-13.16-4.78-22.09 4.24-8.93 13.26-13.52 20.15-10.25 6.88 3.27 9.02 13.16 4.78 22.09Z"
						style={{
							fill: '#596434',
						}}
					/>
					<path
						d="M123.3 66.91c-4.22 8.88-13.19 13.45-20.04 10.19-6.85-3.25-8.98-13.09-4.76-21.98 4.22-8.88 13.19-13.45 20.04-10.19 6.85 3.25 8.98 13.09 4.76 21.98Z"
						style={{
							fill: '#5c6534',
						}}
					/>
					<path
						d="M123.23 66.88c-4.2 8.84-13.12 13.38-19.94 10.14-6.81-3.24-8.93-13.02-4.73-21.86 4.2-8.84 13.12-13.38 19.94-10.14 6.81 3.24 8.93 13.02 4.73 21.86Z"
						style={{
							fill: '#5d6635',
						}}
					/>
					<path
						d="M123.17 66.85c-4.18 8.79-13.05 13.31-19.83 10.09-6.78-3.22-8.88-12.95-4.71-21.74 4.18-8.79 13.05-13.31 19.83-10.09 6.78 3.22 8.88 12.95 4.71 21.74Z"
						style={{
							fill: '#5e6836',
						}}
					/>
					<path
						d="M123.1 66.82c-4.15 8.74-12.99 13.23-19.73 10.03-6.74-3.2-8.84-12.89-4.68-21.63 4.15-8.74 12.99-13.23 19.73-10.03 6.74 3.2 8.84 12.89 4.68 21.63Z"
						style={{
							fill: '#606936',
						}}
					/>
					<path
						d="M123.04 66.79c-4.13 8.7-12.92 13.16-19.62 9.98-6.7-3.19-8.79-12.82-4.66-21.51 4.13-8.7 12.92-13.16 19.62-9.98 6.7 3.19 8.79 12.82 4.66 21.51Z"
						style={{
							fill: '#606a37',
						}}
					/>
					<path
						d="M122.97 66.76c-4.11 8.65-12.85 13.09-19.51 9.92-6.67-3.17-8.74-12.75-4.63-21.4s12.85-13.09 19.51-9.92c6.67 3.17 8.74 12.75 4.63 21.4Z"
						style={{
							fill: '#626c38',
						}}
					/>
					<path
						d="M122.91 66.73c-4.09 8.6-12.78 13.02-19.41 9.87-6.63-3.15-8.69-12.68-4.61-21.28 4.09-8.6 12.78-13.02 19.41-9.87 6.63 3.15 8.69 12.68 4.61 21.28Z"
						style={{
							fill: '#636d39',
						}}
					/>
					<path
						d="M122.84 66.7c-4.07 8.56-12.71 12.95-19.3 9.82-6.6-3.13-8.65-12.61-4.58-21.17 4.07-8.56 12.71-12.95 19.3-9.82 6.6 3.13 8.65 12.61 4.58 21.17Z"
						style={{
							fill: '#646e39',
						}}
					/>
					<path
						d="M122.78 66.66c-4.04 8.51-12.64 12.88-19.2 9.76-6.56-3.12-8.6-12.54-4.56-21.05 4.04-8.51 12.64-12.88 19.2-9.76 6.56 3.12 8.6 12.54 4.56 21.05Z"
						style={{
							fill: '#65703a',
						}}
					/>
					<path
						d="M122.71 66.63c-4.02 8.46-12.57 12.81-19.09 9.71-6.52-3.1-8.55-12.47-4.53-20.93 4.02-8.46 12.57-12.81 19.09-9.71 6.52 3.1 8.55 12.47 4.53 20.93Z"
						style={{
							fill: '#67713b',
						}}
					/>
					<path
						d="M122.64 66.6c-4 8.41-12.5 12.74-18.99 9.66-6.49-3.08-8.51-12.4-4.51-20.82 4-8.41 12.5-12.74 18.99-9.66 6.49 3.08 8.51 12.4 4.51 20.82Z"
						style={{
							fill: '#68723b',
						}}
					/>
					<path
						d="M122.58 66.57c-3.98 8.37-12.43 12.67-18.88 9.6-6.45-3.07-8.46-12.33-4.48-20.7 3.98-8.37 12.43-12.67 18.88-9.6 6.45 3.07 8.46 12.33 4.48 20.7Z"
						style={{
							fill: '#69733b',
						}}
					/>
					<path
						d="M122.51 66.54c-3.95 8.32-12.36 12.6-18.78 9.55-6.42-3.05-8.41-12.27-4.46-20.59s12.36-12.6 18.78-9.55c6.42 3.05 8.41 12.27 4.46 20.59Z"
						style={{
							fill: '#6a753c',
						}}
					/>
					<path
						d="M122.45 66.51c-3.93 8.28-12.29 12.53-18.67 9.5-6.38-3.03-8.36-12.2-4.43-20.47 3.93-8.28 12.29-12.53 18.67-9.5 6.38 3.03 8.36 12.2 4.43 20.47Z"
						style={{
							fill: '#6b763c',
						}}
					/>
					<path
						d="M122.38 66.48c-3.91 8.23-12.22 12.46-18.56 9.44-6.34-3.01-8.32-12.13-4.41-20.36 3.91-8.23 12.22-12.46 18.56-9.44 6.34 3.01 8.32 12.13 4.41 20.36Z"
						style={{
							fill: '#6d783d',
						}}
					/>
					<path
						d="M122.32 66.45c-3.89 8.18-12.15 12.38-18.46 9.39-6.31-3-8.27-12.06-4.38-20.24 3.89-8.18 12.15-12.38 18.46-9.39 6.31 3 8.27 12.06 4.38 20.24Z"
						style={{
							fill: '#6e793e',
						}}
					/>
					<path
						d="M122.25 66.42c-3.87 8.14-12.08 12.31-18.35 9.34-6.27-2.98-8.22-11.99-4.36-20.13 3.87-8.14 12.08-12.31 18.35-9.34 6.27 2.98 8.22 11.99 4.36 20.13Z"
						style={{
							fill: '#6f7a3e',
						}}
					/>
					<path
						d="M122.19 66.39c-3.84 8.09-12.01 12.24-18.25 9.28-6.24-2.96-8.17-11.92-4.33-20.01 3.84-8.09 12.01-12.24 18.25-9.28 6.24 2.96 8.17 11.92 4.33 20.01Z"
						style={{
							fill: '#707c3f',
						}}
					/>
					<path
						d="M122.12 66.35c-3.82 8.04-11.94 12.17-18.14 9.23-6.2-2.95-8.13-11.85-4.31-19.89 3.82-8.04 11.94-12.17 18.14-9.23 6.2 2.95 8.13 11.85 4.31 19.89Z"
						style={{
							fill: '#717d40',
						}}
					/>
					<path
						d="M122.06 66.32c-3.8 7.99-11.87 12.1-18.04 9.17-6.16-2.93-8.08-11.78-4.28-19.78 3.8-7.99 11.87-12.1 18.04-9.17 6.16 2.93 8.08 11.78 4.28 19.78Z"
						style={{
							fill: '#727e41',
						}}
					/>
					<path
						d="M121.99 66.29c-3.78 7.95-11.8 12.03-17.93 9.12-6.13-2.91-8.03-11.71-4.26-19.66 3.78-7.95 11.8-12.03 17.93-9.12 6.13 2.91 8.03 11.71 4.26 19.66Z"
						style={{
							fill: '#748042',
						}}
					/>
					<path
						d="M121.93 66.26c-3.75 7.9-11.74 11.96-17.83 9.07-6.09-2.89-7.99-11.65-4.23-19.55 3.75-7.9 11.74-11.96 17.83-9.07 6.09 2.89 7.99 11.65 4.23 19.55Z"
						style={{
							fill: '#758143',
						}}
					/>
					<path
						d="M121.86 66.23c-3.73 7.86-11.67 11.89-17.72 9.01-6.05-2.88-7.94-11.58-4.21-19.43 3.73-7.86 11.67-11.89 17.72-9.01 6.05 2.88 7.94 11.58 4.21 19.43Z"
						style={{
							fill: '#768243',
						}}
					/>
					<path
						d="M121.8 66.2c-3.71 7.81-11.6 11.82-17.62 8.96-6.02-2.86-7.89-11.51-4.18-19.32 3.71-7.81 11.6-11.82 17.62-8.96 6.02 2.86 7.89 11.51 4.18 19.32Z"
						style={{
							fill: '#778344',
						}}
					/>
					<path
						d="M121.73 66.17c-3.69 7.76-11.53 11.75-17.51 8.9-5.98-2.84-7.84-11.44-4.16-19.2 3.69-7.76 11.53-11.75 17.51-8.9 5.98 2.84 7.84 11.44 4.16 19.2Z"
						style={{
							fill: '#788544',
						}}
					/>
					<path
						d="M121.67 66.14c-3.67 7.71-11.46 11.68-17.4 8.85-5.95-2.83-7.8-11.37-4.13-19.08 3.67-7.71 11.46-11.68 17.4-8.85 5.95 2.83 7.8 11.37 4.13 19.08Z"
						style={{
							fill: '#798544',
						}}
					/>
					<path
						d="M121.6 66.11c-3.64 7.67-11.39 11.61-17.3 8.8-5.91-2.81-7.75-11.3-4.11-18.97 3.64-7.67 11.39-11.61 17.3-8.8 5.91 2.81 7.75 11.3 4.11 18.97Z"
						style={{
							fill: '#7a8644',
						}}
					/>
					<path
						d="M121.54 66.08c-3.62 7.62-11.32 11.54-17.19 8.75-5.87-2.79-7.7-11.23-4.08-18.85 3.62-7.62 11.32-11.54 17.19-8.75 5.87 2.79 7.7 11.23 4.08 18.85Z"
						style={{
							fill: '#7c8846',
						}}
					/>
					<path
						d="M121.47 66.05c-3.6 7.57-11.25 11.46-17.09 8.69-5.84-2.77-7.66-11.16-4.06-18.74 3.6-7.57 11.25-11.46 17.09-8.69 5.84 2.77 7.66 11.16 4.06 18.74Z"
						style={{
							fill: '#7d8a45',
						}}
					/>
					<path
						d="M121.4 66.01c-3.58 7.53-11.18 11.39-16.98 8.64-5.8-2.76-7.61-11.09-4.03-18.62 3.58-7.53 11.18-11.39 16.98-8.64 5.8 2.76 7.61 11.09 4.03 18.62Z"
						style={{
							fill: '#7e8b46',
						}}
					/>
					<path
						d="M121.34 65.98c-3.55 7.48-11.11 11.32-16.88 8.58s-7.56-11.03-4.01-18.51c3.55-7.48 11.11-11.32 16.88-8.58s7.56 11.03 4.01 18.51Z"
						style={{
							fill: '#7f8c47',
						}}
					/>
					<path
						d="M121.27 65.95c-3.53 7.43-11.04 11.25-16.77 8.53-5.73-2.72-7.51-10.96-3.98-18.39s11.04-11.25 16.77-8.53c5.73 2.72 7.51 10.96 3.98 18.39Z"
						style={{
							fill: '#808c48',
						}}
					/>
					<path
						d="M121.21 65.92c-3.51 7.39-10.97 11.18-16.67 8.48-5.69-2.71-7.47-10.89-3.96-18.28 3.51-7.39 10.97-11.18 16.67-8.48 5.69 2.71 7.47 10.89 3.96 18.28Z"
						style={{
							fill: '#818d49',
						}}
					/>
					<path
						d="M121.14 65.89c-3.49 7.34-10.9 11.11-16.56 8.42-5.66-2.69-7.42-10.82-3.93-18.16 3.49-7.34 10.9-11.11 16.56-8.42 5.66 2.69 7.42 10.82 3.93 18.16Z"
						style={{
							fill: '#828f49',
						}}
					/>
					<path
						d="M121.08 65.86c-3.47 7.29-10.83 11.04-16.46 8.37-5.62-2.67-7.37-10.75-3.91-18.04 3.47-7.29 10.83-11.04 16.46-8.37 5.62 2.67 7.37 10.75 3.91 18.04Z"
						style={{
							fill: '#82904a',
						}}
					/>
					<path
						d="M121.01 65.83c-3.44 7.25-10.76 10.97-16.35 8.32-5.59-2.65-7.32-10.68-3.88-17.93 3.44-7.25 10.76-10.97 16.35-8.32 5.59 2.65 7.32 10.68 3.88 17.93Z"
						style={{
							fill: '#83914b',
						}}
					/>
					<path
						d="M120.95 65.8c-3.42 7.2-10.69 10.9-16.24 8.26-5.55-2.64-7.28-10.61-3.86-17.81 3.42-7.2 10.69-10.9 16.24-8.26 5.55 2.64 7.28 10.61 3.86 17.81Z"
						style={{
							fill: '#85924b',
						}}
					/>
					<path
						d="M120.88 65.77c-3.4 7.15-10.62 10.83-16.14 8.21-5.51-2.62-7.23-10.54-3.83-17.7 3.4-7.15 10.62-10.83 16.14-8.21 5.51 2.62 7.23 10.54 3.83 17.7Z"
						style={{
							fill: '#86944d',
						}}
					/>
					<path
						d="M120.82 65.74c-3.38 7.11-10.55 10.76-16.03 8.15-5.48-2.6-7.18-10.47-3.81-17.58 3.38-7.11 10.55-10.76 16.03-8.15 5.48 2.6 7.18 10.47 3.81 17.58Z"
						style={{
							fill: '#87954d',
						}}
					/>
					<path
						d="M120.75 65.7c-3.35 7.06-10.49 10.69-15.93 8.1-5.44-2.59-7.14-10.4-3.78-17.46 3.35-7.06 10.49-10.69 15.93-8.1 5.44 2.59 7.14 10.4 3.78 17.46Z"
						style={{
							fill: '#88964e',
						}}
					/>
					<path
						d="M120.69 65.67c-3.33 7.01-10.42 10.62-15.82 8.05-5.41-2.57-7.09-10.34-3.76-17.35 3.33-7.01 10.42-10.62 15.82-8.05 5.41 2.57 7.09 10.34 3.76 17.35Z"
						style={{
							fill: '#89974e',
						}}
					/>
					<path
						d="M120.62 65.64c-3.31 6.97-10.35 10.55-15.72 7.99-5.37-2.55-7.04-10.27-3.73-17.23 3.31-6.97 10.35-10.55 15.72-7.99 5.37 2.55 7.04 10.27 3.73 17.23Z"
						style={{
							fill: '#8a984e',
						}}
					/>
					<path
						d="M120.56 65.61c-3.29 6.92-10.28 10.47-15.61 7.94-5.33-2.53-6.99-10.2-3.71-17.12 3.29-6.92 10.28-10.47 15.61-7.94 5.33 2.53 6.99 10.2 3.71 17.12Z"
						style={{
							fill: '#8b9950',
						}}
					/>
					<path
						d="M120.49 65.58c-3.27 6.87-10.21 10.4-15.51 7.89-5.3-2.52-6.95-10.13-3.68-17 3.27-6.87 10.21-10.4 15.51-7.89 5.3 2.52 6.95 10.13 3.68 17Z"
						style={{
							fill: '#8c9b4f',
						}}
					/>
					<path
						d="M120.43 65.55c-3.24 6.83-10.14 10.33-15.4 7.83s-6.9-10.06-3.66-16.89 10.14-10.33 15.4-7.83 6.9 10.06 3.66 16.89Z"
						style={{
							fill: '#8d9c50',
						}}
					/>
					<path
						d="M120.36 65.52c-3.22 6.78-10.07 10.26-15.29 7.78-5.23-2.48-6.85-9.99-3.63-16.77 3.22-6.78 10.07-10.26 15.29-7.78 5.23 2.48 6.85 9.99 3.63 16.77Z"
						style={{
							fill: '#8e9d51',
						}}
					/>
					<path
						d="M120.3 65.49c-3.2 6.73-10 10.19-15.19 7.73-5.19-2.47-6.8-9.92-3.61-16.66 3.2-6.73 10-10.19 15.19-7.73 5.19 2.47 6.8 9.92 3.61 16.66Z"
						style={{
							fill: '#8e9e51',
						}}
					/>
					<path
						d="M120.23 65.46c-3.18 6.69-9.93 10.12-15.08 7.67-5.15-2.45-6.76-9.85-3.58-16.54 3.18-6.69 9.93-10.12 15.08-7.67 5.15 2.45 6.76 9.85 3.58 16.54Z"
						style={{
							fill: '#909f52',
						}}
					/>
					<path
						d="M120.17 65.42c-3.15 6.64-9.86 10.05-14.98 7.62-5.12-2.43-6.71-9.79-3.56-16.42s9.86-10.05 14.98-7.62c5.12 2.43 6.71 9.79 3.56 16.42Z"
						style={{
							fill: '#91a051',
						}}
					/>
					<path
						d="M120.1 65.39c-3.13 6.59-9.79 9.98-14.87 7.56-5.08-2.41-6.66-9.72-3.53-16.31 3.13-6.59 9.79-9.98 14.87-7.56 5.08 2.41 6.66 9.72 3.53 16.31Z"
						style={{
							fill: '#92a053',
						}}
					/>
					<path
						d="M120.03 65.36c-3.11 6.55-9.72 9.91-14.77 7.51s-6.62-9.65-3.51-16.19c3.11-6.55 9.72-9.91 14.77-7.51s6.62 9.65 3.51 16.19Z"
						style={{
							fill: '#93a352',
						}}
					/>
					<path
						d="M119.97 65.33c-3.09 6.5-9.65 9.84-14.66 7.46s-6.57-9.58-3.48-16.08c3.09-6.5 9.65-9.84 14.66-7.46s6.57 9.58 3.48 16.08Z"
						style={{
							fill: '#94a453',
						}}
					/>
					<path
						d="M119.9 65.3c-3.07 6.45-9.58 9.77-14.56 7.4-4.97-2.36-6.52-9.51-3.46-15.96 3.07-6.45 9.58-9.77 14.56-7.4 4.97 2.36 6.52 9.51 3.46 15.96Z"
						style={{
							fill: '#95a554',
						}}
					/>
					<path
						d="M119.84 65.27c-3.04 6.41-9.51 9.7-14.45 7.35-4.94-2.35-6.47-9.44-3.43-15.85 3.04-6.41 9.51-9.7 14.45-7.35 4.94 2.35 6.47 9.44 3.43 15.85Z"
						style={{
							fill: '#96a654',
						}}
					/>
					<path
						d="M119.77 65.24c-3.02 6.36-9.44 9.63-14.35 7.3-4.9-2.33-6.43-9.37-3.41-15.73 3.02-6.36 9.44-9.63 14.35-7.3 4.9 2.33 6.43 9.37 3.41 15.73Z"
						style={{
							fill: '#98a754',
						}}
					/>
					<path
						d="M119.71 65.21c-3 6.31-9.37 9.55-14.24 7.24-4.87-2.31-6.38-9.3-3.38-15.62 3-6.31 9.37-9.55 14.24-7.24 4.87 2.31 6.38 9.3 3.38 15.62Z"
						style={{
							fill: '#99a855',
						}}
					/>
					<path
						d="M119.64 65.18c-2.98 6.26-9.3 9.48-14.13 7.19s-6.33-9.23-3.35-15.5c2.98-6.26 9.3-9.48 14.13-7.19s6.33 9.23 3.35 15.5Z"
						style={{
							fill: '#9aa955',
						}}
					/>
					<path
						d="M119.58 65.15c-2.95 6.22-9.24 9.41-14.03 7.14-4.79-2.28-6.28-9.17-3.33-15.38 2.95-6.22 9.24-9.41 14.03-7.14 4.79 2.28 6.28 9.17 3.33 15.38Z"
						style={{
							fill: '#9aa956',
						}}
					/>
					<path
						d="M119.51 65.11c-2.93 6.17-9.17 9.34-13.92 7.08-4.76-2.26-6.24-9.1-3.31-15.27s9.17-9.34 13.92-7.08c4.76 2.26 6.24 9.1 3.31 15.27Z"
						style={{
							fill: '#9bab57',
						}}
					/>
					<path
						d="M119.45 65.08c-2.91 6.13-9.1 9.27-13.82 7.03-4.72-2.24-6.19-9.03-3.28-15.15 2.91-6.13 9.1-9.27 13.82-7.03 4.72 2.24 6.19 9.03 3.28 15.15Z"
						style={{
							fill: '#9cab58',
						}}
					/>
					<path
						d="M119.38 65.05c-2.89 6.08-9.03 9.2-13.71 6.97-4.69-2.23-6.14-8.96-3.25-15.04 2.89-6.08 9.03-9.2 13.71-6.97 4.69 2.23 6.14 8.96 3.25 15.04Z"
						style={{
							fill: '#9dac58',
						}}
					/>
					<path
						d="M119.32 65.02c-2.87 6.03-8.96 9.13-13.61 6.92s-6.1-8.89-3.23-14.92c2.87-6.03 8.96-9.13 13.61-6.92s6.1 8.89 3.23 14.92Z"
						style={{
							fill: '#9faf59',
						}}
					/>
					<path
						d="M119.25 64.99c-2.84 5.98-8.89 9.06-13.5 6.87-4.61-2.19-6.05-8.82-3.2-14.8 2.84-5.98 8.89-9.06 13.5-6.87 4.61 2.19 6.05 8.82 3.2 14.8Z"
						style={{
							fill: '#a1b15a',
						}}
					/>
					<path
						d="M119.19 64.96c-2.82 5.94-8.82 8.99-13.4 6.81-4.58-2.17-6-8.75-3.18-14.69 2.82-5.94 8.82-8.99 13.4-6.81 4.58 2.17 6 8.75 3.18 14.69Z"
						style={{
							fill: '#a2b25c',
						}}
					/>
					<path
						d="M119.12 64.93c-2.8 5.89-8.75 8.92-13.29 6.76-4.54-2.16-5.95-8.68-3.15-14.57 2.8-5.89 8.75-8.92 13.29-6.76 4.54 2.16 5.95 8.68 3.15 14.57Z"
						style={{
							fill: '#a3b35c',
						}}
					/>
					<path
						d="M119.06 64.9c-2.78 5.84-8.68 8.85-13.18 6.71-4.51-2.14-5.91-8.61-3.13-14.46 2.78-5.84 8.68-8.85 13.18-6.71 4.51 2.14 5.91 8.61 3.13 14.46Z"
						style={{
							fill: '#a3b45c',
						}}
					/>
					<path
						d="M118.99 64.87c-2.75 5.8-8.61 8.78-13.08 6.65-4.47-2.12-5.86-8.54-3.1-14.34 2.75-5.8 8.61-8.78 13.08-6.65 4.47 2.12 5.86 8.54 3.1 14.34Z"
						style={{
							fill: '#a4b55c',
						}}
					/>
					<path
						d="M118.93 64.84c-2.73 5.75-8.54 8.71-12.97 6.6-4.43-2.11-5.81-8.47-3.08-14.23 2.73-5.75 8.54-8.71 12.97-6.6 4.43 2.11 5.81 8.47 3.08 14.23Z"
						style={{
							fill: '#a5b65d',
						}}
					/>
					<path
						d="M118.86 64.8c-2.71 5.7-8.47 8.64-12.87 6.55-4.4-2.09-5.76-8.41-3.05-14.11 2.71-5.7 8.47-8.64 12.87-6.55 4.4 2.09 5.76 8.41 3.05 14.11Z"
						style={{
							fill: '#a6b75d',
						}}
					/>
					<path
						d="M118.79 64.77c-2.69 5.66-8.4 8.56-12.76 6.49-4.36-2.07-5.72-8.34-3.03-14s8.4-8.56 12.76-6.49c4.36 2.07 5.72 8.34 3.03 14Z"
						style={{
							fill: '#a7b75e',
						}}
					/>
					<path
						d="M118.73 64.74c-2.67 5.61-8.33 8.49-12.66 6.44-4.32-2.06-5.67-8.27-3-13.88 2.67-5.61 8.33-8.49 12.66-6.44 4.32 2.06 5.67 8.27 3 13.88Z"
						style={{
							fill: '#a8b95f',
						}}
					/>
					<path
						d="M118.66 64.71c-2.64 5.56-8.26 8.42-12.55 6.38-4.29-2.04-5.62-8.2-2.98-13.76 2.64-5.56 8.26-8.42 12.55-6.38 4.29 2.04 5.62 8.2 2.98 13.76Z"
						style={{
							fill: '#a9ba60',
						}}
					/>
					<path
						d="M118.6 64.68c-2.62 5.52-8.19 8.35-12.45 6.33-4.25-2.02-5.58-8.13-2.95-13.65 2.62-5.52 8.19-8.35 12.45-6.33 4.25 2.02 5.58 8.13 2.95 13.65Z"
						style={{
							fill: '#a9bb60',
						}}
					/>
					<path
						d="M118.53 64.65c-2.6 5.47-8.12 8.28-12.34 6.28-4.22-2-5.53-8.06-2.93-13.53 2.6-5.47 8.12-8.28 12.34-6.28 4.22 2 5.53 8.06 2.93 13.53Z"
						style={{
							fill: '#a9bc61',
						}}
					/>
					<path
						d="M118.47 64.62c-2.58 5.42-8.05 8.21-12.24 6.22-4.18-1.99-5.48-7.99-2.9-13.42 2.58-5.42 8.05-8.21 12.24-6.22 4.18 1.99 5.48 7.99 2.9 13.42Z"
						style={{
							fill: '#aabd61',
						}}
					/>
					<path
						d="M118.4 64.59c-2.55 5.38-7.99 8.14-12.13 6.17-4.15-1.97-5.43-7.92-2.88-13.3 2.55-5.38 7.99-8.14 12.13-6.17 4.15 1.97 5.43 7.92 2.88 13.3Z"
						style={{
							fill: '#acbd61',
						}}
					/>
					<path
						d="M118.34 64.56c-2.53 5.33-7.92 8.07-12.02 6.12-4.11-1.95-5.39-7.86-2.85-13.19 2.53-5.33 7.92-8.07 12.02-6.12 4.11 1.95 5.39 7.86 2.85 13.19Z"
						style={{
							fill: '#adbe61',
						}}
					/>
					<path
						d="M118.27 64.53c-2.51 5.28-7.85 8-11.92 6.06-4.07-1.93-5.34-7.79-2.83-13.07s7.85-8 11.92-6.06c4.07 1.93 5.34 7.79 2.83 13.07Z"
						style={{
							fill: '#aec062',
						}}
					/>
					<path
						d="M118.21 64.5c-2.49 5.24-7.78 7.93-11.81 6.01-4.04-1.92-5.29-7.72-2.8-12.95 2.49-5.24 7.78-7.93 11.81-6.01 4.04 1.92 5.29 7.72 2.8 12.95Z"
						style={{
							fill: '#aec062',
						}}
					/>
					<path
						d="M118.14 64.46c-2.47 5.19-7.71 7.86-11.71 5.95-4-1.9-5.24-7.65-2.78-12.84 2.47-5.19 7.71-7.86 11.71-5.95 4 1.9 5.24 7.65 2.78 12.84Z"
						style={{
							fill: '#aec163',
						}}
					/>
					<path
						d="M118.08 64.43c-2.44 5.14-7.64 7.79-11.6 5.9-3.96-1.88-5.2-7.58-2.75-12.72 2.44-5.14 7.64-7.79 11.6-5.9 3.96 1.88 5.2 7.58 2.75 12.72Z"
						style={{
							fill: '#afc263',
						}}
					/>
					<path
						d="M118.01 64.4c-2.42 5.1-7.57 7.71-11.5 5.85-3.93-1.87-5.15-7.51-2.73-12.61 2.42-5.1 7.57-7.71 11.5-5.85 3.93 1.87 5.15 7.51 2.73 12.61Z"
						style={{
							fill: '#b0c365',
						}}
					/>
					<path
						d="M117.95 64.37c-2.4 5.05-7.5 7.64-11.39 5.79-3.89-1.85-5.1-7.44-2.7-12.49 2.4-5.05 7.5-7.64 11.39-5.79 3.89 1.85 5.1 7.44 2.7 12.49Z"
						style={{
							fill: '#b1c365',
						}}
					/>
					<path
						d="M117.88 64.34c-2.38 5-7.43 7.57-11.29 5.74-3.86-1.83-5.06-7.37-2.68-12.38 2.38-5 7.43-7.57 11.29-5.74 3.86 1.83 5.06 7.37 2.68 12.38Z"
						style={{
							fill: '#b2c466',
						}}
					/>
					<path
						d="M117.82 64.31c-2.35 4.96-7.36 7.5-11.18 5.69-3.82-1.82-5.01-7.31-2.65-12.26 2.35-4.96 7.36-7.5 11.18-5.69 3.82 1.82 5.01 7.31 2.65 12.26Z"
						style={{
							fill: '#b3c566',
						}}
					/>
					<path
						d="M117.75 64.28c-2.33 4.91-7.29 7.43-11.08 5.63-3.78-1.8-4.96-7.24-2.63-12.14 2.33-4.91 7.29-7.43 11.08-5.63 3.78 1.8 4.96 7.24 2.63 12.14Z"
						style={{
							fill: '#b4c666',
						}}
					/>
					<path
						d="M117.69 64.25c-2.31 4.86-7.22 7.36-10.97 5.58-3.75-1.78-4.91-7.17-2.6-12.03 2.31-4.86 7.22-7.36 10.97-5.58 3.75 1.78 4.91 7.17 2.6 12.03Z"
						style={{
							fill: '#b4c767',
						}}
					/>
					<path
						d="M117.62 64.22c-2.29 4.82-7.15 7.29-10.86 5.53-3.71-1.76-4.87-7.1-2.58-11.91 2.29-4.82 7.15-7.29 10.86-5.53 3.71 1.76 4.87 7.1 2.58 11.91Z"
						style={{
							fill: '#b5c867',
						}}
					/>
					<path
						d="M117.55 64.18c-2.27 4.77-7.08 7.22-10.76 5.47-3.68-1.75-4.82-7.03-2.55-11.8 2.27-4.77 7.08-7.22 10.76-5.47 3.68 1.75 4.82 7.03 2.55 11.8Z"
						style={{
							fill: '#b6c967',
						}}
					/>
					<path
						d="M117.49 64.15c-2.24 4.72-7.01 7.15-10.65 5.42-3.64-1.73-4.77-6.96-2.53-11.68 2.24-4.72 7.01-7.15 10.65-5.42 3.64 1.73 4.77 6.96 2.53 11.68Z"
						style={{
							fill: '#b7ca68',
						}}
					/>
					<path
						d="M117.42 64.12c-2.22 4.68-6.94 7.08-10.55 5.37-3.6-1.71-4.73-6.89-2.5-11.57 2.22-4.68 6.94-7.08 10.55-5.37 3.6 1.71 4.73 6.89 2.5 11.57Z"
						style={{
							fill: '#b8cb68',
						}}
					/>
					<path
						d="M117.36 64.09c-2.2 4.63-6.87 7.01-10.44 5.31-3.57-1.69-4.68-6.82-2.48-11.45 2.2-4.63 6.87-7.01 10.44-5.31 3.57 1.69 4.68 6.82 2.48 11.45Z"
						style={{
							fill: '#b8cc69',
						}}
					/>
					<path
						d="M117.29 64.06c-2.18 4.58-6.8 6.94-10.34 5.26-3.53-1.68-4.63-6.75-2.45-11.34 2.18-4.58 6.8-6.93 10.34-5.26 3.53 1.68 4.63 6.75 2.45 11.34Z"
						style={{
							fill: '#b9cc69',
						}}
					/>
					<path
						d="M117.23 64.03c-2.15 4.53-6.74 6.86-10.23 5.2-3.5-1.66-4.58-6.68-2.43-11.22 2.15-4.53 6.74-6.86 10.23-5.2 3.5 1.66 4.58 6.68 2.43 11.22Z"
						style={{
							fill: '#b9cd6a',
						}}
					/>
					<path
						d="M117.16 64c-2.13 4.49-6.67 6.79-10.13 5.15-3.46-1.64-4.54-6.62-2.4-11.1 2.13-4.49 6.67-6.79 10.13-5.15 3.46 1.64 4.54 6.62 2.4 11.1Z"
						style={{
							fill: '#bace6a',
						}}
					/>
					<path
						d="M117.1 63.97c-2.11 4.44-6.6 6.72-10.02 5.1-3.42-1.63-4.49-6.55-2.38-10.99 2.11-4.44 6.6-6.72 10.02-5.1 3.42 1.63 4.49 6.55 2.38 10.99Z"
						style={{
							fill: '#bbcf6a',
						}}
					/>
					<path
						d="M117.03 63.94c-2.09 4.4-6.53 6.65-9.92 5.04-3.39-1.61-4.44-6.48-2.35-10.87 2.09-4.4 6.53-6.65 9.92-5.04 3.39 1.61 4.44 6.48 2.35 10.87Z"
						style={{
							fill: '#bbd06b',
						}}
					/>
					<path
						d="M116.97 63.91c-2.07 4.35-6.46 6.58-9.81 4.99-3.35-1.59-4.39-6.41-2.33-10.76 2.07-4.35 6.46-6.58 9.81-4.99 3.35 1.59 4.39 6.41 2.33 10.76Z"
						style={{
							fill: '#bdd16a',
						}}
					/>
					<path
						d="M116.9 63.87c-2.04 4.3-6.39 6.51-9.7 4.94-3.32-1.58-4.35-6.34-2.3-10.64 2.04-4.3 6.39-6.51 9.7-4.94 3.32 1.58 4.35 6.34 2.3 10.64Z"
						style={{
							fill: '#bed26c',
						}}
					/>
					<path
						d="M116.84 63.84c-2.02 4.25-6.32 6.44-9.6 4.88-3.28-1.56-4.3-6.27-2.28-10.53 2.02-4.25 6.32-6.44 9.6-4.88 3.28 1.56 4.3 6.27 2.28 10.53Z"
						style={{
							fill: '#bfd26c',
						}}
					/>
					<path
						d="M116.77 63.81c-2 4.21-6.25 6.37-9.49 4.83s-4.25-6.2-2.25-10.41 6.25-6.37 9.49-4.83 4.25 6.2 2.25 10.41Z"
						style={{
							fill: '#bfd36d',
						}}
					/>
					<path
						d="M116.71 63.78c-1.98 4.16-6.18 6.3-9.39 4.77-3.21-1.52-4.21-6.13-2.23-10.29 1.98-4.16 6.18-6.3 9.39-4.77 3.21 1.52 4.21 6.13 2.23 10.29Z"
						style={{
							fill: '#c0d46d',
						}}
					/>
					<path
						d="M116.64 63.75c-1.95 4.11-6.11 6.23-9.28 4.72-3.17-1.51-4.16-6.06-2.2-10.18 1.95-4.11 6.11-6.23 9.28-4.72 3.17 1.51 4.16 6.06 2.2 10.18Z"
						style={{
							fill: '#c1d56d',
						}}
					/>
					<path
						d="M116.58 63.72c-1.93 4.07-6.04 6.16-9.18 4.67-3.14-1.49-4.11-5.99-2.18-10.06 1.93-4.07 6.04-6.16 9.18-4.67 3.14 1.49 4.11 5.99 2.18 10.06Z"
						style={{
							fill: '#c3d66e',
						}}
					/>
					<path
						d="M116.51 63.69c-1.91 4.02-5.97 6.09-9.07 4.61s-4.06-5.93-2.15-9.95c1.91-4.02 5.97-6.09 9.07-4.61 3.1 1.47 4.06 5.93 2.15 9.95Z"
						style={{
							fill: '#c3d76e',
						}}
					/>
					<path
						d="M116.45 63.66c-1.89 3.98-5.9 6.02-8.97 4.56-3.06-1.45-4.02-5.86-2.13-9.83 1.89-3.98 5.9-6.02 8.97-4.56 3.06 1.46 4.02 5.86 2.13 9.83Z"
						style={{
							fill: '#c4d76f',
						}}
					/>
					<path
						d="M116.38 63.63c-1.87 3.93-5.83 5.94-8.86 4.51-3.03-1.44-3.97-5.79-2.1-9.72 1.87-3.93 5.83-5.94 8.86-4.51 3.03 1.44 3.97 5.79 2.1 9.72Z"
						style={{
							fill: '#c5d86f',
						}}
					/>
					<path
						d="M116.31 63.6c-1.84 3.88-5.76 5.87-8.76 4.45-2.99-1.42-3.92-5.72-2.08-9.6 1.84-3.88 5.76-5.87 8.76-4.45 2.99 1.42 3.92 5.72 2.08 9.6Z"
						style={{
							fill: '#c6d96f',
						}}
					/>
					<path
						d="M116.25 63.56c-1.82 3.83-5.69 5.8-8.65 4.4-2.96-1.4-3.87-5.65-2.05-9.48 1.82-3.83 5.69-5.8 8.65-4.4 2.96 1.4 3.87 5.65 2.05 9.48Z"
						style={{
							fill: '#c6da70',
						}}
					/>
					<path
						d="M116.18 63.53c-1.8 3.79-5.62 5.73-8.54 4.35-2.92-1.39-3.83-5.58-2.03-9.37 1.8-3.79 5.62-5.73 8.54-4.35 2.92 1.39 3.83 5.58 2.03 9.37Z"
						style={{
							fill: '#c6da70',
						}}
					/>
					<path
						d="M116.12 63.5c-1.78 3.74-5.56 5.66-8.44 4.29s-3.78-5.51-2-9.25c1.78-3.74 5.56-5.66 8.44-4.29s3.78 5.51 2 9.25Z"
						style={{
							fill: '#c8db71',
						}}
					/>
					<path
						d="M116.05 63.47c-1.75 3.69-5.49 5.59-8.33 4.24-2.85-1.35-3.73-5.44-1.98-9.14 1.75-3.69 5.49-5.59 8.33-4.24 2.85 1.35 3.73 5.44 1.98 9.14Z"
						style={{
							fill: '#c8dd72',
						}}
					/>
					<path
						d="M115.99 63.44c-1.73 3.65-5.42 5.52-8.23 4.19-2.81-1.34-3.69-5.38-1.95-9.02 1.73-3.65 5.42-5.52 8.23-4.19 2.81 1.34 3.69 5.38 1.95 9.02Z"
						style={{
							fill: '#c9de72',
						}}
					/>
					<path
						d="M115.92 63.41c-1.71 3.6-5.35 5.45-8.12 4.13-2.78-1.32-3.64-5.31-1.93-8.91 1.71-3.6 5.35-5.45 8.12-4.13 2.78 1.32 3.64 5.31 1.93 8.91Z"
						style={{
							fill: '#cade73',
						}}
					/>
					<path
						d="M115.86 63.38c-1.69 3.55-5.28 5.38-8.02 4.08-2.74-1.3-3.59-5.24-1.9-8.79 1.69-3.55 5.28-5.38 8.02-4.08 2.74 1.3 3.59 5.24 1.9 8.79Z"
						style={{
							fill: '#cade73',
						}}
					/>
					<path
						d="M115.79 63.35c-1.67 3.51-5.21 5.31-7.91 4.02-2.7-1.28-3.54-5.17-1.88-8.68 1.67-3.51 5.21-5.31 7.91-4.02 2.7 1.28 3.54 5.17 1.88 8.68Z"
						style={{
							fill: '#cbde73',
						}}
					/>
					<path
						d="M115.73 63.32c-1.64 3.46-5.14 5.24-7.81 3.97s-3.5-5.1-1.85-8.56c1.64-3.46 5.14-5.24 7.81-3.97s3.5 5.1 1.85 8.56Z"
						style={{
							fill: '#ccdf74',
						}}
					/>
					<path
						d="M115.66 63.29c-1.62 3.41-5.07 5.17-7.7 3.92s-3.45-5.03-1.83-8.44c1.62-3.41 5.07-5.17 7.7-3.92s3.45 5.03 1.83 8.44Z"
						style={{
							fill: '#ccdf74',
						}}
					/>
					<path
						d="M115.6 63.25c-1.6 3.37-5 5.1-7.59 3.86-2.6-1.23-3.4-4.96-1.8-8.33 1.6-3.37 5-5.1 7.59-3.86 2.6 1.23 3.4 4.96 1.8 8.33Z"
						style={{
							fill: '#cddf76',
						}}
					/>
					<path
						d="M115.53 63.22c-1.58 3.32-4.93 5.02-7.49 3.81-2.56-1.22-3.35-4.89-1.78-8.21 1.58-3.32 4.93-5.02 7.49-3.81 2.56 1.22 3.35 4.89 1.78 8.21Z"
						style={{
							fill: '#cedf76',
						}}
					/>
					<path
						d="M115.47 63.19c-1.55 3.27-4.86 4.95-7.38 3.75-2.52-1.2-3.31-4.82-1.75-8.1 1.55-3.27 4.86-4.95 7.38-3.75 2.52 1.2 3.31 4.82 1.75 8.1Z"
						style={{
							fill: '#cee077',
						}}
					/>
					<path
						d="M115.4 63.16c-1.53 3.23-4.79 4.88-7.28 3.7-2.49-1.18-3.26-4.76-1.73-7.98 1.53-3.23 4.79-4.88 7.28-3.7 2.49 1.18 3.26 4.75 1.73 7.98Z"
						style={{
							fill: '#cfe076',
						}}
					/>
					<path
						d="M115.34 63.13c-1.51 3.18-4.72 4.81-7.17 3.65-2.45-1.16-3.21-4.69-1.7-7.86 1.51-3.18 4.72-4.81 7.17-3.65 2.45 1.16 3.21 4.69 1.7 7.86Z"
						style={{
							fill: '#cfe077',
						}}
					/>
					<path
						d="M115.27 63.1c-1.49 3.13-4.65 4.74-7.07 3.59s-3.17-4.62-1.68-7.75 4.65-4.74 7.07-3.59c2.41 1.15 3.17 4.62 1.68 7.75Z"
						style={{
							fill: '#d0e078',
						}}
					/>
					<path
						d="M115.21 63.07c-1.47 3.08-4.58 4.67-6.96 3.54s-3.12-4.55-1.65-7.63c1.47-3.08 4.58-4.67 6.96-3.54s3.12 4.55 1.65 7.63Z"
						style={{
							fill: '#d0e179',
						}}
					/>
					<path
						d="M115.14 63.04c-1.44 3.04-4.51 4.6-6.86 3.49-2.34-1.11-3.07-4.48-1.63-7.52s4.51-4.6 6.86-3.49c2.34 1.11 3.07 4.48 1.63 7.52Z"
						style={{
							fill: '#d0e17a',
						}}
					/>
					<path
						d="M115.07 63.01c-1.42 2.99-4.44 4.53-6.75 3.43-2.31-1.1-3.02-4.41-1.6-7.4s4.44-4.53 6.75-3.43c2.31 1.1 3.02 4.41 1.6 7.4Z"
						style={{
							fill: '#d0e17a',
						}}
					/>
					<path
						d="M115.01 62.97c-1.4 2.95-4.37 4.46-6.65 3.38-2.27-1.08-2.98-4.34-1.58-7.29s4.37-4.46 6.65-3.38c2.27 1.08 2.98 4.34 1.58 7.29Z"
						style={{
							fill: '#d1e17a',
						}}
					/>
					<path
						d="M114.94 62.94c-1.38 2.9-4.31 4.39-6.54 3.33-2.23-1.06-2.93-4.27-1.55-7.17 1.38-2.9 4.31-4.39 6.54-3.33 2.23 1.06 2.93 4.27 1.55 7.17Z"
						style={{
							fill: '#d2e17a',
						}}
					/>
					<path
						d="M114.88 62.91c-1.36 2.85-4.24 4.32-6.43 3.27-2.2-1.04-2.88-4.2-1.53-7.06 1.36-2.85 4.24-4.32 6.43-3.27 2.2 1.04 2.88 4.2 1.53 7.06Z"
						style={{
							fill: '#d3e27b',
						}}
					/>
					<path
						d="M114.81 62.88c-1.33 2.8-4.17 4.25-6.33 3.22-2.16-1.03-2.84-4.13-1.5-6.94 1.33-2.8 4.17-4.25 6.33-3.22 2.16 1.03 2.84 4.13 1.5 6.94Z"
						style={{
							fill: '#d3e27c',
						}}
					/>
					<path
						d="M114.75 62.85c-1.31 2.76-4.1 4.18-6.22 3.17-2.13-1.01-2.79-4.07-1.48-6.82 1.31-2.76 4.1-4.18 6.22-3.17 2.13 1.01 2.79 4.07 1.48 6.82Z"
						style={{
							fill: '#d3e27c',
						}}
					/>
					<path
						d="M114.68 62.82c-1.29 2.71-4.03 4.11-6.12 3.11-2.09-.99-2.74-4-1.45-6.71 1.29-2.71 4.03-4.11 6.12-3.11 2.09.99 2.74 4 1.45 6.71Z"
						style={{
							fill: '#d3e27c',
						}}
					/>
					<path
						d="M114.62 62.79c-1.27 2.66-3.96 4.03-6.01 3.06-2.05-.98-2.69-3.93-1.43-6.59 1.27-2.66 3.96-4.03 6.01-3.06 2.05.98 2.69 3.93 1.43 6.59Z"
						style={{
							fill: '#d5e37d',
						}}
					/>
					<path
						d="M114.55 62.76c-1.24 2.62-3.89 3.96-5.91 3s-2.65-3.86-1.4-6.48c1.24-2.62 3.89-3.96 5.91-3s2.65 3.86 1.4 6.48Z"
						style={{
							fill: '#d4e27d',
						}}
					/>
					<path
						d="M114.49 62.73c-1.22 2.57-3.82 3.89-5.8 2.95s-2.6-3.79-1.38-6.36c1.22-2.57 3.82-3.89 5.8-2.95s2.6 3.79 1.38 6.36Z"
						style={{
							fill: '#d6e37d',
						}}
					/>
					<path
						d="M114.42 62.7c-1.2 2.53-3.75 3.82-5.7 2.9-1.95-.92-2.55-3.72-1.35-6.25 1.2-2.53 3.75-3.82 5.7-2.9 1.95.92 2.55 3.72 1.35 6.25Z"
						style={{
							fill: '#d5e37e',
						}}
					/>
					<path
						d="M114.36 62.66c-1.18 2.48-3.68 3.75-5.59 2.84-1.91-.91-2.5-3.65-1.33-6.13 1.18-2.48 3.68-3.75 5.59-2.84 1.91.91 2.5 3.65 1.33 6.13Z"
						style={{
							fill: '#d7e37e',
						}}
					/>
					<path
						d="M114.29 62.63c-1.16 2.43-3.61 3.68-5.49 2.79s-2.46-3.58-1.3-6.01c1.16-2.43 3.61-3.68 5.49-2.79 1.87.89 2.46 3.58 1.3 6.01Z"
						style={{
							fill: '#d6e37e',
						}}
					/>
					<path
						d="M114.23 62.6c-1.13 2.38-3.54 3.61-5.38 2.74-1.84-.87-2.41-3.51-1.28-5.9 1.13-2.38 3.54-3.61 5.38-2.74 1.84.87 2.41 3.51 1.28 5.9Z"
						style={{
							fill: '#d7e37e',
						}}
					/>
					<path
						d="M114.16 62.57c-1.11 2.34-3.47 3.54-5.27 2.68-1.8-.86-2.36-3.44-1.25-5.78 1.11-2.34 3.47-3.54 5.27-2.68 1.8.86 2.36 3.44 1.25 5.78Z"
						style={{
							fill: '#d7e480',
						}}
					/>
					<path
						d="M114.1 62.54c-1.09 2.29-3.4 3.47-5.17 2.63-1.77-.84-2.32-3.38-1.23-5.67 1.09-2.29 3.4-3.47 5.17-2.63 1.77.84 2.32 3.38 1.23 5.67Z"
						style={{
							fill: '#d7e480',
						}}
					/>
					<path
						d="M114.03 62.51c-1.07 2.24-3.33 3.4-5.06 2.58-1.73-.82-2.27-3.31-1.2-5.55 1.07-2.24 3.33-3.4 5.06-2.58 1.73.82 2.27 3.31 1.2 5.55Z"
						style={{
							fill: '#d7e480',
						}}
					/>
					<path
						d="M113.97 62.48c-1.04 2.2-3.26 3.33-4.96 2.52-1.69-.81-2.22-3.24-1.18-5.44 1.04-2.2 3.26-3.33 4.96-2.52 1.69.81 2.22 3.24 1.18 5.44Z"
						style={{
							fill: '#d8e480',
						}}
					/>
					<path
						d="M113.9 62.45c-1.02 2.15-3.19 3.26-4.85 2.47-1.66-.79-2.17-3.17-1.15-5.32 1.02-2.15 3.19-3.26 4.85-2.47 1.66.79 2.17 3.17 1.15 5.32Z"
						style={{
							fill: '#d8e480',
						}}
					/>
					<path
						d="M113.84 62.42c-1 2.1-3.13 3.19-4.75 2.41-1.62-.77-2.13-3.1-1.13-5.2s3.13-3.19 4.75-2.41c1.62.77 2.13 3.1 1.13 5.2Z"
						style={{
							fill: '#d9e580',
						}}
					/>
					<path
						d="M113.77 62.39c-.98 2.06-3.06 3.11-4.64 2.36-1.59-.75-2.08-3.03-1.1-5.09s3.06-3.11 4.64-2.36c1.59.75 2.08 3.03 1.1 5.09Z"
						style={{
							fill: '#d9e580',
						}}
					/>
					<path
						d="M113.7 62.36c-.96 2.01-2.99 3.04-4.54 2.31-1.55-.74-2.03-2.96-1.08-4.97.96-2.01 2.99-3.04 4.54-2.31 1.55.74 2.03 2.96 1.08 4.97Z"
						style={{
							fill: '#dae581',
						}}
					/>
					<path
						d="M113.64 62.32c-.93 1.96-2.92 2.97-4.43 2.25-1.51-.72-1.99-2.89-1.05-4.86.93-1.96 2.92-2.97 4.43-2.25 1.51.72 1.99 2.89 1.05 4.86Z"
						style={{
							fill: '#dae581',
						}}
					/>
					<path
						d="M113.57 62.29c-.91 1.92-2.85 2.9-4.32 2.2-1.48-.7-1.94-2.83-1.03-4.74.91-1.92 2.85-2.9 4.32-2.2 1.48.7 1.94 2.83 1.03 4.74Z"
						style={{
							fill: '#dbe581',
						}}
					/>
					<path
						d="M113.51 62.26c-.89 1.87-2.78 2.83-4.22 2.15-1.44-.68-1.89-2.76-1-4.63.89-1.87 2.78-2.83 4.22-2.15 1.44.68 1.89 2.76 1 4.63Z"
						style={{
							fill: '#dbe581',
						}}
					/>
					<path
						d="M113.44 62.23c-.87 1.82-2.71 2.76-4.11 2.09-1.41-.67-1.84-2.69-.98-4.51.87-1.82 2.71-2.76 4.11-2.09 1.41.67 1.84 2.69.98 4.51Z"
						style={{
							fill: '#dbe582',
						}}
					/>
					<path
						d="M113.38 62.2c-.84 1.78-2.64 2.69-4.01 2.04s-1.8-2.62-.95-4.4c.84-1.78 2.64-2.69 4.01-2.04 1.37.65 1.8 2.62.95 4.4Z"
						style={{
							fill: '#dbe582',
						}}
					/>
					<path
						d="M113.31 62.17c-.82 1.73-2.57 2.62-3.9 1.98-1.33-.63-1.75-2.55-.93-4.28.82-1.73 2.57-2.62 3.9-1.98 1.33.63 1.75 2.55.93 4.28Z"
						style={{
							fill: '#dbe582',
						}}
					/>
					<path
						d="M113.25 62.14c-.8 1.68-2.5 2.55-3.8 1.93-1.3-.62-1.7-2.48-.9-4.16.8-1.68 2.5-2.55 3.8-1.93 1.3.62 1.7 2.48.9 4.16Z"
						style={{
							fill: '#dce682',
						}}
					/>
					<path
						d="M113.18 62.11c-.78 1.64-2.43 2.48-3.69 1.88-1.26-.6-1.65-2.41-.88-4.05.78-1.64 2.43-2.48 3.69-1.88 1.26.6 1.65 2.41.88 4.05Z"
						style={{
							fill: '#dce682',
						}}
					/>
					<path
						d="M113.12 62.08c-.76 1.59-2.36 2.41-3.59 1.82-1.23-.58-1.61-2.34-.85-3.93.76-1.59 2.36-2.41 3.59-1.82 1.23.58 1.61 2.34.85 3.93Z"
						style={{
							fill: '#dde682',
						}}
					/>
					<path
						d="M113.05 62.05c-.73 1.54-2.29 2.34-3.48 1.77-1.19-.57-1.56-2.27-.83-3.82.73-1.54 2.29-2.34 3.48-1.77 1.19.57 1.56 2.27.83 3.82Z"
						style={{
							fill: '#dde682',
						}}
					/>
					<path
						d="M112.99 62.01c-.71 1.5-2.22 2.26-3.37 1.72-1.15-.55-1.51-2.21-.8-3.7.71-1.5 2.22-2.26 3.37-1.72 1.15.55 1.51 2.21.8 3.7Z"
						style={{
							fill: '#dde682',
						}}
					/>
					<path
						d="M112.92 61.98c-.69 1.45-2.15 2.19-3.27 1.66s-1.46-2.14-.78-3.59c.69-1.45 2.15-2.19 3.27-1.66s1.46 2.14.78 3.59Z"
						style={{
							fill: '#dee682',
						}}
					/>
					<path
						d="M112.86 61.95c-.67 1.4-2.08 2.12-3.16 1.61s-1.42-2.07-.75-3.47c.67-1.4 2.08-2.12 3.16-1.61s1.42 2.07.75 3.47Z"
						style={{
							fill: '#dfe784',
						}}
					/>
					<path
						d="M112.79 61.92c-.64 1.36-2.01 2.05-3.06 1.56-1.05-.5-1.37-2-.73-3.35.64-1.36 2.01-2.05 3.06-1.56 1.05.5 1.37 2 .73 3.35Z"
						style={{
							fill: '#dfe783',
						}}
					/>
					<path
						d="M112.73 61.89c-.62 1.31-1.94 1.98-2.95 1.5-1.01-.48-1.32-1.93-.7-3.24.62-1.31 1.94-1.98 2.95-1.5 1.01.48 1.32 1.93.7 3.24Z"
						style={{
							fill: '#e0e783',
						}}
					/>
					<path
						d="M112.66 61.86c-.6 1.26-1.87 1.91-2.85 1.45-.97-.46-1.28-1.86-.68-3.12s1.87-1.91 2.85-1.45c.97.46 1.28 1.86.68 3.12Z"
						style={{
							fill: '#e0e783',
						}}
					/>
					<path
						d="M112.6 61.83c-.58 1.22-1.81 1.84-2.74 1.4-.94-.44-1.23-1.79-.65-3.01.58-1.22 1.81-1.84 2.74-1.4.94.44 1.23 1.79.65 3.01Z"
						style={{
							fill: '#e0e783',
						}}
					/>
					<path
						d="M112.53 61.8c-.56 1.17-1.74 1.77-2.64 1.34-.9-.43-1.18-1.72-.63-2.89.56-1.17 1.74-1.77 2.64-1.34.9.43 1.18 1.72.63 2.89Z"
						style={{
							fill: '#e1e884',
						}}
					/>
					<path
						d="M112.46 61.77c-.53 1.12-1.67 1.7-2.53 1.29-.87-.41-1.13-1.65-.6-2.78.53-1.12 1.67-1.7 2.53-1.29.87.41 1.13 1.65.6 2.78Z"
						style={{
							fill: '#e1e884',
						}}
					/>
					<path
						d="M112.4 61.74c-.51 1.08-1.6 1.63-2.43 1.23-.83-.39-1.09-1.58-.58-2.66.51-1.08 1.6-1.63 2.43-1.23.83.39 1.09 1.58.58 2.66Z"
						style={{
							fill: '#e1e884',
						}}
					/>
					<path
						d="M112.33 61.7c-.49 1.03-1.53 1.56-2.32 1.18-.79-.38-1.04-1.52-.55-2.54.49-1.03 1.53-1.56 2.32-1.18.79.38 1.04 1.52.55 2.54Z"
						style={{
							fill: '#e2e883',
						}}
					/>
					<path
						d="M112.27 61.67c-.47.98-1.46 1.49-2.22 1.13-.76-.36-.99-1.45-.53-2.43.47-.98 1.46-1.49 2.22-1.13.76.36.99 1.45.53 2.43Z"
						style={{
							fill: '#e2e883',
						}}
					/>
					<path
						d="M112.2 61.64c-.44.93-1.39 1.41-2.11 1.07-.72-.34-.95-1.38-.5-2.31.44-.93 1.39-1.41 2.11-1.07.72.34.95 1.38.5 2.31Z"
						style={{
							fill: '#e2e883',
						}}
					/>
					<path
						d="M112.14 61.61c-.42.89-1.32 1.34-2 1.02-.69-.33-.9-1.31-.48-2.2.42-.89 1.32-1.34 2-1.02.69.33.9 1.31.48 2.2Z"
						style={{
							fill: '#e2e883',
						}}
					/>
					<path
						d="M112.07 61.58c-.4.84-1.25 1.27-1.9.97-.65-.31-.85-1.24-.45-2.08.4-.84 1.25-1.27 1.9-.97.65.31.85 1.24.45 2.08Z"
						style={{
							fill: '#e2e884',
						}}
					/>
					<path
						d="M112.01 61.55c-.38.8-1.18 1.2-1.79.91-.61-.29-.8-1.17-.43-1.97.38-.8 1.18-1.2 1.79-.91.61.29.8 1.17.43 1.97Z"
						style={{
							fill: '#e3e883',
						}}
					/>
					<path
						d="M111.94 61.52c-.36.75-1.11 1.13-1.69.86-.58-.27-.76-1.1-.4-1.85s1.11-1.13 1.69-.86c.58.27.76 1.1.4 1.85Z"
						style={{
							fill: '#e3e883',
						}}
					/>
					<path
						d="M111.88 61.49c-.33.7-1.04 1.06-1.58.8s-.71-1.03-.38-1.74c.33-.7 1.04-1.06 1.58-.8s.71 1.03.38 1.74Z"
						style={{
							fill: '#e3e883',
						}}
					/>
					<path
						d="M111.81 61.46c-.31.65-.97.99-1.48.75-.5-.24-.66-.96-.35-1.62.31-.65.97-.99 1.48-.75.5.24.66.96.35 1.62Z"
						style={{
							fill: '#e3e883',
						}}
					/>
					<path
						d="M111.75 61.42c-.29.61-.9.92-1.37.7-.47-.22-.61-.9-.33-1.5s.9-.92 1.37-.7c.47.22.61.9.33 1.5Z"
						style={{
							fill: '#e3e983',
						}}
					/>
					<path
						d="M111.68 61.39c-.27.56-.83.85-1.27.64-.43-.2-.57-.83-.3-1.39.27-.56.83-.85 1.27-.64.43.2.57.83.3 1.39Z"
						style={{
							fill: '#e3e983',
						}}
					/>
					<path
						d="M111.62 61.36c-.24.51-.76.78-1.16.59-.4-.19-.52-.76-.28-1.27s.76-.78 1.16-.59c.4.19.52.76.28 1.27Z"
						style={{
							fill: '#e3e983',
						}}
					/>
					<path
						d="M111.55 61.33c-.22.47-.69.71-1.05.54-.36-.17-.47-.69-.25-1.16.22-.47.69-.71 1.05-.54.36.17.47.69.25 1.16Z"
						style={{
							fill: '#e3e983',
						}}
					/>
					<path
						d="M111.49 61.3c-.2.42-.63.64-.95.48-.32-.15-.43-.62-.23-1.04.2-.42.63-.64.95-.48.32.15.43.62.23 1.04Z"
						style={{
							fill: '#e3e983',
						}}
					/>
					<path
						d="M111.42 61.27c-.18.37-.56.57-.84.43-.29-.14-.38-.55-.2-.92s.56-.57.84-.43c.29.14.38.55.2.92Z"
						style={{
							fill: '#e3e983',
						}}
					/>
					<path
						d="M111.36 61.24c-.16.33-.49.5-.74.38s-.33-.48-.18-.81c.16-.33.49-.5.74-.38s.33.48.18.81Z"
						style={{
							fill: '#e4e983',
						}}
					/>
					<path
						d="M111.29 61.21c-.13.28-.42.42-.63.32-.22-.1-.28-.41-.15-.69.13-.28.42-.42.63-.32.22.1.28.41.15.69Z"
						style={{
							fill: '#e4e983',
						}}
					/>
					<path
						d="M111.22 61.18c-.11.23-.35.35-.53.27-.18-.09-.24-.35-.13-.58.11-.23.35-.35.53-.27.18.09.24.35.13.58Z"
						style={{
							fill: '#e4e983',
						}}
					/>
					<path
						d="M111.16 61.15c-.09.19-.28.28-.42.22-.14-.07-.19-.28-.1-.46.09-.19.28-.28.42-.22.14.07.19.28.1.46Z"
						style={{
							fill: '#e4e983',
						}}
					/>
					<path
						d="M111.09 61.11c-.07.14-.21.21-.32.16-.11-.05-.14-.21-.07-.35.07-.14.21-.21.32-.16.11.05.14.21.07.35Z"
						style={{
							fill: '#e4e983',
						}}
					/>
					<path
						d="M111.03 61.08c-.04.09-.14.14-.21.11-.07-.03-.09-.14-.05-.23.04-.09.14-.14.21-.11.07.03.09.14.05.23Z"
						style={{
							fill: '#e4e983',
						}}
					/>
					<path
						d="M110.96 61.05s-.07.07-.11.05c-.04-.02-.05-.07-.02-.12.02-.05.07-.07.11-.05.04.02.05.07.02.12Z"
						style={{
							fill: '#e4e983',
						}}
					/>
				</g>
			</g>
			<path
				d="M136.46 60.03c.64 1.78 1.45 3.48 2.44 5.03 1.02 1.58 2.06 3.27 3.93 3.97 1.65.62 4.62 1.4 5.73-.37 1.12-1.78-.09-4.34-1.18-5.86-2.03-2.79-4.07-5.58-5.94-8.47-1.94-3-3.01-5.68-3.01-9.22 0-.96.13-2.19-.78-2.76-3.09-1.96-3.1 4.41-3.12 5.76-.05 3.86.55 8.08 1.93 11.92Z"
				style={{
					fill: 'url(#minyak_goreng_svg__g)',
					mixBlendMode: 'screen',
				}}
			/>
			<path
				d="M144.79 27.55c0 1.89-6.24 3.43-13.94 3.43s-13.93-1.53-13.93-3.43v14.26h.05c0 1.89 6.24 3.43 13.93 3.43s13.94-1.54 13.94-3.43V27.55h-.05Z"
				style={{
					fill: '#bf2026',
				}}
			/>
			<path
				d="M117.74 28.7c-.52-.36-.82-.74-.82-1.14v14.26h.05c0 .35.22.69.61 1.01-.07-2.24.14-11.87.16-14.12ZM144.79 27.55c0 .85-1.28 1.63-3.37 2.23.16 2.37.28 12.06-.07 14.29 2.17-.6 3.49-1.39 3.49-2.27V27.54h-.05Z"
				style={{
					fill: '#b21f29',
				}}
			/>
			<path
				d="M144.86 27.55c0 1.89-6.25 3.43-13.97 3.43s-13.97-1.53-13.97-3.43 6.25-3.43 13.97-3.43 13.97 1.53 13.97 3.43Z"
				style={{
					fill: 'url(#minyak_goreng_svg__h)',
				}}
			/>
			<path
				d="M131.04 30.19c-4.08-.16-12.42-.17-14.07-2.87-.02.08-.05.16-.05.24 0 1.89 6.25 3.43 13.97 3.43s13.75-1.49 13.95-3.34c-1.6 1.51-8.3 2.76-13.8 2.55Z"
				style={{
					fill: '#fbc9b9',
				}}
			/>
			<path
				d="M127.76 43.89c-10.02-.01-10.79-8.45-10.79-10.35h-.05v8.26h.05c0 1.89 6.24 3.43 13.93 3.43s13.94-1.54 13.94-3.43v-8.26c0 1.89-7.87 10.36-17.08 10.35Z"
				style={{
					fill: '#9c1b25',
				}}
			/>
			<path
				d="M130.58 45c.31-.02.62-.02.92 0V32.22c0-.5-.25-.92-.56-.92s-.56.41-.56.92v12.79s.05-.02.09-.01c.04 0 .08.01.12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M131 31.31s.07.08.08.15c.29 1.24.11 12.72.1 13.45.14 0 .19.08.32.08V32.21c0-.47-.22-.85-.5-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M130.58 45c.06-.73 0-11.46.08-13 .02-.39.21-.66.29-.7-.31 0-.56.41-.56.91V45h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M132.78 44.88c.31-.02.61-.02.92 0V32.1c0-.51-.25-.92-.56-.92s-.56.41-.56.92v12.79s.05-.01.08-.01c.04 0 .08.01.12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M133.2 31.2s.06.08.08.14c.29 1.24.11 12.72.1 13.45.14 0 .19.07.33.08V32.09c0-.47-.22-.85-.5-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M132.78 44.88c.06-.73 0-11.47.08-13 .02-.39.21-.66.29-.7-.31 0-.56.41-.56.92v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M128.37 45c.31-.02.62-.02.92 0V32.22c0-.5-.25-.92-.56-.92s-.56.41-.56.92v12.79s.05-.02.09-.01c.04 0 .08.01.12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M128.79 31.31s.06.08.08.15c.29 1.24.11 12.72.1 13.45.14 0 .19.08.32.08V32.21c0-.47-.22-.85-.5-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M128.37 45c.06-.73 0-11.46.08-13 .02-.39.21-.66.29-.7-.31 0-.56.41-.56.91V45h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M135.01 44.89c.31-.02.62-.02.92 0V32.11c0-.5-.25-.92-.56-.92s-.57.41-.57.92V44.9s.05-.01.09 0c.04 0 .08 0 .12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M135.42 31.21s.07.08.08.14c.29 1.24.11 12.72.1 13.45.14 0 .19.08.33.08V32.1c0-.47-.22-.85-.51-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M135.01 44.89c.06-.73 0-11.46.08-13 .02-.39.21-.66.28-.7-.31 0-.57.41-.57.91v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M137.17 44.55c.31-.02.62-.02.92-.01V31.76c0-.5-.25-.92-.56-.92s-.56.41-.56.92v12.79s.05-.01.08-.01c.04 0 .08 0 .12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M137.59 30.86s.07.09.08.14c.29 1.24.11 12.71.1 13.45.14 0 .19.07.32.08V31.75c0-.47-.22-.84-.5-.89Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M137.17 44.55c.06-.73 0-11.46.08-13.01.02-.38.21-.66.29-.7-.31 0-.56.41-.56.91v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M139.25 44.25c.31-.02.62-.02.92 0V31.47c0-.51-.25-.92-.56-.92s-.57.41-.57.92v12.79s.05-.01.09-.01c.04 0 .08 0 .12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M139.67 30.56s.07.08.08.14c.29 1.24.11 12.72.1 13.45.14 0 .19.08.32.08V31.45c0-.47-.22-.84-.5-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M139.25 44.25c.06-.73 0-11.46.08-13 .02-.39.21-.66.29-.7-.31 0-.57.41-.57.91v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M141.02 43.9c.28-.02.56-.02.84 0V31.12c0-.51-.23-.92-.51-.92s-.51.41-.51.92v12.79s.05-.01.08 0c.04 0 .07 0 .11.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M141.4 30.22s.06.08.07.14c.26 1.24.1 12.72.09 13.45.13 0 .17.08.3.08V31.11c0-.47-.2-.84-.46-.89Z"
				style={{
					fill: '#891729',
				}}
			/>
			<path
				d="M141.02 43.9c.06-.73 0-11.46.07-13 .02-.39.19-.66.26-.7-.28 0-.51.41-.51.91V43.9h.19Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M142.65 43.25c.22-.02.43-.02.65-.01V30.46c0-.51-.18-.92-.4-.92s-.4.41-.4.92v12.79s.04-.01.06-.01c.03 0 .06.01.09.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M142.95 29.57s.05.08.06.14c.2 1.24.08 12.72.07 13.45.1 0 .13.07.23.08V30.46c0-.47-.16-.84-.36-.89Z"
				style={{
					fill: '#891729',
				}}
			/>
			<path
				d="M142.65 43.25c.04-.73 0-11.46.05-13.01.01-.38.15-.66.2-.7-.22 0-.4.41-.4.91v12.79h.15Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M143.94 42.67h.1c.17-.17.35-.32.55-.45V29.88c0-.5-.18-.92-.4-.92s-.4.41-.4.92v12.79s.04-.01.06-.01c.03 0 .06 0 .09.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M144.37 42.37c.07-.06.15-.1.22-.15V29.88c0-.47-.16-.84-.36-.9.02.05.05.09.06.15.19 1.16.1 12.18.08 13.24Z"
				style={{
					fill: '#891729',
				}}
			/>
			<path
				d="M143.94 42.67c.04-.73 0-11.46.05-13 .01-.39.15-.66.2-.7-.22 0-.4.41-.4.91v12.79h.15Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M120.65 43.91h.17V31.13c0-.51-.23-.92-.51-.92s-.51.41-.51.92v12.26c.28.21.56.37.86.53Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M120.37 30.23s.06.09.07.15c.26 1.23.1 12.72.09 13.45.12 0 .17.07.29.08V31.13c0-.47-.2-.85-.46-.9Z"
				style={{
					fill: '#891729',
				}}
			/>
			<path
				d="M120 43.51c.03-1.16 0-11.26.05-12.6.02-.39.19-.66.26-.7-.28 0-.51.41-.51.92v12.26c.07.05.14.08.21.13Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M118.91 43.34h.18V30.56c0-.51-.18-.92-.4-.92s-.4.41-.4.92v12.39c.24.1.44.23.62.39Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M118.73 29.66c.02.05.05.09.06.15.2 1.23.08 12.72.07 13.45.1 0 .13.07.23.08V30.56c0-.47-.16-.84-.36-.9Z"
				style={{
					fill: '#891729',
				}}
			/>
			<path
				d="M118.44 43.02c.02-1.09 0-11.3.04-12.68.01-.39.15-.66.2-.7-.22 0-.4.41-.4.91v12.39c.06.02.11.05.16.08Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M117.28 42.24c.14.14.33.27.52.4.04-.03.06-.06.1-.09V29.93c0-.53-.18-.97-.4-.97s-.4.43-.4.97v12.19c.06.03.12.06.18.12Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M117.68 42.55s.07.06.11.08c.04-.03.08-.05.11-.08V29.93c0-.5-.16-.89-.36-.95.02.05.04.09.06.15.18 1.18.1 12.14.08 13.41Z"
				style={{
					fill: '#891729',
				}}
			/>
			<path
				d="M117.26 42.23c.01-1.35 0-11.23.04-12.53.01-.41.15-.69.2-.74-.22 0-.4.43-.4.97v12.19c.06.03.11.06.16.11Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M126.16 44.87c.31-.02.62-.01.92 0V32.09c0-.51-.25-.92-.56-.92s-.57.41-.57.92v12.79s.05-.01.09-.01c.04 0 .08 0 .12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M126.58 31.19s.07.08.08.14c.29 1.23.11 12.72.1 13.45.14 0 .19.07.33.08V32.08c0-.47-.22-.84-.51-.89Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M126.16 44.87c.06-.73 0-11.46.08-13 .02-.39.21-.66.28-.7-.31 0-.57.41-.57.91v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M123.94 44.68c.31-.02.62-.02.92 0V31.9c0-.51-.25-.92-.57-.92s-.56.41-.56.92v12.79s.05-.01.09-.01c.04 0 .08.01.12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M124.35 31s.07.09.08.14c.29 1.24.11 12.72.1 13.45.14 0 .19.07.33.08V31.89c0-.47-.22-.84-.51-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M123.94 44.68c.06-.73 0-11.46.08-13 .02-.39.21-.66.29-.7-.31 0-.56.41-.56.92v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M121.8 44.38c.31-.02.61-.02.92-.01V31.59c0-.51-.25-.92-.56-.92s-.57.41-.57.92v12.79s.05-.01.08-.01c.04 0 .08.01.12.01Z"
				style={{
					fill: '#d12027',
				}}
			/>
			<path
				d="M122.22 30.7s.07.08.08.14c.29 1.24.12 12.72.1 13.46.14 0 .19.07.33.08V31.6c0-.47-.22-.85-.5-.9Z"
				style={{
					fill: '#a41e28',
				}}
			/>
			<path
				d="M121.8 44.38c.06-.73 0-11.46.08-13 .02-.39.21-.65.29-.7-.31 0-.57.41-.57.92v12.79h.21Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
			<path
				d="M130.9 44.66c-7.69 0-13.93-1.53-13.93-3.43h-.05v.58h.05c0 1.89 6.24 3.43 13.93 3.43s13.94-1.54 13.94-3.43v-.58c0 1.89-6.24 3.43-13.94 3.43Z"
				style={{
					fill: '#ee2d33',
				}}
			/>
		</g>
	</svg>
);

export default MinyakGoreng;
