import * as React from 'react';

const SvgHighTemp = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 101.05 176.67"
		{...props}
	>
		<path
			d="M7.99 156.17c0-9.85 5.63-18.36 13.85-22.54l.05-78.33h-8.04v73.53C5.64 133.01 0 141.52 0 151.37c0 10.88 6.88 20.13 16.52 23.7-5.22-4.63-8.53-11.38-8.53-18.91Z"
			style={{
				fill: '#ef3e5c',
			}}
		/>
		<path
			d="M21.92.56c-4.66 1.46-8.07 5.76-8.07 10.89V55.3h8.04L21.93.56Z"
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d="m21.88 55.3-.05 78.33c-8.22 4.18-13.85 12.69-13.85 22.54 0 7.53 3.31 14.28 8.53 18.91a25.09 25.09 0 0 0 8.78 1.59c13.97 0 25.3-11.33 25.3-25.3 0-9.85-5.63-18.36-13.85-22.54V55.3H21.88Z"
			style={{
				fill: '#cd1d50',
			}}
		/>
		<path
			d="M25.3 0c-1.19 0-2.31.23-3.38.56l-.04 54.74h14.86V11.44C36.74 5.11 31.62 0 25.29 0Z"
			style={{
				fill: '#bdc4ce',
			}}
		/>
		<path
			d="M84.48 131.84c2.38 0 4.43-.39 6.15-1.2 1.72-.8 3.51-2.08 5.39-3.84l4.45 4.56c-4.34 4.8-9.6 7.21-15.78 7.21s-11.32-1.99-15.4-5.97c-4.08-3.98-6.12-9.02-6.12-15.11s2.08-11.15 6.24-15.23c4.15-4.06 9.4-6.08 15.75-6.08s11.64 2.34 15.9 7.02l-4.4 4.8c-1.95-1.88-3.8-3.18-5.53-3.93-1.73-.73-3.77-1.11-6.12-1.11-4.14 0-7.61 1.34-10.42 4.02-2.81 2.67-4.22 6.08-4.22 10.25s1.39 7.62 4.18 10.41c2.79 2.8 6.11 4.19 9.93 4.19ZM54 87.01c-5.06 0-9.17 4.1-9.17 9.17s4.1 9.16 9.17 9.16 9.17-4.11 9.17-9.16-4.1-9.17-9.17-9.17Zm0 13.37c-2.32 0-4.2-1.88-4.2-4.19s1.88-4.21 4.2-4.21 4.2 1.89 4.2 4.21-1.88 4.19-4.2 4.19Z"
			style={{
				fill: '#fff',
			}}
		/>
		<path
			style={{
				fill: '#dbf0fc',
			}}
			d="M25.42 61.62h11.32v4.59H25.42zM25.42 74.64h11.32v4.59H25.42zM25.42 87.66h11.32v4.59H25.42zM25.42 100.67h11.32v4.6H25.42zM25.42 113.69h11.32v4.59H25.42z"
		/>
	</svg>
);

export default SvgHighTemp;
