import React, {useEffect, useState} from 'react';

import axios from "axios";
import styles from '../../styles/components/RadarPanel.module.scss'
import {axios6717} from "../../utils/axiosInstance";

import {Radar} from "react-chartjs-2";

import {
    Chart,
    PolarAreaController, RadialLinearScale, PointElement, LineElement, ArcElement
} from 'chart.js';
import Dropdown from "../Dropdown/Dropdown";
import {
    estateNodeOptions,
    marvesRadarNodeOptions,
    radarModelPlantOptions,
    radarNodeOptions
} from "../../utils/dictionary";
import useAuth from "../../hooks/useAuth";

Chart.register(PolarAreaController, RadialLinearScale, PointElement, LineElement, ArcElement);

function RadarPanel() {
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);

    const [selectedField, setSelectedField] = useState({label:"Food Estate Ria Ria", value: "node_23"});
    const [selectedPlant, setSelectedPlant] = useState({label:"Kentang", value: "kentang"});
    const [radarData, setRadarData] = useState({});
    const [radarModelData, setRadarModelData] = useState({});
    const [chartData, setChartData] = useState({});

    const {auth} = useAuth();
    const user = auth.user;
    const retrieveRadarData = () => {
        const radarURL = `/api/${selectedField.value}/radar`;
        // const radarURL = 'api/plant_model/jagung'

        axios6717.get(radarURL).then((d)=>{
            setRadarData(d.data)
        }).catch((e) => {
            console.error(e)
        }).finally(()=>{
            setIsRetrieved(true)
        })
    }

    useEffect(() => {
        const radarURL = `/api/${selectedField.value}/radar`;
        const modelPlantURL = `api/plant_model/${selectedPlant.value}`;

        const getRadar = axios6717.get(radarURL)
        const getModelPlantRadar = axios6717.get(modelPlantURL)

        axios.all([
            getRadar,
            getModelPlantRadar
        ]).then(
            axios.spread((...allData)=>{
                const combinedChartData = {
                    labels:['Nitrogen', 'Phosphorous', 'Potassium', "Konduktivitas Tanah", "Kelembapan Tanah", "pH Tanah", "Temperatur Tanah", "Cahaya"],
                    datasets: [
                        {
                        label: `Tanah ${selectedField.label}`,
                        data: Object.values(allData[0].data),
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgb(255, 99, 132)',
                        pointBackgroundColor: 'rgb(255, 99, 132)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)',

                        // backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        // borderColor: 'rgb(217, 186, 101)',
                        // pointBackgroundColor: 'rgb(217, 186, 101)',
                        // pointBorderColor: '#fff',
                        // pointHoverBackgroundColor: '#fff',
                        // pointHoverBorderColor: 'rgb(217, 186, 101)'
                    }, {
                        label: `Tanaman ${selectedPlant.label}`,
                        data: [
                            allData[1].data.nitrogen,
                            allData[1].data.phosphorous,
                            allData[1].data.potassium,
                            allData[1].data.soil_conductivity,
                            allData[1].data.soil_moisture,
                            allData[1].data.soil_ph,
                            allData[1].data.soil_temperature,
                            allData[1].data.ambient_light
                        ],
                        fill: true,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgb(54, 162, 235)',
                        pointBackgroundColor: 'rgb(54, 162, 235)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(54, 162, 235)'
                        }
                    ]
                }

                setChartData(combinedChartData)

            })
        ).catch((e) => {
            console.error(e)
        }).finally(()=>{
            setIsAssigned(true)
        })

        // axios6717.get(radarURL).then((d)=>{
        //     console.log(d.data.nitrogen)
        //     const cd = {
        //         labels:Object.keys(d.data),
        //         datasets: [{
        //             label: `Tanah ${selectedField.label}`,
        //             data: Object.values(d.data),
        //             fill: true,
        //             backgroundColor: 'rgba(255, 99, 132, 0.2)',
        //             borderColor: 'rgb(255, 99, 132)',
        //             pointBackgroundColor: 'rgb(255, 99, 132)',
        //             pointBorderColor: '#fff',
        //             pointHoverBackgroundColor: '#fff',
        //             pointHoverBorderColor: 'rgb(255, 99, 132)'
        //         }]
        //     }
        //
        //     setChartData(cd)
        //
        // }).catch((e) => {
        //     console.error(e)
        // }).finally(()=>{
        //     setIsAssigned(true)
        // })
        // return () => {
        //     effect
        // };
    }, [selectedField, selectedPlant]);


    // useEffect(() => {
    //     if(!isRetrieved){
    //         console.log("RENDERED ISRETRIEVED = FALSE")
    //         retrieveRadarData();
    //     }else{
    //         console.log("RENDERED ELSE")
    //         console.log(selectedField)
    //         const cd = {
    //             labels:Object.keys(radarData),
    //             datasets: [{
    //                 label: `Tanah ${selectedField.label}`,
    //                 data: Object.values(radarData),
    //                 fill: true,
    //                 backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //                 borderColor: 'rgb(255, 99, 132)',
    //                 pointBackgroundColor: 'rgb(255, 99, 132)',
    //                 pointBorderColor: '#fff',
    //                 pointHoverBackgroundColor: '#fff',
    //                 pointHoverBorderColor: 'rgb(255, 99, 132)'
    //             }]
    //         }
    //
    //         setChartData(cd)
    //         setIsAssigned(true);
    //     }
    //
    //     return()=>{
    //         //setIsRetrieved(false)
    //         console.log("return rendered")
    //         console.log(`the value of isRetrieved = ${isRetrieved}`)
    //     }
    //     // eslint-disable-next-lin
    // }, [isRetrieved, selectedField]);


    if(!isAssigned){
        return (
            <div className={styles.loading_container}>
                <h2>TINJAUAN PROFIL TANAH</h2>
            </div>
        )
    }else {
        return (
            <div className={styles.radar_main_container}>
                <div className={styles.radar_chart_container}>
                    <Radar
                        height={'500px'}
                        width={'inherit'}
                        data={chartData}
                        options={{
                            responsive:true,
                            maintainAspectRatio: false,
                            scales: {
                                r: {
                                    max: 1,
                                    min: 0,
                                    ticks: {
                                        stepSize: 0.1,
                                        color: 'rgba(255,255,255,0.5)',
                                        backdropColor:"rgba(0,0,0,0.1)"
                                    },
                                    grid: {
                                        color: 'rgba(255,255,255,0.5)'
                                    },
                                    angleLines: {
                                        color: 'rgba(255,255,255,0.5)'
                                    },
                                    pointLabels: {
                                        color: 'rgba(255,255,255,0.5)'
                                    },

                                },

                            }
                        }}
                    />
                </div>
                <div className={styles.selection_container}>
                    <div className={styles.selection_title}>
                        <h2>Pilihan Tanah dan Tanaman</h2>
                    </div>
                    <div className={styles.dropdown_wrapper}>
                        <div className={styles.dropdown_menu_container}>
                            <div className={styles.selection_caption}>
                                <p>Pilih untuk melihat profil tanah</p>
                            </div>
                            <Dropdown
                                dropdownOptions={

                                (auth.user === 'marves_admin') ? marvesRadarNodeOptions :
                                    (auth.user === 'marves_estate') ? estateNodeOptions : radarNodeOptions
                                }
                                selected={selectedField}
                                onSelectedChange={setSelectedField}
                            />
                        </div>
                        <div className={styles.dropdown_menu_container}>
                            <div className={styles.selection_caption}>
                                <p>Pilih untuk melihat profil tanaman</p>
                            </div>
                            <Dropdown
                                dropdownOptions={radarModelPlantOptions}
                                selected={selectedPlant}
                                onSelectedChange={setSelectedPlant}
                            />
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}

export default RadarPanel;