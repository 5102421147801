import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from "react-router-dom";

import styles from '../../styles/pages/PriceRegionDetails.module.scss'
import {pDict} from "../../utils/dictionary";
import {formatDateToString} from "../../utils/textFormatter";
import {generateAPIURL} from "../../utils/generator";
import {axios6717} from "../../utils/axiosInstance";
import axios from "axios";
import MSTGraph from "../../components/MST/MSTGraph/MSTGraph";
import MSTInfo from "../../components/MST/MSTInfo/MSTInfo";
import PriceGraphPanel from "../../components/PriceGraphPanel/PriceGraphPanel";
import {TabTitle} from "../../utils/GeneralFunctions";
import {PriceWidget} from "../../components/PriceWidget/PriceWidget";
import {handleMSTExport, handlePriceExport} from "../../utils/downloadHandler";

function PriceRegionDetails() {

    TabTitle('Tinjauan Harga Komoditas')

    const { type } = useParams();
    const provinceName = pDict[type];
    const region = type;

    const [isRetrieved, setIsRetrieved] = useState(false);

    const [graphData, setGraphData] = useState(); // -- mstURL
    const [nPrice, setNPrice] = useState(); // nPriceURL
    const [dPrice, setDPrice] = useState(); // dPriceURL
    const [comPrices, setComPrices] = useState(); // allPriceURL

    const retrievePriceData = async ()=>{
        const d = new Date();
        d.setDate(d.getDate() - 3);
        const current = formatDateToString(d);

        const d2 = new Date();
        d2.setDate(d2.getDate() - 10);
        const lastWeek = formatDateToString(d2);

        const d4 = new Date();
        d4.setMonth(d4.getMonth() - 1);
        const lastMonth = formatDateToString(d4);

        const mstURL = generateAPIURL('mst', 'province', region, current);
        const nPriceURL = generateAPIURL('price', 'province', region, current);
        const dPriceURL = generateAPIURL('price', 'province', region, lastWeek);
        const allPriceURL = `/api/price/all/${region}/from=${lastMonth}`;

        const getMST = axios6717.get(mstURL);
        const getNPrice = axios6717.get(nPriceURL);
        const getDPrice = axios6717.get(dPriceURL);
        const getAllPrice = axios6717.get(allPriceURL );


        axios.all([
            getMST,
            getNPrice,
            getDPrice,
            getAllPrice,
        ]).then(
            axios.spread((...allData)=>{
                setGraphData(allData[0].data);
                setNPrice(allData[1].data);
                setDPrice(allData[2].data);
                setComPrices(allData[3].data);

                setIsRetrieved(true);

            })
        );

    };

    useEffect(() => {
        if(!isRetrieved){
            retrievePriceData()
        }
        // return () => {
        //     effect
        // };
    }, [isRetrieved]);


    if(!provinceName){
        return(
            <Navigate replace to="/agroekonomi"/>
        )
    } else {
        if(isRetrieved){
            return (
                <div className={styles.prd_main_container}>
                    <div className={styles.prd_main_title}>
                        <h2>TINJAUAN HARGA KOMODITAS PROVINSI  {provinceName.toUpperCase()}</h2>
                    </div>
                    <div className={styles.prd_content_wrapper}>
                        <div className={styles.mst_wrapper}>
                            <div className={styles.mst_info_container}>
                                <MSTInfo />
                            </div>
                            <div className={styles.mst_graph_container}>
                                <MSTGraph graphData={graphData.edges} nPrice={nPrice} dPrice={dPrice} level={'province'}></MSTGraph>
                            </div>
                        </div>
                        <div className={styles.price_widget_container}>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'bawang_merah'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'bawang_putih'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'cabai_merah'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'cabai_rawit'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'beras'} level={'province'} region={region}/>
                        </div>
                        <div className={styles.price_widget_container}>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'daging_ayam'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'daging_sapi'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'telur_ayam'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'minyak_goreng'} level={'province'} region={region}/>
                            <PriceWidget nPrice={nPrice} dPrice={dPrice} com={'gula_pasir'} level={'province'} region={region}/>
                        </div>
                        <div className={styles.price_graph_wrapper}>
                            <div className={styles.price_graph_1_container}>
                                <PriceGraphPanel commodity={'bawang_merah'} comPrice={comPrices['bawang_merah']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_2_container}>
                                <PriceGraphPanel commodity={'bawang_putih'} comPrice={comPrices['bawang_putih']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_3_container}>
                                <PriceGraphPanel commodity={'daging_ayam'} comPrice={comPrices['daging_ayam']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_4_container}>
                                <PriceGraphPanel commodity={'daging_sapi'} comPrice={comPrices['daging_sapi']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_5_container}>
                                <PriceGraphPanel commodity={'cabai_merah'} comPrice={comPrices['cabai_merah']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_6_container}>
                                <PriceGraphPanel commodity={'cabai_rawit'} comPrice={comPrices['cabai_rawit']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_7_container}>
                                <PriceGraphPanel commodity={'beras'} comPrice={comPrices['beras']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_8_container}>
                                <PriceGraphPanel commodity={'telur_ayam'} comPrice={comPrices['telur_ayam']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_9_container}>
                                <PriceGraphPanel commodity={'minyak_goreng'} comPrice={comPrices['minyak_goreng']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                            <div className={styles.price_graph_10_container}>
                                <PriceGraphPanel commodity={'gula_pasir'} comPrice={comPrices['gula_pasir']} nPrice={nPrice} dPrice={dPrice} region={region} />
                            </div>
                        </div>

                        <div className={styles.download_button_container}>
                            <div className={styles.download_button} onClick={()=>{handlePriceExport(region, provinceName)}}>
                                Unduh Data Harga {provinceName} dari 1 Januari 2018
                            </div>

                            <div className={styles.download_button} onClick={()=>{handleMSTExport(region, provinceName)}}>
                                Unduh Edgelist MST {provinceName} Hari Ini
                            </div>
                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.prd_main_container}>
                    <div className={styles.prd_main_title}>
                        <h2>TINJAUAN HARGA KOMODITAS PROVINSI  {provinceName.toUpperCase()}</h2>
                    </div>

                    <div className={styles.prd_main_title}>
                        <p>loading...</p>
                    </div>
                </div>
            )
        }

    }
//provinceID





}

export default PriceRegionDetails;