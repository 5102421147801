import React from 'react';
import styles from '../../styles/pages/TentangKami.module.scss'
import Footer from "../../components/Footer/Footer";
import SvgBfitech from "../../assets/icons/supportlogo/BfItech";
import SvgItdel from "../../assets/icons/supportlogo/Itdel";
import SvgMarvest from "../../assets/icons/supportlogo/Marvest";



function TentangKami() {
    return (
        <div className={styles.about_main_container}>
            <div className={styles.about_title}>
                <h2>TENTANG KAMI</h2>
            </div>
            <div className={styles.about_description}>
                <p>
                    Sembako.net adalah inisiatif penelitian Bandung Fe Institute dalam bidang agro-informatika.  Melalui sembako.net, Bandung Fe Institute menyajikan analitika agroklimatologi dan harga bahan pangan menggunakan data primer dari sensor pertanian dan harga pasar berskala regional.
                    Sembako.net memungkinkan pemantauan situasi terkini keadaan lahan dan harga komoditas guna memberi masukan terkait kebijakan pangan dan pertanian.
                    Untuk informasi lebih lanjut, harap hubungi Bandung Fe Institute melalui laman bandungfe.net.
                </p>
            </div>
            <div className={styles.about_title_team}>
                <h4>TIM SEMBAKO.NET</h4>
            </div>

            <div className={styles.about_description_team}>

                <p>
                    <b>Pelindung</b>
                </p>
                <p>
                    <i>Board of Science Bandung Fe Institute</i>
                </p>
            </div>

            <div className={styles.about_description_team}>

                <p>
                    <b>Pembina</b>
                </p>
                <p>
                    Presiden Bandung Fe Institue - Hokky Situngkir
                </p>
            </div>

            <div className={styles.about_description_team}>

                <p>
                    <b>Juru Bicara Penelitian</b>
                </p>
                <p>
                    Vande Leonardo
                </p>
            </div>

            <div className={styles.about_description_team}>

                <p>
                    <b>Tim Penelitian</b>
                </p>
                {/*<p>*/}
                {/*    Vande Leonardo*/}
                {/*</p>*/}
                <p>
                    Bernad Situmorang
                </p>
                <p>
                    Bunga Indraswari Sekaton
                </p>
                <p>
                    Andhika Lumbantobing
                </p>
                <p>
                    Fausto Keiluhu
                </p>
            </div>
            <div className={styles.about_description_team}>
                <p>
                    <b>Kesekretariatan</b>
                </p>
                <p>
                    Ria Angelia Panjaitan
                </p>
                <p>
                    Cornelia Simbolon
                </p>
            </div>

            <div className={styles.about_title_team}>
                <h4>DIDUKUNG OLEH</h4>
            </div>

            <div className={styles.support_container}>
                <div className={`${styles.support_icon} ${styles.bfitech_icon}`}>
                    <a href="https://bfinews.com" target="_blank" rel="noreferrer">
                        <SvgBfitech height={'50px'} width={'50px'} />
                    </a>
                </div>
                <div className={styles.support_icon}>
                    <SvgItdel height={'50px'} width={'50px'} />
                </div>
                <div className={styles.support_icon}>
                    <SvgMarvest height={'50px'} width={'50px'} />
                </div>

            </div>

            <div className={styles.about_title_team}>
                <h4>KONTAK</h4>
            </div>
            <div className={styles.about_description_team}>

                <p>
                    <b>BFI Technologies</b>
                </p>
                <p>
                    Jl. Sarimadu Permai 175, Bandung, Jawa Barat, Indonesia 40164
                </p>
                <p>
                    +62-2282000390 bfi@bandungfe.net
                </p>
            </div>

            <Footer />
        </div>
    );
}

export default TentangKami;