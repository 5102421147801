import * as React from 'react';

const CerahBerawanMalam = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="cerahBerawanMalam_svg__d"
				cx={-871.88}
				cy={64.53}
				fx={-871.88}
				fy={64.53}
				r={167.54}
				gradientTransform="translate(981.45)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#f8f8f8" />
				<stop offset={0.16} stopColor="#ede8f0" />
				<stop offset={1} stopColor="#9a9ca1" />
			</radialGradient>
			<radialGradient
				id="cerahBerawanMalam_svg__a"
				cx={1158.49}
				cy={-3232.86}
				fx={1158.49}
				fy={-3232.86}
				r={25.15}
				gradientTransform="matrix(.84114 1.15645 1.61769 -1.31205 4355.71 -5498.11)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahBerawanMalam_svg__b"
				cx={-3710.61}
				cy={1484.53}
				fx={-3710.61}
				fy={1484.53}
				r={24.27}
				gradientTransform="matrix(1.17345 -1.10233 -.2198 -.7265 4856.61 -2837)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahBerawanMalam_svg__c"
				cx={73.83}
				cy={144.81}
				fx={73.83}
				fy={144.81}
				r={87.07}
				gradientTransform="matrix(-1 0 0 -.73 147.65 250.59)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="cerahBerawanMalam_svg__e"
				cx={1349.34}
				cy={-3117.02}
				fx={1349.34}
				fy={-3117.02}
				r={10.5}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3844.59 -5498.11)"
				xlinkHref="#cerahBerawanMalam_svg__a"
			/>
			<radialGradient
				id="cerahBerawanMalam_svg__f"
				cx={1349.03}
				cy={-3136.42}
				fx={1349.03}
				fy={-3136.42}
				r={8.26}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3844.59 -5498.11)"
				xlinkHref="#cerahBerawanMalam_svg__a"
			/>
			<radialGradient
				id="cerahBerawanMalam_svg__g"
				cx={15.91}
				cy={191.59}
				fx={15.91}
				fy={191.59}
				r={6.04}
				gradientTransform="rotate(-25.71 -786.338 374.614) scale(1 2.91)"
				xlinkHref="#cerahBerawanMalam_svg__b"
			/>
			<radialGradient
				id="cerahBerawanMalam_svg__h"
				cx={220.28}
				cy={77.79}
				fx={220.28}
				fy={77.79}
				r={67.42}
				gradientTransform="matrix(-1 0 0 -.73 440.57 134.61)"
				xlinkHref="#cerahBerawanMalam_svg__c"
			/>
			<radialGradient
				id="cerahBerawanMalam_svg__i"
				cx={1246.86}
				cy={-3155.15}
				fx={1246.86}
				fy={-3155.15}
				r={8.13}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3844.59 -5498.11)"
				xlinkHref="#cerahBerawanMalam_svg__a"
			/>
			<radialGradient
				id="cerahBerawanMalam_svg__j"
				cx={1246.62}
				cy={-3170.17}
				fx={1246.62}
				fy={-3170.17}
				r={6.39}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3844.59 -5498.11)"
				xlinkHref="#cerahBerawanMalam_svg__a"
			/>
			<radialGradient
				id="cerahBerawanMalam_svg__k"
				cx={175.44}
				cy={114.01}
				fx={175.44}
				fy={114.01}
				r={4.67}
				gradientTransform="rotate(-25.71 -301.968 222.918) scale(1 2.91)"
				xlinkHref="#cerahBerawanMalam_svg__b"
			/>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M218.01 119.87c0 48.74-39.51 88.25-88.25 88.25S41.5 168.61 41.5 119.87s39.51-88.26 88.25-88.26S218 71.13 218 119.86Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__d)',
				}}
			/>
			<path
				d="M191.42 135.48c-8.29-.74-14.22-10.15-13.24-21.02.97-10.86 8.48-19.05 16.78-18.31 8.29.74 14.22 10.15 13.24 21.01-.98 10.86-8.49 19.05-16.78 18.31ZM198.61 143.37c4.97 2.61 6.23 10 2.81 16.5-3.42 6.51-10.21 9.66-15.18 7.05-4.96-2.6-6.22-10-2.8-16.5 3.41-6.51 10.21-9.67 15.17-7.06ZM178.93 135.53c2.94 1.55 3.68 5.9 1.66 9.75-2.02 3.84-6.03 5.7-8.97 4.17-2.93-1.54-3.67-5.9-1.66-9.75 2.02-3.84 6.03-5.71 8.97-4.17ZM48.24 123.06c1.53-10.8 9.44-18.6 17.69-17.44 8.24 1.16 13.68 10.86 12.16 21.65-1.52 10.79-9.44 18.6-17.68 17.43-8.24-1.16-13.69-10.86-12.17-21.64ZM59.14 97.37c-4.82-2.86-5.7-10.3-1.96-16.62 3.73-6.32 10.68-9.13 15.51-6.27 4.82 2.85 5.7 10.29 1.96 16.62-3.73 6.32-10.68 9.13-15.51 6.28ZM77.25 96.38c2.21-3.74 6.31-5.4 9.17-3.71 2.85 1.68 3.37 6.08 1.16 9.82s-6.32 5.4-9.17 3.71c-2.85-1.68-3.38-6.08-1.16-9.82Z"
				style={{
					fill: '#adadb2',
				}}
			/>
			<path
				d="M116.3 110.79c22.47-18.24 31.26-46 19.6-62.02-11.65-16.02-39.33-14.23-61.82 4C51.61 71 42.84 98.78 54.49 114.78c11.67 16.04 39.33 14.23 61.81-3.99Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__a)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M207.76 139.37c-2.98-9.76-18.11-5.72-33.82 9.04-15.71 14.75-26.02 34.62-23.05 44.39 2.97 9.75 18.1 5.72 33.82-9.03 15.7-14.75 26.02-34.64 23.05-44.39Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__b)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="m169.9 161.65-4.73 9.59a.52.52 0 0 1-.4.29l-10.59 1.54c-.44.06-.61.6-.3.91l7.66 7.47c.13.12.18.3.15.47l-1.81 10.54c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-10.59-1.54a.544.544 0 0 1-.4-.29l-4.73-9.59c-.2-.4-.76-.4-.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m170.38 163.87 4.22 8.17c.22.43.65.71 1.13.76l9.16.84-9.68.45c-.61.03-1.18-.34-1.39-.92l-3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m180.67 191.26.21 1.21c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l3.55-.52c5.73 10.29 14.52 15.71 22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m186.73 148.71-3.13 6.34a.34.34 0 0 1-.27.19l-6.99 1.02c-.29.04-.41.4-.2.6l5.06 4.93c.08.08.12.2.1.31l-1.19 6.96c-.05.29.25.51.51.37l6.25-3.29c.1-.05.23-.05.33 0l6.25 3.29c.26.14.56-.08.51-.37l-1.19-6.96c-.02-.11.02-.23.1-.31l5.06-4.93c.21-.2.09-.56-.2-.6l-6.99-1.02a.382.382 0 0 1-.27-.19l-3.13-6.34a.352.352 0 0 0-.63 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m187.04 150.17 2.79 5.4c.15.28.43.47.74.5l6.05.55-6.39.3a.932.932 0 0 1-.92-.61l-2.27-6.14Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m193.84 168.26.14.8c.05.29-.25.51-.51.37l-6.25-3.29a.382.382 0 0 0-.33 0l-6.25 3.29a.349.349 0 0 1-.51-.37l1.19-6.96a.358.358 0 0 0-.1-.31l-5.06-4.93c-.21-.2-.09-.56.2-.6l2.34-.34c3.79 6.8 9.59 10.38 15.15 12.35Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m199.52 169.77-4.73 9.59a.52.52 0 0 1-.4.29l-10.59 1.54c-.44.06-.61.6-.3.91l7.66 7.47c.13.12.18.3.15.47l-1.81 10.54c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-10.59-1.54a.544.544 0 0 1-.4-.29l-4.73-9.59c-.2-.4-.76-.4-.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m200 171.99 4.22 8.17c.22.43.65.71 1.13.76l9.16.84-9.68.45c-.61.03-1.18-.34-1.39-.92l-3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m210.29 199.38.21 1.21c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l3.55-.52c5.73 10.29 14.52 15.71 22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="M92.82 158.9c-1.19-17.41-15.68-31.16-33.39-31.16s-32.2 13.75-33.39 31.16c-13.19.65-23.69 11.56-23.69 24.91s11.17 24.94 24.94 24.94h64.28c13.77 0 24.94-11.17 24.94-24.94s-10.5-24.25-23.69-24.91Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__c)',
				}}
			/>
			<path
				d="M92.82 158.9c-1.19-17.41-15.68-31.16-33.39-31.16s-32.2 13.75-33.39 31.16c-13.19.65-23.69 11.56-23.69 24.91s11.17 24.94 24.94 24.94h64.28c13.77 0 24.94-11.17 24.94-24.94s-10.5-24.25-23.69-24.91Z"
				style={{
					fill: '#9cbdd8',
				}}
			/>
			<path
				d="M59.43 162c-9.39-7.62-13.06-19.21-8.19-25.91 4.87-6.69 16.43-5.94 25.82 1.67 9.39 7.62 13.05 19.22 8.19 25.91-4.87 6.69-16.43 5.94-25.82-1.67Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__e)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M93 184.52c-7.38-5.99-10.26-15.1-6.44-20.36 3.83-5.26 12.91-4.67 20.29 1.31 7.38 5.99 10.25 15.1 6.43 20.36-3.83 5.26-12.91 4.67-20.29-1.31Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__f)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M20.19 185.35c-5.44-8.49-11.77-12.58-14.14-9.14-2.36 3.44.14 13.13 5.58 21.62 5.45 8.49 11.78 12.59 14.14 9.14 2.36-3.45-.13-13.13-5.58-21.62Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__g)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M234.99 88.7c-.92-13.48-12.14-24.13-25.85-24.13s-24.93 10.65-25.85 24.13c-10.21.5-18.34 8.95-18.34 19.28s8.65 19.31 19.31 19.31h49.77c10.66 0 19.31-8.65 19.31-19.31S245.21 89.2 235 88.7Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__h)',
				}}
			/>
			<path
				d="M234.99 88.7c-.92-13.48-12.14-24.13-25.85-24.13s-24.93 10.65-25.85 24.13c-10.21.5-18.34 8.95-18.34 19.28s8.65 19.31 19.31 19.31h49.77c10.66 0 19.31-8.65 19.31-19.31S245.21 89.2 235 88.7Z"
				style={{
					fill: '#9cbdd8',
				}}
			/>
			<path
				d="M209.14 91.1c-7.27-5.9-10.11-14.87-6.34-20.06 3.77-5.18 12.72-4.6 19.99 1.29 7.27 5.9 10.1 14.88 6.34 20.06-3.77 5.18-12.72 4.6-19.99-1.29Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__i)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M235.13 108.53c-5.71-4.64-7.94-11.69-4.98-15.77 2.96-4.07 10-3.61 15.71 1.01 5.71 4.64 7.94 11.7 4.98 15.77-2.97 4.07-10 3.61-15.71-1.01Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__j)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M178.75 109.17c-4.21-6.57-9.11-9.74-10.95-7.07-1.82 2.66.11 10.16 4.32 16.74 4.22 6.57 9.12 9.74 10.95 7.07s-.1-10.16-4.32-16.74Z"
				style={{
					fill: 'url(#cerahBerawanMalam_svg__k)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
		</g>
	</svg>
);

export default CerahBerawanMalam;
