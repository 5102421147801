import axios from "axios";

import styles from '../../styles/pages/Register.module.scss';

import {useEffect, useState} from "react";

import Decorations from "../../assets/svg/LoginOrnament"
import {TabTitle} from "../../utils/GeneralFunctions";
import {useNavigate} from "react-router-dom";
import {axios8081} from "../../utils/axiosInstance";
import useAuth from "../../hooks/useAuth";


export default function Register() {

    TabTitle('Sembako - Register')

    axios.defaults.withCredentials = true;
    // Login Variables
    const [data, setData] = useState({
        org_id:"",
        firstname:"",
        lastname:"",
        username:"",
        email:"",
        password:""
    });
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const { data: res } = await axios8081.post("/api/auth/signup", data);
            navigate("/login");
            return res.payload;
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <div className={styles.container}>

            <div className={styles.main_container}>
                <div className={styles.ornaments}>
                    <Decorations className={styles.ornament1}/>
                    <Decorations className={styles.ornament2}/>
                    <Decorations className={styles.ornament3}/>
                </div>

                <div className={styles.register_form_container}>
                    <div className={styles.register_content_container}>
                        <div className={styles.register_header}>
                            <h3>Daftar</h3>
                            <p>untuk melanjutkan ke dashboard</p>
                        </div>
                        <div className={styles.register_form}>
                            <form className={styles.form_container} onSubmit={handleLogin}>
                                <div className={styles.register_form_object}>
                                    <label>Organization ID</label>
                                    <input
                                        type="text"
                                        placeholder="Registered Organization ID"
                                        name="org_id"
                                        onChange={handleChange}
                                        value={data.org_id}
                                        required
                                        className={styles.input_field}
                                    />
                                </div>
                                <div className={styles.register_form_object}>
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        name="firstname"
                                        onChange={handleChange}
                                        value={data.firstname}
                                        required
                                        className={styles.input_field}
                                    />
                                </div>
                                <div className={styles.register_form_object}>
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastname"
                                        onChange={handleChange}
                                        value={data.lastname}
                                        required
                                        className={styles.input_field}
                                    />
                                </div>
                                <div className={styles.register_form_object}>
                                    <label>Sembako Username</label>
                                    <input
                                        type="text"
                                        placeholder="Your unique username"
                                        name="username"
                                        onChange={handleChange}
                                        value={data.username}
                                        required
                                        className={styles.input_field}
                                    />
                                </div>
                                <div className={styles.register_form_object}>
                                    <label>E-mail Address</label>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleChange}
                                        value={data.email}
                                        required
                                        className={styles.input_field}
                                    />
                                </div>
                                <div className={styles.register_form_object}>
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChange}
                                        value={data.password}
                                        required
                                        className={styles.input_field}
                                    />
                                </div>
                                {error && <div className={styles.error_msg}>{error}</div>}
                                <button type="submit" className={styles.green_btn}>
                                    Daftar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

