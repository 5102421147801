import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./context/AuthProvider";
import {SensorProvider} from "./context/SensorProvider";
import {StationProvider} from "./context/StationProvider";

//axios.defaults.withCredentials = true

// axios8081.interceptors.request.use((req) => {
//     console.log("===== Interceptors Works =====")
//     console.log(req.data)
//
//     return req;
// })

// axios6717.interceptors.request.use( async (req) => {
//     await axios8081.get("/api/verify", {withCredentials:true})
//     return req
// })



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <SensorProvider>
            <StationProvider>
                <App />
            </StationProvider>
        </SensorProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
