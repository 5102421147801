
import styles from '../../styles/pages/Login.module.scss';

import {useEffect, useState} from "react";

import Decorations from "../../assets/svg/LoginOrnament"
import {TabTitle} from "../../utils/GeneralFunctions";
import useAuth from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import {axios8081} from "../../utils/axiosInstance";


export default function Login() {

    TabTitle('Sembako - Login')

    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";


    //axios.defaults.withCredentials = true;
    // Login Variables
    const [data, setData] = useState({ email: "", password: "" });
    const [error, setError] = useState("");

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            //const url = "https://iotsf.bfitech.co.id:8081/api/auth/signin";
            //const url = "http://localhost:8081/api/auth/signin";
            const { data: res } = await axios8081.post(
                "/api/auth/signin",
                data,
                {
                    withCredentials:true
                });


            const user = res.payload.username;
            //const pwd = data.password;
            const role = res.payload.role;
            const accessToken = res.payload.token;


            setAuth({ user, role, accessToken });

            // localStorage.setItem("token", res.payload.token);
            // localStorage.setItem("refreshToken", res.payload.refreshToken);
             localStorage.setItem("username", res.payload.username);
             localStorage.setItem("org_id", res.payload.org_id);

            navigate(from, { replace: true });

            // const url2 = "http://localhost:8081/api/verify";
            // await axios.get(url2, {withCredentials:true})


            //window.location = "/";
            //return res.payload;
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message);
            }
        }
    };

    useEffect(() => {
        if(auth && auth.user !== undefined) {
            navigate("/dashboard");
        }
    }, []);


    return (
        <div className={styles.container}>


            <div className={styles.main_container}>
                <div className={styles.ornaments}>
                <Decorations className={styles.ornament1}/>
                <Decorations className={styles.ornament2}/>
                <Decorations className={styles.ornament3}/>
                </div>

                <div className={styles.login_form_container}>
                    <div className={styles.login_content_container}>
                        <div className={styles.login_header}>
                            <h3>Masuk</h3>
                            <p>untuk melanjutkan ke dashboard</p>
                        </div>
                        <div className={styles.login_form}>
                            <form className={styles.form_container} onSubmit={handleLogin}>
                            <div className={styles.login_form_object}>
                                <label>E-mail Address</label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleChange}
                                    value={data.email}
                                    required
                                    className={styles.input_field}
                                />
                            </div>
                            <div className={styles.login_form_object}>
                                <label>Password</label>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    onChange={handleChange}
                                    value={data.password}
                                    required
                                    className={styles.input_field}
                                />
                            </div>
                                {error && <div className={styles.error_msg}>{error}</div>}
                                <button type="submit" className={styles.green_btn}>
                                    Masuk
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

