import styles from '../../styles/pages/Home.module.scss';

import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";


import LogoSVG from '../../assets/svg/HeaderSvg';
import Section2 from '../../assets/svg/Section2';
import Section3 from '../../assets/svg/Section3';

import { BsFillArrowRightCircleFill } from "react-icons/bs";

import {TabTitle} from "../../utils/GeneralFunctions";
import Footer from "../../components/Footer/Footer";


export default function Home() {

    const particlesInit = useCallback(async (engine) => {
        //console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        //await console.log(container);
    }, []);

    TabTitle('Sembako - Home')

    return (
        <div className={styles.home_container}>
            <div className={styles.main_container}>
                <Particles id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 50,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#f4f4f4",
                            },
                            links: {
                                color: "#F7FE72",
                                distance: 200,
                                enable: true,
                                opacity: 0.4,
                                width: 1,
                            },
                            collisions: {
                                enable: true,
                            },
                            move: {
                                directions: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce",
                                },
                                random: false,
                                speed: 3,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 600,
                                },
                                value: 10,
                            },
                            opacity: {
                                value: 0.4,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 3 },
                            },
                        },
                        detectRetina: true,
                    }}
                />
                <section className={styles.section_one}>
                    <div className={styles.circle_ornament_1}></div>
                    <div className={styles.logo_container}>
                        <LogoSVG />
                    </div>
                </section>
                <section className={styles.section_two}>
                    <div className={styles.content_container}>
                        <div className={styles.text_content_container}>
                            <div className={styles.section_header}>
                                <h1>Farming Management System</h1>
                            </div>
                            <div className={styles.text_grid_container}>
                                <div className={styles.grid_icons}>
                                    <BsFillArrowRightCircleFill className={styles.arrow_icons} height={'20px'}/>
                                </div>
                                <div className={styles.grid_content}>
                                    Melakukan analisis dan pemrosesan data deret waktu, analisis vegetasi, dan analisis geospasial untuk mengetahui dampak lingkungan bagi tumbuhan
                                </div>
                                <div className={styles.grid_icons}>
                                    <BsFillArrowRightCircleFill className={styles.arrow_icons}/>
                                </div>
                                <div className={styles.grid_content}>
                                    Memberikan input yang didasarkan oleh hasil pemrosesan data untuk membuat model analitik prediktif guna mendukung pengambilan keputusan terkait intervensi agroekonomi
                                </div>
                            </div>
                        </div>
                        <div className={styles.image_container}>
                            {/*image here*/}
                            <div className={styles.image_wrapper}>
                                <Section2 width={'90%'} height={'400px'}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.circle_ornament_2}></div>
                </section>

                <section className={styles.section_three}>
                    <div className={styles.content_container}>
                        <div className={styles.image_container}>
                            <div className={styles.image_wrapper}>
                                <Section3 width={'90%'} height={'400px'}/>
                            </div>
                        </div>
                        <div className={styles.text_content_container}>
                            <div className={styles.section_header}>
                                <h1>Data Analytics & Precision Farming</h1>
                            </div>
                            <div className={styles.text_grid_container}>

                                <div className={styles.grid_icons}>
                                    <BsFillArrowRightCircleFill className={styles.arrow_icons}/>
                                </div>
                                <div className={styles.grid_content}>
                                    Menganalisis dampak variabilitas lingkungan terhadap proses pertumbuhan tanaman dan memprediksi hasil panen berdasarkan analisis vegetasi berbasis index spektral dan vegetasi
                                </div>

                                <div className={styles.grid_icons}>
                                    <BsFillArrowRightCircleFill className={styles.arrow_icons}/>
                                </div>
                                <div className={styles.grid_content}>
                                    Membuat model, analisis spasial dan penginderaan jarak jauh untuk mendukung perancangan, pengembangan dan implementasi sistem di lapangan </div>
                                <div className={styles.grid_icons}>
                                    <BsFillArrowRightCircleFill className={styles.arrow_icons}/>
                                </div>
                                <div className={styles.grid_content}>
                                    Mengembangkan sistem pemantauan jarak jauh dengan mengimplementasikan kecerdasan buatan untuk mendukung riset operasional dan pengambilan keputusan</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.circle_ornament_3}></div>
                    <div className={styles.circle_ornament_4}></div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

