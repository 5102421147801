import axios from "axios";


const configHeaders = {
    "content-type": "application/json",
    "Accept": "application/json"
};

export const axios6717 = axios.create({
    baseURL: `https://iotsf.bfitech.co.id:6717`,
    //timeout: 5000,
    withCredentials: true,
    headers: configHeaders
});

export const axios8081 = axios.create({
    baseURL:   `https://iotsf.bfitech.co.id:8081`,
    //baseURL:'http://localhost:8081',
    //timeout: 5000,
    withCredentials: true,
    headers: configHeaders
});