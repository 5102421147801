import {createContext, useState} from "react";

const SensorContext = createContext({});

export const SensorProvider = ({ children}) => {
    const [sensorDetails, setSensorDetails] = useState({});
    return(
        <SensorContext.Provider value={{sensorDetails, setSensorDetails}}>
            {children}
        </SensorContext.Provider>
    )
}

export default SensorContext;