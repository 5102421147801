import React from 'react';
import styles from '../../../styles/components/MSTInfo.module.scss'

function MstInfo(props) {

    return (
        <div className={styles.mst_info_main_container}>
            <div className={styles.mst_info_title}>
                Jaring Interkorelasi Harga
            </div>

            <div className={styles.mst_info_description}>
                <div className={styles.mst_info_description_title}>
                    Deskripsi
                </div>
                <div className={styles.mst_info_caption}> Jika titik melambangkan satu harga produk barang, maka garis yang menghubungkannya adalah kait-mengkait antara perubahan harga keduanya.
                    Semakin tebal garisnya, makin kuat kait-mengkaitnya.
                </div>
            </div>

            <div className={styles.mst_node_description}>
                <div className={styles.node_desc_row}>
                    <div className={styles.node_element}>
                        <div className={`${styles.circle_node} ${styles.green_node}`}></div>
                    </div>
                    <div className={styles.mst_node_desc_caption}>
                        Harga komoditas turun dibandingkan harga minggu sebelumnya
                    </div>
                </div>
                <div className={styles.node_desc_row}>
                    <div className={styles.node_element}>
                        <div className={`${styles.circle_node} ${styles.red_node}`}></div>
                    </div>
                    <div className={styles.mst_node_desc_caption}>
                        Harga komoditas naik dibandingkan harga minggu sebelumnya
                    </div>
                </div>
                <div className={styles.node_desc_row}>
                    <div className={styles.node_element}>
                        <div className={`${styles.circle_node} ${styles.white_node}`}></div>
                    </div>
                    <div className={styles.mst_node_desc_caption}>
                        Harga komoditas tidak berubah dibandingkan harga minggu sebelumnya
                    </div>
                </div>
                <div className={styles.node_desc_row}>
                    <div className={styles.node_element}>
                        <div className={styles.edges_element}></div>
                    </div>
                    <div className={styles.mst_node_desc_caption}>
                        Tingkat keterkaitan antara harga komoditas
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MstInfo;