import * as React from 'react';

const CerahMalam = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
		<defs>
			<radialGradient
				id="cerahMalam_svg__a"
				cx={-70.07}
				cy={69.89}
				fx={-70.07}
				fy={69.89}
				r={167.54}
				gradientTransform="matrix(-1 0 0 1 81.67 0)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#f8f8f8" />
				<stop offset={0.16} stopColor="#ede8f0" />
				<stop offset={1} stopColor="#9a9ca1" />
			</radialGradient>
			<radialGradient
				id="cerahMalam_svg__b"
				cx={1669.89}
				cy={-3030.06}
				fx={1669.89}
				fy={-3030.06}
				r={25.15}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3336.97 -5816.51)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahMalam_svg__c"
				cx={-3086.84}
				cy={951.57}
				fx={-3086.84}
				fy={951.57}
				r={24.27}
				gradientTransform="matrix(-1.17345 -1.10233 .2198 -.7265 -3746.1 -2533.21)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M43.3 125.22c0 48.74 39.51 88.25 88.25 88.25s88.25-39.51 88.25-88.25-39.51-88.25-88.25-88.25S43.3 76.49 43.3 125.22Z"
				style={{
					fill: 'url(#cerahMalam_svg__a)',
				}}
			/>
			<path
				d="M69.89 140.84c8.29-.74 14.22-10.15 13.24-21.02-.97-10.86-8.48-19.05-16.78-18.31-8.29.74-14.22 10.15-13.24 21.01.98 10.86 8.49 19.05 16.78 18.31ZM62.7 148.73c-4.97 2.61-6.23 10-2.81 16.5 3.42 6.51 10.21 9.66 15.18 7.05 4.96-2.6 6.22-10 2.8-16.5-3.41-6.51-10.21-9.67-15.17-7.06ZM82.38 140.89c-2.94 1.55-3.68 5.9-1.66 9.75 2.02 3.84 6.03 5.7 8.97 4.17 2.93-1.54 3.67-5.9 1.66-9.75-2.02-3.84-6.03-5.71-8.97-4.17ZM213.07 128.42c-1.53-10.8-9.44-18.6-17.69-17.44-8.24 1.16-13.68 10.86-12.16 21.65 1.52 10.79 9.44 18.6 17.68 17.43 8.24-1.16 13.69-10.86 12.17-21.64ZM202.17 102.73c4.82-2.86 5.7-10.3 1.96-16.62-3.73-6.32-10.68-9.13-15.51-6.27-4.82 2.85-5.7 10.29-1.96 16.62 3.73 6.32 10.68 9.13 15.51 6.28ZM184.06 101.73c-2.21-3.74-6.31-5.4-9.17-3.71-2.85 1.68-3.37 6.08-1.16 9.82s6.32 5.4 9.17 3.71c2.85-1.68 3.38-6.08 1.16-9.82Z"
				style={{
					fill: '#adadb2',
				}}
			/>
			<path
				d="M145.01 116.15c-22.47-18.24-31.26-46-19.6-62.02 11.65-16.02 39.33-14.23 61.82 4 22.47 18.23 31.24 46.01 19.59 62.01-11.67 16.04-39.33 14.23-61.81-3.99Z"
				style={{
					fill: 'url(#cerahMalam_svg__b)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M53.56 144.73c2.98-9.76 18.11-5.72 33.82 9.04 15.71 14.75 26.02 34.62 23.05 44.39-2.97 9.75-18.1 5.72-33.82-9.03-15.7-14.75-26.02-34.64-23.05-44.39Z"
				style={{
					fill: 'url(#cerahMalam_svg__c)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="m91.41 167.01 4.73 9.59c.08.16.23.27.4.29l10.59 1.54c.44.06.61.6.3.91l-7.66 7.47c-.13.12-.18.3-.15.47l1.81 10.54c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l10.59-1.54c.17-.03.32-.13.4-.29l4.73-9.59c.2-.4.76-.4.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m90.93 169.22-4.22 8.17c-.22.43-.65.71-1.13.76l-9.16.84 9.68.45c.61.03 1.18-.34 1.39-.92l3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m80.64 196.61-.21 1.21c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-3.55-.52c-5.73 10.29-14.52 15.71-22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m74.58 154.06 3.13 6.34c.05.1.15.18.27.19l6.99 1.02c.29.04.41.4.2.6l-5.06 4.93c-.08.08-.12.2-.1.31l1.19 6.96c.05.29-.25.51-.51.37l-6.25-3.29a.382.382 0 0 0-.33 0l-6.25 3.29a.349.349 0 0 1-.51-.37l1.19-6.96a.358.358 0 0 0-.1-.31l-5.06-4.93c-.21-.2-.09-.56.2-.6l6.99-1.02c.12-.02.21-.09.27-.19l3.13-6.34c.13-.26.5-.26.63 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m74.27 155.53-2.79 5.4c-.15.28-.43.47-.74.5l-6.05.55 6.39.3c.41.02.78-.23.92-.61l2.27-6.14Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m67.47 173.62-.14.8c-.05.29.25.51.51.37l6.25-3.29c.1-.05.23-.05.33 0l6.25 3.29c.26.14.56-.08.51-.37l-1.19-6.96c-.02-.11.02-.23.1-.31l5.06-4.93c.21-.2.09-.56-.2-.6l-2.34-.34c-3.79 6.8-9.59 10.38-15.15 12.35Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m61.79 175.13 4.73 9.59c.08.16.23.27.4.29l10.59 1.54c.44.06.61.6.3.91l-7.66 7.47c-.13.12-.18.3-.15.47l1.81 10.54c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l10.59-1.54c.17-.03.32-.13.4-.29l4.73-9.59c.2-.4.76-.4.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m61.31 177.35-4.22 8.17c-.22.43-.65.71-1.13.76l-9.16.84 9.68.45c.61.03 1.18-.34 1.39-.92l3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m51.02 204.74-.21 1.21c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56L70 195.41c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-3.55-.52c-5.73 10.29-14.52 15.71-22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m213.63 51.29 4.73 9.59c.08.16.23.27.4.29l10.59 1.54c.44.06.61.6.3.91l-7.66 7.47c-.13.12-.18.3-.15.47l1.81 10.54c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l10.59-1.54c.17-.03.32-.13.4-.29l4.73-9.59c.2-.4.76-.4.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m213.15 53.51-4.22 8.17c-.22.43-.65.71-1.13.76l-9.16.84 9.68.45c.61.03 1.18-.34 1.39-.92l3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m202.87 80.9-.21 1.21c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-3.55-.52c-5.73 10.29-14.52 15.71-22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m196.81 38.35 3.13 6.34c.05.1.15.18.27.19l6.99 1.02c.29.04.41.4.2.6l-5.06 4.93c-.08.08-.12.2-.1.31l1.19 6.96c.05.29-.25.51-.51.37l-6.25-3.29a.382.382 0 0 0-.33 0l-6.25 3.29a.349.349 0 0 1-.51-.37l1.19-6.96a.358.358 0 0 0-.1-.31l-5.06-4.93c-.21-.2-.09-.56.2-.6l6.99-1.02c.12-.02.21-.09.27-.19l3.13-6.34c.13-.26.5-.26.63 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m196.49 39.81-2.79 5.4c-.15.28-.43.47-.74.5l-6.05.55 6.39.3c.41.02.78-.23.92-.61l2.27-6.14Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m189.7 57.9-.14.8c-.05.29.25.51.51.37l6.25-3.29c.1-.05.23-.05.33 0l6.25 3.29c.26.14.56-.08.51-.37l-1.19-6.96c-.02-.11.02-.23.1-.31l5.06-4.93c.21-.2.09-.56-.2-.6l-2.34-.34c-3.79 6.8-9.59 10.38-15.15 12.35Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m184.01 59.42 4.73 9.59c.08.16.23.27.4.29l10.59 1.54c.44.06.61.6.3.91l-7.66 7.47c-.13.12-.18.3-.15.47l1.81 10.54c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l10.59-1.54c.17-.03.32-.13.4-.29l4.73-9.59c.2-.4.76-.4.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m183.53 61.63-4.22 8.17c-.22.43-.65.71-1.13.76l-9.16.84 9.68.45c.61.03 1.18-.34 1.39-.92l3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m173.25 89.02-.21 1.21c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-3.55-.52c-5.73 10.29-14.52 15.71-22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
		</g>
	</svg>
);

export default CerahMalam;
