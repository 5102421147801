import * as React from 'react';

const SvgRichNutrition = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 393.83 797.95"
		{...props}
	>
		<path
			d="M190.99 262.98c.71 5.73.71 11.57-.6 17.26-16.04-9.98-31.17-20.43-47.99-28.77-15.35-7.66-32.56-15.2-45.43-26.95-12.69-11.55-23.51-29.22-19.58-47.03 18.69 12.15 39.98 16.15 60.31 24.45 25.95 9.74 49.85 32.25 53.28 61.04Z"
			style={{
				fill: '#43f23d',
			}}
		/>
		<path
			d="M190.96 277.25c-.15.99-.33 1.99-.56 2.99-16.04-9.98-31.17-20.43-47.99-28.77-15.35-7.66-32.56-15.2-45.43-26.95-12.69-11.55-23.51-29.22-19.58-47.03 4.83 8.12 11.6 14.61 15.55 18.82 4.33 4.63 9 8.96 13.96 12.94 27.46 21.87 66.63 36.3 84.05 68Z"
			style={{
				fill: '#39cc33',
			}}
		/>
		<path
			d="M77.4 177.49c21.18 30.02 57.31 42.07 85.1 64.42 12.78 10.05 21.69 23.96 29.66 37.91 7.26 9.72 14.91 29.5 14.65 41.85-2.62-5.33-7.29-19.21-9.67-24.63-3.22-7.14-4.52-15.17-9.46-21.49-5.52-8.12-12.72-15.01-20.25-21.24-12.04-10.19-24.74-19.35-37.89-28.16-20.06-12.76-40.52-27.27-52.15-48.68Z"
			style={{
				fill: '#35a137',
			}}
		/>
		<path
			d="M154.25 471.44c-.96 9.89-1.78 20.09-5.54 29.39-1.38 3.07-1.97 5.18-3.69 8.09-.2-.27-.4-.53-.6-.8h-.01c-.14-.19-.28-.38-.43-.57-3.86-4.99-8.33-10.11-11.71-15-2.75-4.12-5.61-8.29-8.57-12.49-.74-1.05-1.48-2.1-2.23-3.16-31.94-45.12-73.08-94.71-93.72-147.15-5.95-15.12-9.68-30.72-11.84-46.24-.32-2.27-.53-4.4-.67-6.53-.04-.54-.07-1.07-.09-1.61-.03-.61-.05-1.22-.07-1.84-.17-4.48-.13-8.98.16-13.49 0-.03 0-.07.01-.11.03-.22.05-.44.09-.67 0-.04 0-.08.01-.12 73.18 49.13 144.01 123.95 138.9 212.3Z"
			style={{
				fill: '#3fe339',
			}}
		/>
		<path
			d="M143.98 507.54c-3.86-4.98-8.33-10.1-11.71-14.99-2.75-4.12-5.61-8.29-8.57-12.49-.73-1.05-1.47-2.1-2.23-3.16-31.94-45.12-73.08-94.71-93.72-147.15-5.95-15.12-9.68-30.72-11.84-46.24-.32-2.27-.53-4.4-.67-6.53-.04-.54-.07-1.07-.09-1.61-.22-4.62-.09-9.47.19-16.11 0 0 2.77 10.92 8.78 23.56 6.74 14.17 17.14 30.76 24.16 41.74 26.32 41.14 54.41 66.8 74.25 109.86 8.72 18.92 18.93 52.09 21.49 73.03-.01.03-.03.06-.04.09ZM143.98 507.55c.15.19.29.38.43.57-.26-.17-.48-.34-.43-.57ZM145.02 508.92c-.2-.27-.4-.53-.6-.8.37.23.81.44.6.8Z"
			style={{
				fill: '#2ec427',
			}}
		/>
		<path
			d="M130.99 456.07c-5.03-13.54-2.11-29.78 7-40.96-2.36 13.84-4.65 27.12-7 40.96ZM120.85 429.47c-9.9-6.96-19.96-11.95-31.44-15.8 12.12-2.92 27.11 3.86 31.44 15.8ZM105.25 402.29c-5.9-11.11-3.59-25.97 5.12-35.01-1.96 11.86-3.63 23.08-5.12 35.01ZM93.2 386.59c-4.11-1.75-8.31-1.38-12.64-1.99-7.32-.98-14.55-2.57-21.14-6.11 8.46-1 29.14.21 33.78 8.1ZM81.87 370.3c-6.66-11.56-3.4-27.68 6.75-36.21-3.69 12.03-5.89 23.66-6.75 36.21ZM64.76 349.91c-11.53 2.06-24.98-3.53-30.58-13.97 10.35 4.99 20.03 9.44 30.58 13.97Z"
			style={{
				fill: '#238724',
			}}
		/>
		<path
			d="M393.38 318.86c-.99-.51-1.94-.98-2.9-1.42-13.15-6.06-27.47-5.62-40.53-1.35-17.9 6.93-32.17 19.19-47.6 31.16-17.38 13.74-34.37 26.97-52.73 40.22-8.53 5.85-16.77 12.95-26.85 16.51-10.72-61.34 46.71-129.1 109.04-131.74 17.07-.78 34.84 4.27 47.71 15.29 9.07 7.71 16.21 19.53 13.85 31.33Z"
			style={{
				fill: '#3fe339',
			}}
		/>
		<path
			d="M393.37 318.85c-13.15-6.06-30.37-7.04-43.43-2.77-17.9 6.93-32.17 19.19-47.6 31.16-17.38 13.74-34.37 26.97-52.73 40.22-8.53 5.87-16.82 12.91-26.85 16.63-.02-.06 0-.05 0-.12 9.4-23.04 24.53-44.11 38.41-63.84 7.5-10.65 15.71-21.12 26.51-29.06 29.41-22.29 79.33-18.43 105.68 7.77Z"
			style={{
				fill: '#2ec427',
			}}
		/>
		<path
			d="M234.44 383.4c5.22-12.16 19.77-19.53 32.77-18.11-11.2 6.19-21.58 11.89-32.77 18.11ZM248.95 352.42c9.35-15.15 32.75-19.97 47.91-11.06-17-.88-33.08 2.69-47.91 11.06ZM273.88 319.81c13.37-7.91 32.5-6.77 44.66 2.97-15.12-2.18-29.38-3.15-44.66-2.97ZM309.11 298.28c12.8-3.26 28 .92 36.67 10.98-12.5-2.94-24.61-6.6-36.67-10.98ZM257.59 337.36c-6.67-6.37-5.18-18.57 2.6-23.42.09 8.24-.74 15.39-2.6 23.42ZM289.38 304.31c-3.37-8.29 4.29-17.9 12.88-18.21-3.83 6.87-7.71 12.28-12.88 18.21ZM331.69 293.68c7.72-10.9 26.52-12.44 36.17-3.33-12.58-1.29-24.06-.28-36.17 3.33ZM241.57 368.94c-8.36-3.69-11.42-15.95-5.01-22.7 2.41 7.79 3.89 14.63 5.01 22.7Z"
			style={{
				fill: '#238724',
			}}
		/>
		<path
			d="M186.75 459.18c14.77-22.8 29.75-45.56 41.95-69.8 12-24.68 22.51-50.69 41.99-70.74 30.49-34.05 93.25-35.95 122.68.21-26.67-26.16-72.91-27.84-103.17-7.12-14.56 10.51-24.6 25.75-34.1 40.76-9.26 14.98-20.28 30.93-29.33 45.94-11.52 22.57-21.16 43.41-40.02 60.75ZM168.36 558.14c-16.19-15.11-23.03-36.18-26.74-57.3-5.55-20.09-10.8-40.59-19.32-59.57-28.25-64.54-91.38-111.71-106.95-182.14 21.36 65.35 81.39 108.24 109.84 169.85 11.99 25.91 13.06 54.7 22.32 81.23 7.54 15.84 11.76 32.69 20.84 47.94Z"
			style={{
				fill: '#119e11',
			}}
		/>
		<path
			d="M324.75 691.08c2.84-21.23-17.61-37.95-37.79-30.96-4.43-8.92-13.91-14.47-24.23-13.52-4.43-10.55-14.85-17.96-27.01-17.96s-21.72 6.81-26.43 16.66a24.46 24.46 0 0 0-28.52 2.68c-7.9-10.5-22.58-12.86-33.31-5.61-13.39-9.06-31.99-2.8-37.02 12.77-7.94-1.34-15.71 1.27-21.19 6.61a26.627 26.627 0 0 0-8.52-5.22c-20.14-7.64-41.01 10.68-35.38 31.95-10.48 3.53-17.02 13.63-16.67 23.58C11 709.01-3.82 725.38.87 742.63c4.48 16.52 24.06 23.35 37.84 13.54 2.98 8.72 10.73 15.22 20.14 16.38 6.39 17.21 28.67 21.48 41.02 8.05 3.46.94 7.17 1.16 11.01.44 2.98 4.25 7.65 7.72 12.99 9.28 5.47 1.82 13.14 1.08 18.05-1.65.18 0 4.03-1.95 7.34-5.71 5.55 2.57 12 2.96 17.94.99 11.33 13.78 31.27 13.17 42.01.61 10.77 6.72 24.8 4.72 33.29-4.72 17.06 12.4 41.62 2.11 44.28-19.18 9.21-.42 16.91-5.7 20.86-13.45 19.49 8.11 40.83-6.28 40.83-27.23 0-14.32-10.2-26.24-23.73-28.92Z"
			style={{
				fill: '#663410',
			}}
		/>
		<path
			d="M324.75 691.08c.78-5.82-.19-11.47-2.64-16.48-1.47-.08-3.22 0-5 .3-4.77-14.76-22.37-20.63-35.02-12.08-10.1-6.83-24-4.67-31.5 5.31a23.15 23.15 0 0 0-26.98-2.54c-4.46-9.32-13.98-15.76-25-15.76s-21.37 7.01-25.55 16.99c-9.35-.91-18.59 4.07-22.92 12.79-19.09-6.62-38.5 9.26-35.74 29.29-12.93 2.59-22.45 13.91-22.45 27.36 0 19.93 20.32 33.39 38.63 25.76 3.68 7.29 11.09 12.37 19.74 12.73 2.51 20.08 25.71 29.91 41.88 18.14.27.3.55.6.84.88 6.45-1.08 12.13-4.42 16.21-9.19 10.77 6.72 24.8 4.72 33.29-4.72 17.06 12.4 41.62 2.11 44.28-19.18 9.29-.42 16.95-5.78 20.86-13.45 19.49 8.11 40.83-6.28 40.83-27.23 0-14.32-10.2-26.24-23.73-28.92Z"
			style={{
				fill: '#572c0e',
			}}
		/>
		<path
			d="M216.65 696.02c.78-5.82-.19-11.47-2.64-16.48-1.46-.08-3.21 0-5 .3-4.77-14.76-22.37-20.63-35.02-12.08a23.17 23.17 0 0 0-31.5 5.31 23.15 23.15 0 0 0-26.98-2.54c-6.21-12.99-21.32-19.05-34.77-13.99-7.13 2.68-12.85 8.23-15.79 15.22-8.22-.74-15.76 2.81-20.47 8.89-.1 2.44.17 5.18.88 7.84-10.48 3.53-17.02 13.63-16.67 23.58C13.61 709.46.02 721.11.02 736.21c0 19.82 22.46 31.53 38.7 19.97 2.98 8.72 10.73 15.22 20.14 16.38 6.39 17.21 28.67 21.48 41.02 8.05 3.46.94 7.17 1.16 11.01.44 3.39 4.82 8.97 8.68 15.36 9.86 3.11-1.49 5.88-3.58 8.16-6.11 17.32 12.6 41.66 1.8 44.28-19.18 3.53-.16 6.81-1.04 9.44-2.32 4.72-2.21 8.84-6.07 11.42-11.13 19.36 8.05 40.83-6.16 40.83-27.23 0-14.28-10.17-26.24-23.73-28.92Z"
			style={{
				fill: '#572c0e',
			}}
		/>
		<path
			d="M198.07 686.84c4.34 13.1-16.76 19.58-21.65 6.2-6.52-19.56-10.68-39.2-13.01-58.9-19.06-160.94 84.02-325.72 18.51-483.91a252.04 252.04 0 0 0-16.99-32.1c-1.71-2.62-.74-6.03 2.15-7.54 1.19-.62 2.51-.83 3.78-.66 1.74.22 3.35 1.13 4.32 2.61a260.1 260.1 0 0 1 18.42 33.38c57.65 129.13.48 268.15-10.2 399.92-3.6 47.31-1.22 95.64 14.66 141Z"
			style={{
				fill: '#119e11',
			}}
		/>
		<path
			d="M198.52 365.99c-8.83 67.4-17.65 134.8-26.47 202.2l-8.64 65.95c-19.06-160.94 84.02-325.72 18.51-483.91a252.04 252.04 0 0 0-16.99-32.1c-1.71-2.62-.74-6.03 2.15-7.54 1.19-.62 2.51-.83 3.78-.66.17.57.37 1.13.58 1.66 2.94 7.36 7.28 14.05 10.84 21.16 15.7 31.35 23.04 65.81 25.13 100.1 2.69 44.45-3.1 88.93-8.89 133.13Z"
			style={{
				fill: '#1bc21f',
			}}
		/>
		<path
			d="M108.28 47.38c-6.28-32.3 21.98-54.54 50.78-35.89 16.59 10.15 27.15 28.1 27.25 46.36.11 18.22-13.12 49.46-38.46 43.04-22.31-5.65-36.65-34.58-39.57-53.51Z"
			style={{
				fill: '#ffae0d',
			}}
		/>
		<path
			d="M181 112.84c6.77-7.01 18.69-3.96 25.59 1.38 10.25 7.59 15.77 21.23 29.34 23.46-23.92 10.05-32.38-28.26-54.93-24.83ZM167.09 119.22c-24.34 3.47-15.17 35.89-32.23 47.57 3.35-11.78.98-23.1 5.84-34.63 3.76-9.84 16.1-18.29 26.39-12.94Z"
			style={{
				fill: '#238724',
			}}
		/>
		<path
			d="M188.11 111.33c6.59-6.91 3.59-33.46-4.77-38.3-8.32-2.62-27.63 7.98-37.25 10.22-4.92 1.71-10.47 4.01-11.92 8.65-1.16 3.7.77 7.57 2.81 10.93 4.7 7.74 10.95 20.08 21.82 21.1 9.58.9 24.14-5.39 29.31-12.6Z"
			style={{
				fill: '#35a137',
			}}
		/>
		<path
			d="M102.76 29.38c.9-8.91 4.62-18.71 13.53-22.41 2.5 20.29 11.72 39.84 26.12 55.43 3.96 4.29 8.35 8.35 11.18 13.33s3.86 11.25.83 16.14c-10.2 16.49-31.99-7.79-37.83-15.53-10.26-13.6-15.51-30.46-13.83-46.97Z"
			style={{
				fill: '#ffe70d',
			}}
		/>
		<path
			d="M164.84 13.64C159.32 6.25 150.88-.67 141.13.05c12.59 25.21 8.36 50.55 3.83 76.69.17 5.61 2.59 11.55 7.83 14.48 17.66 9.9 23.85-21.01 24.85-30.37 1.76-16.45-2.58-33.52-12.8-47.22Z"
			style={{
				fill: '#ffe70d',
			}}
		/>
		<path
			d="M194.9 81.46c-1.78 20.7-23.45 37.66-43.93 32.53 10.54-7.92 19-20.03 17.28-33.65-1.09-10.42-4.75-20.79-5.88-31.31-2.55-16.74 4.56-37.82 22.71-42.05-10.54 25.56 13.67 48.45 9.81 74.48Z"
			style={{
				fill: '#238724',
			}}
		/>
		<path
			d="M185.09 102.32c-17.05 19.18-50.67 16.1-67.75-1.79-17.39-17.28-13.66-42.59-26.92-61.62 27.97.63 35.68 32.71 51.98 48.77 11.43 9.62 27.63 13.86 42.7 14.64Z"
			style={{
				fill: '#238724',
			}}
		/>
		<path
			d="M141.57 41.32c2.55-10.85 1.49-46.9-17.08-36.05-7.06 6.94 10.18 31.26 17.08 36.05Z"
			style={{
				fill: '#ffe70d',
			}}
		/>
		<path
			d="M179.93 102.05c-16.62 6.82-36.43-4.13-43.43-19.83-8.81-17.48.94-35.77-3.4-53.54 22.12 2.27 25.87 28.91 29.64 46.06 2.78 10.38 9.08 19.79 17.19 27.32Z"
			style={{
				fill: '#238724',
			}}
		/>
		<path
			d="M154.67 115.28c-21.97 1.16-42.75-13.89-49.31-34.53-5.3-14.32-5.65-28.42-14.44-41.08 27.13 20.13 27.03 65.97 63.75 75.61ZM141.35 88.51c-15.91-15.84-4.34-38.94-7.75-57.92 13.56 16.87-3.58 40.29 7.75 57.92ZM185.09 6.97c-21.72 15.33-10.77 50.29-16.83 73.36-1.09-10.43-4.75-20.79-5.88-31.31-2.55-16.73 4.56-37.82 22.71-42.05Z"
			style={{
				fill: '#35a137',
			}}
		/>
		<path
			d="M320.64 735.07c2.84-21.23-17.61-37.95-37.79-30.96-4.42-8.9-13.88-14.47-24.23-13.52-7.71-18.37-30.94-23.69-45.88-11.07-3.17 2.67-5.76 6-7.57 9.77a24.46 24.46 0 0 0-28.52 2.68c-7.9-10.5-22.58-12.86-33.3-5.61-13.32-9.01-31.96-2.9-37.02 12.77a24.42 24.42 0 0 0-21.19 6.61c-13.87-12.61-36.24-7.4-43.01 10.22-1.96 5.06-2.37 10.88-.88 16.5-10.48 3.53-17.02 13.63-16.67 23.58-4.43-.76-8.8-.28-12.68 1.16a24.492 24.492 0 0 0 26.82-1.03c2.98 8.72 10.73 15.22 20.14 16.38 6.39 17.21 28.67 21.48 41.02 8.05 3.46.94 7.17 1.16 11.01.44 8.88 12.64 27.69 14.05 38.38 1.93 1.35.62 2.77 1.13 4.24 1.5 7.39 13.07 24.13 17.39 36.88 9.6 7.15-.48 13.98-3.83 18.83-9.5 10.77 6.72 24.8 4.72 33.29-4.72 17.06 12.4 41.62 2.11 44.28-19.18 9.02-.41 16.81-5.49 20.86-13.45 9.28 3.86 20.23 2.82 28.74-3.42-4.13-4.4-9.59-7.53-15.75-8.75Z"
			style={{
				fill: '#4f2507',
			}}
		/>
		<path
			d="M292.81 759.68c3.07-.93 5.8-2.4 7.97-4.11-2.86 1.01-5.53 2.4-7.97 4.11Zm-27.5-22.07c-8.64-23.28-38.84-29.14-55.6-10.91a33.13 33.13 0 0 0-14.92-.6c-12.47-17.49-37.92-18.72-52.03-2.61-7.3-3.39-16.05-4.08-24.31-1.34-14.57-18.05-41.87-18.44-56.94-.83-14.68-9.17-33.66-6.34-45.12 6.39a37.69 37.69 0 0 0-11.8-5.77 24.475 24.475 0 0 0-3.72 20.69c4.48 16.52 24.06 23.35 37.84 13.54 2.98 8.72 10.73 15.22 20.14 16.38 6.39 17.21 28.67 21.48 41.02 8.05 3.46.94 7.17 1.16 11.01.44 4.9 6.97 13.5 11.14 22.59 10.15 3.23-.33 6.6-1.48 8.45-2.51.38 0 6.66 2.72 15.21.75 8.67 9.37 22.61 11.14 33.25 4.64 7.15-.48 13.98-3.83 18.83-9.5 10.77 6.72 24.8 4.72 33.29-4.72 17.06 12.4 41.62 2.11 44.28-19.18 1.85-.08 3.83-.36 5.8-.94-4.05-11.78-14.54-20.56-27.28-22.13Z"
			style={{
				fill: '#42210b',
			}}
		/>
	</svg>
);

export default SvgRichNutrition;
