import React from 'react';
import styles from "../../styles/components/Footer.module.scss";
import {Link} from "react-router-dom";

function Footer() {

    return (
        <footer className={styles.footer_main_container}>
            <div className={styles.footer_content_container}>
                <span className={styles.footer_content}>ⓒ 2023</span>
                <a href="https://bandungfe.net" target="_blank" rel="noreferrer">
                    <span className={styles.footer_content_about}>Bandung Fe Institute</span>
                </a>
                <Link to={'/tentang-kami'}>
                    <span className={styles.footer_content_about}>Tentang Kami</span>
                </Link>

            </div>
        </footer>
    );
}

export default Footer;