import * as d3 from "d3";

export const setMapProjection = function(mapData) {
    // use the geoAlbers map projection
    const projection = d3.geoAlbers();
    // adjust projection to fit area of map canvas
    projection
        .scale(1080)
        .rotate([-118.727838, 0])
        .center([0, -3])
        .parallels([0,0])
    return projection;
};
