import * as React from 'react';

const BawangMerah = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M77.82 146.26c5.91-1.35 11.04-7.18 13.71-16.81 4.79-17.28-.15-40.26-11.03-51.35-4.1-4.17-8.47-6.06-12.56-5.85-6.79.3-12.84 6.32-15.83 17.09-4.8 17.28.14 40.26 11.02 51.35 4.82 4.9 10 6.65 14.68 5.57Zm9.43-17.99c-2.09 7.52-5.81 12.48-10.21 13.61-3.34.87-7.16-.66-10.73-4.31-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.59-14.76 1.14-21.02C58.47 83 62.2 78.05 66.6 76.9c3.34-.87 7.15.67 10.73 4.31 5.52 5.62 9.65 15.35 11.06 26.04.99 7.48.59 14.75-1.14 21.01Z"
			style={{
				fill: '#a78a94',
			}}
		/>
		<path
			d="M91.53 129.45c4.79-17.28-.15-40.26-11.04-51.35-4.81-4.9-9.99-6.65-14.68-5.57-5.9 1.35-11.03 7.18-13.69 16.81-4.8 17.28.14 40.26 11.02 51.35 4.1 4.17 8.46 6.06 12.55 5.85 6.78-.3 12.84-6.31 15.84-17.09ZM54.26 90.8c2.09-7.52 5.81-12.47 10.21-13.62 3.35-.87 7.15.67 10.73 4.31 5.52 5.62 9.65 15.35 11.06 26.04.99 7.48.6 14.75-1.14 21.01-2.09 7.52-5.81 12.48-10.21 13.61-3.34.87-7.16-.66-10.73-4.31-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.59-14.76 1.14-21.02Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M75.69 146.54c5.91-1.35 11.04-7.18 13.71-16.81 4.79-17.28-.15-40.26-11.03-51.35-4.1-4.17-8.47-6.06-12.56-5.85-6.79.3-12.84 6.32-15.83 17.09-4.8 17.28.14 40.26 11.03 51.35 4.82 4.9 10 6.65 14.68 5.57Zm9.43-17.99c-2.09 7.52-5.81 12.48-10.21 13.61-3.34.87-7.16-.66-10.73-4.31-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.59-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.35-.87 7.15.67 10.73 4.31 5.52 5.62 9.65 15.35 11.06 26.04.99 7.48.6 14.75-1.14 21.01Z"
			style={{
				fill: '#d9b2bf',
			}}
		/>
		<path
			d="M75.46 144.31c5.19-1.34 9.49-6.88 11.8-15.17 1.81-6.55 2.23-14.12 1.2-21.9-1.47-11.15-5.83-21.36-11.68-27.3-4.16-4.24-8.74-5.98-12.87-4.91-5.2 1.34-9.49 6.87-11.8 15.17-1.82 6.55-2.23 14.12-1.2 21.91 1.47 11.14 5.83 21.35 11.67 27.29 4.15 4.24 8.73 5.98 12.87 4.9Zm-11.29-6.46c-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.59-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.35-.87 7.15.67 10.73 4.31 5.52 5.62 9.65 15.35 11.06 26.04.99 7.48.6 14.75-1.14 21.01-2.09 7.52-5.81 12.48-10.21 13.61-3.34.87-7.16-.66-10.73-4.31Z"
			style={{
				fill: '#fbd8e4',
			}}
		/>
		<path
			d="M75.19 143.24c4.79-1.24 8.8-6.48 11-14.39 1.77-6.4 2.18-13.83 1.17-21.45-1.44-10.92-5.69-20.89-11.37-26.67-3.87-3.94-8.06-5.57-11.8-4.61-4.79 1.24-8.81 6.48-11 14.39-1.78 6.4-2.18 13.83-1.17 21.46 1.44 10.91 5.69 20.88 11.37 26.66 3.87 3.94 8.06 5.57 11.8 4.61Zm-11.01-5.38c-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.59-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.35-.87 7.15.67 10.73 4.31 5.52 5.62 9.65 15.35 11.06 26.04.99 7.48.6 14.75-1.14 21.01-2.09 7.52-5.81 12.48-10.21 13.61-3.34.87-7.16-.66-10.73-4.31Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M105.11 147.12c5.91-1.35 11.04-7.18 13.71-16.81 4.79-17.28-.15-40.26-11.03-51.35-4.1-4.17-8.47-6.06-12.56-5.85-6.79.3-12.84 6.32-15.83 17.09-4.8 17.27.14 40.26 11.03 51.35 4.82 4.9 10 6.65 14.68 5.57Zm9.43-17.99c-2.09 7.52-5.81 12.48-10.21 13.62-3.34.87-7.16-.67-10.73-4.31-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.6-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.35-.87 7.15.67 10.73 4.32 5.52 5.61 9.65 15.34 11.06 26.04.99 7.48.59 14.75-1.14 21.01Z"
			style={{
				fill: '#a78a94',
			}}
		/>
		<path
			d="M118.81 130.32c4.79-17.28-.15-40.26-11.04-51.35-4.81-4.91-9.99-6.65-14.68-5.57-5.9 1.35-11.03 7.18-13.7 16.81-4.8 17.27.14 40.26 11.02 51.35 4.1 4.17 8.46 6.06 12.55 5.85 6.78-.3 12.84-6.31 15.84-17.09ZM81.54 91.67c2.09-7.52 5.81-12.47 10.21-13.62 3.34-.87 7.15.67 10.73 4.32 5.52 5.61 9.65 15.34 11.06 26.04.99 7.48.59 14.75-1.14 21.01-2.08 7.52-5.81 12.48-10.21 13.62-3.34.87-7.16-.67-10.73-4.31-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.6-14.76 1.14-21.02Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M102.97 147.41c5.91-1.35 11.04-7.18 13.71-16.81 4.79-17.28-.15-40.26-11.03-51.35-4.1-4.17-8.47-6.06-12.56-5.85-6.79.3-12.84 6.32-15.83 17.09-4.8 17.27.14 40.26 11.03 51.35 4.82 4.9 10 6.65 14.68 5.57Zm9.43-17.99c-2.08 7.52-5.81 12.48-10.21 13.62-3.34.87-7.16-.67-10.73-4.31-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.6-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.34-.87 7.15.67 10.73 4.32 5.52 5.61 9.65 15.34 11.06 26.04.99 7.48.59 14.75-1.14 21.01Z"
			style={{
				fill: '#d9b2bf',
			}}
		/>
		<path
			d="M102.74 145.18c5.2-1.34 9.49-6.87 11.8-15.17 1.81-6.55 2.23-14.12 1.2-21.9-1.47-11.15-5.83-21.36-11.67-27.3-4.17-4.24-8.74-5.98-12.87-4.9-5.2 1.33-9.49 6.87-11.8 15.17-1.82 6.55-2.23 14.12-1.2 21.9 1.47 11.14 5.83 21.35 11.68 27.29 4.15 4.24 8.72 5.98 12.87 4.9Zm-11.29-6.46c-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.6-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.34-.87 7.15.67 10.73 4.32 5.52 5.61 9.65 15.34 11.06 26.04.99 7.48.59 14.75-1.14 21.01-2.08 7.52-5.81 12.48-10.21 13.62-3.34.87-7.16-.67-10.73-4.31Z"
			style={{
				fill: '#fbd8e4',
			}}
		/>
		<path
			d="M102.47 144.1c4.79-1.24 8.8-6.48 11-14.39 1.77-6.4 2.18-13.83 1.17-21.46-1.44-10.92-5.69-20.89-11.37-26.67-3.87-3.94-8.06-5.57-11.8-4.61-4.79 1.24-8.81 6.48-11 14.39-1.78 6.4-2.18 13.83-1.17 21.46 1.44 10.91 5.69 20.89 11.37 26.66 3.87 3.94 8.06 5.57 11.8 4.61Zm-11.01-5.38c-5.52-5.62-9.65-15.35-11.06-26.03-.99-7.49-.6-14.76 1.14-21.02 2.09-7.52 5.81-12.47 10.21-13.62 3.34-.87 7.15.67 10.73 4.32 5.52 5.61 9.65 15.34 11.06 26.04.99 7.48.59 14.75-1.14 21.01-2.08 7.52-5.81 12.48-10.21 13.62-3.34.87-7.16-.67-10.73-4.31Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M102.64 181.05c5.43-2.71 9.03-9.58 9.36-19.57.58-17.92-9.64-39.09-22.83-47.3-4.97-3.09-9.66-3.89-13.58-2.72-6.52 1.9-10.99 9.17-11.35 20.34-.59 17.92 9.63 39.09 22.83 47.3 5.84 3.63 11.29 4.1 15.58 1.95Zm4.92-19.71c-.25 7.8-2.7 13.49-6.71 15.64-3.05 1.63-7.12 1.04-11.45-1.66-6.69-4.16-13-12.64-16.89-22.69-2.73-7.05-4.06-14.2-3.85-20.7.25-7.79 2.7-13.49 6.71-15.64 3.04-1.63 7.11-1.04 11.45 1.66 6.69 4.16 13 12.64 16.89 22.69 2.73 7.04 4.06 14.2 3.85 20.69Z"
			style={{
				fill: '#a78a94',
			}}
		/>
		<path
			d="M112 161.48c.58-17.92-9.64-39.09-22.84-47.3-5.83-3.63-11.27-4.11-15.58-1.95-5.41 2.7-9.02 9.57-9.35 19.56-.59 17.92 9.63 39.1 22.83 47.3 4.97 3.08 9.65 3.89 13.57 2.72 6.51-1.89 10.99-9.16 11.36-20.34Zm-45.34-28.77c.25-7.8 2.7-13.49 6.71-15.64 3.04-1.63 7.1-1.04 11.45 1.66 6.69 4.16 13 12.64 16.89 22.69 2.72 7.04 4.06 14.2 3.85 20.69-.25 7.8-2.7 13.49-6.71 15.64-3.05 1.63-7.12 1.04-11.45-1.66-6.69-4.16-13-12.64-16.89-22.69-2.73-7.04-4.06-14.2-3.85-20.7Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M100.63 181.82c5.43-2.71 9.04-9.58 9.36-19.57.58-17.92-9.64-39.09-22.83-47.3-4.97-3.09-9.66-3.89-13.58-2.72-6.52 1.9-10.99 9.17-11.35 20.34-.59 17.92 9.63 39.09 22.83 47.3 5.84 3.63 11.29 4.1 15.58 1.95Zm4.92-19.71c-.25 7.8-2.7 13.49-6.71 15.64-3.05 1.63-7.12 1.04-11.45-1.66-6.69-4.16-13-12.64-16.89-22.69-2.73-7.04-4.06-14.2-3.85-20.7.25-7.8 2.7-13.49 6.71-15.64 3.04-1.63 7.1-1.04 11.45 1.66 6.69 4.16 13 12.64 16.89 22.69 2.72 7.04 4.06 14.2 3.85 20.69Z"
			style={{
				fill: '#d9b2bf',
			}}
		/>
		<path
			d="M99.89 179.71c4.73-2.52 7.6-8.92 7.89-17.52.21-6.8-1.16-14.25-4-21.56-4.06-10.49-10.7-19.38-17.79-23.78-5.05-3.14-9.9-3.75-13.66-1.73-4.73 2.52-7.6 8.92-7.89 17.52-.22 6.8 1.16 14.24 4 21.57 4.06 10.48 10.7 19.37 17.78 23.76 5.04 3.14 9.89 3.75 13.66 1.73ZM87.4 176.1c-6.69-4.16-13-12.64-16.89-22.69-2.73-7.04-4.06-14.2-3.85-20.7.25-7.8 2.7-13.49 6.71-15.64 3.04-1.63 7.1-1.04 11.45 1.66 6.69 4.16 13 12.64 16.89 22.69 2.72 7.04 4.06 14.2 3.85 20.69-.25 7.8-2.7 13.49-6.71 15.64-3.05 1.63-7.12 1.04-11.45-1.66Z"
			style={{
				fill: '#fbd8e4',
			}}
		/>
		<path
			d="M99.37 178.74c4.37-2.34 7.03-8.37 7.3-16.58.21-6.64-1.14-13.96-3.92-21.13-3.98-10.27-10.46-18.96-17.34-23.23-4.69-2.92-9.14-3.52-12.55-1.69-4.37 2.33-7.04 8.38-7.3 16.58-.22 6.64 1.14 13.96 3.92 21.14 3.98 10.27 10.46 18.95 17.34 23.22 4.69 2.92 9.14 3.52 12.56 1.7ZM87.4 176.1c-6.69-4.16-13-12.64-16.89-22.69-2.73-7.04-4.06-14.2-3.85-20.7.25-7.8 2.7-13.49 6.71-15.64 3.04-1.63 7.1-1.04 11.45 1.66 6.69 4.16 13 12.64 16.89 22.69 2.72 7.04 4.06 14.2 3.85 20.69-.25 7.8-2.7 13.49-6.71 15.64-3.05 1.63-7.12 1.04-11.45-1.66Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M140.76 43.46c-2.01 7.38-5.66 14.21-7.93 21.52a60.448 60.448 0 0 0-1.79 28.37l3.69 3.88c-.01-2.51-.03-5.03.02-7.54.32-15.55 3.17-30.93 6.01-46.23Z"
			style={{
				fill: '#4f7435',
			}}
		/>
		<path
			d="M134.63 43.89c2.01 7.38 5.66 14.22 7.93 21.52a60.448 60.448 0 0 1 1.79 28.37l-3.69 3.88c.02-2.51.03-5.03-.02-7.54-.32-15.55-3.17-30.93-6.01-46.23ZM119.84 43.89c2.01 7.38 5.66 14.22 7.93 21.52a60.448 60.448 0 0 1 1.79 28.37l-3.69 3.88c.01-2.51.03-5.03-.02-7.54-.32-15.55-3.17-30.93-6.02-46.23Z"
			style={{
				fill: '#4f7435',
			}}
		/>
		<path
			d="M130.93 69.65c-1.61 2.69-3.17 5.47-4 8.5-1.11 4.08-.84 8.39-.56 12.61l3.37 2.76c2.19-7.61 3.12-15.61 6.26-22.88 4.44-10.3 13.11-18.61 16.07-29.43a176.738 176.738 0 0 0-21.15 28.43ZM141.84 69.65c-1.61 2.69-3.17 5.47-4 8.5-1.11 4.08-.84 8.39-.56 12.61l3.37 2.76c2.19-7.61 3.12-15.61 6.26-22.88 4.44-10.3 13.11-18.61 16.07-29.43a176.738 176.738 0 0 0-21.15 28.43Z"
			style={{
				fill: '#5b9340',
			}}
		/>
		<path
			d="M141.14 35.59c.4 18.56-7.15 36.64-6.47 55.19l1.7 4.82c.34.63 1 1.08 1.72 1.17.28-7.19.91-14.37 1.89-21.5 1.04-7.5 2.47-14.97 2.7-22.54a71.48 71.48 0 0 0-1.54-17.14Z"
			style={{
				fill: '#4f7435',
			}}
		/>
		<path
			d="M118.92 197.74c.64 1.3 2.1 1.93 3.45 2.47 4.93 1.96 9.97 3.95 15.27 4.32 9.07.63 17.86-3.6 25.05-9.18l-43.76 2.39Z"
			style={{
				fill: '#bc9ca6',
			}}
		/>
		<path
			d="M196.02 164.59c-.92 4.55-3.11 8.78-5.77 12.58-5.55 7.96-13.43 14.17-22.3 18.05-.68.31-1.37.58-2.05.85a86.637 86.637 0 0 1-6.49 2.22c-4.5 1.34-9.24 2.33-14.05 2.91-.62.07-1.23.14-1.85.2-1.08.1-2.17.19-3.27.24-.56.03-1.15.07-1.73.09-.92.03-1.86.05-2.79.05-.46 0-.94 0-1.4-.02-.58 0-1.15-.02-1.73-.05-1.79-.07-3.61-.19-5.39-.39a83.559 83.559 0 0 1-14-2.7c-1.01-.27-2.02-.58-3.01-.91-1.59-.51-3.13-1.06-4.64-1.64-9.7-3.8-18.38-10.34-24.35-18.91-2.65-3.8-4.84-8.03-5.77-12.58-2.17-10.56 2.65-21.51 9.7-29.67 7.03-8.16 16.16-14.22 24.57-20.96 4.54-3.63 8.95-7.55 12.01-12.48.21-.32.39-.67.58-1.01.94-1.66 1.61-3.32 2.09-5.01.37-1.32.63-2.65.8-4.01.2-1.47.29-2.98.29-4.53.03-1.32 0-2.65-.07-4.04 1.15 1.95 2.29 3.92 3.44 5.89.44.75 1.21 1.63 2.02 1.27.31-3.99.61-7.96.91-11.95.73 2.62 1.45 5.24 2.16 7.85.17.57.32 1.11.48 1.68l.26.96c.05.17.12.36.2.53.21.45.53.84.94.86.08 0 .17 0 .25-.03.05-.02.1-.03.14-.09.19-.1.29-.27.37-.5.05-.09.09-.17.1-.27.17-.53.29-1.15.79-1.28.02.96.27 1.88.73 2.72.34.63.79 1.21 1.34 1.69.96-2.09 1.9-4.16 2.84-6.23.68-1.49 1.49-3.08 2.98-3.7.05 1.61.12 3.18.24 4.74.29 3.9.87 7.63 2.43 11.24.46 1.08 1.01 2.16 1.68 3.22 3.06 4.93 7.48 8.85 12.01 12.48 8.42 6.74 17.54 12.8 24.57 20.96 7.05 8.16 11.87 19.11 9.7 29.67Z"
			style={{
				fill: '#e5c3cf',
			}}
		/>
		<path
			d="M173.86 192.17a56.795 56.795 0 0 1-5.9 3.05c-.68.31-1.37.58-2.05.85-9.1 3.56-19.73 5.73-30.17 5.72-10.44.02-21.07-2.16-30.17-5.72-9.7-3.8-18.38-10.34-24.35-18.91-2.65-3.8-4.84-8.03-5.77-12.58-2.17-10.56 2.65-21.51 9.7-29.67 7.03-8.16 16.16-14.22 24.57-20.96 4.54-3.63 8.95-7.55 12.01-12.48.21-.32.39-.67.58-1.01.94-1.66 1.61-3.32 2.09-5.01 0 .09-.02.15-.02.24-.07 1.3-.17 2.6-.56 3.83-.43 1.39-1.23 2.6-2.04 3.82-1.9 2.88-3.46 5.92-6.21 7.97-2.77 2.07-5.49 4.23-8.23 6.35-5.46 4.21-10.85 8.59-15.35 13.83-7.05 8.16-11.87 19.12-9.7 29.67.93 4.55 3.11 8.78 5.77 12.58 5.97 8.57 14.65 15.11 24.35 18.91 9.11 3.56 19.73 5.73 30.17 5.72 10.44.02 21.07-2.16 30.17-5.72.38-.15.74-.33 1.11-.48Z"
			style={{
				fill: '#d9b2bf',
			}}
		/>
		<path
			d="M186 145.33c-.25-.73-.56-1.47-.91-2.19-1.94-3.98-4.74-7.57-8.23-10.31-5.09-3.99-9.9-8.23-14.65-12.62-5.64-5.22-11.76-10.07-15.96-16.6-1.56-2.42-3.06-5.19-5.73-6.24-1.89-.74-4.55-.42-6.03 1.07-1.37 1.39-1.03 3.64-1.77 5.41-2.07 4.87-5.6 8.99-9.29 12.7-5.05 5.08-10.66 9.79-14.2 16.01-4.87 8.55-5.18 19.37-1.49 28.49 3.69 9.12 11.15 16.51 19.99 20.82 8.85 4.31 18.99 5.64 28.77 4.55 6.29-.7 12.56-2.42 17.93-5.77 5.37-3.35 9.79-8.45 11.6-14.52.87-2.92 1.13-5.99 1.26-9.04.17-4.07.04-7.98-1.27-11.75Z"
			style={{
				fill: '#fbd8e4',
			}}
		/>
		<path
			d="M174.02 138.29c-2.79-2.72-6.31-4.83-9.45-6.99-2.81-1.94-5.57-4.1-7.6-6.87-.97-1.32-1.56-2.81-2.43-4.17-1.05-1.64-2.22-3.23-3.36-4.81-1.22-1.68-2.14-4.09-4.67-3.01-2.55 1.09-1.93 4.82-1.66 7.05.78 6.21 4.3 11.07 9.14 14.87 1.88 1.47 3.97 2.66 5.89 4.07 5.42 3.98 9.48 9.79 11.36 16.26.21.71.39 1.45.83 2.05.44.6 1.19 1.04 1.91.86.43-.1.79-.41 1.12-.7 1.92-1.75 3.54-3.69 3.66-6.36.11-2.41.15-4.44-.81-6.71-.91-2.15-2.29-3.95-3.92-5.54ZM181.77 160.46c-.39-1.07-1.51-2.12-2.74-1.83-.89.21-1.72 1.35-2.12 2.11-.77 1.45-.51 3.21-1.39 4.73-.52.9-1.21 1.68-1.81 2.52-.6.85-1.11 1.8-1.19 2.83-.08 1.03.37 2.16 1.29 2.63 1.14.59 2.56.01 3.56-.8 1.76-1.42 2.81-3.48 3.49-5.6.61-1.93 1.63-4.61.91-6.59Z"
			style={{
				fill: '#fce5ee',
			}}
		/>
	</svg>
);

export default BawangMerah;
