import React from 'react';
import styles from '../../styles/pages/Error404Page.module.scss'
import Error404 from "../../assets/svg/Error404";
import {TabTitle} from "../../utils/GeneralFunctions";

function ErrorPageNotFound(props) {
    TabTitle('404 Not Found!')
    return (
        <div className={styles.error_main_container}>
            <div className={styles.error_svg_container}>
                <Error404 height={"400px"}/>
            </div>
            {/*<div className={styles.error_button_back_container}>*/}
            {/*    <div className={styles.button_back_container}>*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default ErrorPageNotFound;