import React, {useEffect, useRef, useState} from 'react';
import styles from '../../styles/components/PriceGraphPanel.module.scss'

import {Bar, Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    TimeScale, //Import timescale instead of category for X axis
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

import {comColor, comDict, measurementsDict} from "../../utils/dictionary";

import {formatDateToString, formatPrice, formatUnits} from "../../utils/textFormatter";
import {PriceWidget} from "../PriceWidget/PriceWidget";
import {generateHistAPIURL} from "../../utils/generator";
import {axios6717} from "../../utils/axiosInstance";
import axios from "axios";

ChartJS.register(
    TimeScale, //Register timescale instead of category for X axis
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function PriceGraphPanel(props) {
    const {commodity, comPrice, nPrice, dPrice, region} = props;

    const comName = comDict[commodity];

    const [isRetrieved, setIsRetrieved] = useState(false);
    const [histData, setHistData] = useState({});
    const panelRef = useRef();

    const d4 = new Date();
    d4.setMonth(d4.getMonth() - 12);
    const lastMonth = formatDateToString(d4);

    const histURL = generateHistAPIURL('province', commodity, region, lastMonth)

    const retrieveData = () => {
        const getPriceHistogram = axios6717.get(histURL);

        axios.all([
            getPriceHistogram
        ]).then(
            axios.spread((...allData) => {
                const barData = {
                    labels: allData[0].data.map(function (d) {
                        return Math.round(d.x * 10) / 10;;
                    }),
                    datasets: [
                        {
                            label: `Frekuensi Harga ${comName} `,
                            data: allData[0].data.map(function (d) {
                                return Math.round(d.y * 10) / 10;
                            }),
                            backgroundColor: comColor[commodity],
                        },
                    ]
                };
                setHistData(barData);
                setIsRetrieved(true);
        })
        )
    }

    useEffect(() => {
        if(!isRetrieved){
            retrieveData()
        }
    }, [isRetrieved]);

    const timeSeriesChartData = {
        labels: comPrice.map((d) => {
            return d.x
        }),
        datasets:[
            {
                label:comDict[commodity],
                data: comPrice.map((d) => {
                    return d.y
                }),

                //Style
                fill:false,
                backgroundColor: 'white',
                borderColor: comColor[commodity],
                borderWidth: 2,
                // tension: 1,
                showTooltips: true,

                elements: {
                    point:{
                        radius: 1,
                        backgroundColor:'#FFFFFF'
                    }
                }
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales:{
            x: {
                display: true,
                ticks: {
                    color: '#FFFFFF',
                    callback: function (val) {
                        return formatPrice(this.getLabelForValue(val));
                    }
                },
            }
        },
        plugins: {
            legend: {
                display:false,
            },
            title: {
                display: false,
            },

        },
    };

    function clickRotate() {
        panelRef.current.classList.toggle(`${styles.rotated}`);
    }

    if(isRetrieved){
        return (
            <div className={styles.graph_panel_container}>
                <div className={styles.graph_panel} ref={panelRef} onClick={clickRotate}>
                    <div className={`${styles.panel_contents} ${styles.panel_time_series}`}>
                        <div className={styles.graph_title}>
                            <h2> GRAFIK HARGA {comName.toUpperCase()} </h2>
                            <div className={styles.station_title_caption}>
                                <p>Harga per Kilogram</p>
                            </div>
                        </div>
                        <div className={styles.panel_depth}>
                            <div className={styles.chart_container}>
                                <Line
                                    height={'inherit'}
                                    width={'inherit'}
                                    data={timeSeriesChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {

                                                display: true,
                                                type: 'time',
                                                time: {
                                                    displayFormats: {
                                                        month: 'DDD'
                                                    },
                                                    unit: 'day'
                                                },
                                                ticks: {
                                                    color: '#FFFFFF',
                                                },
                                                grid: {
                                                    display: false,
                                                    borderWidth: 0,
                                                }
                                            },
                                            y: {
                                                display: true,
                                                ticks: {
                                                    color: '#FFFFFF',
                                                    callback: function (value, index, values) {
                                                        return formatPrice(value);
                                                    }
                                                },
                                            },
                                        },
                                        plugins: {
                                            title: {
                                                display: false,
                                                color: '#FFFFFF'
                                            },
                                            legend: {
                                                display: false,
                                                position: 'top',
                                                labels: {
                                                    boxWidth: 10,
                                                    boxHeight: 10,
                                                }
                                            },
                                        },

                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/*===========================================================================================*/}
                    <div className={`${styles.panel_contents} ${styles.panel_price_widget}`}>
                        <div className={styles.graph_title}>
                            <h2> HISTOGRAM HARGA {comName.toUpperCase()} </h2>
                            <div className={styles.station_title_caption}>
                                <p>Distribusi harga satu tahun kebelakang</p>
                            </div>
                        </div>
                        <div className={styles.panel_depth}>
                            <div className={styles.chart_container}>
                                <Bar options={options} data={histData}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{return <></>}
}

export default PriceGraphPanel;


//
// return (
//     <div className={styles.graph_panel_container}>
//         <div className={styles.graph_panel} ref={panelRef} onClick={clickRotate}>
//             <div className={`${styles.panel_contents} ${styles.panel_time_series}`}>
//                 <div className={styles.graph_title}>
//                     <h2> GRAFIK HARGA {comName.toUpperCase()} </h2>
//                     <div className={styles.station_title_caption}>
//                         <p>Harga per Kilogram</p>
//                     </div>
//                 </div>
//                 <div className={styles.panel_depth}>
//                     <div className={styles.chart_container}>
//                         <Line
//                             height={'inherit'}
//                             width={'inherit'}
//                             data={timeSeriesChartData}
//                             options={{
//                                 responsive:true,
//                                 maintainAspectRatio: false,
//                                 scales: {
//                                     x: {
//
//                                         display: true,
//                                         type: 'time',
//                                         time: {
//                                             displayFormats: {
//                                                 month: 'DDD'
//                                             },
//                                             unit: 'day'
//                                         },
//                                         ticks: {
//                                             color: '#FFFFFF',
//                                         },
//                                         grid:{
//                                             display : false,
//                                             borderWidth:0,
//                                         }
//                                     },
//                                     y: {
//                                         display: true,
//                                         ticks: {
//                                             color: '#FFFFFF',
//                                             callback: function(value, index, values) {
//                                                 return formatPrice(value);
//                                             }
//                                         },
//                                     },
//                                 },
//                                 plugins: {
//                                     title: {
//                                         display: false,
//                                         color:'#FFFFFF'
//                                     },
//                                     legend: {
//                                         display:false,
//                                         position: 'top',
//                                         labels:{
//                                             boxWidth:10,
//                                             boxHeight:10,
//                                         }
//                                     },
//                                 },
//
//                             }}
//                         />
//                     </div>
//                 </div>
//             </div>
//             {/*===========================================================================================*/}
//             <div className={`${styles.panel_contents} ${styles.panel_price_widget}`}>
//                 <div className={styles.graph_title}>
//                     <h2> HARGA {comName.toUpperCase()} </h2>
//                     <div className={styles.station_title_caption}>
//                         <p>Perbandingan harga satu minggu ke belakang </p>
//                     </div>
//                 </div>
//                 <div className={styles.panel_depth}>
//                     <div className={styles.price_widget_container}>
//                         <PriceWidget nPrice={nPrice} dPrice={dPrice} com={commodity} level={'province'} region={region}/>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// );