import * as React from 'react';

const TelurAyam = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M125.03 132.62c0 26.6-13.07 40.05-30.35 42.83-2.29.37-4.65.55-7.07.55h-.78c-.25 0-.5 0-.75-.02-.14 0-.27 0-.4-.01l-.87-.04c-.61-.04-1.2-.09-1.81-.15-.22-.02-.45-.05-.68-.07-1.58-.18-3.12-.45-4.63-.81-.19-.04-.37-.09-.56-.14a37.36 37.36 0 0 1-4.83-1.58c-.22-.09-.45-.18-.67-.28-.22-.09-.43-.19-.66-.29-.22-.1-.43-.2-.65-.3-1.15-.56-2.26-1.18-3.34-1.87-.14-.09-.26-.17-.39-.26-.41-.26-.8-.53-1.18-.82-.2-.14-.39-.28-.57-.43a8.5 8.5 0 0 1-.5-.39c-.07-.06-.14-.11-.22-.18-.14-.11-.28-.22-.41-.34-.37-.31-.74-.64-1.1-.96-.18-.16-.36-.33-.53-.5s-.35-.34-.52-.51c-.18-.18-.34-.35-.51-.53-.51-.53-1-1.1-1.48-1.67-.64-.77-1.26-1.58-1.83-2.43-.44-.64-.86-1.29-1.26-1.97-.26-.46-.53-.92-.78-1.39-.12-.24-.25-.47-.37-.72-.32-.63-.63-1.29-.92-1.96-.02-.05-.05-.11-.07-.16-2.66-6.09-4.16-13.58-4.16-22.59 0-30.14 16.76-65.74 37.43-65.74 11.78 0 22.28 11.56 29.14 27.08 1.61 3.62 3.01 7.46 4.18 11.42.14.47.28.95.41 1.43l.39 1.41.36 1.4c.14.53.26 1.06.39 1.6.1.43.2.85.29 1.28l.3 1.43c.1.48.2.96.28 1.44.07.41.16.82.22 1.23.06.32.11.63.16.95.07.38.13.76.19 1.15.35 2.26.62 4.52.8 6.78.04.43.07.86.09 1.28.03.34.05.68.07 1.03.03.39.04.78.06 1.16 0 .16.01.31.02.47.02.51.03 1.01.04 1.51.01.56.02 1.12.02 1.68Z"
				style={{
					fill: '#d5834b',
				}}
			/>
			<path
				d="M125.03 132.62c0 26.6-13.07 40.05-30.35 42.83-2.29.37-4.65.55-7.07.55h-.78c-.25 0-.5 0-.75-.02-.14 0-.27 0-.4-.01l-.87-.04c-.61-.04-1.2-.09-1.81-.15-.22-.02-.45-.05-.68-.07-1.58-.18-3.12-.45-4.63-.81-.19-.04-.37-.09-.56-.14a37.36 37.36 0 0 1-4.83-1.58c-.22-.09-.45-.18-.67-.28-.22-.09-.43-.19-.66-.29-.22-.1-.43-.2-.65-.3-1.15-.56-2.26-1.18-3.34-1.87-.14-.09-.26-.17-.39-.26-.41-.26-.8-.53-1.18-.82-.2-.14-.39-.28-.57-.43a8.5 8.5 0 0 1-.5-.39c-.07-.06-.14-.11-.22-.18-.14-.11-.28-.22-.41-.34-.37-.31-.74-.64-1.1-.96-.18-.16-.36-.33-.53-.5s-.35-.34-.52-.51c-.18-.18-.34-.35-.51-.53-.51-.53-1-1.1-1.48-1.67a32.13 32.13 0 0 1-1.83-2.43c-.43-.64-.85-1.3-1.26-1.97-.26-.46-.53-.92-.78-1.39-.12-.24-.25-.47-.37-.72-1.35-2.66-2.46-5.62-3.3-8.88 7.22 7.24 16.27 11.55 26.08 11.55 5.44 0 10.63-1.32 15.41-3.72 15.75-7.93 27.01-27.66 27.41-50.88v-.02c.14.48.28.96.41 1.45l.39 1.41.36 1.4c.14.53.26 1.06.39 1.6.1.43.2.85.29 1.28l.3 1.43c.1.48.2.96.28 1.44.07.41.16.82.22 1.23.06.32.11.63.16.95.07.38.13.76.19 1.15.35 2.26.62 4.52.8 6.78.04.43.07.86.09 1.28.03.34.05.68.07 1.03.03.39.04.78.06 1.16 0 .16.01.31.02.47.02.51.03 1.01.04 1.51.01.56.02 1.12.02 1.68Z"
				style={{
					fill: '#c56034',
				}}
			/>
			<ellipse
				cx={77.25}
				cy={92.93}
				rx={18.65}
				ry={11.66}
				transform="rotate(-68.32 77.248 92.924)"
				style={{
					fill: '#efa866',
				}}
			/>
			<path
				d="M221.73 132.62c0 30.13-16.75 43.39-37.43 43.39h-.78c-.25 0-.5 0-.75-.02-.14 0-.27 0-.4-.01l-.87-.04c-.38-.03-.75-.05-1.12-.08-.22-.02-.45-.04-.68-.07-.22-.02-.45-.05-.68-.07-1.58-.18-3.12-.45-4.63-.81-.19-.04-.37-.09-.56-.14a37.36 37.36 0 0 1-4.83-1.58c-.22-.09-.45-.18-.67-.28-.22-.09-.43-.19-.66-.29-.22-.1-.43-.2-.65-.3-1.15-.56-2.26-1.18-3.34-1.87-.14-.09-.26-.17-.39-.26-.41-.26-.8-.53-1.18-.82-.2-.14-.39-.28-.57-.43a8.5 8.5 0 0 1-.5-.39c-.07-.06-.14-.11-.22-.18-.14-.11-.28-.22-.41-.34-.37-.31-.74-.64-1.1-.96-.18-.16-.36-.33-.53-.5-.18-.17-.34-.34-.52-.51-.18-.18-.34-.35-.51-.53-.34-.35-.67-.72-.99-1.1-.16-.19-.32-.38-.49-.57-.41-.49-.8-1-1.19-1.53l-.15-.21c-.34-.46-.66-.94-.98-1.43-.05-.07-.09-.15-.14-.23-.32-.5-.63-1.01-.93-1.54-.03-.06-.06-.11-.1-.17-.32-.57-.63-1.15-.93-1.75l-.02-.04c-3.15-6.38-4.97-14.43-4.97-24.32 0-13.79 3.51-28.72 9.3-40.87 6.86-14.4 16.92-24.87 28.14-24.87 14.53 0 27.12 17.57 33.32 38.48.14.48.28.96.41 1.45.14.47.26.94.39 1.41s.24.93.36 1.4c.14.53.26 1.06.39 1.6.1.43.2.85.29 1.28.11.48.21.95.3 1.43.1.48.2.96.28 1.44.08.41.16.82.22 1.23.06.32.11.63.16.95a71.752 71.752 0 0 1 .47 3.12c.08.57.15 1.15.22 1.72.07.55.12 1.1.18 1.64v.07c.05.53.1 1.07.14 1.6.06.69.11 1.39.14 2.08.03.39.04.78.06 1.16 0 .16.01.31.02.47.02.51.03 1.01.04 1.51.01.56.02 1.12.02 1.68Z"
				style={{
					fill: '#d5834b',
				}}
			/>
			<path
				d="M221.73 132.62c0 30.13-16.75 43.39-37.43 43.39h-.78c-.25 0-.5 0-.75-.02-.14 0-.27 0-.4-.01l-.87-.04c-.38-.03-.75-.05-1.12-.08-.22-.02-.45-.04-.68-.07-.22-.02-.45-.05-.68-.07-1.58-.18-3.12-.45-4.63-.81-.19-.04-.37-.09-.56-.14a37.36 37.36 0 0 1-4.83-1.58c-.22-.09-.45-.18-.67-.28-.22-.09-.43-.19-.66-.29-.22-.1-.43-.2-.65-.3-1.15-.56-2.26-1.18-3.34-1.87-.14-.09-.26-.17-.39-.26-.41-.26-.8-.53-1.18-.82-.2-.14-.39-.28-.57-.43a8.5 8.5 0 0 1-.5-.39c-.07-.06-.14-.11-.22-.18-.14-.11-.28-.22-.41-.34-.37-.31-.74-.64-1.1-.96-.18-.16-.36-.33-.53-.5-.18-.17-.34-.34-.52-.51-.18-.18-.34-.35-.51-.53-.34-.35-.67-.72-.99-1.1-.16-.19-.32-.38-.49-.57-3.35-4.06-5.94-9.17-7.54-15.4 7.22 7.24 16.27 11.55 26.08 11.55 2.35 0 4.65-.24 6.9-.73 20.07-4.23 35.47-26.66 35.92-53.9.14.48.28.96.41 1.45.14.47.26.94.39 1.41s.24.93.36 1.4c.14.53.26 1.06.39 1.6.1.43.2.85.29 1.28.11.48.21.95.3 1.43.1.48.2.96.28 1.44.08.41.16.82.22 1.23.06.32.11.63.16.95a71.752 71.752 0 0 1 .47 3.12c.08.57.15 1.15.22 1.72.07.55.12 1.1.18 1.64v.07c.05.53.1 1.07.14 1.6.06.69.11 1.39.14 2.08.03.39.04.78.06 1.16 0 .16.01.31.02.47.02.51.03 1.01.04 1.51.01.56.02 1.12.02 1.68Z"
				style={{
					fill: '#c56034',
				}}
			/>
			<ellipse
				cx={173.94}
				cy={92.93}
				rx={18.65}
				ry={11.66}
				transform="rotate(-68.32 173.938 92.925)"
				style={{
					fill: '#efa866',
				}}
			/>
			<path
				d="M125.01 130.93c.01.56.02 1.12.02 1.68 0 26.6-13.07 40.05-30.35 42.83-.83-4.41-1.27-9.25-1.27-14.53 0-1.54.03-3.1.11-4.66 1.09-23.34 10.2-48.5 23.22-62.31 1.61 3.62 3.01 7.46 4.18 11.42v-.02c.14.48.28.96.41 1.45l.39 1.41.36 1.4c.14.53.26 1.06.39 1.6.1.43.2.85.29 1.28l.3 1.43c.1.48.2.96.28 1.44.07.41.16.82.22 1.23.06.32.11.63.16.95.07.38.13.76.19 1.15.35 2.26.62 4.52.8 6.78.04.43.07.86.09 1.28.03.34.05.68.07 1.03.03.39.04.78.06 1.16 0 .16.01.31.02.47.02.51.03 1.01.04 1.51ZM181.72 160.92c0 5.44-.47 10.4-1.34 14.93-.22-.02-.45-.04-.68-.07-.22-.02-.45-.05-.68-.07-1.58-.18-3.12-.45-4.63-.81-.19-.04-.37-.09-.56-.14a37.36 37.36 0 0 1-4.83-1.58c-.22-.09-.45-.18-.67-.28-.22-.09-.43-.19-.66-.29-.22-.1-.43-.2-.65-.3-1.15-.56-2.26-1.18-3.34-1.87-.14-.09-.26-.17-.39-.26-.41-.26-.8-.53-1.18-.82-.2-.14-.39-.28-.57-.43a8.5 8.5 0 0 1-.5-.39c-.07-.06-.14-.11-.22-.18-.14-.11-.28-.22-.41-.34-.37-.31-.74-.64-1.1-.96-.18-.16-.36-.33-.53-.5-.18-.17-.34-.34-.52-.51-.18-.18-.34-.35-.51-.53-.34-.35-.67-.72-.99-1.1-.16-.19-.32-.38-.49-.57-.41-.49-.8-1-1.19-1.53l-.15-.21c-.34-.46-.66-.94-.98-1.43-.05-.07-.09-.15-.14-.23-.32-.5-.63-1.01-.93-1.54-.03-.06-.06-.11-.1-.17-.32-.57-.63-1.15-.93-1.75l-.02-.04c-3.15-6.38-4.97-14.43-4.97-24.32 0-13.79 3.51-28.72 9.3-40.87 1.72 1.6 3.39 3.41 4.98 5.38 3.18 3.95 6.09 8.57 8.65 13.66 3.35 6.65 6.12 14.07 8.13 21.8.03.09.05.19.07.28.31 1.22.61 2.45.89 3.68.01.08.03.17.05.26.29 1.29.55 2.58.8 3.89l.02.12c.24 1.26.45 2.53.65 3.8.01.11.03.23.05.34.19 1.26.36 2.51.51 3.77l.02.18c.16 1.3.28 2.59.38 3.88 0 .09.01.18.02.27.09 1.23.17 2.45.22 3.68 0 .11 0 .22.01.34.03.73.05 1.45.06 2.18.01.55.02 1.11.02 1.66Z"
				style={{
					fill: '#df8a5b',
					mixBlendMode: 'multiply',
					opacity: 0.51,
				}}
			/>
			<path
				d="M174.99 158.91c0 30.13-16.76 43.39-37.43 43.39s-37.43-13.26-37.43-43.39 16.76-65.74 37.43-65.74 37.43 35.6 37.43 65.74Z"
				style={{
					fill: '#d5834b',
				}}
			/>
			<path
				d="M170.88 131.66c-.5 30.28-19.48 54.63-42.82 54.63-9.82 0-18.86-4.31-26.08-11.56 4.89 19 18.97 27.57 35.58 27.57 20.67 0 37.43-13.26 37.43-43.39 0-8.96-1.48-18.41-4.11-27.26Z"
				style={{
					fill: '#c56034',
				}}
			/>
			<ellipse
				cx={127.2}
				cy={119.22}
				rx={18.65}
				ry={11.66}
				transform="rotate(-68.32 127.199 119.222)"
				style={{
					fill: '#efa866',
				}}
			/>
		</g>
	</svg>
);

export default TelurAyam;
