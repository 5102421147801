import styles from '../../styles/pages/Dashboard.module.scss';

import React, {useEffect} from 'react';
import L from "leaflet";
import semnetMarker from "../../assets/png/SemnetMarker.png";
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

import {estateSensorList, marvestSensorList, sensorList} from "../../utils/dictionary";
import {Link} from "react-router-dom";

import useSensorDetails from "../../hooks/useSensorDetails";
import useAuth from "../../hooks/useAuth";
import {TabTitle} from "../../utils/GeneralFunctions";



var SemnetMarker = L.icon({
    iconUrl: semnetMarker,
    shadowUrl: iconShadow,
    iconSize: new L.Point(50, 50),
});
L.Marker.prototype.options.icon = SemnetMarker;


function Dashboard() {
    TabTitle('Sembako - Dashboard')

    const {setSensorDetails} = useSensorDetails()
    const {auth} = useAuth();
    const user = auth.user;

    //const accessToken = 'pk.eyJ1IjoidGFyLWhlbCIsImEiOiJjbDJnYWRieGMwMTlrM2luenIzMzZwbGJ2In0.RQRMAJqClc4qoNwROT8Umg'
    //const mapUrl = `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${accessToken}`

    const markers = sensorList.map((sensor) => {
        return(
            <Marker
                key={sensor["urlType"]}

                position={[sensor['latitude'], sensor['longitude']]}
                eventHandlers={{
                    click: () => {
                        setSensorDetails(sensor);
                    }
                }}
            >
                <Popup>
                    <b>{sensor['locationName']}</b>
                    <br/>
                    Klik untuk menetapkan pilihan lokasi sensor menjadi {sensor['locationName']}
                </Popup>
            </Marker>
        )
    })

    const marvest_markers = marvestSensorList.map((sensor) => {
        return(
            <Marker
                key={sensor["urlType"]}

                position={[sensor['latitude'], sensor['longitude']]}
                eventHandlers={{
                    click: () => {
                        setSensorDetails(sensor);
                    }
                }}
            >
                <Popup>
                    <b>{sensor['locationName']}</b>
                    <br/>
                    Klik untuk menetapkan pilihan lokasi sensor menjadi {sensor['locationName']}
                </Popup>
            </Marker>
        )
    })

    const estate_markers = estateSensorList.map((sensor) => {
        return(
            <Marker
                key={sensor["urlType"]}

                position={[sensor['latitude'], sensor['longitude']]}
                eventHandlers={{
                    click: () => {
                        setSensorDetails(sensor);
                    }
                }}
            >
                <Popup>
                    <b>{sensor['locationName']}</b>
                    <br/>
                    Klik untuk menetapkan pilihan lokasi sensor menjadi {sensor['locationName']}
                </Popup>
            </Marker>
        )
    })

    const markerCluster = new L.MarkerClusterGroup();

    markerCluster.addLayer(L.marker([175.3107, -37.7784]));


    return (
       <div className={styles.main_container}>
           <div className={styles.dashboard_title}>
               <h1>PILIH LOKASI SENSOR</h1>
           </div>

           <div className={styles.map_container}>
                <MapContainer className={styles.map_canvas}
                              center={ (auth.user === 'marves_admin' || 'marves_estate') ? ['2.2958420', '98.6291450']  : ['-2.5', '117.5']}
                              zoom={(auth.user === 'marves_admin') ? 13 : 5}
                              scrollWheelZoom={true}
                              style={{width:"inherit",height:"inherit", borderRadius:'12px', zIndex:'0'}}>
                	<TileLayer
                		attribution='Map tiles by Mapbox. Data by OpenStreetMap, under ODbL.'
                		url='https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGFyLWhlbCIsImEiOiJjbDJnYWRieGMwMTlrM2luenIzMzZwbGJ2In0.RQRMAJqClc4qoNwROT8Umg'
                	/>


                    {/* Insert marker that we mapped earlier from sensorList*/}
                    {/*{(auth.user === 'marves_admin') ?*/}
                    {/*    <MarkerClusterGroup showCoverageOnHover={true}>*/}
                    {/*        {marvest_markers}*/}
                    {/*    </MarkerClusterGroup>*/}
                    {/*    :*/}
                    {/*    (auth.user === 'marves_estate') ?*/}
                    {/*        <MarkerClusterGroup showCoverageOnHover={true}>*/}
                    {/*            {estate_markers}*/}
                    {/*        </MarkerClusterGroup>*/}
                    {/*        :*/}
                    {/*        <MarkerClusterGroup showCoverageOnHover={true}>*/}
                    {/*            {markers}*/}
                    {/*        </MarkerClusterGroup>*/}
                    {/*}*/}

                    {(auth.user === 'marves_admin') ?
                        marvest_markers
                        :
                        (auth.user === 'marves_estate') ?
                            estate_markers
                            :
                            markers
                    }


                </MapContainer>

               <div className={styles.map_caption}>
                   Geser peta untuk melihat lokasi sensor lainnya
               </div>
           </div>


           <Link
               to={`/tinjauan-agroklimatologi`}
           >

               <div className={styles.proceed_button_container}>
                   <div className={styles.proceed_button_text}>
                       <p> Lanjutkan </p>
                   </div>
               </div>
           </Link>
       </div>
    );
}

export default Dashboard;