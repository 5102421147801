import * as React from 'react';

const BawangPutih = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M194.17 133.98c-1.32 6.62-3.96 11.31-8.45 15.31-1.45 1.3-3.1 2.52-4.95 3.73l-17.3-15.41c-4.34-5.09-8.7-10.24-12.68-15.61-3.4-4.53-6.5-9.24-9.11-14.25-2.79-5.36-4.98-11.05-6.21-16.97h-.5c-1.38.08-2.76.18-4.14.27-1.16 5.84-3.28 11.51-5.97 16.8-5.57 11.02-13.5 20.63-21.4 30.12l-16.73 15.27-.37.35c-7.69-4.79-11.79-10.15-13.64-18.88-1.69-7.94-.13-15.63 5.87-21.63 4.46-4.48 10.43-6.93 16.03-9.87a96.622 96.622 0 0 0 22.86-17.04c.35-.35.53-1.05.76-1.48.22-.5.49-1 .71-1.49.51-1.03.96-2.03 1.38-3.12.87-2.07 1.61-4.19 2.26-6.35 1.32-4.31 2.21-8.77 2.68-13.23.21-2.27.36-4.53.35-6.76 0-1.11.02-2.23-.09-3.29-.09-.5-.06-1.03-.14-1.58-.11-.57-.23-1.14-.23-1.71v-.2c.03-.88.34-1.66.83-2.31.64-.92 1.63-1.63 2.64-2.13 4.02-2.05 9.97-1.98 13.27.2 1.39.88 2.09 2.01 2.14 3.17-.03.28-.03.53-.07.81-.1 1.6-.22 3.26-.25 4.88-.08 4.97.98 9.95 1.97 14.79.93 4.52 1 9.88 3.16 13.95 2.87 5.39 6.44 9 11.14 12.86a207.6 207.6 0 0 0 11.08 8.46c5.47 3.93 12.09 6.04 16.96 10.8 6.06 5.93 7.76 13.6 6.15 21.56Z"
			style={{
				fill: '#fece9c',
			}}
		/>
		<path
			d="M185.72 149.29c-1.45 1.3-3.1 2.52-4.95 3.73l-17.3-15.41c-4.34-5.09-8.7-10.24-12.68-15.61a74.57 74.57 0 0 1-3-5.75c-4.96-10.68-6.85-22.53-10.97-33.57-1.81-4.85-4.09-9.73-3.88-14.91.13-3.53 1.43-7.12.53-10.53l.56-2.41c2.29 3.78 1.93 8.53 2.71 12.89.51 2.81 1.52 5.49 2.68 8.1 1.66 3.78 3.62 7.45 6.1 10.75 1.89 2.49 4.06 4.75 6.15 7.07 9.08 10.16 16.51 21.68 23.5 33.36 4.22 7.06 8.37 14.4 10.55 22.29ZM128.32 51.35c-.07.77-.11 1.57-.18 2.34-.11 1.57-.17 3.19-.28 4.76-.18 2.69-.51 5.38-.8 8.07-.29 2.81-.24 5.53-.8 8.34-.56 2.65-1.47 5.27-2.76 7.66-2.51 4.62-6.13 8.56-10.07 11.97-5.77 4.95-12.35 8.91-18.43 13.47-3.48 2.56-6.8 5.38-9.28 8.89-5.1 7.18-6.08 16.86-3.34 25.21 1.21 3.6 3.04 7.01 4.17 10.61.08.19.12.38.16.58l-.38.35c-7.67-4.79-11.77-10.14-13.63-18.89-1.7-7.94-.13-15.63 5.86-21.62 4.47-4.48 10.44-6.93 16.03-9.88a96.312 96.312 0 0 0 22.86-17.03c.34-.35.53-1.04.76-1.47.23-.5.49-1 .72-1.5.49-1.04.95-2.04 1.36-3.12.87-2.08 1.63-4.19 2.27-6.35 1.32-4.31 2.21-8.77 2.69-13.23.22-2.27.36-4.53.34-6.76 0-1.11.03-2.23-.1-3.3-.08-.5-.04-1.04-.12-1.57-.12-.58-.24-1.15-.24-1.73v-.19c.03-.88.33-1.65.83-2.31l2.48 4.52c.2.38-.1 1.69-.14 2.19Z"
			style={{
				fill: '#eab986',
			}}
		/>
		<path
			d="M180.1 166.59c-3.02 5.88-8.59 10.28-14.86 12.23-2.96.93-6.72 1.64-10.26 1.4-.58-.02-1.15-.08-1.73-.19-.51-.08-1.03-.2-1.52-.35-2.08-.58-3.9-1.64-5.24-3.41-3.54-4.66-4.23-11.57-5.28-17.16-1.21-6.41-2.3-12.86-3.29-19.36-.14-1.01-.31-2.01-.45-3.02a571.726 571.726 0 0 1-4.77-44.33c-.02-.23-.02-.45-.04-.68-.02.23-.02.45-.02.68-.84 14.84-2.26 29.65-4.23 44.37-1.01 7.5-2.16 14.98-3.45 22.44-.99 5.59-1.62 12.49-5.1 17.22-.58.76-1.25 1.42-2.01 1.95-.39.29-.82.54-1.25.74-.39.21-.82.41-1.27.56-.53.21-1.09.37-1.66.49-4.07.88-8.88.1-12.49-.99-6.31-1.87-11.92-6.21-15.02-12.04-4.25-8.05-3.74-17.98-.64-26.57 3.74-10.4 10.52-20.33 17.88-28.5 7.29-8.08 15.37-15.6 21.17-24.93.72-1.22 1.44-2.45 2.1-3.7 4.11-7.91 6.12-16.89 5.86-25.81-.02-.93-.14-1.56.39-2.38.16-.23.41-.47.7-.45.35.02.56.39.7.7.7 1.5.56 3.37.64 5.05.1 1.87.18 3.76.33 5.61.31 3.62.82 7.34 2.26 10.71 1.77 4.29 4.77 7.87 7.65 11.43 3.14 3.84 6.08 7.85 9.02 11.84 4.05 5.51 8.12 11.02 12.16 16.5 5.41 7.36 10.89 14.84 14.08 23.37 3.19 8.57 3.86 18.47-.33 26.59Z"
			style={{
				fill: '#f3d2a8',
			}}
		/>
		<path
			d="M155.01 119.63c-1.44-3.82-3.66-7.5-5.49-11.16-2.07-4.11-4.13-8.23-6.13-12.37-.9-1.85-2.13-3.6-3.08-5.45-.98-1.9-1.89-3.84-2.74-5.8-.72-1.67-1.39-3.37-2.33-4.93-.46-.76-1.18-1.58-2.06-1.46-.4.06-.75.3-1.04.58-.96.89-1.52 2.12-2.05 3.32-.97 2.19-1.95 4.39-2.92 6.58-.44.98-6.08 13.43-8.06 17.54-2.73 5.69-9.72 24.89-10.65 28.81-1.11 4.66-1.87 9.41-2.08 14.2-.43 11.08 1.32 21.76 8.41 29.63.16.19.35.37.53.56.45-.14.88-.35 1.27-.56a7.9 7.9 0 0 0 1.25-.74c.76-.53 1.44-1.19 2.01-1.95 3.47-4.73 4.11-11.63 5.1-17.22 1.29-7.46 2.45-14.94 3.45-22.44 1.97-14.71 3.39-29.53 4.23-44.37 0-.23 0-.45.02-.68.02.23.02.45.04.68 1.01 14.84 2.61 29.63 4.77 44.33.14 1.01.31 2.01.45 3.02.99 6.5 2.07 12.95 3.29 19.36 1.05 5.59 1.75 12.49 5.28 17.16.39.51.83.95 1.29 1.35.11.1.24.18.35.27.36.28.73.53 1.13.76.17.1.35.19.52.27.35.17.71.32 1.08.45.23.09.46.18.71.25.05.02.1.04.17.04.5.14.99.28 1.51.36.58.1 1.15.17 1.73.19.06-.06.1-.12.17-.19 5.03-5.96 8.06-14.32 8.06-22.58.02-13.38-4.54-28.09-8.2-37.81Z"
			style={{
				fill: '#eab986',
			}}
		/>
		<path
			d="M161.32 157.44c0 8.26-3.03 16.62-8.06 22.6-5.87 6.92-14.39 10.67-24.03 7.43-1.81-.6-3.65-1.45-5.52-2.56-2.81-1.67-5.16-3.61-7.1-5.77-7.1-7.87-8.84-18.58-8.41-29.64.19-4.79.96-9.54 2.07-14.21.93-3.92 7.91-23.11 10.65-28.8 2.75-5.74 4.96-11.76 7.54-17.57.72-1.66 2.84-8.58 3.63-10.83.04-.13.07-.22.11-.31.78-2.16 2.28-.49 2.58.9 1.06 4.73 2.97 9.8 5.16 14.13 2.44 5.27 5.07 10.48 7.68 15.68 1.84 3.65 4.06 7.32 5.5 11.16 3.65 9.72 8.21 24.43 8.2 37.8Z"
			style={{
				fill: '#f3d2a8',
			}}
		/>
		<path
			d="M144.03 45.88c-.04.27-.03.54-.07.81-.3 1.31-1.44 2.58-3.36 3.55-4.06 2.06-9.97 1.98-13.29-.15-.96-.61-1.62-1.37-1.89-2.18v-.04c-.43-1-.28-2.11.48-3.11 0-.04 0-.08.04-.11.57-.77 1.49-1.55 2.68-2.13 4.02-2.06 9.97-1.98 13.29.19 1.39.88 2.08 2.02 2.13 3.18Z"
			style={{
				fill: '#fbeed5',
			}}
		/>
		<path
			d="M177.16 147.78c-.03-.17-.06-.34-.09-.5-.58-3.33-1.26-6.68-2.58-9.79-1.1-2.6-2.63-4.98-4.16-7.35-2.03-3.16-3.93-6.52-6.18-9.53-1.52-2.04-15.85-24.74-17.54-26.56-.61-.65-1.41-1.25-2.29-1.16-2.68.27.07 3.69.61 4.64 2.18 3.85 3.64 8.05 5.51 12.04 1.97 4.21 3.89 8.44 5.84 12.66 1.34 2.9 2.68 5.8 3.69 8.83 1.07 3.19 1.77 6.49 2.42 9.79.93 4.73 1.77 9.47 2.24 14.27.38 3.86.49 7.91-1.11 11.45-1.09 2.42-2.94 4.5-3.62 7.06-.08.29-.14.6-.05.89.17.57.82.84 1.41.93 2.48.39 4.89-.95 6.96-2.38 2.25-1.55 4.41-3.28 6.03-5.48 4.09-5.56 3.98-13.28 2.91-19.81Z"
			style={{
				fill: '#ffe2bd',
			}}
		/>
		<path
			d="M170.86 139.43c-.42-.99-.93-1.96-1.44-2.91-5-9.4-9.29-19.18-15.07-28.15-.27-.42-3.37-4.03-3.32-2.19.04 1.3 1.77 3.05 2.46 4.11.95 1.46 1.89 2.93 2.79 4.42 1.81 2.97 3.5 6.02 4.94 9.19 2.48 5.49 3.63 11.1 4.67 16.97.27 1.55.72 3.08 1.32 4.53.45 1.08 1.27 2.29 2.43 2.18.88-.09 1.49-.93 1.79-1.76.56-1.54.44-3.26-.01-4.83-.15-.54-.34-1.06-.56-1.57Z"
			style={{
				fill: '#fbeed5',
			}}
		/>
		<path
			d="M153.96 137.11c-1.91-6.19-4.06-12.3-6.28-18.37-1-2.74-11.71-29.29-12.92-32.79-.14-.39-.46-.88-.84-.71-.27.12-.3.48-.29.77.09 4.02.45 8.03.58 12.02.09 2.66.12 5.33.08 7.99-.07 5.23-.37 10.45-.92 15.65-1.26 12.07-3.77 24.56-.03 36.11 1.31 4.05 3.5 7.99 7.04 10.35 3.54 2.36 8.62 2.73 11.91.04 1.69-1.38 2.75-3.38 3.47-5.44 2.88-8.23.75-17.3-1.82-25.63Z"
			style={{
				fill: '#ffe2bd',
			}}
		/>
		<path
			d="m147.97 126.19-2.97-8.73c-1.89-5.57-3.79-11.16-6.29-16.49-.69-1.48-1.26-3.06-2.03-4.49-1.04-1.93-.69 1.78-.61 2.19.79 3.9 2.7 7.58 3.7 11.46 1.13 4.41 1.77 8.93 2.07 13.47.5 7.49.48 15.07 2.08 22.43.27 1.24.6 2.5 1.39 3.49.79.99 2.15 1.62 3.35 1.2 1.29-.46 1.91-1.9 2.25-3.23 1.76-7.06-.6-14.42-2.95-21.31Z"
			style={{
				fill: '#fbeed5',
			}}
		/>
		<path
			d="M196.52 187.5c-.39 1.75-.89 3.44-1.44 4.94-.11.32-.21.62-.34.91-1.24 3.07-2.86 6.03-4.83 8.67-.05.03-.08.08-.11.14-.98 1.32-2.08 2.59-3.28 3.75l-.03.03c-2.4 2.35-5.15 4.3-8.2 5.66-.51.25-1.04.46-1.58.65-6.4 2.4-13.63 3.03-20.09.76-4.11-1.42-7.8-4.11-10.35-7.63-1.27-1.79-2.36-3.74-3.12-5.78-.08-.19-.16-.4-.22-.62-.27-.83-.49-1.63-.58-2.41-.14-1.07-.04-2.09.36-2.93.21-.46.53-.81.88-1.08.24-.19.51-.33.78-.43 2.54-.98 6.79.36 9.36 0 6-.81 11.62-3.58 16.97-6.24 2.81-1.38 5.64-2.77 8.13-4.66 3.12-2.38 5.6-5.43 7.81-8.66 1.73-2.53 3.07-7.58 6.29-6.64.3.08.62.21.94.4.97.56 1.67 1.49 2.19 2.64 2.28 5.19 1.93 12.38.46 18.53Z"
			style={{
				fill: '#f8efce',
			}}
		/>
		<path
			d="M192.14 167.77c1.06 1.57-1.38 5.35-2.09 6.68a38.305 38.305 0 0 1-4.3 6.4c-3.7 4.46-8.42 8.03-13.67 10.48a42.2 42.2 0 0 1-5.35 2.06c-1.16.36-5.58 1.45-6.67 1.66-1.09.21-2.17.43-3.27.53-2.21.21-4.44.15-6.64-.1-1.07-.12-3.34-.84-3.45-2.16-.02-.19.05-.42.23-.5.5-.22 1.16.19 1.64.29.76.15 1.61.13 2.39.13 2.3 0 4.64-.41 6.9-.79 2.37-.4 4.72-.97 6.99-1.75 2.67-.91 5.23-2.14 7.67-3.55 1.38-.8 2.78-1.58 4.1-2.48 1.34-.91 8.1-7.96 10.03-10.79.62-.91 3.62-7.95 5.31-6.33.07.06.13.14.18.21Z"
			style={{
				fill: '#fffbf3',
			}}
		/>
		<path
			d="M196.52 187.5c-.39 1.75-.89 3.44-1.44 4.94-.11.32-.21.62-.34.91-1.24 3.07-2.86 6.03-4.83 8.67-.05.03-.08.08-.11.14-1.12 1.24-2.08 2.59-3.3 3.78-2.4 2.35-5.15 4.3-8.2 5.66-.51.25-1.04.46-1.58.65-6.4 2.4-13.63 3.03-20.09.76-4.11-1.42-7.8-4.11-10.35-7.63-1.27-1.79-2.36-3.74-3.12-5.78-.08-.19-.16-.4-.22-.62-.27-.83-.49-1.63-.58-2.41-.08-.72-.11-1.53.08-2.25.19-.73.98-1.66 1.66-.84.58.7.61 2.23.94 3.11 2.06 5.48 7.35 9.36 12.78 11.05 2.9.89 5.94 1.31 8.97 1.56 3.06.22 5.64.45 8.72-.35 2.12-.52 4.23-1.26 6.05-2.45 1.47-.98 2.75-2.22 3.95-3.49 4.34-4.61 8.07-9.81 11.01-15.41Z"
			style={{
				fill: '#e9cb82',
			}}
		/>
		<path
			d="M194.86 172.23c-.15-.61-.52-1.33-1.15-1.31-.51.02-.84.54-1.06 1-1.69 3.43-2.65 7.14-4.29 10.58-.79 1.65-1.7 3.13-2.97 4.46-1.96 2.05-4.41 3.56-6.89 4.93-3.7 2.04-7.57 3.84-11.68 4.79-4.46 1.03-9.08 1.04-13.65 1.04-1.23 0-2.4-.05-3.62-.17-.1 0-.21-.02-.31.02-.36.14-.25.68-.06 1.01.87 1.47 2.2 2.63 3.65 3.53 1.45.9 3.04 1.56 4.63 2.2 1.39.56 2.78 1.11 4.23 1.49 2.88.74 5.89.74 8.87.74 2.8 0 5.77-.43 8.37-1.52 2.47-1.03 4.59-2.74 6.55-4.55 1.81-1.68 3.53-3.48 4.85-5.56 2.19-3.44 3.2-7.47 4.03-11.46.78-3.7 1.43-7.56.5-11.23Z"
			style={{
				fill: '#f0e3b1',
			}}
		/>
		<path
			d="M58.4 177.16c-.12 1.79-.13 3.55-.02 5.15.01.34.03.65.07.97.32 3.29 1.03 6.59 2.16 9.68.04.04.05.1.06.16.57 1.55 1.25 3.07 2.07 4.53l.02.03c1.63 2.93 3.71 5.59 6.25 7.76.42.38.87.74 1.33 1.08 5.45 4.12 12.21 6.79 19.04 6.46 4.35-.19 8.65-1.71 12.09-4.37 1.73-1.35 3.33-2.92 4.64-4.65.13-.15.27-.34.38-.53.5-.72.94-1.43 1.24-2.15.44-.99.63-2 .49-2.91-.07-.5-.28-.93-.54-1.28a2.9 2.9 0 0 0-.62-.64c-2.16-1.66-6.61-1.59-8.97-2.67-5.52-2.49-10.12-6.75-14.49-10.82-2.3-2.13-4.62-4.26-6.46-6.78-2.32-3.17-3.82-6.8-5.02-10.53-.94-2.92-.78-8.14-4.14-8.15-.31 0-.65.02-1.02.11-1.09.26-2.03.95-2.85 1.91-3.67 4.32-5.37 11.32-5.72 17.63Z"
			style={{
				fill: '#f8efce',
			}}
		/>
		<path
			d="M68.23 159.5c-1.46 1.21-.2 5.52.1 7 .51 2.53 1.28 4.99 2.29 7.36 2.27 5.34 5.78 10.1 10.11 13.95a41.54 41.54 0 0 0 4.54 3.5c1.01.67 4.93 2.97 5.92 3.49.99.51 1.95 1.03 2.99 1.44 2.06.83 4.21 1.42 6.4 1.8 1.06.19 3.44.14 3.93-1.08.07-.18.07-.41-.08-.54-.41-.35-1.16-.14-1.65-.19-.77-.07-1.58-.34-2.33-.56-2.2-.66-4.33-1.72-6.39-2.72a44.916 44.916 0 0 1-6.2-3.67c-2.3-1.63-4.41-3.54-6.34-5.6-1.09-1.16-2.22-2.3-3.23-3.54-1.03-1.26-5.5-9.94-6.54-13.2-.34-1.05-1.2-8.65-3.29-7.58-.08.04-.16.09-.23.15Z"
			style={{
				fill: '#fffbf3',
			}}
		/>
		<path
			d="M58.4 177.16c-.12 1.79-.13 3.55-.02 5.15.01.34.03.65.07.97.32 3.29 1.03 6.59 2.16 9.68.04.04.05.1.06.16.72 1.51 1.25 3.07 2.09 4.56 1.63 2.93 3.71 5.59 6.25 7.76.42.38.87.74 1.33 1.08 5.45 4.12 12.21 6.79 19.04 6.46 4.35-.19 8.65-1.71 12.09-4.37 1.73-1.35 3.33-2.92 4.64-4.65.13-.15.27-.34.38-.53.5-.72.94-1.43 1.24-2.15.28-.67.54-1.43.57-2.18.02-.75-.47-1.87-1.36-1.28-.76.51-1.21 1.96-1.78 2.71-3.53 4.66-9.71 6.88-15.4 6.95-3.04.03-6.06-.44-9.04-1.06-3-.66-5.53-1.18-8.26-2.82-1.88-1.11-3.7-2.41-5.1-4.07-1.13-1.35-2.01-2.91-2.79-4.46a69.961 69.961 0 0 1-6.16-17.91Z"
			style={{
				fill: '#e9cb82',
			}}
		/>
		<path
			d="M64.35 162.99c.32-.54.87-1.13 1.47-.93.48.17.65.76.73 1.26.64 3.77.5 7.6 1.1 11.37.29 1.8.74 3.48 1.58 5.12 1.29 2.53 3.21 4.67 5.2 6.69 2.96 3.01 6.16 5.84 9.83 7.92 3.98 2.26 8.4 3.59 12.79 4.89 1.17.35 2.31.63 3.52.87.1.02.21.04.29.11.31.23.05.72-.23.98-1.26 1.16-2.86 1.9-4.51 2.35-1.65.45-3.36.63-5.06.79-1.49.14-2.99.28-4.48.22-2.97-.11-5.86-.97-8.71-1.81-2.68-.8-5.41-2.06-7.59-3.84-2.07-1.69-3.62-3.93-4.98-6.23-1.26-2.12-2.39-4.34-3.07-6.71-1.12-3.91-.93-8.07-.6-12.13.31-3.77.79-7.66 2.72-10.9Z"
			style={{
				fill: '#f0e3b1',
			}}
		/>
		<path
			d="M90.25 137.47c-.79 1.43-1.47 2.88-1.98 4.34-1.86 5.32-2.02 11.04-1.98 16.62.03 4.03.35 8.15 1.98 11.83 1.22 2.76 3.29 5.27 6 6.68 2.31 1.2 5.69 2.15 7.84.28 1.09-.94 1.39-2.51 1.33-3.94-.1-2.3-.94-4.49-1.61-6.69-2.72-8.82-2.91-18.41-.55-27.33 1.28-4.83 3.28-9.44 5.28-14.01 3.01-6.87 6.89-12.95 10.42-19.52.68-1.27 1.32-2.68 1.06-4.1-.95.02-1.68.81-2.28 1.55-1.45 1.8-6.58 9.27-8.11 11.07-3.69 4.33-7.24 8.79-10.64 13.35-2.26 3.03-4.83 6.38-6.76 9.87ZM186.08 125.69c-1.64-2.97-4.16-5.35-6.68-7.61-3.3-2.96-6.71-5.82-10.37-8.32-1.36-.93-2.77-1.8-4.12-2.74-1.06-.74-2.4-2.34-3.7-2.56-.43-.07-.93.06-1.15.44-.25.43-.07.97.15 1.41 1.3 2.66 3.06 5.05 4.71 7.51 3.45 5.15 6.92 10.31 9.67 15.86 1.69 3.42 3.11 6.96 4.8 10.38.86 1.74 3.74 9.16 6.68 6.38.28-.27.43-.63.57-.99 2.3-6.11 2.7-13.88-.55-19.76Z"
			style={{
				fill: '#ffe2bd',
			}}
		/>
		<path
			d="M181.88 126.21c-3.94-5.86-9.24-10.64-14.25-15.54-.64-.62-2-1.99-2.98-1.5.09.38.32.7.59 1.01.62.7 1.38 1.28 2.1 1.88 4.07 3.4 7.46 7.63 9.9 12.34.89 1.71 1.65 3.49 2.78 5.05.38.53 3.42 4.29 3.93 3.91.82-.61.18-2.63-.07-3.38-.45-1.35-1.2-2.59-1.99-3.77Z"
			style={{
				fill: '#fbeed5',
			}}
		/>
	</svg>
);

export default BawangPutih;
