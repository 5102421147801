import * as React from 'react';

const BerawanPagi = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="berawanPagi_svg__b"
				cx={148.46}
				cy={90.95}
				fx={148.46}
				fy={90.95}
				r={158.02}
				gradientTransform="matrix(-1 0 0 -.73 296.92 157.4)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="berawanPagi_svg__a"
				cx={703.24}
				cy={-2790.52}
				fx={703.24}
				fy={-2790.52}
				r={19.06}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3788.23 -4373.93)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="berawanPagi_svg__c"
				cx={702.67}
				cy={-2825.74}
				fx={702.67}
				fy={-2825.74}
				r={14.98}
				xlinkHref="#berawanPagi_svg__a"
			/>
			<radialGradient
				id="berawanPagi_svg__d"
				cx={43.34}
				cy={175.86}
				fx={43.34}
				fy={175.86}
				r={10.96}
				gradientTransform="rotate(-25.71 -693.038 343.856) scale(1 2.91)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M182.93 116.53c-2.16-31.6-28.45-56.56-60.6-56.56s-58.44 24.96-60.59 56.56c-23.95 1.18-42.99 20.97-42.99 45.2s20.26 45.26 45.26 45.26h116.65c25 0 45.26-20.27 45.26-45.26s-19.04-44.02-42.99-45.2Z"
				style={{
					fill: 'url(#berawanPagi_svg__b)',
				}}
			/>
			<path
				d="M122.34 122.16c-17.04-13.83-23.69-34.88-14.86-47.02 8.84-12.15 29.82-10.78 46.86 3.03 17.04 13.82 23.68 34.88 14.86 47.01-8.84 12.15-29.82 10.79-46.86-3.03Z"
				style={{
					fill: 'url(#berawanPagi_svg__a)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M183.26 163.02c-13.4-10.87-18.63-27.41-11.68-36.95 6.95-9.55 23.43-8.47 36.83 2.38 13.39 10.86 18.61 27.41 11.68 36.94-6.95 9.55-23.44 8.48-36.82-2.38Z"
				style={{
					fill: 'url(#berawanPagi_svg__c)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M51.11 164.53c-9.88-15.41-21.36-22.83-25.66-16.58-4.28 6.25.25 23.81 10.12 39.24 9.89 15.42 21.37 22.84 25.66 16.59 4.29-6.27-.24-23.81-10.12-39.24Z"
				style={{
					fill: 'url(#berawanPagi_svg__d)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
		</g>
	</svg>
);

export default BerawanPagi;
