import * as React from 'react';

const BerawanTebalMalam = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="berawanTebalMalam_svg__a"
				cx={130.21}
				cy={48.07}
				fx={130.21}
				fy={48.07}
				r={58.3}
				gradientTransform="matrix(-1 0 0 -.73 260.42 83.19)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#b5ccd2" />
				<stop offset={0.2} stopColor="#97b6be" />
				<stop offset={1} stopColor="#5d87b5" />
			</radialGradient>
			<radialGradient
				id="berawanTebalMalam_svg__d"
				cx={191.17}
				cy={85.82}
				fx={191.17}
				fy={85.82}
				r={121.42}
				gradientTransform="matrix(-1 0 0 -.73 382.34 148.51)"
				xlinkHref="#berawanTebalMalam_svg__a"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__b"
				cx={469.86}
				cy={-3361.42}
				fx={469.86}
				fy={-3361.42}
				r={14.65}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -4859.83 -4861.33)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="berawanTebalMalam_svg__e"
				cx={470.86}
				cy={-3387.21}
				fx={470.86}
				fy={-3387.21}
				r={11.51}
				xlinkHref="#berawanTebalMalam_svg__b"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__c"
				cx={110.4}
				cy={151.06}
				fx={110.4}
				fy={151.06}
				r={8.42}
				gradientTransform="rotate(-25.71 -522.11 295.36) scale(1 2.91)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="berawanTebalMalam_svg__f"
				cx={92.09}
				cy={72.15}
				fx={92.09}
				fy={72.15}
				r={95.04}
				gradientTransform="matrix(-1 0 0 -.73 184.17 124.85)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#b8e5f8" />
				<stop offset={1} stopColor="#72a9db" />
			</radialGradient>
			<radialGradient
				id="berawanTebalMalam_svg__g"
				cx={506.07}
				cy={-3318}
				fx={506.07}
				fy={-3318}
				r={11.47}
				xlinkHref="#berawanTebalMalam_svg__b"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__h"
				cx={505.73}
				cy={-3339.19}
				fx={505.73}
				fy={-3339.19}
				r={9.01}
				xlinkHref="#berawanTebalMalam_svg__b"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__i"
				cx={28.86}
				cy={123.21}
				fx={28.86}
				fy={123.21}
				r={6.59}
				gradientTransform="rotate(-25.71 -487.072 240.91) scale(1 2.91)"
				xlinkHref="#berawanTebalMalam_svg__c"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__j"
				cx={141.58}
				cy={101.99}
				fx={141.58}
				fy={101.99}
				r={132.9}
				gradientTransform="matrix(-1 0 0 -.73 283.16 176.5)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="berawanTebalMalam_svg__k"
				cx={502.73}
				cy={-3345.35}
				fx={502.73}
				fy={-3345.35}
				r={16.03}
				xlinkHref="#berawanTebalMalam_svg__b"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__l"
				cx={502.25}
				cy={-3374.97}
				fx={502.25}
				fy={-3374.97}
				r={12.6}
				xlinkHref="#berawanTebalMalam_svg__b"
			/>
			<radialGradient
				id="berawanTebalMalam_svg__m"
				cx={53.17}
				cy={173.4}
				fx={53.17}
				fy={173.4}
				r={9.21}
				gradientTransform="rotate(-25.71 -672.9 339.046) scale(1 2.91)"
				xlinkHref="#berawanTebalMalam_svg__c"
			/>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="m94.51 43.11 2.68 5.42c.04.09.13.15.23.17l5.98.87c.25.04.35.34.17.52l-4.33 4.22c-.07.07-.1.17-.09.27l1.02 5.96c.04.25-.22.44-.44.32l-5.35-2.81a.28.28 0 0 0-.28 0l-5.35 2.81c-.22.12-.48-.07-.44-.32l1.02-5.96c.02-.1-.02-.2-.09-.27l-4.33-4.22c-.18-.17-.08-.48.17-.52l5.98-.87c.1-.01.18-.08.23-.17l2.68-5.42c.11-.22.43-.22.54 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m94.24 44.36-2.38 4.62c-.13.24-.37.4-.64.43l-5.18.47 5.47.26c.35.02.67-.19.79-.52l1.94-5.25Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m88.43 59.84-.12.69c-.04.25.22.44.44.32l5.35-2.81a.28.28 0 0 1 .28 0l5.35 2.81c.22.12.48-.07.44-.32l-1.02-5.96c-.02-.1.02-.2.09-.27l4.33-4.22c.18-.17.08-.48-.17-.52l-2-.29c-3.24 5.82-8.2 8.88-12.96 10.57Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m85 35.8 1.77 3.58c.03.06.09.1.15.11l3.95.57c.16.02.23.22.11.34l-2.86 2.79s-.07.11-.06.18l.67 3.94c.03.16-.14.29-.29.21l-3.53-1.86a.212.212 0 0 0-.19 0l-3.53 1.86a.199.199 0 0 1-.29-.21l.67-3.94a.21.21 0 0 0-.06-.18l-2.86-2.79c-.12-.12-.05-.32.11-.34l3.95-.57c.07 0 .12-.05.15-.11l1.77-3.58c.07-.15.28-.15.36 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m84.83 36.62-1.57 3.05c-.08.16-.24.27-.42.28l-3.42.31 3.61.17c.23.01.44-.13.52-.34l1.28-3.47Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m80.99 46.85-.08.45c-.03.16.14.29.29.21l3.53-1.86c.06-.03.13-.03.19 0l3.53 1.86c.15.08.32-.05.29-.21l-.67-3.94a.21.21 0 0 1 .06-.18l2.86-2.79c.12-.12.05-.32-.11-.34l-1.32-.19c-2.14 3.84-5.42 5.86-8.56 6.98Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m77.77 47.7 2.68 5.42c.04.09.13.15.23.17l5.98.87c.25.04.35.34.17.52L82.5 58.9c-.07.07-.1.17-.09.27l1.02 5.96c.04.25-.22.44-.44.32l-5.35-2.81a.28.28 0 0 0-.28 0l-5.35 2.81c-.22.12-.48-.07-.44-.32l1.02-5.96c.02-.1-.02-.2-.09-.27l-4.33-4.22c-.18-.17-.08-.48.17-.52l5.98-.87c.1-.01.18-.08.23-.17l2.68-5.42c.11-.22.43-.22.54 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m77.5 48.95-2.38 4.62c-.13.24-.37.4-.64.43l-5.18.47 5.47.26c.35.02.67-.19.79-.52l1.94-5.25Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m71.69 64.43-.12.69c-.04.25.22.44.44.32l5.35-2.81a.28.28 0 0 1 .28 0l5.35 2.81c.22.12.48-.07.44-.32l-1.02-5.96c-.02-.1.02-.2.09-.27l4.33-4.22c.18-.17.08-.48-.17-.52l-2-.29c-3.24 5.82-8.2 8.88-12.96 10.57Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m146.66 44.32-4.73 9.59a.52.52 0 0 1-.4.29l-10.59 1.54c-.44.06-.61.6-.3.91l7.66 7.47c.13.12.18.3.15.47l-1.81 10.54c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-10.59-1.54a.544.544 0 0 1-.4-.29l-4.73-9.59c-.2-.4-.76-.4-.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m147.14 46.54 4.22 8.17c.22.43.65.71 1.13.76l9.16.84-9.68.45c-.61.03-1.18-.34-1.39-.92l-3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m157.42 73.93.21 1.21c.07.44-.38.77-.78.56l-9.47-4.98a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l3.55-.52c5.73 10.29 14.52 15.71 22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m163.48 31.38-3.13 6.34a.34.34 0 0 1-.27.19l-6.99 1.02c-.29.04-.41.4-.2.6l5.06 4.93c.08.08.12.2.1.31l-1.19 6.96c-.05.29.25.51.51.37l6.25-3.29c.1-.05.23-.05.33 0l6.25 3.29c.26.14.56-.08.51-.37l-1.19-6.96c-.02-.11.02-.23.1-.31l5.06-4.93c.21-.2.09-.56-.2-.6l-6.99-1.02a.382.382 0 0 1-.27-.19l-3.13-6.34a.352.352 0 0 0-.63 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m163.8 32.84 2.79 5.4c.15.28.43.47.74.5l6.05.55-6.39.3a.932.932 0 0 1-.92-.61l-2.27-6.14Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m170.59 50.93.14.8c.05.29-.25.51-.51.37l-6.25-3.29a.382.382 0 0 0-.33 0l-6.25 3.29a.349.349 0 0 1-.51-.37l1.19-6.96a.358.358 0 0 0-.1-.31l-5.06-4.93c-.21-.2-.09-.56.2-.6l2.34-.34c3.79 6.8 9.59 10.38 15.15 12.35Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="m178.28 48.45-4.73 9.59a.52.52 0 0 1-.4.29l-10.59 1.54c-.44.06-.61.6-.3.91l7.66 7.47c.13.12.18.3.15.47l-1.81 10.54c-.07.44.38.77.78.56l9.47-4.98a.55.55 0 0 1 .5 0l9.47 4.98c.39.21.85-.13.78-.56l-1.81-10.54c-.03-.17.03-.35.15-.47l7.66-7.47c.32-.31.14-.85-.3-.91l-10.59-1.54a.544.544 0 0 1-.4-.29l-4.73-9.59c-.2-.4-.76-.4-.96 0Z"
				style={{
					fill: '#ffce09',
				}}
			/>
			<path
				d="m178.76 50.66 4.22 8.17c.22.43.65.71 1.13.76l9.16.84-9.68.45c-.61.03-1.18-.34-1.39-.92l-3.44-9.3Z"
				style={{
					fill: '#ffdc54',
				}}
			/>
			<path
				d="m189.04 78.05.21 1.21c.07.44-.38.77-.78.56L179 74.84a.55.55 0 0 0-.5 0l-9.47 4.98c-.39.21-.85-.13-.78-.56l1.81-10.54a.543.543 0 0 0-.15-.47l-7.66-7.47a.533.533 0 0 1 .3-.91l3.55-.52c5.73 10.29 14.52 15.71 22.94 18.7Z"
				style={{
					fill: '#fbbe15',
				}}
			/>
			<path
				d="M142.93 57.51c-.8-11.66-10.5-20.87-22.36-20.87s-21.56 9.21-22.36 20.87c-8.83.44-15.86 7.74-15.86 16.68s7.48 16.7 16.7 16.7h43.04c9.22 0 16.7-7.47 16.7-16.7s-7.03-16.24-15.86-16.68Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__a)',
				}}
			/>
			<path
				d="M217.66 105.47C216 81.19 195.8 62.01 171.1 62.01s-44.9 19.18-46.56 43.46c-18.4.91-33.03 16.11-33.03 34.73s15.57 34.78 34.78 34.78h89.63c19.21 0 34.78-15.57 34.78-34.78s-14.64-33.83-33.03-34.73Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__d)',
				}}
			/>
			<path
				d="M174.37 109.79C161.28 99.16 156.16 83 162.95 73.66c6.79-9.33 22.91-8.28 36.01 2.33 13.09 10.62 18.2 26.8 11.41 36.12-6.79 9.34-22.91 8.29-36-2.33Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__b)',
					mixBlendMode: 'overlay',
					opacity: 0.6,
				}}
			/>
			<path
				d="M217.9 141.19c-10.29-8.35-14.31-21.06-8.97-28.39 5.34-7.33 18-6.51 28.3 1.83 10.29 8.35 14.3 21.06 8.97 28.39-5.34 7.34-18.01 6.51-28.29-1.83Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__e)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M116.37 142.35c-7.59-11.84-16.41-17.55-19.72-12.74-3.29 4.8.19 18.3 7.78 30.15 7.6 11.84 16.42 17.55 19.72 12.74 3.29-4.81-.19-18.3-7.78-30.15Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__c)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M112.82 87.53c-1.3-19-17.11-34.02-36.44-34.02S41.23 68.53 39.94 87.53c-14.4.71-25.86 12.61-25.86 27.19s12.19 27.22 27.22 27.22h70.15c15.04 0 27.22-12.19 27.22-27.22s-11.46-26.48-25.86-27.19Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__f)',
				}}
			/>
			<path
				d="M76.38 90.91c-10.25-8.31-14.25-20.97-8.94-28.28 5.32-7.3 17.93-6.48 28.19 1.82 10.25 8.31 14.24 20.98 8.94 28.28-5.32 7.31-17.94 6.49-28.18-1.83Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__g)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M113.01 115.49c-8.05-6.54-11.2-16.48-7.03-22.22 4.18-5.74 14.1-5.1 22.15 1.43 8.05 6.53 11.2 16.48 7.02 22.22-4.18 5.74-14.1 5.1-22.15-1.43Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__h)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M33.53 116.4c-5.94-9.27-12.85-13.73-15.43-9.97s.15 14.33 6.09 23.6c5.95 9.27 12.85 13.74 15.43 9.98 2.58-3.77-.14-14.33-6.09-23.6Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__i)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M170.57 123.5c-1.81-26.57-23.93-47.57-50.96-47.57s-49.15 21-50.96 47.57c-20.14 1-36.16 17.64-36.16 38.02s17.04 38.07 38.07 38.07h98.11c21.02 0 38.07-17.04 38.07-38.07s-16.02-37.02-36.16-38.02Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__j)',
				}}
			/>
			<path
				d="M119.61 128.23c-14.33-11.63-19.93-29.33-12.5-39.54 7.43-10.22 25.08-9.07 39.41 2.55 14.33 11.63 19.92 29.34 12.49 39.54-7.44 10.22-25.08 9.07-39.41-2.55Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__k)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M170.84 162.6c-11.26-9.14-15.66-23.05-9.82-31.08 5.84-8.03 19.71-7.12 30.98 2.01 11.26 9.13 15.65 23.05 9.82 31.07-5.84 8.03-19.71 7.13-30.97-2Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__l)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M59.71 163.87c-8.31-12.96-17.96-19.2-21.58-13.95-3.6 5.25.21 20.03 8.51 33 8.31 12.96 17.97 19.21 21.58 13.95 3.6-5.27-.2-20.03-8.51-33Z"
				style={{
					fill: 'url(#berawanTebalMalam_svg__m)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
		</g>
	</svg>
);

export default BerawanTebalMalam;
