import * as React from 'react';

const DagingAyam = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M43.05 130.3c-.61.13-1.24.24-1.77.65-1.34 1.04-1.51 3.5-1.5 5.57.02 3.96.28 8.32 2.37 11.11 1.47 1.96 3.58 2.76 5.6 3.41 1.91.62 3.84 1.18 5.77 1.68 1.47.38 3.06.71 4.42-.12 1.59-.96 2.47-3.24 3.09-5.42.58-2.02 1.04-4.13 1.16-6.29.32-5.75-2.16-11.53-5.86-14.41-4.39-3.41-8.63 2.79-13.28 3.8Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M189.07 74.99c1.16-.66 2.37-1.37 3.03-2.52.84-1.45.77-3.52 2.17-4.45 1.1-.73 2.62-.35 3.71.39 1.04.71 1.89 1.75 2.17 2.97.29 1.22-.06 2.62-1.02 3.43 2.34 0 4.53 1.94 4.81 4.27.16 1.37-.52 3.01-1.88 3.24-1.31.22-2.43-.91-3.71-1.25-1.63-.42-3.27.48-4.73 1.32-3.06 1.76-6.2 3.4-9.4 4.9-2.66 1.25-5.39 2.41-8.27 3.02-2.88.61-5.93.63-8.7-.36-1.39-.5-2.88-1.56-2.79-3.04.26-4.48 7.81-4.44 10.66-5.31 4.95-1.5 9.49-4.07 13.96-6.62Z"
			style={{
				fill: '#dae5eb',
			}}
		/>
		<path
			d="M203.94 79.08c-.28-2.33-2.47-4.27-4.81-4.27.96-.81 1.31-2.21 1.02-3.43-.29-1.22-1.13-2.27-2.17-2.97-.95-.65-2.23-1.02-3.27-.62.46.14.9.36 1.29.62 1.04.71 1.89 1.75 2.17 2.97.29 1.22-.06 2.62-1.02 3.43 2.34 0 4.53 1.94 4.81 4.27.14 1.16-.33 2.5-1.3 3.03.45.18.92.29 1.4.21 1.36-.23 2.04-1.87 1.88-3.24ZM196.36 81.07c-1.63-.42-3.27.48-4.73 1.32-3.06 1.76-6.2 3.4-9.4 4.9-2.66 1.25-5.39 2.41-8.27 3.02-.96.2-1.95.34-2.93.4 1.64.1 3.31-.06 4.92-.4 2.88-.61 5.61-1.77 8.27-3.02 3.2-1.5 6.34-3.13 9.4-4.9.89-.51 1.85-1.05 2.83-1.29-.03 0-.06-.02-.09-.03Z"
			style={{
				fill: '#cad4d6',
			}}
		/>
		<path
			d="M175.9 83.83c.17.39.63.59 1.05.58.42 0 .83-.18 1.21-.36 1.18-.52 2.36-1.05 3.55-1.57 2.07-.92 4.15-1.84 6.09-3.01 1.03-.62 2.06-1.38 2.52-2.49.07-.16.12-.36.01-.5-.19-.26-.59-.04-.82.18-.73.7-1.5 1.37-2.37 1.9-1.32.81-12.16 3.18-11.25 5.27ZM193.73 69.35c-.11.52.32.99.35 1.52.02.41-.19.78-.31 1.17-.12.39-.1.89.25 1.1.2.12.46.1.69.04 1.04-.28 1.73-1.4 1.67-2.48-.03-.43-.16-.86-.42-1.19-.41-.53-2-1.25-2.23-.16Z"
			style={{
				fill: '#fff',
				opacity: 0.37,
			}}
		/>
		<path
			d="M222.67 110.78c1.16-.66 2.37-1.37 3.03-2.52.84-1.45.77-3.52 2.17-4.45 1.1-.73 2.62-.35 3.71.39 1.04.71 1.89 1.75 2.17 2.97.29 1.22-.06 2.62-1.02 3.43 2.34 0 4.53 1.94 4.81 4.27.16 1.37-.52 3.01-1.88 3.24-1.31.22-2.43-.91-3.71-1.25-1.63-.42-3.27.48-4.73 1.32-3.06 1.76-6.2 3.4-9.4 4.9-2.66 1.25-5.39 2.41-8.27 3.02-2.88.61-5.93.63-8.7-.36-1.39-.5-2.88-1.56-2.79-3.04.26-4.48 7.81-4.44 10.66-5.31 4.95-1.5 9.49-4.07 13.96-6.62Z"
			style={{
				fill: '#dae5eb',
			}}
		/>
		<path
			d="M237.55 114.88c-.28-2.33-2.46-4.27-4.81-4.27.96-.81 1.31-2.21 1.02-3.43-.29-1.22-1.13-2.27-2.17-2.97-.95-.65-2.23-1.02-3.27-.62.46.14.9.36 1.29.62 1.04.71 1.89 1.75 2.17 2.97.29 1.22-.06 2.62-1.02 3.43 2.34 0 4.53 1.94 4.81 4.27.14 1.16-.33 2.5-1.3 3.03.45.18.92.29 1.4.21 1.36-.23 2.04-1.87 1.88-3.24ZM229.97 116.87c-1.63-.42-3.27.48-4.73 1.32-3.06 1.76-6.2 3.4-9.4 4.9-2.66 1.25-5.39 2.41-8.27 3.02-.96.2-1.95.34-2.93.4 1.64.1 3.31-.06 4.92-.4 2.88-.61 5.61-1.77 8.27-3.02 3.2-1.5 6.34-3.13 9.4-4.9.89-.51 1.85-1.05 2.83-1.29-.03 0-.06-.02-.09-.03Z"
			style={{
				fill: '#cad4d6',
			}}
		/>
		<path
			d="M163.95 79.91c5.06-1.34 10.18-3.03 15.4-2.62 1.16.09 2.48.4 3.03 1.42.35.65.29 1.43.23 2.17-.78 9.1-2.2 19.37-9.61 24.72-4.34 3.13-9.93 3.88-15.27 4.17-10.25.55-21-.36-29.79-5.65-3.35-2.02-6.63-5.3-6.23-9.19.7-6.8 10.83-10.29 16.52-11.06 8.69-1.17 17.16-1.69 25.71-3.96Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M176.16 88.74c-5.68-.6-11.14-2.47-16.71-3.72-10.39-2.33-21.16-2.48-31.78-1.69-13.25.98-26.49 3.41-38.75 8.52-12.26 5.11-23.53 13.01-31.48 23.65-5.31 7.12-9.12 15.55-9.77 24.41-.65 8.86 2.11 18.13 8.33 24.47 7.03 7.16 17.38 9.82 27.28 11.45 17.81 2.94 36.01 3.57 53.98 1.85 15.55-1.49 32.05-5.33 42.5-16.93 5.96-6.62 9.33-15.1 12.59-23.39 3.62-9.24 7.25-18.48 10.87-27.72 1.5-3.81 3.01-7.69 3.39-11.76.39-4.08-.49-8.45-3.25-11.47-3.7-4.04-7.72-3.38-11.67-1.06-4.92 2.89-9.77 4.01-15.53 3.41Z"
			style={{
				fill: '#eab171',
			}}
		/>
		<path
			d="M140.35 83.48c-2.63-.23-5.26-.38-7.89-.45-1.49.07-2.97.16-4.46.27-13.25.98-26.49 3.41-38.75 8.52-12.26 5.11-23.53 13.01-31.48 23.65a55.967 55.967 0 0 0-5.21 8.42c3.25.82 8.05-4.45 9.91-5.98a536.88 536.88 0 0 0 16.42-14.09c2.55-2.28 5.19-4.65 8.48-5.6.77-.22 1.69-.34 2.31.17 1.29 1.04.02 3.05-1.23 4.14-8.25 7.18-16.5 14.35-24.74 21.53-1.08.94-2.26 2.16-1.99 3.56.25 1.28 1.7 2.04 3.01 1.95 1.3-.09 2.47-.81 3.55-1.54 9.05-6.1 16.58-14.5 26.39-19.28 4.49-2.19 9.32-3.55 14.16-4.79 5.34-1.38 10.76-2.65 15.7-5.1 4.28-2.13 8.08-5.09 11.85-8.03 2.53-1.98 5.06-3.96 7.6-5.93-.8-1.06-2.29-1.28-3.61-1.4Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M206.6 98.12c.39-4.08-.49-8.45-3.25-11.47-.77-.84-1.55-1.47-2.35-1.94-.25.3-.68.61-.71 1.08-.14 1.77 2.93 4.85 1.89 9.71-3.56 16.66-9.36 22.06-17.21 32.45-3.57 4.73-7.82 9.07-13.02 11.91-7.24 3.95-15.74 4.71-23.96 5.39-21.03 1.75-42.06 3.49-63.09 5.24-6.19.51-12.44 1.03-18.59.13-6.14-.9-12.26-3.35-16.46-7.93-.69-.76-1.42-1.61-2.23-2.25-.57 8.77 2.19 17.91 8.35 24.18 7.03 7.16 17.38 9.82 27.28 11.45 17.81 2.94 36.01 3.57 53.98 1.85 15.55-1.49 32.05-5.33 42.5-16.93 5.96-6.62 9.33-15.1 12.59-23.39 3.62-9.24 7.25-18.48 10.87-27.72 1.5-3.81 3.01-7.69 3.39-11.76Z"
			style={{
				fill: '#d6a06a',
			}}
		/>
		<path
			d="M144.5 119.21c6.05-7.1 14.26-11.77 22.96-13.05 1.64-.24 3.79-.02 4.32 1.78.57 1.9-1.25 3.49-2.81 4.33-6.6 3.56-13.81 6.41-18.76 12.49-2.91 3.58-4.79 8.02-6.31 12.56-1.4 4.2-2.02 10.65-4.47 14.18-2.87 4.14-6.71-.82-6.96-4.77-.25-3.97 2.38-8.71 3.59-12.26 1.94-5.68 4.72-10.88 8.44-15.25Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M96.59 163.89c5.04-4.01 9.51-8.8 13.85-13.69 1.27-1.44 2.59-2.97 3.06-4.9.91-3.7-1.69-7.53-4.91-9.09-.66 2.47-1.35 5-2.79 7.04-2.33 3.29-6.18 4.72-9.75 6.21a98.93 98.93 0 0 0-17.26 9.32c-2.42 1.63-4.79 3.39-6.82 5.57-1.34 1.45-2.6 3.5-1.93 5.43.41 1.17 1.46 1.95 2.56 2.28 1.11.33 2.27.28 3.42.22 1.58-.08 3.24.18 4.79.03 1.87-.18 3.92-1.25 5.63-2.04 3.6-1.66 7-3.86 10.15-6.37ZM130.71 167.56c1.84 3.02 4.6 5.47 8.03 6.31 3.44.84 7.04.53 10.56.1 4.75-.58 9.48-1.38 14.17-2.39 1.49-.32 2.98-.66 4.4-1.2 5.16-1.96 9-6.28 12.62-10.45 2.32-2.67 4.78-5.76 4.47-9.28-.02-.25-.06-.52-.23-.71-.26-.31-.72-.32-1.12-.31-13.46.45-26.31 6.45-39.77 6.56-3.3.03-8.67-11.92-11.37-9.48-2.32 2.1-6.18 13.58-1.75 20.84Z"
			style={{
				fill: '#bf8c62',
			}}
		/>
		<path
			d="M54.95 165.98c-5.82 4.86-6.05 8.44-5.73 11.92.31 3.49 3.84 5.72 7.12 6.96 6.86 2.59 14.27 3.28 21.59 3.8 6.79.48 13.6.84 20.4 1.06 1.83.06 3.7.11 5.43-.48 1.49-.51 2.79-1.45 4.05-2.39 9.74-7.21 19.24-14.73 28.49-22.55-7.4 3.87-14.91 7.53-22.52 10.98-1.86.85-3.78 1.69-5.82 1.84-3.26.23-6.34-1.32-9.33-2.66-6.13-2.74-12.6-4.7-19.21-5.82 9.26-6.81 18.51-13.62 27.77-20.43 2.13-1.56 4.44-3.44 4.7-6.07.24-2.46-1.45-4.71-3.41-6.21-4.84-3.7-10.73-6.77-20.13-5.19-10.77 1.8-13.98 6.87-18 12.97-5.65 8.59-7.48 15.63-15.4 22.26Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M136.34 164.31c-3.55 1.85-7.12 3.66-10.72 5.42-.45.42-.9.85-1.35 1.27-5.18 4.88-10.74 9.67-17.48 11.94-2.05.69-4.22 1.14-6.38.94-2.27-.21-4.41-1.11-6.54-1.92a92.42 92.42 0 0 0-34.43-6.04c-2.55.04-5.25.16-7.47-1.1-.88-.5-1.44-1.71-2-2.74-.91 2.09-.89 3.97-.73 5.83.31 3.49 3.84 5.72 7.12 6.96 6.86 2.59 14.27 3.28 21.59 3.8 6.79.48 13.6.84 20.4 1.06 1.83.06 3.7.11 5.43-.48 1.49-.51 2.79-1.45 4.05-2.39 9.74-7.21 19.24-14.73 28.49-22.55ZM80.29 168.74c-.29-.05-.58-.1-.86-.15 9.26-6.81 18.51-13.62 27.77-20.43 2.13-1.56 4.44-3.44 4.7-6.07.24-2.45-1.45-4.71-3.41-6.2-3.28-2.51-7.04-4.72-12.06-5.4-.42.13-.77.36-.93.76-.22.57.08 1.23.54 1.65.45.42 1.03.66 1.55.99 2.36 1.51 3.2 4.7 2.61 7.44-.59 2.74-2.33 5.08-4.24 7.12-4.2 4.5-9.39 8.03-14.99 10.6-4.1 1.88-8.63 3.39-11.53 6.86-.41.49-.8 1.11-.64 1.73.25.93 1.47 1.11 2.44 1.11 1.79-.01 3.57-.03 5.36-.06 1.04-.01 2.5.19 3.69.06Z"
			style={{
				fill: '#eab171',
			}}
		/>
		<path
			d="M195.55 112.76c4.41-2.77 8.25-6.62 10.37-11.37-1.76 5.72-3.52 11.43-5.29 17.15-.32 1.05-.77 2.23-1.79 2.61-.82.31-1.72-.01-2.56-.25-1.61-.46-3.29-.62-4.95-.79-2.32-.23-4.64-.45-6.96-.68-2.14-.21-2.26-.74-.25-1.64 3.86-1.72 7.78-2.75 11.42-5.03Z"
			style={{
				fill: '#bf8c62',
			}}
		/>
		<path
			d="M146 130.24c-8.21 5.39-15.74 14.23-14.26 23.94 1.1 7.24 7.29 13.05 14.33 15.04 7.04 1.99 14.71.6 21.36-2.44 6.66-3.04 12.47-7.64 18.11-12.3 9.62-7.96 18.96-16.27 27.99-24.9 1.54-1.47 3.21-3.57 2.3-5.51-3.92.64-6.81-3.44-10.34-5.25-1.94-.99-4.15-1.29-6.32-1.48-18.07-1.64-37.93 2.88-53.18 12.9Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M215.82 123.93c-2.39.39-4.39-.98-6.4-2.51-6.24 2.96-11.63 7.49-17.31 11.45-5.34 3.72-11 6.98-16.9 9.73-10.49 4.89-21.74 8.21-33.22 9.71-2.61.34-5.31.8-7.83-.19-1.04-.41-1.78-1.09-2.59-1.45-.06 1.11-.01 2.24.16 3.38 1.1 7.24 7.29 13.05 14.33 15.04 7.04 1.99 14.71.6 21.36-2.44 6.66-3.04 12.47-7.64 18.11-12.3 9.62-7.96 18.96-16.27 27.99-24.9 1.54-1.47 3.21-3.57 2.3-5.51Z"
			style={{
				fill: '#eab171',
			}}
		/>
		<path
			d="M144.73 89.01c-.62.57-1.23 1.19-1.55 1.96-.32.78-.31 1.74.24 2.38.36.42.88.64 1.4.81 2.97.94 6.16.29 9.25-.07 6.41-.73 13.12-.09 18.88 2.81 1.98 1 3.83 2.26 5.88 3.12 3.34 1.41 7.24 1.68 10.57.25s5.92-4.71 6.06-8.33c.02-.5 0-1.01-.22-1.46-.37-.78-1.24-1.22-2.1-1.31-.86-.09-1.72.11-2.55.31-4.66 1.11-9.41 2.18-14.18 1.87-3.14-.21-6.2-1.01-9.24-1.8-2.42-.63-4.84-1.27-7.27-1.9-2.11-.55-4.23-1.11-6.39-1.33-3.86-.4-5.9.08-8.77 2.7ZM72.86 141.02c.24.92 1.14 1.55 2.08 1.74.93.19 1.9.02 2.84-.15 5.1-.91 10.22-1.83 15.13-3.49 1.76-.59 3.56-1.33 4.78-2.74 1.21-1.41 1.63-3.67.46-5.11-.97-1.2-2.67-1.5-4.21-1.59-4.68-.3-10.35.22-14.56 2.48-1.27.68-2.08 1.76-3.01 2.82-1.27 1.45-4.05 3.86-3.5 6.05Z"
			style={{
				fill: '#f8b97a',
			}}
		/>
		<path
			d="M209.51 119.63c.17.39.63.59 1.05.58.42 0 .83-.18 1.21-.36 1.18-.52 2.36-1.05 3.55-1.57 2.07-.92 4.15-1.84 6.09-3.01 1.03-.62 2.06-1.38 2.52-2.49.07-.16.12-.36.01-.5-.19-.26-.59-.04-.82.18-.73.7-1.5 1.37-2.36 1.9-1.32.81-12.16 3.18-11.25 5.27ZM227.33 105.15c-.11.52.32.99.35 1.52.02.41-.19.78-.31 1.17-.12.39-.1.89.25 1.1.2.12.46.1.69.04 1.04-.28 1.73-1.4 1.67-2.48-.03-.43-.16-.86-.42-1.19-.41-.53-2-1.25-2.23-.16Z"
			style={{
				fill: '#fff',
				opacity: 0.37,
			}}
		/>
		<path
			d="M90.88 94.07c-.19.32-.34.69-.31 1.06.06.84 1 1.34 1.84 1.38.84.04 1.68-.21 2.51-.14 1.39.12 2.55 1.11 3.88 1.53 1.85.57 3.85-.01 5.67-.69 8.64-3.25 16.63-8.8 25.82-9.6.64-.06 1.32-.1 1.83-.49s.69-1.27.17-1.65c-.33-.24-.78-.19-1.18-.13-7.34 1.12-14.67 2.3-21.99 3.53-3.63.61-7.26 1.23-10.88 1.87-2.66.47-5.89.7-7.39 3.35ZM138.48 142.26c-.21.87-.3 1.81.06 2.63.54 1.22 2 1.84 3.33 1.75 1.33-.1 2.55-.78 3.64-1.56 5.9-4.23 9.18-11.67 15.64-14.98 2.2-1.13 4.9-1.93 5.93-4.17-1.4-.79-3.15-.35-4.68.15-7.69 2.53-21.71 7.03-23.92 16.18ZM176.27 124.41c2.7-.87 5.66-.37 8.44-.92.58-.11 1.19-.29 1.59-.73.41-.43.52-1.19.09-1.6-.2-.19-.48-.28-.75-.34-.97-.22-1.98-.18-2.98-.13-3.12.14-9.67-.31-12.08 2.16-.92.94-1.13 2.53.25 3.18 1.43.67 4.1-1.19 5.43-1.61ZM53.85 170.18c-.33.29-.68.63-.68 1.07 0 .51.48.89.94 1.12 1.62.78 3.66.65 5.16-.35 2.36-1.55 3.05-4.62 4.29-7.16 1.47-3.02 3.85-5.47 5.94-8.11s3.95-5.67 4.06-9.03c-2.31.07-3.64 2.55-4.68 4.62-3.55 7.05-9.19 12.65-15.01 17.83Z"
			style={{
				fill: '#fff',
				opacity: 0.29,
			}}
		/>
	</svg>
);

export default DagingAyam;
