
import styles from '../../../styles/components/WeatherTimeContent.module.scss'
import {epochDict, wCaption} from '../../../utils/dictionary';
import {weatherSvgDict} from '../../../utils/dictionary';
import {formatDateToString, formatUnits, getDayAndDate} from '../../../utils/textFormatter';
import {useEffect, useState} from 'react';

const WeatherTimeContent = (props) => {

	const data = props.data;

	const [dateKey, setDateKey] = useState('');
	//const [timeKey, setTimeKey] = useState('');
	const [widgetTitle, setWidgetTitle] = useState('');

	const [weatherIcon, setWeatherIcon] = useState();
	const [weatherCaption, setWeatherCaption] = useState();
	const [nTemp, setNTemp] = useState(0);
	const [nHumid, setNHumid] = useState(0);
	const [nCuaca, setNCuaca] = useState('');
	const [tempStr, setTempStr] = useState('');
	const [humidStr, setHumidStr] = useState('');

	const [dateStr, setDateStr] = useState('');

	const [isDateSet, setIsDateSet] = useState(false);


	useEffect(() => {

		if(isDateSet && data.data !== undefined){

			if(data.data[dateKey] === undefined){
				const tempDate = new Date(dateKey)
				tempDate.setDate(tempDate.getDate() - 1);

				const tempDateKey = formatDateToString(tempDate)

				setNTemp(data.data[tempDateKey][epochDict[props.time]['time']]['Temperature']);
				setNHumid(data['data'][tempDateKey][epochDict[props.time]['time']]['Humidity']);
				setNCuaca(data['data'][tempDateKey][epochDict[props.time]['time']]['Weather']);
				setWeatherIcon(weatherSvgDict[nCuaca]);
				setWeatherCaption(wCaption[nCuaca]);
				setTempStr(formatUnits('temperature', nTemp));
				setHumidStr(formatUnits('humidity', nHumid));

			} else {
				setNTemp(data.data[dateKey][epochDict[props.time]['time']]['Temperature']);
				setNHumid(data['data'][dateKey][epochDict[props.time]['time']]['Humidity']);
				setNCuaca(data['data'][dateKey][epochDict[props.time]['time']]['Weather']);
				setWeatherIcon(weatherSvgDict[nCuaca]);
				setWeatherCaption(wCaption[nCuaca]);
				setTempStr(formatUnits('temperature', nTemp));
				setHumidStr(formatUnits('humidity', nHumid));
			}



		}
		else if(!isDateSet){

			if (props.day === 'today') {
				const d1 = new Date();
				setDateKey(formatDateToString(new Date()));
				setDateStr(getDayAndDate(d1));
			} else if (props.day === 'tomorrow') {
				const d2 = new Date();
				d2.setDate(d2.getDate() + 1);
				setDateKey(formatDateToString(d2));
				setDateStr(getDayAndDate(d2));
			}

			setWidgetTitle(epochDict[props.time]['text']);
			//setTimeKey(epochDict[props.time]['time']);

			setIsDateSet(true);
		}

	}, [data, dateKey, isDateSet, nCuaca, nHumid, nTemp, props.time, props.day]);

	if(data.data !== undefined){
		return (
			// <div className={'TimeWeatherContainer'}>
			// 	{isAssigned && (
					<div className={`${styles.time_weather_container}`}>
						<div className={styles.weather_title_container}>
							<div className={styles.widget_title}>
								{widgetTitle}
							</div>

							<div className={styles.widget_day_title}>
								{dateStr}
							</div>
						</div>
						<div className={styles.weather_icon_caption_container}>
							<div className={styles.weather_icon}>
								{weatherIcon}
							</div>

							<div className={styles.weather_caption}>
								{weatherCaption}
							</div>
						</div>

						<div className={styles.weather_info_container}>
							<div className={styles.weather_info_background}>
								<div className={styles.air_info}>
									{tempStr} / {humidStr}
								</div>
							</div>
						</div>
					</div>
			// 	)}
			// </div>
		);
	} else {
		return(<></>)
	}
};

export default WeatherTimeContent;