import {createContext, useState} from "react";

const StationContext = createContext({});

export const StationProvider = ({ children}) => {
    const [stationData, setStationData] = useState({});
    return(
        <StationContext.Provider value={{stationData, setStationData}}>
            {children}
        </StationContext.Provider>
    )
}

export default StationContext;