import * as React from 'react';

const DagingSapi = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M178.67 103.81a43.55 43.55 0 0 1 7.62 5.89c2.38 2.3 4.52 4.89 6.03 7.84 1.7 3.31 2.56 6.98 3.35 10.61 1.47 6.72 2.76 13.55 2.58 20.43-.18 6.88-1.92 13.88-6.02 19.4-1.49 2.01-3.77 3.96-6.21 3.41-1.35-.3-2.4-1.32-3.34-2.33-5.91-6.33-10.1-14.08-13.41-22.08-3.72-8.99-6.43-18.48-7.08-28.19-.22-3.36-.2-6.74.08-10.1.21-2.62.07-7.81 1.78-9.93 2.98-3.7 11.86 3.37 14.62 5.06Z"
			style={{
				fill: '#9f3746',
			}}
		/>
		<path
			d="M43.12 126.14c5.54-18.89 15.68-26.15 23.44-29.1 7.76-2.95 15.33-6.94 23.49-8.07 6.43-.89 12.81-2.24 19.07-4.03 3.32-.95 6.67-2.06 9.47-4.36 2.67-2.2 4.7-5.36 7.23-7.78 5.84-5.57 14.44-6.63 21.52-2.64 4.27 2.4 7.85 6.37 11.35 10.27 11.7 13.04 23.76 26.69 30.06 44.29 1.6 4.45 2.73 9.85.61 13.69-1.47 2.66-4.14 3.84-6.71 4.69-15.98 5.27-33.23 2.83-50.05 1.96-12.48-.64-24.95-.4-37.33.73-10.27.94-20.52 2.49-30.26 6.11-6.38 2.37-21.28 10.46-27.16 1.57-2.82-4.26-.04-9.43 1.58-13.03 2.12-4.72 2.18-9.19 3.68-14.29Z"
			style={{
				fill: '#e28681',
			}}
		/>
		<path
			d="M142.67 68.85c-.56-.25 5.69-.28 13.47 6.13 2.97 2.44 15.13 15 17.77 18.73 3.98 5.62 7.2 11.98 10.2 18.46 3.36 7.25 6.48 14.78 8.05 22.87.21 1.11.4 2.29.09 3.34-.36 1.23-1.32 2.04-2.33 2.41-2.54.93-5.33-.52-7.5-2.52-3.47-3.19-5.96-7.71-8.62-11.94-4.03-6.4-8.61-12.28-12.36-18.93-3.46-6.13-6.27-13.13-6.81-20.6-.46-6.3-4.75-14.68-11.95-17.96Z"
			style={{
				fill: '#9f3746',
			}}
		/>
		<path
			d="M36.95 152.01c-1.67 6.5-2.53 13.32-1.46 19.96 1.07 6.64 4.23 13.1 9.47 17.23 2.62 2.07 5.69 3.51 8.86 4.53 15.32 4.93 31.76.11 47.35-3.71 20.83-5.11 42.07-8.45 63.38-10.84 5.51-.62 11.1-1.19 16.31-3.11 5.21-1.93 10.09-5.4 12.53-10.44 1.94-4.02 2.19-8.65 2.12-13.14-.08-5.65-.69-11.55-3.71-16.3-4.79-7.55-14.31-10.28-23.06-11.51-28.1-3.94-56.55 1.47-84.32 7.18-9.87 2.03-19.77 4.11-29.24 7.55-4.93 1.79-9.98 4.15-12.99 8.49-2.1 3.04-3.24 7.04-6.43 8.85l1.18-4.74Z"
			style={{
				fill: '#fdddd7',
			}}
		/>
		<path
			d="M194.47 156.7c-2.89 2.73-3.66 7.03-6.1 10.17-3.28 4.24-8.95 5.68-14.21 6.73-17.17 3.44-34.6 5.62-52.1 6.5-12.9.65-25.92.61-38.55 3.28-8.97 1.9-17.79 5.17-26.96 4.99-4.05-.08-8.13-1.03-11.76-2.84-3.01-1.5-4.8-4.03-7.16-6.04 1.65 3.78 4.07 7.18 7.28 9.71 2.62 2.07 5.69 3.51 8.86 4.53 15.32 4.93 31.76.11 47.35-3.71 20.83-5.11 42.07-8.45 63.38-10.84 5.51-.62 11.1-1.19 16.31-3.11 5.21-1.93 10.09-5.4 12.53-10.44 1.44-2.98 1.95-6.3 2.09-9.64-.35.18-.68.44-.97.72Z"
			style={{
				fill: '#fdd5c6',
			}}
		/>
		<path
			d="M51.06 169.73c-4.84-.07-9.92-1.96-12.61-5.99-2.51-3.76-2.52-8.77-1.09-13.06 1.43-4.29 4.13-8.03 6.84-11.65 3.73-5 7.65-10.03 12.83-13.51 6.78-4.54 15.12-6.02 23.23-6.96 17.04-1.97 34.57-2.08 50.69-7.92 3.25-1.18 6.63-2.77 8.34-5.76 2.49-4.37.82-10.65 4.4-14.18 2.26-2.23 6.3-2.32 8.65-.18 1.37 1.24 2.12 3.01 3.22 4.49 1.82 2.43 4.52 4 7.08 5.64a93.459 93.459 0 0 1 21.46 18.95c3.64 4.38 6.96 9.29 7.93 14.9 1.41 8.17-2.58 16.66-8.84 22.1-6.26 5.44-14.47 8.19-22.67 9.4-8.21 1.21-16.54 1.02-24.83 1.31-11.5.4-22.93 1.72-34.42 2.33-11.86.63-22.86-.66-34.57-1.95-5.29-.58-10.29 2.12-15.64 2.05Z"
			style={{
				fill: '#cc4653',
			}}
		/>
		<path
			d="M72.37 164.53c2.61-4.16 7.11-6.74 11.65-8.61 4.54-1.88 9.32-3.25 13.51-5.8 4.32-2.64 7.88-6.51 10.14-11.04.76-1.53 1.39-3.13 2.37-4.53.98-1.4 2.4-2.59 4.09-2.82-2.51 1.06-3.17 4.2-4.13 6.74-2.49 6.6-8.66 11.03-14.81 14.49-6.15 3.46-12.77 6.5-17.41 11.82-.8.91-1.55 1.94-1.72 3.14-.17 1.2.44 2.58 1.6 2.91.77.22 1.93.23 1.94 1.03 0 .26-.15.5-.32.7-1.78 2.1-4.84 1.45-6.63-.23-2.26-2.12-1.86-5.31-.29-7.8ZM185.37 126.41c-.83.95-1.25 2.18-1.65 3.38-1.1 3.3-2.19 6.6-3.29 9.91-.63 1.89-1.28 3.83-2.57 5.34-1.68 1.97-4.25 2.98-6.8 3.43-2.55.45-5.16.43-7.73.75-9.27 1.13-17.76 6.59-27.09 6.69-2.81.03-6.07-.27-7.96 1.82l12.34.09c2.38.02 4.79.03 7.12-.49 2.05-.46 3.98-1.32 5.98-1.95 2.27-.71 4.63-1.12 7.01-1.2 3.42-.12 6.89.41 10.25-.25 4.12-.81 7.84-3.48 9.92-7.13.33-.57.63-1.19 1.16-1.57.53-.39 1.37-.45 1.78.07.21.26.26.6.27.93.07 2.34-1.8 4.28-3.76 5.57-1.96 1.29-4.2 2.29-5.63 4.14 2.42.85 4.86-1.04 6.73-2.79 2.41-2.26 5.04-5.19 4.31-8.42-.3-1.31-1.15-2.57-.88-3.89.42-2.02 3-2.57 4.54-3.94 1.59-1.4 2.05-3.77 1.68-5.86-.47-2.71-2.47-8.33-5.74-4.62Z"
			style={{
				fill: '#fdddd7',
			}}
		/>
		<path
			d="M46.6 163.53c4.2.97 8.7.57 12.66-1.12 5.12-2.18 9.16-6.35 14.17-8.8 8.03-3.92 18.04-3.14 25.33-8.31.47-.33.97-.97.59-1.41-4.42 2.04-9.1 3.53-13.89 4.4-4.01.73-8.15 1.06-11.91 2.64-3.02 1.27-5.64 3.3-8.47 4.95a37.664 37.664 0 0 1-15.88 4.99c-3.81.31-7.31-.3-10.27-2.75 1.32 3.04 4.59 4.68 7.67 5.39ZM174.8 132.42c-.7 1.01-.86 2.27-1.03 3.49-.17 1.21-.4 2.49-1.19 3.42-1.39 1.63-3.84 1.59-5.95 1.93-1.64.27-3.23.85-4.66 1.7-.4.24-.85.66-.67 1.1.07.18.24.3.4.39 1.13.66 2.55.49 3.81.15 1.26-.34 2.51-.84 3.82-.78 1.32.06 2.56.69 3.88.82 2.04.19 4.27-1.19 4.52-3.22.1-.84-.12-1.68-.15-2.52-.04-1.05.23-2.12.78-3.02.42-.7 1-1.32 1.26-2.1.9-2.78-3.52-3.19-4.79-1.36Z"
			style={{
				fill: '#fdddd7',
			}}
		/>
		<path
			d="M46.97 152.36c-1.32 2.82-2.65 5.66-3.3 8.71-1.4 6.53.63 13.69 5.25 18.51 1.44 1.51 3.18 2.82 5.21 3.31 2.03.49 4.38 0 5.73-1.59 1.5-1.77 1.43-4.44.56-6.59-.87-2.15-2.4-3.96-3.69-5.88-2.02-3.03-3.51-6.56-3.39-10.2.13-3.82 2-7.36 4.22-10.47 2.46-3.44 5.45-6.58 9.11-8.69 2.01-1.16 4.23-2.02 5.94-3.59 3.37-3.11 2.39-7.19-1.69-8.77-3.95-1.53-6.79.35-9.76 2.99-6.62 5.89-10.49 14.4-14.18 22.27ZM57.4 119.5c.26.24.61.36.95.43 2.27.45 4.28-1.36 6.1-2.8 4.79-3.78 10.81-5.66 16.78-6.93 5.97-1.27 12.08-2.03 17.87-3.95 1.44-.47 3.17-1.74 2.5-3.1-6.32 1.05-12.64 2.1-18.97 3.15-3.89.65-7.79 1.3-11.54 2.52-1.9.62-17.03 7.65-13.69 10.68Z"
			style={{
				fill: '#fff',
				opacity: 0.34,
			}}
		/>
		<path
			d="M142.98 72.8c2.44 2.36 4.93 4.73 6.75 7.59 1.82 2.86 2.94 6.33 2.33 9.67-.27 1.51-.87 3.15-.14 4.49.36.65.98 1.11 1.58 1.55 2.32 1.7 4.64 3.39 6.96 5.09 1.54 1.12 9.75 4.78 11.19 6.02 2.44 2.08-5.2-7.84-5.82-9.17-.92-1.98-2.43-3.62-3.48-5.53-1.65-2.99-2.13-6.54-3.93-9.43-1.45-2.33-3.66-4.07-5.39-6.2-1.58-1.95-2.76-4.22-4.48-6.04-1.29-1.36-4.86-2.48-7.94-2.93-.28-.04 1.19 3.74 2.38 4.89Z"
			style={{
				fill: '#cc4653',
			}}
		/>
		<path
			d="M155.33 95.52c2.86.67 5.15 2.87 6.78 5.31 1.63 2.45 2.74 5.2 4.22 7.74 1.96 3.38 4.55 6.4 7.6 8.84.86.69 1.78 1.36 2.36 2.3.86 1.4.79 3.31-.18 4.64-.97 1.33-2.76 1.99-4.36 1.6-2.35-.57-3.72-2.99-4.57-5.25-2.06-5.41-3.08-11.68-7.53-15.39-2.52-2.1-4.13-1.45-6.99-1.12-1.96.22-3.58.04-4.53-1.93-2.14-4.47 3.44-7.62 7.21-6.74Z"
			style={{
				fill: '#fff',
				opacity: 0.34,
			}}
		/>
		<path
			d="M161.87 93.59c-1.87-1.46-4.09-2.53-6.45-2.8 1.12-.51 2.48-.13 3.63-.57 1.39-.54 2.09-2.38 1.4-3.7.14-.4.75-.11 1.01.23 2.58 3.36 4.53 7.2 5.73 11.26.06.21.12.44.05.64-.35.97-2.09-1.8-2.38-2.14-.9-1.07-1.89-2.06-2.99-2.92Z"
			style={{
				fill: '#9f3746',
			}}
		/>
	</svg>
);

export default DagingSapi;
