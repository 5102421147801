
import React, {useEffect, useRef, useState} from 'react';
import styles from '../../styles/components/GraphPanel.module.scss';
import useSensorDetails from "../../hooks/useSensorDetails";
import {generateMonthlyHistogramAPIURL, generateMonthlyTimeSeriesAPIURL} from "../../utils/generator";
import {axios6717} from "../../utils/axiosInstance";
import axios from "axios";
import {measurementsDict} from "../../utils/dictionary";
import WeatherTimeContent from "../WeatherPanel/weatherTimeContent/weatherTimeContent";

import {Bar, Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    TimeScale, //Import timescale instead of category for X axis
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, Chart
} from 'chart.js';
import {formatUnits} from "../../utils/textFormatter";

ChartJS.register(
    TimeScale, //Register timescale instead of category for X axis
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
function GraphPanel(props) {
    const {sensorDetails} = useSensorDetails();
    const measurement = props.measurement;

    const measurementTitle = measurementsDict[measurement]

    const tsURL = generateMonthlyTimeSeriesAPIURL(sensorDetails.nodeID, measurement);
    const histURL = generateMonthlyHistogramAPIURL(sensorDetails.nodeID, measurement);

    const [isRetrieved, setIsRetrieved] = useState(false);
    const [tsData, setTsData] = useState({});
    const [histData, setHistData] = useState({});

    const panelRef = useRef();

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display:false,
            },
            title: {
                display: false,
            },
        },
    };

    function clickRotate() {
        panelRef.current.classList.toggle(`${styles.rotated}`);
    }

    const retrieveData = () => {
        const getTimeSeries = axios6717.get(tsURL);
        const getHistogram = axios6717.get(histURL);

        axios.all([
            getTimeSeries,
            getHistogram
        ]).then(
            axios.spread((...allData) => {

                const timeSeriesChartData = {
                    labels: allData[0].data.map((d) => {
                        return d.x
                    }),
                    datasets:[
                        {
                            label:`${measurementsDict[measurement]}`,
                            data: allData[0].data.map((d) => {
                                return  Math.round(d.y * 10) / 10;
                            }),

                            //Style
                            fill:false,
                            backgroundColor: 'white',
                            borderColor: '#FFFFFF',
                            borderWidth: 2,
                            // tension: 1,
                            showTooltips: true,

                            elements: {
                                point:{
                                    radius: 1,
                                    backgroundColor:'#FFFFFF'
                                }
                            }
                        }
                    ]
                }
                const barData = {
                    labels: allData[1].data.map(function (d) {
                        return Math.round(d.x * 10) / 10;;
                    }),
                    datasets: [
                        {
                            label: `Frekuensi ${measurementsDict[measurement]} `,
                            data: allData[1].data.map(function (d) {
                                return Math.round(d.y * 10) / 10;
                            }),
                            backgroundColor: ['rgba(8, 154, 243, 1)']
                        },
                    ]
                };

                setTsData(timeSeriesChartData);
                setHistData(barData);
                setIsRetrieved(true);
            })
        )
    }

    useEffect(() => {
       if(!isRetrieved){
           retrieveData()
       } else {

       }
    }, [isRetrieved]);

    if(!isRetrieved){
        return(
            <div className={styles.loading_container}>
                <h2>GRAFIK {measurementsDict[measurement].toUpperCase()}</h2>
            </div>
        )
    } else {
        return (
            <div className={styles.graph_panel_container}>
                <div className={styles.graph_panel} ref={panelRef} onClick={clickRotate}>
                    <div className={`${styles.panel_contents} ${styles.panel_time_series}`}>
                        <div className={styles.graph_title}>
                            <h2> GRAFIK {measurementTitle.toUpperCase()} </h2>
                            <div className={styles.station_title_caption}>
                                <p>Data satu bulan ke belakang</p>
                            </div>
                        </div>
                        <div className={styles.panel_depth}>
                            <div className={styles.chart_container}>
                                <Line
                                    height={'inherit'}
                                    width={'inherit'}
                                    data={tsData}
                                    options={{
                                        responsive:true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {

                                                display: true,
                                                type: 'time',
                                                time: {
                                                    displayFormats: {
                                                        month: 'DDD'
                                                    },
                                                    unit: 'day'
                                                },
                                                ticks: {
                                                    color: '#FFFFFF',
                                                },
                                                grid:{
                                                    display : false,
                                                    borderWidth:0,
                                                }
                                            },
                                            y: {
                                                display: true,
                                                ticks: {
                                                    color: '#FFFFFF',
                                                    callback: function(value, index, values) {
                                                        return formatUnits(props.measurement, value);
                                                    }
                                                },
                                            },
                                        },
                                        plugins: {
                                            title: {
                                                display: false,
                                                color:'#FFFFFF'
                                            },
                                            legend: {
                                                display:false,
                                                position: 'top',
                                                labels:{
                                                    boxWidth:10,
                                                    boxHeight:10,
                                                }
                                            },
                                        },

                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/*===========================================================================================*/}
                    <div className={`${styles.panel_contents} ${styles.panel_histogram}`}>
                        <div className={styles.graph_title}>
                            <h2> HISTOGRAM {measurementTitle.toUpperCase()} </h2>
                            <div className={styles.station_title_caption}>
                                <p>Data satu bulan ke belakang</p>
                            </div>
                        </div>
                        <div className={styles.panel_depth}>
                            <div className={styles.chart_container}>
                                <Bar options={options} data={histData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GraphPanel;