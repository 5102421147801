import useAuth from './useAuth';
import {axios8081} from "../utils/axiosInstance";

const useRefreshToken = () => {
    const {setAuth } = useAuth();

    return async () => {
        const response = await axios8081.get('/api/verify', {
            withCredentials: true
        });
        setAuth((prev) => {
            return {
                ...prev,
                //roles: response.data.roles,
                accessToken: response.data.accessToken,
                user:response.data.username
            }
        });

        return response.data.accessToken;
    };
};

export default useRefreshToken;