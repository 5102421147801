import * as React from 'react';

const CabaiMerah = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M112.11 83.52c-1.18-1.72-2.8-3.18-3.56-5.13-.45-1.17-.56-2.45-.53-3.7.07-3.31 1.01-6.6 2.72-9.44.43-.71.9-1.4 1.14-2.19.24-.79.2-1.72-.34-2.35-.42-.49-1.07-.72-1.71-.79-2.63-.27-4.98 1.98-5.83 4.48-.85 2.51-.61 5.23-.55 7.88.01.47-.14 3.44-1.63 5.24-.48.57-4.81 5.15-5.67 7.02-.87 1.9-.99 4.21.18 5.94.79 1.16 2.2 1.72 3.55 2.1 2.85.82 5.84 1.18 8.8 1.06 1.89-.07 4.83-.57 5.51-2.69.72-2.22-.9-5.68-2.1-7.44Z"
			style={{
				fill: '#519353',
			}}
		/>
		<path
			d="M111.88 63.06c.24-.79.2-1.72-.34-2.35-.35-.41-.87-.64-1.4-.74.02.06.05.12.07.18.44 1.27.02 2.69-.67 3.85-.68 1.16-1.62 2.16-2.31 3.31-1.77 2.91-1.89 6.59-1.11 9.9.78 3.32 2.37 6.37 3.89 9.43.16.31.31.64.31 1 0 .35-.23.72-.58.77-.14.02-.27-.01-.41-.04-2.17-.52-4.35-1.05-6.58-1.2-2.23-.14-4.53.13-6.51 1.16-.22.11-.43.25-.63.4.13.62.37 1.21.74 1.75.79 1.16 2.2 1.72 3.55 2.1 2.85.82 5.84 1.18 8.8 1.06 1.89-.07 4.83-.57 5.51-2.69.72-2.22-.9-5.68-2.1-7.44-1.18-1.72-2.8-3.18-3.56-5.13-.45-1.17-.56-2.45-.53-3.7.07-3.31 1.01-6.6 2.72-9.44.42-.71.9-1.4 1.14-2.19Z"
			style={{
				fill: '#4a854b',
			}}
		/>
		<path
			d="M157.31 210.84c-4.04-3.56-8.83-6.14-13.38-9.02-32.96-20.79-54.42-58.72-55.28-97.68-.09-3.93.14-8.21 2.68-11.22 3.46-4.09 9.66-4.17 14.98-3.52 4.14.51 8.49 1.38 11.58 4.2 5.26 4.81 4.8 13.05 4.19 20.15-.87 10.1-1.2 20.32.36 30.34s5.11 19.88 11.38 27.84c2.97 3.78 6.51 7.08 9.55 10.81 6.24 7.66 10.24 17.01 16.89 24.32 1.48 1.63 3.13 3.18 5.17 4 2.04.82 4.54.8 6.28-.56 1.28-1 2.59-2.75 4.07-2.09.9.4 1.21 1.62.88 2.55-.98 2.84-6.37 5.31-9.25 5.34-4.23.03-7.15-2.89-10.09-5.47Z"
			style={{
				fill: '#df3c26',
			}}
		/>
		<path
			d="M89.83 95.38c.61-.79 1.31-1.5 2.16-2.06 2.58-1.73 5.91-1.72 9.02-1.66 3.34.07 6.93.22 9.56 2.27 2.75 2.14 3.76 5.81 4.12 9.27 1.21 11.41-2.78 22.74-3 34.21-.27 14.47 5.81 28.95 16.32 38.9 4.28 4.05 9.22 7.35 13.61 11.29 5.34 4.8 9.79 10.53 13.61 16.61 2.57 4.09 5.38 8.76 10.1 9.84 4.1.93 6.61-1.62 10.32-2.2.22-.03.41-.05.59-.05.17-.27.31-.54.41-.82.32-.93.02-2.15-.88-2.55-1.48-.66-2.79 1.09-4.07 2.09-1.73 1.35-4.24 1.38-6.28.56-2.04-.82-3.69-2.38-5.17-4-6.65-7.31-10.65-16.66-16.89-24.32-3.03-3.73-6.57-7.03-9.55-10.81-6.27-7.96-9.82-17.83-11.38-27.84-1.56-10.02-1.23-20.24-.36-30.34.61-7.1 1.07-15.35-4.19-20.15-3.08-2.81-7.43-3.69-11.58-4.2-5.31-.66-11.52-.57-14.98 3.52-.63.75-1.12 1.57-1.5 2.45Z"
			style={{
				fill: '#c62f27',
			}}
		/>
		<path
			d="M122.49 144.18c-.41-2.63-.69-5.27-.86-7.91-.84 1.84-1.59 3.72-2.01 5.7-1.18 5.48.17 11.2 2.2 16.43 2.84 7.29 7.19 14.26 13.7 18.62 1.81 1.21 4.08 2.84 6.36 4-2.65-3.01-5.52-5.83-8-8.98-6.27-7.96-9.82-17.83-11.38-27.84ZM93.21 91.36c5.55-1.96 11.87-1.59 17.59.07 2.76.8 5.48 1.91 7.72 3.7.71.57 1.37 1.2 1.97 1.89a12.08 12.08 0 0 0-2.55-3.32c-3.08-2.81-7.43-3.69-11.58-4.2-4.48-.55-9.59-.58-13.15 1.87Z"
			style={{
				fill: '#af2824',
			}}
		/>
		<path
			d="M144.38 195.5c-1.47-1.62-3.54-2.52-5.51-3.49-10.55-5.18-19.55-13.47-25.57-23.56-.77.06-.66 1.24-.28 1.92 4.23 7.51 10.95 13.24 17.53 18.8 3.47 2.94 6.83 6.19 10.5 8.88.87.64 2.42 1.61 3.54.85 1.11-.75.66-2.6-.22-3.4ZM110.77 164.21a48.044 48.044 0 0 1-5.84-13.59c-.27-1.04-.52-2.13-1.21-2.95-.69-.82-1.98-1.25-2.86-.64-.64.44-.87 1.31-.77 2.08.11.77.5 1.47.9 2.13 2.37 3.87 5.49 7.33 7.1 11.57.38 1.01.75 2.27 1.7 2.9 1.36.92 1.61-.48.97-1.51Z"
			style={{
				fill: '#fff',
				opacity: 0.31,
			}}
		/>
		<path
			d="M97.07 86.14c.22.17.54.03.78-.1 2.25-1.18 4.98-.82 7.44-.2.64.16 1.42.31 1.9-.15.43-.4.4-1.12.13-1.64-.27-.52-.74-.91-1.14-1.34a7.093 7.093 0 0 1-1.78-3.65c-.07-.44-.2-1-.64-1.07-.4-.06-.7.34-.92.68-.83 1.21-2.06 2.08-3.18 3.04-.71.61-1.39 1.28-1.88 2.08-.22.36-1.15 2.01-.72 2.35ZM104.63 73.62c.2-.23.19-.57.19-.88 0-3.26 1.72-6.27 3.7-8.86.46-.6.95-1.42.53-2.05-.24-.36-.71-.49-1.15-.47-1.01.05-1.88.81-2.35 1.71-.47.9-.61 1.93-.74 2.93-.2 1.49-.39 2.97-.59 4.46-.1.74-.2 1.49-.27 2.24-.03.3-.01 1.75.67.93Z"
			style={{
				fill: '#5ea55d',
			}}
		/>
		<path
			d="M120.41 52.47c.04-1.5.41-2.99.14-4.48-.16-.89-.55-1.72-1-2.5-1.18-2.04-2.83-3.74-4.74-4.89-.47-.29-.97-.54-1.37-.95-.4-.4-.7-1-.63-1.58.06-.46.33-.84.65-1.11 1.33-1.12 3.37-.57 4.7.68s2.14 3.04 3.02 4.71c.16.3 1.27 2.09 2.69 2.67.46.18 4.38 1.46 5.49 2.32 1.13.86 1.99 2.26 1.96 3.76-.02 1.01-.59 1.86-1.19 2.59a17.348 17.348 0 0 1-4.38 3.84c-1.05.64-2.8 1.39-3.91.32-1.15-1.12-1.48-3.86-1.44-5.39Z"
			style={{
				fill: '#519353',
			}}
		/>
		<path
			d="M113.44 39.66c-.4-.4-.7-1-.63-1.58.05-.38.25-.71.5-.97 0 .05.02.09.03.14.2.95.92 1.68 1.69 2.16.77.47 1.62.76 2.39 1.22 1.96 1.17 3.3 3.42 4.03 5.76.73 2.35.93 4.82 1.17 7.27.02.25.05.51.18.73.12.22.38.37.58.27.08-.04.14-.11.2-.17.99-1.11 1.98-2.23 3.14-3.12 1.15-.89 2.49-1.56 3.92-1.63.16 0 .32 0 .48.02.14.43.22.89.21 1.36-.02 1.01-.59 1.86-1.19 2.59a17.348 17.348 0 0 1-4.38 3.84c-1.05.64-2.8 1.39-3.91.32-1.15-1.12-1.48-3.86-1.44-5.39.04-1.5.41-2.99.14-4.48-.16-.89-.55-1.72-1-2.5-1.18-2.04-2.83-3.74-4.74-4.89-.47-.29-.97-.54-1.37-.95Z"
			style={{
				fill: '#4a854b',
			}}
		/>
		<path
			d="M140.08 148.04c.95-3.68 2.64-7.01 4.1-10.45 10.59-24.85 9.05-56.2-3.98-80.76-1.31-2.48-2.92-5.06-5.33-6.01-3.28-1.3-6.66.89-9.3 3.22-2.06 1.82-4.11 3.93-4.79 6.8-1.18 4.89 1.93 9.86 4.71 14.06 3.96 5.97 7.68 12.21 10.31 19.01 2.62 6.8 4.12 14.22 3.49 21.44-.3 3.43-1.07 6.76-1.41 10.17-.71 7.02.36 14.29-.69 21.24-.23 1.55-.59 3.11-1.41 4.36-.82 1.25-2.18 2.14-3.58 1.92-1.03-.16-2.35-.77-2.92.17-.35.58-.09 1.44.41 1.91 1.51 1.41 5.28 1.01 6.84-.02 2.29-1.51 2.86-4.38 3.55-7.05Z"
			style={{
				fill: '#df3c26',
			}}
		/>
		<path
			d="M136.53 51.8c-.6-.27-1.22-.46-1.88-.5-1.99-.14-3.79 1.06-5.44 2.23-1.78 1.25-3.66 2.64-4.37 4.87-.74 2.32-.02 4.98.98 7.26 3.3 7.54 9.37 13.15 13.46 20.21 5.16 8.91 6.89 20.12 4.65 30.11-.91 4.07-2.43 7.91-3.43 11.94-1.22 4.92-1.64 10.09-1.59 15.25.03 3.48.13 7.4-2.04 9.77-1.89 2.06-4.13 1.38-6.33 2.36-.13.06-.24.12-.34.18-.19-.1-.36-.23-.51-.36-.5-.46-.76-1.33-.41-1.91.57-.94 1.88-.33 2.92-.17 1.4.22 2.76-.67 3.58-1.92.82-1.25 1.17-2.81 1.41-4.36 1.06-6.95-.02-14.21.69-21.24.35-3.42 1.11-6.75 1.41-10.17.63-7.22-.87-14.64-3.49-21.44-2.62-6.8-6.34-13.04-10.31-19.01-2.79-4.2-5.89-9.16-4.71-14.06.69-2.86 2.74-4.98 4.79-6.8 2.64-2.33 6.02-4.52 9.3-3.22.6.24 1.15.57 1.66.99Z"
			style={{
				fill: '#c62f27',
			}}
		/>
		<path
			d="M135.8 93.97c-.69-1.78-1.45-3.53-2.28-5.24 1.09.84 2.14 1.74 3.06 2.82 2.53 2.98 3.78 7.03 4.5 11.02.99 5.56 1.06 11.48-.95 16.53-.56 1.41-1.22 3.24-2.05 4.78.39-2.83.96-5.62 1.21-8.48.63-7.22-.87-14.64-3.49-21.44ZM133.32 50.53c-3.67.79-6.96 3.3-9.47 6.39-1.21 1.49-2.3 3.16-2.88 5.09-.19.61-.32 1.24-.41 1.89-.08-.99-.01-1.99.23-2.99.69-2.86 2.74-4.98 4.79-6.8 2.22-1.96 4.97-3.83 7.74-3.59Z"
			style={{
				fill: '#af2824',
			}}
		/>
		<path
			d="M141.75 133.82c.23-1.54 1.04-2.85 1.77-4.16 3.9-7.03 5.89-15.45 5.64-23.9.44-.25.79.54.81 1.09.31 6.2-1.33 12.2-2.95 18.04-.86 3.08-1.55 6.32-2.59 9.32-.25.71-.75 1.88-1.62 1.81-.86-.07-1.26-1.38-1.06-2.2ZM149.06 102.2c-.03-3.56-.56-7.14-1.55-10.57-.22-.74-.46-1.51-.37-2.27.09-.76.63-1.49 1.32-1.43.5.05.92.5 1.13 1.01.21.52.24 1.09.25 1.65.06 3.26-.43 6.55.17 9.77.14.76.38 1.68.09 2.42-.42 1.07-1.04.28-1.04-.59Z"
			style={{
				fill: '#fff',
				opacity: 0.31,
			}}
		/>
		<path
			d="M129.43 48.67c-.06.19-.28.21-.46.22-1.62.08-2.97 1.29-4.09 2.56-.29.33-.66.7-1.08.59-.37-.1-.6-.55-.63-.97-.03-.42.08-.83.15-1.25.16-.96.05-1.99-.3-2.91-.11-.3-.24-.7-.02-.9.19-.18.49-.04.73.09.87.45 1.83.55 2.77.75.59.12 1.19.29 1.73.62.24.14 1.32.83 1.2 1.2ZM121.01 43.61c-.19-.07-.3-.29-.41-.48-1.13-2.03-3.1-3.28-5.06-4.18-.46-.21-1.01-.54-1-1.09 0-.31.21-.57.45-.71.56-.34 1.29-.18 1.86.21.56.39.99.98 1.41 1.56.62.85 1.24 1.71 1.86 2.56.31.43.62.86.92 1.29.12.18.61 1.08-.04.82Z"
			style={{
				fill: '#5ea55d',
			}}
		/>
	</svg>
);

export default CabaiMerah;
