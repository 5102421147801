import * as React from 'react';

const CerahPagi = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
		<defs>
			<radialGradient
				id="cerahPagi_svg__b"
				cx={-66.87}
				cy={83.12}
				fx={-66.87}
				fy={83.12}
				r={140.97}
				gradientTransform="matrix(-1 0 0 1 81.67 0)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff4c4" />
				<stop offset={0.16} stopColor="#fee034" />
				<stop offset={1} stopColor="#f47621" />
			</radialGradient>
			<radialGradient
				id="cerahPagi_svg__c"
				cx={1488.06}
				cy={-2583.96}
				fx={1488.06}
				fy={-2583.96}
				r={21.16}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -2772.99 -5010.72)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahPagi_svg__d"
				cx={-3021}
				cy={1682.87}
				fx={-3021}
				fy={1682.87}
				r={20.42}
				gradientTransform="matrix(-1.17345 -1.10233 .2198 -.7265 -3823.45 -1931.01)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<linearGradient
				id="cerahPagi_svg__a"
				x1={222.97}
				y1={27.52}
				x2={46.76}
				y2={224.44}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fdb925" />
				<stop offset={1} stopColor="#f15822" />
			</linearGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				style={{
					fill: 'url(#cerahPagi_svg__a)',
				}}
				d="m210.01 192.16-21.97 1.45-4.14 21.62-21.13-6.16-11.28 18.9-17.76-13.02-17.06 13.91-12.24-18.3-20.79 7.22-5.23-21.38-22.01-.32 2.39-21.88-20.57-7.83 9.73-19.74-16.65-14.4 15.89-15.22-10.72-19.22 20.14-8.87-3.5-21.73 21.96-1.44 4.14-21.62 21.13 6.15 11.28-18.9 17.76 13.02 17.07-13.9 12.23 18.29 20.79-7.22 5.24 21.38 22.01.32-2.4 21.88 20.58 7.83-9.74 19.74 16.66 14.4-15.9 15.22 10.73 19.22-20.15 8.87 3.51 21.73z"
			/>
			<path
				d="M57.29 129.67c0 41.01 33.25 74.26 74.26 74.26s74.26-33.25 74.26-74.26-33.25-74.25-74.26-74.25-74.26 33.24-74.26 74.25Z"
				style={{
					fill: 'url(#cerahPagi_svg__b)',
				}}
			/>
			<path
				d="M142.87 122.04c-18.92-15.35-26.3-38.71-16.5-52.19 9.81-13.48 33.09-11.96 52.02 3.37 18.91 15.34 26.29 38.71 16.49 52.18-9.81 13.49-33.1 11.97-52.01-3.36Z"
				style={{
					fill: 'url(#cerahPagi_svg__c)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M65.92 146.09c2.51-8.21 15.24-4.81 28.46 7.6 13.21 12.41 21.89 29.13 19.4 37.35-2.5 8.21-15.24 4.81-28.46-7.6-13.21-12.42-21.9-29.15-19.4-37.35Z"
				style={{
					fill: 'url(#cerahPagi_svg__d)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
		</g>
	</svg>
);

export default CerahPagi;
