import * as React from 'react';

const CerahBerawanPagi = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="cerahBerawanPagi_svg__f"
				cx={-617.31}
				cy={76.8}
				fx={-617.31}
				fy={76.8}
				r={126.48}
				gradientTransform="matrix(-1 0 0 1 -470.33 0)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff4c4" />
				<stop offset={0.16} stopColor="#fee034" />
				<stop offset={1} stopColor="#f47621" />
			</radialGradient>
			<radialGradient
				id="cerahBerawanPagi_svg__a"
				cx={1087.01}
				cy={-2687.92}
				fx={1087.01}
				fy={-2687.92}
				r={18.99}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -3280.61 -4692.32)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahBerawanPagi_svg__b"
				cx={-3480.03}
				cy={1980.24}
				fx={-3480.03}
				fy={1980.24}
				r={18.32}
				gradientTransform="matrix(-1.17345 -1.10233 .2198 -.7265 -4422.84 -2234.81)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahBerawanPagi_svg__c"
				cx={73.5}
				cy={143.61}
				fx={73.5}
				fy={143.61}
				r={87.07}
				gradientTransform="matrix(-1 0 0 -.73 146.99 248.52)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="cerahBerawanPagi_svg__d"
				cx={93.25}
				cy={154.97}
				fx={93.25}
				fy={154.97}
				r={98.99}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fee034" />
				<stop offset={0.08} stopColor="#fccb30" stopOpacity={0.81} />
				<stop offset={0.18} stopColor="#f9b52c" stopOpacity={0.6} />
				<stop offset={0.28} stopColor="#f8a128" stopOpacity={0.41} />
				<stop offset={0.38} stopColor="#f69226" stopOpacity={0.27} />
				<stop offset={0.48} stopColor="#f58523" stopOpacity={0.15} />
				<stop offset={0.58} stopColor="#f47d22" stopOpacity={0.07} />
				<stop offset={0.68} stopColor="#f47721" stopOpacity={0.02} />
				<stop offset={0.77} stopColor="#f47621" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="cerahBerawanPagi_svg__g"
				cx={1158.86}
				cy={-2669.62}
				fx={1158.86}
				fy={-2669.62}
				r={10.5}
				xlinkHref="#cerahBerawanPagi_svg__a"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__h"
				cx={1158.55}
				cy={-2689.03}
				fx={1158.55}
				fy={-2689.03}
				r={8.26}
				xlinkHref="#cerahBerawanPagi_svg__a"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__i"
				cx={15.58}
				cy={190.39}
				fx={15.58}
				fy={190.39}
				r={6.04}
				gradientTransform="rotate(-25.71 -781.654 372.271) scale(1 2.91)"
				xlinkHref="#cerahBerawanPagi_svg__b"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__j"
				cx={219.95}
				cy={76.59}
				fx={219.95}
				fy={76.59}
				r={67.42}
				gradientTransform="matrix(-1 0 0 -.73 439.91 132.54)"
				xlinkHref="#cerahBerawanPagi_svg__c"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__k"
				cx={175.91}
				cy={99.74}
				fx={175.91}
				fy={99.74}
				r={90.09}
				xlinkHref="#cerahBerawanPagi_svg__d"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__l"
				cx={1056.39}
				cy={-2707.75}
				fx={1056.39}
				fy={-2707.75}
				r={8.13}
				xlinkHref="#cerahBerawanPagi_svg__a"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__m"
				cx={1056.14}
				cy={-2722.78}
				fx={1056.14}
				fy={-2722.78}
				r={6.39}
				xlinkHref="#cerahBerawanPagi_svg__a"
			/>
			<radialGradient
				id="cerahBerawanPagi_svg__n"
				cx={175.11}
				cy={112.81}
				fx={175.11}
				fy={112.81}
				r={4.67}
				gradientTransform="rotate(-25.71 -297.284 220.576) scale(1 2.91)"
				xlinkHref="#cerahBerawanPagi_svg__b"
			/>
			<linearGradient
				id="cerahBerawanPagi_svg__e"
				x1={213.76}
				y1={26.92}
				x2={55.67}
				y2={203.58}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fdb925" />
				<stop offset={1} stopColor="#f15822" />
			</linearGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				style={{
					fill: 'url(#cerahBerawanPagi_svg__e)',
				}}
				d="m202.13 174.63-19.71 1.29-3.71 19.4-18.96-5.52-10.13 16.95-15.92-11.67-15.31 12.47-10.98-16.42-18.65 6.48-4.7-19.18-19.75-.29 2.15-19.63L48 151.49l8.74-17.72-14.94-12.91 14.26-13.66-9.63-17.24L64.51 82l-3.15-19.49 19.71-1.3 3.71-19.39 18.96 5.52 10.12-16.96 15.93 11.68 15.31-12.47L156.08 46l18.65-6.48 4.7 19.18 19.75.29-2.15 19.63 18.46 7.02-8.74 17.72 14.94 12.91-14.26 13.66 9.63 17.24-18.08 7.96 3.15 19.5z"
			/>
			<path
				d="M65.12 118.56c0 36.8 29.83 66.63 66.62 66.63s66.63-29.83 66.63-66.63-29.83-66.62-66.63-66.62-66.62 29.83-66.62 66.62Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__f)',
				}}
			/>
			<path
				d="M141.9 111.71c-16.97-13.77-23.6-34.73-14.8-46.82 8.8-12.1 29.69-10.73 46.67 3.02 16.97 13.76 23.58 34.73 14.79 46.82-8.81 12.1-29.69 10.74-46.66-3.02Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__a)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M72.86 133.29c2.25-7.37 13.67-4.32 25.53 6.82 11.86 11.14 19.64 26.13 17.4 33.51-2.24 7.36-13.67 4.32-25.53-6.82-11.85-11.14-19.64-26.15-17.41-33.51Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__b)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M92.49 157.7c-1.19-17.41-15.68-31.16-33.39-31.16s-32.2 13.75-33.39 31.16c-13.19.65-23.69 11.56-23.69 24.91s11.17 24.94 24.94 24.94h64.28c13.77 0 24.94-11.17 24.94-24.94s-10.5-24.25-23.69-24.91Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__c)',
				}}
			/>
			<path
				d="M92.49 157.7c-1.19-17.41-15.68-31.16-33.39-31.16s-32.2 13.75-33.39 31.16c-13.19.65-23.69 11.56-23.69 24.91s11.17 24.94 24.94 24.94h64.28c13.77 0 24.94-11.17 24.94-24.94s-10.5-24.25-23.69-24.91Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__d)',
				}}
			/>
			<path
				d="M59.1 160.8c-9.39-7.62-13.06-19.21-8.19-25.91 4.87-6.69 16.43-5.94 25.82 1.67 9.39 7.62 13.05 19.22 8.19 25.91-4.87 6.69-16.43 5.94-25.82-1.67Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__g)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M92.66 183.32c-7.38-5.99-10.26-15.1-6.44-20.36 3.83-5.26 12.91-4.67 20.29 1.31 7.38 5.99 10.25 15.1 6.43 20.36-3.83 5.26-12.91 4.67-20.29-1.31Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__h)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M19.86 184.15c-5.44-8.49-11.77-12.58-14.14-9.14-2.36 3.44.14 13.13 5.58 21.62 5.45 8.49 11.78 12.59 14.14 9.14 2.36-3.45-.13-13.13-5.58-21.62Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__i)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
			<path
				d="M234.66 87.5c-.92-13.48-12.14-24.13-25.85-24.13s-24.93 10.65-25.85 24.13c-10.21.5-18.34 8.95-18.34 19.28s8.65 19.31 19.31 19.31h49.77c10.66 0 19.31-8.65 19.31-19.31S244.88 88 234.67 87.5Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__j)',
				}}
			/>
			<path
				d="M234.66 87.5c-.92-13.48-12.14-24.13-25.85-24.13s-24.93 10.65-25.85 24.13c-10.21.5-18.34 8.95-18.34 19.28s8.65 19.31 19.31 19.31h49.77c10.66 0 19.31-8.65 19.31-19.31S244.88 88 234.67 87.5Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__k)',
				}}
			/>
			<path
				d="M208.81 89.9c-7.27-5.9-10.11-14.87-6.34-20.06 3.77-5.18 12.72-4.6 19.99 1.29 7.27 5.9 10.1 14.88 6.34 20.06-3.77 5.18-12.72 4.6-19.99-1.29Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__l)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M234.79 107.33c-5.71-4.64-7.94-11.69-4.98-15.77 2.96-4.07 10-3.61 15.71 1.01 5.71 4.64 7.94 11.7 4.98 15.77-2.97 4.07-10 3.61-15.71-1.01Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__m)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M178.42 107.98c-4.21-6.57-9.11-9.74-10.95-7.07-1.82 2.66.11 10.16 4.32 16.74 4.22 6.57 9.12 9.74 10.95 7.07s-.1-10.16-4.32-16.74Z"
				style={{
					fill: 'url(#cerahBerawanPagi_svg__n)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
		</g>
	</svg>
);

export default CerahBerawanPagi;
