import * as React from 'react';

const SvgHumid = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.33 177.67" {...props}>
		<path
			d="M9.78 54.32c1.04-15.57 9.55-16.71 15.38-33.73 5.83 17.02 14.34 18.16 15.38 33.73 1.59 23.88-32.35 23.88-30.75 0Z"
			style={{
				fill: '#5ebae9',
				fillRule: 'evenodd',
			}}
		/>
		<path
			d="M24.2 29.48C19.97 40.28 13.84 43 13.06 54.72c-.7 10.52 6.2 15.17 14.13 13.94 12.1-1.88 6.71-16.35-1.2-9.61-4.46 3.8-8.77 0-8.73-4.52.1-12.71 4.82-13.13 6.95-25.04Z"
			style={{
				fill: '#fff',
				fillRule: 'evenodd',
			}}
		/>
		<path
			d="M19.46 135.01c2.16-32.46 19.91-34.84 32.05-70.31 12.14 35.47 29.89 37.85 32.05 70.31 3.32 49.77-67.42 49.77-64.1 0Z"
			style={{
				fill: '#5ebae9',
				fillRule: 'evenodd',
			}}
		/>
		<path
			d="M56.79 159.64c-14.13 0-25.59-11.81-25.59-26.37 0-5.24 1.48-10.12 4.04-14.23-5.3 4.83-8.64 11.89-8.64 19.76 0 14.56 11.46 26.37 25.59 26.37 3.84 0 7.47-.87 10.74-2.43 4.43-2.12 8.19-5.51 10.81-9.72a25.196 25.196 0 0 1-10.81 5.85c-1.97.5-4.02.77-6.14.77ZM64.48 103.58c-.53-.78-1.04-1.55-1.55-2.31-4.79-7.23-8.54-13.79-10.6-23-1.43 10.82 4.51 24.49 9.5 31.83l1.09 1.56c3.57 5.06 11.05 15.97 11.44 31.63 1.36-20.57-1.41-27.15-9.9-39.72Z"
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d="M57.88 26.91C58.71 14.49 65.5 13.58 70.15 0c4.65 13.58 11.44 14.49 12.27 26.91 1.27 19.05-25.81 19.05-24.54 0Z"
			style={{
				fill: '#5ebae9',
				fillRule: 'evenodd',
			}}
		/>
		<path
			d="M79.34 32.53c-17.92 1.93-15-14.08-12.53-20.19-7.47 7.74-8.9 25.69 3.97 25.69 3.55 0 7.21-2.48 8.56-5.5Z"
			style={{
				fill: '#fff',
				fillRule: 'evenodd',
			}}
		/>
	</svg>
);

export default SvgHumid;
