import styles from '../../styles/components/Navbar.module.scss'

import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import SvgSemnetLogo from "../../assets/svg/SemnetLogo";
import {VscMenu} from "react-icons/vsc";

import useLogout from "../../hooks/useLogout";

export const Navbar = (props) => {
    const{isUserExist} = props;

    const navigate = useNavigate()
    const logout = useLogout();

    const [visible, setVisible] = useState(false);

    const showSidebar = () =>{
        setVisible(!visible)
    };

    const visState=(visibility)=>{
        if (visibility===true) {
            return 'visible'
        }else{
            return 'hidden'
        }
    }

    const handleLogout = async () => {
        await logout();
        navigate('/')
        //window.location = "/";
    };

    if(isUserExist != null){
        return(
            <div className={styles.navbar_auth_wrapper} >
                <div className={styles.navbar_burger_logo_container} style={{visibility : `${visState(!visible)}`}}>
                    <VscMenu  size={32} color={'#036666'} onClick={showSidebar}/>
                </div>
                <div className={styles.navbar_auth_container} style={{visibility : `${visState(visible)}`}}>
                    <div className={styles.navbar_auth_top}>
                        <div className={styles.navbar_auth_logo_container}>
                            <SvgSemnetLogo width={'40px'} height={'40px'} onClick={showSidebar}/>
                        </div>
                    </div>
                    <div className={styles.navbar_auth_middle}>

                        <div className={styles.navbar_auth_text_link}>
                            <Link to={'/agroklimatologi'} style={{ textDecoration: 'none' }}>
                                <div className={styles.navbar_auth_text_link}>
                                    Agroklimatologi
                                </div>
                            </Link>
                        </div>

                        <div className={styles.navbar_auth_text_link}>
                            <Link to={'/profil-tanah'} style={{ textDecoration: 'none' }}>
                                <div className={styles.navbar_auth_text_link}>
                                    Profil Tanah
                                </div>
                            </Link>
                        </div>

                        <div className={styles.navbar_auth_text_link}>
                            <Link to={'/agroekonomi'} style={{ textDecoration: 'none' }}>
                                <div className={styles.navbar_auth_text_link}>
                                Harga Komoditas
                                </div>
                            </Link>
                        </div>

                        <div className={styles.navbar_auth_text_link}>
                            <Link to={'/jaring-interkorelasi-harga'} style={{ textDecoration: 'none' }}>
                                <div className={styles.navbar_auth_text_link}>
                                    MST Provinsi
                                </div>
                            </Link>
                        </div>

                        <div className={styles.navbar_auth_text_link}>
                            <Link to={'/mst-national'} style={{ textDecoration: 'none' }}>
                                <div className={styles.navbar_auth_text_link}>
                                    MST Nasional
                                </div>
                            </Link>
                        </div>

                        <div className={styles.navbar_auth_text_link}>
                            <Link to={'/tentang'} style={{ textDecoration: 'none' }}>
                                <div className={styles.navbar_auth_text_link}>
                                    Tentang Kami
                                </div>
                            </Link>
                        </div>

                    </div>
                    <div className={styles.navbar_auth_bottom}>
                        <div className={styles.navbar_auth_logout_button} onClick={handleLogout}>
                            <p>Keluar</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <div className={styles.navbar_container}>
                <div className={styles.navbar_left}>
                    <div className={styles.navbar_logo_container}>
                        <Link to={'/'}>
                            <SvgSemnetLogo width={'40px'} height={'40px'} />
                        </Link>
                    </div>
                </div>
                <div className={styles.navbar_right}>
                    <div className={styles.navbar_register_button}></div>
                    <Link to={'/login'}>
                        <div className={styles.navbar_login_button}>
                            Masuk
                        </div>
                    </Link>
                </div>
                </div>
        );
    }
}