import React, {useEffect, useState} from 'react';
import styles from '../../styles/pages/Agroklimatologi.module.scss'
import {Navigate} from "react-router-dom";
import useSensorDetails from "../../hooks/useSensorDetails";

import StationMap from "../../components/StationMap/StationMap";
import useStationData from "../../hooks/useStationData";
import WeatherPanel from "../../components/WeatherPanel/WeatherPanel";
import WeatherCyclePanel from "../../components/CyclePanel/WeatherCyclePanel";
import StatsPanel from "../../components/StatsPanel/StatsPanel";
import {axios6717, axios8081} from "../../utils/axiosInstance";
import axios from "axios";
import {cyclePredKeyGen} from "../../utils/generator";
import SoilPanel from "../../components/SoilPanel/SoilPanel";
import GraphPanel from "../../components/GraphPanel/GraphPanel";
import {TabTitle} from "../../utils/GeneralFunctions";
import {handleSoilExport} from "../../utils/downloadHandler";

function Agroklimatologi() {

    TabTitle('Sembako - Agroklimatologi')
    const {sensorDetails} = useSensorDetails()
    const {setStationData} = useStationData()

    const locationName = sensorDetails.locationName;

    const [isRetrieved, setIsRetrieved] = useState(false);
    const [weatherObject, setWeatherObject] = useState({});


    const retrieveStatsData = async ()=>{

        const airStatsURL = `/api/${sensorDetails.nodeID}/airstats`;
        const powerAllURL = `/api/power/all?lat=${sensorDetails.latitude}&long=${sensorDetails.longitude}`;

        const getAirStats = axios6717.get(airStatsURL);
        const getPowerAll = axios6717.get(powerAllURL);
        //const getVerify = axios8081.get("/api/verify", {withCredentials:true})
        //await axios8081.get("/api/verify", {withCredentials:true})
        axios.all([
            getAirStats,
            getPowerAll,
        ]).then(
            axios.spread((...allData)=>{

                const wobj = {
                    'relative_humidity' : {
                        'interp_relative_humidity':allData[0].data['interp_relative_humidity'],
                        'low_relative_humidity':allData[0].data['low_relative_humidity'],
                        'high_relative_humidity':allData[0].data['high_relative_humidity'],
                        'status': allData[1].data['humidity']['cycle']['status'],
                        'crit_point': allData[1].data['humidity']['cycle'][cyclePredKeyGen(allData[1].data['humidity']['cycle']['status'])],
                        'annual_mean': allData[1].data['humidity']['annual_mean']['value'],
                        'mean': allData[1].data['humidity']['annual_mean']['fluctuation']['mean'],
                        'stdev': allData[1].data['humidity']['annual_mean']['fluctuation']['stdev'],
                        'skew': allData[1].data['humidity']['annual_mean']['fluctuation']['skew'],
                        'kurt': allData[1].data['humidity']['annual_mean']['fluctuation']['kurt'],
                    },
                    'temperature' : {
                        'interp_temperature':allData[0].data['interp_temperature'],
                        'low_temperature':allData[0].data['low_temperature'],
                        'high_temperature':allData[0].data['high_temperature'],
                        'status': allData[1].data['temperature']['cycle']['status'],
                        'crit_point': allData[1].data['temperature']['cycle'][cyclePredKeyGen(allData[1].data['temperature']['cycle']['status'])],
                        'annual_mean': allData[1].data['temperature']['annual_mean']['value'],
                        'mean': allData[1].data['temperature']['annual_mean']['fluctuation']['mean'],
                        'stdev': allData[1].data['temperature']['annual_mean']['fluctuation']['stdev'],
                        'skew': allData[1].data['temperature']['annual_mean']['fluctuation']['skew'],
                        'kurt': allData[1].data['temperature']['annual_mean']['fluctuation']['kurt'],
                    },
                };
                setWeatherObject(wobj)
                setIsRetrieved(true)
            })
        );

    };

    useEffect(() => {
        if(!isRetrieved && sensorDetails.locationName){
            retrieveStatsData()
        }

        return(
            setStationData({})
        )
    // eslint-disable-next-line
    }, [isRetrieved]);


    if(!sensorDetails.locationName) {
        return <Navigate replace to="/agroklimatologi"/>
    } else{
    return (
        <div className={styles.agroklimatologi_main_container}>
            <div className={styles.agroklimatologi_main_title}>
                <h1> TINJAUAN AGROKLIMATOLOGI {locationName.toUpperCase()}</h1>
            </div>

            <div className={styles.first_wrapper}>
                        <div className={styles.map_wrapper}>
                            <StationMap/>
                        </div>

                        <div className={styles.widget_1_container}>
                            <WeatherPanel/>
                        </div>
                        <div className={styles.widget_2_container}>
                            <WeatherCyclePanel/>
                        </div>
                        <div className={styles.widget_3_container}>
                            {isRetrieved && (
                                // <h2>TEST</h2>
                                <StatsPanel data={weatherObject} />
                            )}
                            {!isRetrieved && (
                                <div className={styles.loading_container}>

                                </div>
                            )}
                        </div>
                        <div className={styles.widget_4_container}>
                           <SoilPanel />
                        </div>
                    </div>

            <div className={styles.second_wrapper}>
                <div className={styles.graph_01_wrapper}>
                    <GraphPanel measurement={'temperature'} />
                </div>
                <div className={styles.graph_02_wrapper}>
                    <GraphPanel measurement={'relative_humidity'} />
                </div>
                <div className={styles.graph_03_wrapper}>
                    <GraphPanel measurement={'ambient_light'} />
                </div>
                <div className={styles.graph_1_wrapper}>
                    <GraphPanel measurement={'soil_temperature'} />
                </div>
                <div className={styles.graph_2_wrapper}>
                    <GraphPanel measurement={'soil_moisture'} />

                </div>
                <div className={styles.graph_3_wrapper}>
                    <GraphPanel measurement={'soil_conductivity'} />
                </div>
                <div className={styles.graph_4_wrapper}>
                    <GraphPanel measurement={'nitrogen'} />
                </div>
                <div className={styles.graph_5_wrapper}>
                    <GraphPanel measurement={'phosphorous'} />
                </div>
                <div className={styles.graph_6_wrapper}>
                    <GraphPanel measurement={'potassium'} />
                </div>
                <div className={styles.graph_7_wrapper}>
                    <GraphPanel measurement={'soil_ph'} />
                </div>
            </div>

            <div className={styles.download_button} onClick={()=>{handleSoilExport(sensorDetails.nodeID, sensorDetails.locationName)}}>
                Unduh Data Sensor {sensorDetails.locationName}
            </div>

        </div>
    )
}



}

export default Agroklimatologi;