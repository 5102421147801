export const retrieveTitle = (nl, n, com) => {

	let l;

	if(n === 'national'){
		l = 'nasional';
	} else if(n ==='province'){
		l = 'provinsi';
	} else{ l='kota';}

	const container = document.createElement('div');
	const comPrice = nl[com];
	const formattedPrice = new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(comPrice);

	container.innerHTML = `<p>Harga terakhir: <br> <b>${formattedPrice} </b> </p>`;
	container.style.cssText = 'font-family: "Raleway", sans-serif;' +
        'font-size: 14px;font-weight:bold' +
        'color: #10421D;';

	return container;
};