import styles from '../../styles/components/WeatherPanel.module.scss'

import {useRef} from 'react';
import WeatherTimeContent from './weatherTimeContent/weatherTimeContent';
import useStationData from "../../hooks/useStationData";


const WeatherPanel = () => {
    const {stationData} = useStationData();

    const panelRef = useRef();

    function clickRotate() {
        panelRef.current.classList.toggle(`${styles.rotated}`);
    }


    if(stationData.data === undefined){
        return(
            <div className={styles.weather_panel_unselected}>
                <h3>MOHON PILIH STASIUN CUACA PADA PETA</h3>
                <p>untuk menampilkan data prakiraan cuaca</p>
            </div>
        )
    } else {
        return(
            <div className={styles.weather_panel_container}>

                    <div className={styles.weather_panel} ref={panelRef} onClick={clickRotate}>
                        <div className={`${styles.panel_contents} ${styles.panel_today}`}>
                            <div className={styles.station_title}>
                                <h2> STASIUN CUACA {stationData.name.toUpperCase()} </h2>
                                <div className={styles.station_title_caption}>
                                    <p>Pilih stasiun cuaca pada peta untuk melihat informasi cuaca lainnya</p>
                                </div>
                                <h2> Prakiraan Cuaca Hari Ini </h2>
                            </div>
                            <div className={styles.panel_depth}>
                                <div className={styles.panel_info_container}>
                                <WeatherTimeContent data={stationData} time={'dini'} day={'today'} />
                                </div>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'pagi'} day={'today'} />
                                </div>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'siang'} day={'today'} />
                                </div>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'malam'} day={'today'} />
                                </div>
                            </div>
                            <div className={styles.station_caption}>
                                <p> Jarak stasiun cuaca {stationData.name} dengan sensor {stationData.distance} km</p>
                            </div>

                        </div>
                        {/*===========================================================================================*/}
                        <div className={`${styles.panel_contents} ${styles.panel_tomorrow}`}>
                            <div className={styles.station_title}>
                                <h2> STASIUN CUACA {stationData.name.toUpperCase()} </h2>
                                <div className={styles.station_title_caption}>
                                    <p>Pilih stasiun cuaca pada peta untuk melihat informasi cuaca lainnya</p>
                                </div>
                                <h2> Prakiraan Cuaca Besok </h2>
                            </div>
                            <div className={styles.panel_depth}>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'dini'} day={'tomorrow'} />
                                </div>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'pagi'} day={'tomorrow'} />
                                </div>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'siang'} day={'tomorrow'} />
                                </div>
                                <div className={styles.panel_info_container}>
                                    <WeatherTimeContent data={stationData} time={'malam'} day={'tomorrow'} />
                                </div>
                            </div>

                            <div className={styles.station_caption}>
                                <p> Jarak stasiun cuaca {stationData.name} dengan sensor {stationData.distance} km</p>
                            </div>

                        </div>
                    </div>

            </div>
        )
    }

}

export default WeatherPanel;