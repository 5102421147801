import * as React from 'react';

const CabaiRawit = ({ title, titleId, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		width="1em"
		height="1em"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M218 70.42c-.25 3.5-.6 6.99-1.17 10.45-.91 5.53-2.62 9.96-5.1 14.9-.54 1.04-1.1 2.08-1.73 3.08-3.45 5.81-8.05 10.9-13.09 15.4-12.48 11.17-27.71 19.24-43.09 25.67-8.91 3.72-18.98 8.22-28.71 9.28-18.25 1.98-24.8-18.64-19.87-31.25 1.41-3.58 3.74-6.51 7.01-8.09 14.73-7.14 41.01 1.95 67.54-9.24 15.9-6.68 26.55-20.24 35.76-34.43.31-.48.92-1.93 1.52-2.08 1.56-.37 1.02 5.14.92 6.32Z"
			style={{
				fill: '#4ba346',
			}}
		/>
		<path
			d="M210.01 98.84c-3.45 5.81-8.05 10.9-13.09 15.4-12.48 11.17-27.71 19.24-43.09 25.67-8.91 3.72-18.98 8.22-28.71 9.28-18.25 1.98-24.8-18.64-19.87-31.25l.04-.02c3.7-2.29 8.93-.19 11.22 3.52 2.27 3.7 2.23 8.39 1.39 12.65-.62 3.2-1.44 7.12 1.04 9.24 1.04.9 2.43 1.23 3.81 1.42 10.49 1.5 20.83-2.95 30.36-7.61 20.62-10.05 41.07-21.97 56.9-38.28Z"
			style={{
				fill: '#299346',
			}}
		/>
		<path
			d="M115.74 130.88c-.13.1-.25.19-.4.27-.6.35-1.27.6-1.85 1.02-1.12.83-1.67 2.27-1.71 3.68-.02.04-.02.1-.02.15-.02 1.46.44 2.85.9 4.24.17.52.33 1.16.14 1.66 0 .06-.02.13-.06.17-.04.09-.1.19-.19.25-.4.4-1.08.35-1.56.04-.48-.29-.77-.81-1.02-1.31-1.25-2.56-1.23-6.58-4.99-6.3-4.58.35-9.03 1.64-13.54 2.35-3.64.58-9.11.48-10.42-3.87-.46-1.52-.12-3.16.44-4.66.98-2.64 2.71-5.01 4.91-6.78 1.6-1.25 3.7.08 4.31 1.83.19.52.02.94-.29 1.29-.31.4-.79.73-1.16 1.1-.73.73-1.46 1.48-1.81 2.43-.35.96-.21 2.18.6 2.79.52.38 1.16.46 1.79.52 4.79.42 8.65-.92 13.11-2.44 1.85-.64 2.75-2.52 3.31-4.45.23-.75.4-1.52.56-2.21.15-.54.25-1.08.56-1.54.31-.44.89-.75 1.41-.56.75.25.85 1.23.9 2.02.06 1.71.46 3.64 1.89 4.58.94.62 2.12.69 3.23.83.58.06 1.25.19 1.58.67.52.69.06 1.71-.6 2.23Z"
			style={{
				fill: '#68a944',
			}}
		/>
		<path
			d="M197.87 93.4c-1.98 1.9-4.08 3.68-6.36 5.25-6.23 4.29-13.53 6.93-20.61 9.16-5 1.58-10.09 2.96-15.31 3.51-7.66.82-15.35-.12-23.02-.25-2.63-.04-20.77-.21-16.14 5.83 1.16 1.52 2.66 2.15 3.4 4.07.68 1.77.68 3.73 1.37 5.5 1.35 3.5 5.04 5.66 8.75 6.18 3.71.52 7.46-.35 11.07-1.36 11.68-3.29 22.84-8.18 33.76-13.48 5.77-2.8 11.57-5.78 16.19-10.23 7.91-7.61 11.52-18.66 18.51-27.12-3.78 4.38-7.44 8.94-11.61 12.95Z"
			style={{
				fill: '#74c044',
			}}
		/>
		<path
			d="M157.68 114.27c-4.56.38-9.13.23-13.7.16-2.34-.04-4.67-.06-7.01.02-2.01.07-4.29-.08-6.25.41-1.39.35-2.41.75-3.35 1.88-.45.53-.88 1.13-.97 1.82-.45 3.41 3.67 4.02 6.09 4.4 5.14.81 9.94.02 14.95-1.01 13.97-2.88 28.14-7.62 38.42-17.52a59.94 59.94 0 0 1-28.18 9.85Z"
			style={{
				fill: '#8ac64d',
			}}
		/>
		<path
			d="M111.78 135.86s-.02.1-.02.15c-.02 1.46.44 2.85.9 4.24.17.52.33 1.16.14 1.66 0 .06-.02.13-.06.17-.04.09-.1.19-.19.25-.4.4-1.08.35-1.56.04-.48-.29-.77-.81-1.02-1.31-1.25-2.56-1.23-6.58-4.99-6.3-4.58.35-9.03 1.64-13.54 2.35-3.64.58-9.11.48-10.42-3.87-.46-1.52-.12-3.16.44-4.66.98-2.64 2.71-5.01 4.91-6.78 1.6-1.25 3.7.08 4.31 1.83.19.52.02.94-.29 1.29l-.21-.06c-.42-.98-.96-2.06-1.98-2.29-1-.23-1.93.5-2.66 1.21-1.35 1.31-2.58 2.83-3.24 4.6-.59 1.56-.7 3.37-.07 4.93.59 1.46 2.21 2.27 3.69 2.52 1.37.23 2.77.07 4.14-.09 4.55-.53 9.03-1.45 13.57-2.14 1.52-.23 3.29-.35 4.43.69.69.62.87 1.37 1.77 1.79.19.08.37.17.56.15.37-.02.67-.35.79-.71.15-.35.15-.73.23-1.1.23-1.25 1.12-2.35 2.31-2.81.52-.19 1.1-.29 1.64-.44-.6.35-1.27.6-1.85 1.02-1.12.83-1.67 2.27-1.71 3.68Z"
			style={{
				fill: '#4f7435',
			}}
		/>
		<path
			d="M107.37 129.72c.5-.4 1.25-1.22.9-1.94-.32-.67-1.09-.41-1.53-.03-1.68 1.4-3.66 2.4-5.72 3.12-1.01.35-2.04.66-3.09.82-1.16.17-2.3.57-3.47.74-1.69.26-3.42.25-5.13.25-.38 0-.75 0-1.13-.04-.15-.01-.68-.19-.81-.13-.56.29.17.98.46 1.15.54.31 1.23.33 1.84.33 3.04-.02 6.1-.24 9.09-.79 2.71-.5 5.43-1.3 7.73-2.87.27-.18.58-.39.86-.61Z"
			style={{
				fill: '#a6cb6c',
			}}
		/>
		<path
			d="M38 119.21c.25 3.5.6 6.99 1.17 10.45.92 5.54 2.62 9.97 5.1 14.9.54 1.04 1.1 2.08 1.73 3.08 3.45 5.8 8.05 10.9 13.09 15.4 12.48 11.17 27.71 19.25 43.09 25.68 8.9 3.72 18.98 8.22 28.71 9.28 18.25 1.98 24.8-18.64 19.87-31.25-1.41-3.58-3.74-6.51-7.01-8.09-14.73-7.14-41.01 1.96-67.54-9.24-15.9-6.68-26.55-20.24-35.76-34.43-.31-.48-.92-1.93-1.52-2.08-1.56-.38-1.02 5.14-.92 6.32Z"
			style={{
				fill: '#4ba346',
			}}
		/>
		<path
			d="M45.99 147.63c3.45 5.8 8.05 10.9 13.09 15.4 12.48 11.17 27.71 19.25 43.09 25.68 8.9 3.72 18.98 8.22 28.71 9.28 18.25 1.98 24.8-18.64 19.87-31.25l-.04-.02c-3.7-2.29-8.93-.19-11.21 3.51-2.27 3.7-2.23 8.39-1.4 12.65.62 3.2 1.44 7.11-1.04 9.24-1.04.89-2.43 1.23-3.81 1.41-10.49 1.5-20.83-2.95-30.36-7.61-20.62-10.05-41.07-21.97-56.9-38.28Z"
			style={{
				fill: '#299346',
			}}
		/>
		<path
			d="M140.26 179.67c.13.11.25.19.4.27.6.35 1.27.6 1.85 1.02 1.12.83 1.66 2.27 1.71 3.68.02.04.02.11.02.15.02 1.46-.44 2.85-.89 4.24-.17.52-.33 1.17-.14 1.66 0 .06.02.12.06.17.04.09.1.19.19.25.4.4 1.08.35 1.56.04.48-.29.77-.81 1.02-1.31 1.25-2.56 1.23-6.57 4.99-6.3 4.58.35 9.03 1.64 13.54 2.35 3.64.58 9.11.48 10.42-3.87.46-1.52.12-3.16-.44-4.66-.98-2.64-2.71-5.01-4.91-6.78-1.6-1.25-3.7.08-4.31 1.83-.19.52-.02.94.29 1.29.31.4.79.73 1.16 1.1.73.73 1.46 1.48 1.81 2.44.35.96.21 2.18-.6 2.79-.52.38-1.16.46-1.79.52-4.78.42-8.65-.92-13.11-2.44-1.85-.64-2.75-2.52-3.31-4.45-.23-.75-.4-1.52-.56-2.2-.15-.54-.25-1.08-.56-1.54-.31-.44-.89-.75-1.41-.56-.75.25-.85 1.23-.9 2.02-.06 1.71-.46 3.64-1.89 4.58-.94.62-2.12.69-3.23.83-.58.06-1.25.19-1.58.67-.52.69-.06 1.71.6 2.23Z"
			style={{
				fill: '#68a944',
			}}
		/>
		<path
			d="M58.13 142.19c1.98 1.9 4.08 3.68 6.36 5.25 6.23 4.29 13.53 6.93 20.61 9.16 5 1.58 10.09 2.96 15.31 3.52 7.66.82 15.35-.12 23.02-.25 2.64-.04 20.77-.21 16.14 5.83-1.16 1.52-2.66 2.15-3.4 4.07-.68 1.77-.68 3.73-1.37 5.5-1.35 3.5-5.04 5.66-8.75 6.18-3.71.52-7.46-.35-11.07-1.37-11.68-3.29-22.84-8.18-33.76-13.48-5.77-2.8-11.57-5.78-16.19-10.23-7.91-7.61-11.52-18.66-18.51-27.12 3.78 4.38 7.44 8.95 11.61 12.95Z"
			style={{
				fill: '#74c044',
			}}
		/>
		<path
			d="M98.32 163.06c4.56.38 9.13.23 13.7.16 2.34-.04 4.67-.06 7.01.02 2.01.07 4.3-.08 6.25.41 1.39.35 2.41.75 3.35 1.88.45.53.88 1.13.97 1.82.45 3.41-3.67 4.02-6.09 4.4-5.14.81-9.94.02-14.95-1.01-13.97-2.88-28.14-7.63-38.41-17.52a59.94 59.94 0 0 0 28.18 9.85Z"
			style={{
				fill: '#8ac64d',
			}}
		/>
		<path
			d="M144.22 184.64s.02.11.02.15c.02 1.46-.44 2.85-.89 4.24-.17.52-.33 1.17-.14 1.66 0 .06.02.12.06.17.04.09.1.19.19.25.4.4 1.08.35 1.56.04.48-.29.77-.81 1.02-1.31 1.25-2.56 1.23-6.57 4.99-6.3 4.58.35 9.03 1.64 13.54 2.35 3.64.58 9.11.48 10.42-3.87.46-1.52.12-3.16-.44-4.66-.98-2.64-2.71-5.01-4.91-6.78-1.6-1.25-3.7.08-4.31 1.83-.19.52-.02.94.29 1.29l.21-.06c.42-.98.96-2.06 1.98-2.29 1-.23 1.93.5 2.66 1.21 1.35 1.31 2.58 2.83 3.25 4.6.59 1.56.7 3.37.07 4.93-.59 1.46-2.21 2.27-3.69 2.52-1.37.23-2.77.07-4.14-.09-4.55-.53-9.03-1.45-13.56-2.14-1.52-.23-3.29-.35-4.43.69-.69.62-.88 1.37-1.77 1.79-.19.08-.37.17-.56.15-.37-.02-.67-.35-.79-.71-.15-.35-.15-.73-.23-1.1-.23-1.25-1.12-2.35-2.31-2.81-.52-.19-1.1-.29-1.64-.44.6.35 1.27.6 1.85 1.02 1.12.83 1.66 2.27 1.71 3.68Z"
			style={{
				fill: '#4f7435',
			}}
		/>
		<path
			d="M148.63 178.5c-.5-.4-1.25-1.22-.9-1.94.32-.67 1.09-.41 1.53-.03 1.69 1.4 3.66 2.4 5.73 3.12 1.01.35 2.04.66 3.09.82 1.16.17 2.3.57 3.47.74 1.7.26 3.42.25 5.13.25.38 0 .75 0 1.13-.03.15-.01.68-.19.81-.13.56.29-.17.98-.46 1.15-.54.32-1.23.33-1.84.33-3.04-.02-6.1-.24-9.09-.78-2.71-.5-5.43-1.3-7.73-2.87-.27-.19-.58-.39-.86-.62Z"
			style={{
				fill: '#a6cb6c',
			}}
		/>
	</svg>
);

export default CabaiRawit;
