import React, {useEffect, useState} from 'react';
import useSensorDetails from "../../hooks/useSensorDetails";
import {axios6717} from "../../utils/axiosInstance";
import {defaultSoilData, measurementsDict} from "../../utils/dictionary";

import styles from '../../styles/components/SoilPanel.module.scss'
import SvgRichNutrition from "../../assets/icons/soilIcons/RichNutrition";

function SoilPanel() {
    const {sensorDetails} = useSensorDetails()

    const [soilInfo, setSoilInfo] = useState({});
    const [isRetrieved, setIsRetrieved] = useState(false);

    const retrieveSoilInfo = () => {
        const soilInfoURL = `/api/${sensorDetails.nodeID}/soil_info`

        // fetch(`https://iotsf.bfitech.co.id:6717/api/${sensorDetails.nodeID}/soil_info`, {
        //     method: 'GET',
        //     credentials: 'include',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        // })
        //     .then((response) => {
        //         console.log("===== SOIL RESPONSE")
        //         console.log(response)
        //     })
        //     .then((responseJson) => {
        //         console.log(responseJson);
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });


        axios6717.get(soilInfoURL).then((d) => {
            if(Object.keys(d.data).length === 0){
                setSoilInfo(defaultSoilData)
            } else {
                setSoilInfo(d.data)
            }
        }).catch((e) => {
            setSoilInfo(defaultSoilData)
            console.error(e)
        }).finally(()=>{
            setIsRetrieved(true)
        })
    }

    useEffect(() => {
        if(!isRetrieved){
            retrieveSoilInfo()
        }

    }, [isRetrieved]);

    const nitrogenLv = soilInfo['nitrogen'];
    const phosphorousLv = soilInfo['phosphorous'];
    const potassiumLv = soilInfo['potassium'];
    const conductivityLv = soilInfo['soil_conductivity'];
    const phLevel = soilInfo['soil_ph'];


    if(isRetrieved){
        return(
            <div className={styles.soil_panel_container}>
                <div className={styles.soil_panel_title}>
                    <h2>GAMBARAN UMUM TANAH</h2>
                </div>
                <div className={styles.soil_panel}>
                    <div className={styles.soil_panel_icon}>
                        <SvgRichNutrition height={'inherit'}/>
                    </div>

                    <div className={styles.soil_panel_details}>
                        <div className={styles.mineral_container}>
                            <table className={styles.mineral_table}>
                                <tbody>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.text_info}`}>Nitrogen</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.nitrogen_details} ${styles.mineral_value}`}>{nitrogenLv}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.text_info}`}>ppm</td>
                                </tr>
                                </tbody>
                            </table>

                            <table className={styles.mineral_table}>
                                <tbody>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.text_info}`}>Fosfat</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.phosphorous_details} ${styles.mineral_value}`}>{phosphorousLv}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.text_info}`}>ppm</td>
                                </tr>
                                </tbody>
                            </table>

                            <table className={styles.mineral_table}>
                                <tbody>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.text_info}`}>Kalium</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.potassium_details} ${styles.mineral_value}`}>{potassiumLv}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.mineral_table_cell} ${styles.text_info}`}>ppm</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className={styles.extra_information}>
                    <div className={styles.extra_information_table}>
                        <table className={styles.extra_information_table}>
                            <tbody>
                            <tr>
                                <td colSpan="2" className={styles.table_title}>Konduktivitas Tanah</td>
                                <td className={styles.table_value}>{conductivityLv} μS/cm</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className={styles.table_title}>pH Tanah</td>
                                <td className={styles.table_value}>{phLevel.toFixed(3)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }else{
        return(
            <div className={styles.loading_container}>
                <h2>PANEL KONDISI TANAH</h2>
            </div>
        )
    }
}

export default SoilPanel;