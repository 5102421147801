import * as React from 'react';

const HujanSedang = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 256 256"
		{...props}
	>
		<defs>
			<radialGradient
				id="hujanSedang_svg__a"
				cx={-95.62}
				cy={-786.74}
				fx={-95.62}
				fy={-786.74}
				r={22.23}
				gradientTransform="rotate(30 -1844.698 21.205)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#d0edfb" />
				<stop offset={0.2} stopColor="#d6effc" />
				<stop offset={1} stopColor="#8abae4" />
			</radialGradient>
			<radialGradient
				id="hujanSedang_svg__b"
				cx={-1269.97}
				cy={-2877.41}
				fx={-1269.97}
				fy={-2877.41}
				r={3.48}
				gradientTransform="matrix(-1.30678 .58072 -.7446 -1.94524 -3725.14 -4669)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="hujanSedang_svg__d"
				cx={-69.18}
				cy={-770.75}
				fx={-69.18}
				fy={-770.75}
				r={22.23}
				xlinkHref="#hujanSedang_svg__a"
			/>
			<radialGradient
				id="hujanSedang_svg__e"
				cx={-1272.94}
				cy={-2892.2}
				fx={-1272.94}
				fy={-2892.2}
				r={3.48}
				xlinkHref="#hujanSedang_svg__b"
			/>
			<radialGradient
				id="hujanSedang_svg__f"
				cx={7.58}
				cy={-846.33}
				fx={7.58}
				fy={-846.33}
				r={22.23}
				xlinkHref="#hujanSedang_svg__a"
			/>
			<radialGradient
				id="hujanSedang_svg__g"
				cx={-1348.08}
				cy={-2900.66}
				fx={-1348.08}
				fy={-2900.66}
				r={3.48}
				xlinkHref="#hujanSedang_svg__b"
			/>
			<radialGradient
				id="hujanSedang_svg__h"
				cx={-1.11}
				cy={-810.05}
				fx={-1.11}
				fy={-810.05}
				r={22.23}
				xlinkHref="#hujanSedang_svg__a"
			/>
			<radialGradient
				id="hujanSedang_svg__i"
				cx={-1324.46}
				cy={-2907.54}
				fx={-1324.46}
				fy={-2907.54}
				r={3.48}
				xlinkHref="#hujanSedang_svg__b"
			/>
			<radialGradient
				id="hujanSedang_svg__j"
				cx={158.58}
				cy={54.48}
				fx={158.58}
				fy={54.48}
				r={157.89}
				gradientTransform="matrix(-1 0 0 -.73 317.15 94.28)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#b5ccd2" />
				<stop offset={0.2} stopColor="#97b6be" />
				<stop offset={1} stopColor="#5d87b5" />
			</radialGradient>
			<radialGradient
				id="hujanSedang_svg__k"
				cx={-913.24}
				cy={-3213.13}
				fx={-913.24}
				fy={-3213.13}
				r={19.05}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -5818.71 -3100.36)"
				xlinkHref="#hujanSedang_svg__b"
			/>
			<radialGradient
				id="hujanSedang_svg__l"
				cx={-913.82}
				cy={-3248.32}
				fx={-913.82}
				fy={-3248.32}
				r={14.97}
				gradientTransform="matrix(-.84114 1.15645 -1.61769 -1.31205 -5818.71 -3100.36)"
				xlinkHref="#hujanSedang_svg__b"
			/>
			<radialGradient
				id="hujanSedang_svg__m"
				cx={53.55}
				cy={139.32}
				fx={53.55}
				fy={139.32}
				r={10.95}
				gradientTransform="rotate(-25.71 -529.83 272.414) scale(1 2.91)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={0.1} stopColor="#fff" stopOpacity={0.85} />
				<stop offset={0.29} stopColor="#fff" stopOpacity={0.59} />
				<stop offset={0.47} stopColor="#fff" stopOpacity={0.38} />
				<stop offset={0.63} stopColor="#fff" stopOpacity={0.22} />
				<stop offset={0.78} stopColor="#fff" stopOpacity={0.1} />
				<stop offset={0.91} stopColor="#fff" stopOpacity={0.03} />
				<stop offset={1} stopColor="#fff" stopOpacity={0} />
			</radialGradient>
			<linearGradient
				id="hujanSedang_svg__c"
				x1={122.94}
				y1={230.76}
				x2={148.83}
				y2={149.2}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.16} stopColor="#fee034" />
				<stop offset={1} stopColor="#f47621" />
			</linearGradient>
		</defs>
		<g
			style={{
				isolation: 'isolate',
			}}
		>
			<path
				d="M152.81 180.39h-9.8l16.16-31.41c.69-1.37 1.27-2.93 1.6-4.49 2.07-9.75-4.14-19.33-13.89-21.41-9.75-2.08-19.33 4.14-21.4 13.89l-10.8 50.74-.33 1.56c-.15.72-.22 1.46-.22 2.22 0 6.04 4.9 10.94 10.94 10.94h11.96l-6.45 26.91c-.23.97.12 2.02.98 2.65 1.14.82 2.73.57 3.55-.56l25.7-35.41a9.821 9.821 0 0 0 1.88-5.79c0-5.44-4.41-9.85-9.85-9.85Z"
				style={{
					fill: 'url(#hujanSedang_svg__c)',
				}}
			/>
			<path
				d="M86.4 198.17c-2.55 4.42-8.2 5.93-12.62 3.38s-5.93-8.2-3.38-12.62 18.33-13.27 18.33-13.27.22 18.09-2.33 22.51Z"
				style={{
					fill: 'url(#hujanSedang_svg__a)',
				}}
			/>
			<path
				d="M70.66 196.63c-1.43-3.74-.56-7.68 1.95-8.79 2.5-1.12 5.7 1.01 7.13 4.76 1.43 3.74.56 7.68-1.94 8.79-2.51 1.12-5.7-1.01-7.13-4.76Z"
				style={{
					fill: 'url(#hujanSedang_svg__b)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M101.29 225.24c-2.55 4.42-8.2 5.93-12.62 3.38s-5.93-8.2-3.38-12.62 18.33-13.27 18.33-13.27.22 18.09-2.33 22.51Z"
				style={{
					fill: 'url(#hujanSedang_svg__d)',
				}}
			/>
			<path
				d="M85.56 223.7c-1.43-3.74-.56-7.68 1.94-8.79 2.51-1.11 5.7 1.01 7.13 4.76 1.43 3.74.56 7.68-1.94 8.79-2.51 1.12-5.7-1.01-7.14-4.76Z"
				style={{
					fill: 'url(#hujanSedang_svg__e)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M205.56 198.17c-2.55 4.42-8.2 5.93-12.62 3.38s-5.94-8.2-3.38-12.62c2.55-4.42 18.33-13.27 18.33-13.27s.22 18.09-2.33 22.51Z"
				style={{
					fill: 'url(#hujanSedang_svg__f)',
				}}
			/>
			<path
				d="M189.82 196.63c-1.43-3.74-.56-7.68 1.94-8.79 2.51-1.12 5.7 1.01 7.13 4.76 1.43 3.74.56 7.68-1.94 8.79-2.51 1.12-5.7-1.01-7.13-4.76Z"
				style={{
					fill: 'url(#hujanSedang_svg__g)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M179.9 225.24c-2.55 4.42-8.2 5.93-12.62 3.38s-5.93-8.2-3.38-12.62 18.33-13.27 18.33-13.27.22 18.09-2.33 22.51Z"
				style={{
					fill: 'url(#hujanSedang_svg__h)',
				}}
			/>
			<path
				d="M164.17 223.7c-1.43-3.74-.56-7.68 1.94-8.79 2.51-1.11 5.7 1.01 7.14 4.76 1.43 3.74.56 7.68-1.94 8.79-2.51 1.12-5.7-1.01-7.14-4.76Z"
				style={{
					fill: 'url(#hujanSedang_svg__i)',
					mixBlendMode: 'overlay',
				}}
			/>
			<path
				d="M193.02 80.03c-2.16-31.57-28.43-56.51-60.55-56.51S74.08 48.47 71.93 80.03C48 81.21 28.97 100.98 28.97 125.2s20.25 45.23 45.22 45.23h116.56c24.97 0 45.22-20.25 45.22-45.23s-19.03-43.98-42.95-45.17Z"
				style={{
					fill: 'url(#hujanSedang_svg__j)',
				}}
			/>
			<path
				d="M132.48 85.66c-17.03-13.82-23.68-34.84-14.85-46.98 8.83-12.14 29.79-10.77 46.82 3.03 17.02 13.81 23.66 34.85 14.84 46.98-8.84 12.14-29.8 10.77-46.82-3.03Z"
				style={{
					fill: 'url(#hujanSedang_svg__k)',
					mixBlendMode: 'overlay',
					opacity: 0.6,
				}}
			/>
			<path
				d="M193.34 126.48c-13.38-10.85-18.61-27.38-11.67-36.92 6.94-9.54 23.41-8.46 36.8 2.38 13.38 10.85 18.6 27.39 11.67 36.92-6.94 9.54-23.42 8.47-36.79-2.38Z"
				style={{
					fill: 'url(#hujanSedang_svg__l)',
					mixBlendMode: 'overlay',
					opacity: 0.4,
				}}
			/>
			<path
				d="M61.31 128c-9.88-15.4-21.34-22.82-25.64-16.57-4.28 6.24.25 23.79 10.11 39.2 9.88 15.4 21.35 22.82 25.64 16.57 4.28-6.26-.24-23.8-10.11-39.21Z"
				style={{
					fill: 'url(#hujanSedang_svg__m)',
					mixBlendMode: 'overlay',
					opacity: 0.2,
				}}
			/>
		</g>
	</svg>
);

export default HujanSedang;
